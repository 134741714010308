import { NSCareerDropdownStyle } from "./CareerDropdown.style";
import { NSCareerDropdownType } from "./CareerDropdown.types";
import { SelectBox } from "../SelectBox/SelectBox";

export const CareerDropdown = ({
  onSelect,
}: NSCareerDropdownType.ICareerDropdown) => {
  const items = [
    { label: "Başvuru Tarihi (en yeni)", id: "1" },
    { label: "Başvuru Tarihi (en eski)", id: "2" },
    { label: "İsme Göre (A-Z)", id: "3" },
    { label: "İsme Göre (Z-A)", id: "4" },
  ];

  return (
    <NSCareerDropdownStyle.Container>
      <SelectBox onSelect={onSelect} items={items} placeholder="Sırala" />
    </NSCareerDropdownStyle.Container>
  );
};
