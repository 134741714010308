import { NSJobInfoCardContainerStyle } from "./JobInfoCardContainer.style";
import { NSJobInfoCardContainerType } from "./JobInfoCardContainer.types";

export const JobInfoCardContainer = ({
  isMobile = false,
  children,
}: NSJobInfoCardContainerType.IJobInfoCardContainer) => {
  return (
    <NSJobInfoCardContainerStyle.Container isMobile={isMobile}>
      {children}
    </NSJobInfoCardContainerStyle.Container>
  );
};
