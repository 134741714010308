import {
  ClassAttributes,
  HTMLAttributes,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from "react";
import { NSVideoPlayerCarouselStyle } from "../components/VideoPlayerCarousel/VideoPlayerCarousel.style";
import { ReactComponent as Add } from "../assets/icons/addLarge.svg";
import colors from "../style/colors";

const OverlayElement = (
  props: JSX.IntrinsicAttributes &
    ClassAttributes<HTMLDivElement> &
    HTMLAttributes<HTMLDivElement>,
  contentElement:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined
) => {
  const isMobile = window.outerWidth <= 768;

  return (
    <div {...props}>
      <NSVideoPlayerCarouselStyle.ModalCloseButton isMobile={isMobile}>
        <Add
          height={isMobile ? 24 : 32}
          width={isMobile ? 24 : 32}
          fill={colors.blue.blue01}
        />
      </NSVideoPlayerCarouselStyle.ModalCloseButton>
      {contentElement}
    </div>
  );
};

export default OverlayElement;
