import { NSStatsCardStyle } from "./StatsCard.style";
import { NSStatsCardType } from "./StatsCard.types";

export const StatsCard = ({
  isMobile,
  statsList,
}: NSStatsCardType.IStatsCard) => {
  const renderGridItems = () =>
    statsList.map((stat) => (
      <NSStatsCardStyle.GridItem key={stat.title} isMobile={isMobile}>
        <NSStatsCardStyle.IconOrb isMobile={isMobile}>
          {stat.icon}
        </NSStatsCardStyle.IconOrb>
        <NSStatsCardStyle.GridTextContainer>
          <NSStatsCardStyle.GridTitle isMobile={isMobile}>
            {stat.title}
          </NSStatsCardStyle.GridTitle>
          <NSStatsCardStyle.GridDescription isMobile={isMobile}>
            {stat.subTitle}
          </NSStatsCardStyle.GridDescription>
        </NSStatsCardStyle.GridTextContainer>
      </NSStatsCardStyle.GridItem>
    ));

  const renderGrid = () => {
    if (isMobile) {
      return (
        <NSStatsCardStyle.MobileGrid>
          {renderGridItems()}
        </NSStatsCardStyle.MobileGrid>
      );
    } else {
      return (
        <NSStatsCardStyle.DesktopGrid>
          {renderGridItems()}
        </NSStatsCardStyle.DesktopGrid>
      );
    }
  };

  return (
    <NSStatsCardStyle.Container isMobile={isMobile}>
      <NSStatsCardStyle.Title isMobile={isMobile}>
        Çalışma Modelimiz ve Demografik Detaylar
      </NSStatsCardStyle.Title>
      {renderGrid()}
      <NSStatsCardStyle.FooterText isMobile={isMobile}>
        *Görevi gereği uzaktan çalışabilen arkadaşlarımız, hafta 3 güne kadar
        belirledikleri günlerde uzaktan çalışabilirler.
      </NSStatsCardStyle.FooterText>
    </NSStatsCardStyle.Container>
  );
};
