import { Logo } from "../Logo/Logo";
import { ReactComponent as Hamburger } from "../../assets/icons/hamburger.svg";
import { NSHeaderMobileStyle } from "./HeaderMobile.style";
import { NSHeaderMobileType } from "./HeaderMobile.types";
import colors from "../../style/colors";

export const HeaderMobile = ({
  logoClicked,
  menuButtonClicked,
  isDark = false,
  backgroundColor = "transparent",
}: NSHeaderMobileType.IHeaderMobile) => {
  return (
    <NSHeaderMobileStyle.Container isDark={isDark} backgroundColor={backgroundColor}>
      <Logo isMobile={true} onClick={logoClicked} isDark={isDark} />
      <NSHeaderMobileStyle.HamburgerButton onClick={menuButtonClicked}>
        <Hamburger fill={isDark ? colors.dark.dark : colors.light.light} />
      </NSHeaderMobileStyle.HamburgerButton>
    </NSHeaderMobileStyle.Container>
  );
};
