import { NSBlockHeaderTitleStyle } from "./BlockHeaderTitle.style";
import { NSBlockHeaderTitleType } from "./BlockHeaderTitle.types";

export const BlockHeaderTitle = ({
  isMobile,
  title,
  backgroundColor,
  titleColor,
  onClick,
  fullWidth = true,
}: NSBlockHeaderTitleType.IBlockHeaderTitle) => {
  return (
    <NSBlockHeaderTitleStyle.Container
      fullWidth={fullWidth}
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      titleColor={titleColor}
      onClick={onClick}
      isClickable={!!onClick}
    >
      {title}
    </NSBlockHeaderTitleStyle.Container>
  );
};
