import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import { NSVideoPlayerCarouselStyle } from "./VideoPlayerCarousel.style";
import { NSVideoPlayerCarouselType } from "./VideoPlayerCarousel.types";
import { useRef, useState } from "react";
import { VideoPlayerCard } from "../VideoPlayerCard/VideoPlayerCard";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import "swiper/css";
import "swiper/css/scrollbar";
import ReactPlayer from "react-player";
import { NSVideoPlayerCardType } from "../VideoPlayerCard/VideoPlayerCard.types";
import colors from "../../style/colors";
import { Modal } from "../Modal/Modal";
import { NSImageCarouselStyle } from "../ImageCarousel/ImageCarousel.style";
import { MobileSwiperHelper } from "../MobileSwiperHelper/MobileSwiperHelper";

export const VideoPlayerCarousel = ({
  isMobile,
  imageList,
}: NSVideoPlayerCarouselType.IVideoPlayerCarousel) => {
  const sliderRef = useRef<SwiperRef>(null);

  const playerRef = useRef<ReactPlayer | null>(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] =
    useState<NSVideoPlayerCardType.IVideoPlayerCard>(imageList[0]);
  const currentIndex = imageList.indexOf(selectedVideo);
  const onClickHandler = (
    selectedVideo: NSVideoPlayerCardType.IVideoPlayerCard
  ) => {
    setIsOpen(true);
    setSelectedVideo(selectedVideo);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const nextVideoHandler = () => {
    if (currentIndex !== imageList.length - 1) {
      setSelectedVideo(imageList[currentIndex + 1]);
    }
  };
  const previousVideoHandler = () => {
    if (currentIndex !== 0) {
      setSelectedVideo(imageList[currentIndex + -1]);
    }
  };

  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  const desktopViewRender = () => (
    <NSVideoPlayerCarouselStyle.DesktopContainer>
      <NSImageCarouselStyle.NavigationButton onClick={handlePrev}>
        <ArrowLeft fill={colors.dark.dark} />
      </NSImageCarouselStyle.NavigationButton>

      <Swiper
        modules={[Navigation, Scrollbar]}
        spaceBetween={32}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        ref={sliderRef}
      >
        {imageList.map((image) => (
          <SwiperSlide key={image.imageUrl}>
            <VideoPlayerCard
              {...image}
              isMobile={false}
              onClick={() => onClickHandler(image)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <NSImageCarouselStyle.NavigationButton onClick={handleNext}>
        <ArrowRight fill={colors.dark.dark} />
      </NSImageCarouselStyle.NavigationButton>
    </NSVideoPlayerCarouselStyle.DesktopContainer>
  );

  const mobileViewRender = () => (
    <NSVideoPlayerCarouselStyle.MobileSwiperContainer>
      <MobileSwiperHelper
        childrenList={imageList.map((image) => (
          <VideoPlayerCard
            {...image}
            isMobile={true}
            key={image.imageUrl}
            onClick={() => onClickHandler(image)}
          />
        ))}
      />
    </NSVideoPlayerCarouselStyle.MobileSwiperContainer>
  );

  return (
    <>
      {isMobile ? mobileViewRender() : desktopViewRender()}
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <NSVideoPlayerCarouselStyle.ModalContentContainer isMobile={isMobile}>
          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousVideoHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}
          <ReactPlayer
            ref={playerRef}
            url={selectedVideo?.videoUrl}
            controls={true}
            width={isMobile ? "100%" : "60vw"}
            height={"auto"}
          />

          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextVideoHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}
        </NSVideoPlayerCarouselStyle.ModalContentContainer>
        {isMobile && (
          <NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousVideoHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextVideoHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          </NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
        )}
      </Modal>
    </>
  );
};
