import Modal from "react-modal";
import { NSDrawerStyle } from "./Drawer.style";
import { NSDrawerType } from "./Drawer.types";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import {
  desktopStylesDrawer,
  mobileStylesDrawer,
} from "../../util/modalStyles";
import colors from "../../style/colors";

export const Drawer = ({
  isMobile,
  children,
  isOpen,
  onCloseHandler,
}: NSDrawerType.IDrawer) => {
  function afterOpenModal() {
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    document.body.style.overflow = "unset";
    onCloseHandler();
  }

  return (
    <Modal
      isOpen={isOpen}
      style={isMobile ? mobileStylesDrawer : desktopStylesDrawer}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      onAfterOpen={afterOpenModal}
    >
      <NSDrawerStyle.Container isMobile={isMobile}>
        {children}
        <NSDrawerStyle.CloseButton onClick={closeModal} isMobile={isMobile}>
          <Add
            height={isMobile ? 24 : 32}
            width={isMobile ? 24 : 32}
            fill={colors.yellow.yellow01}
          />
        </NSDrawerStyle.CloseButton>
      </NSDrawerStyle.Container>
    </Modal>
  );
};
