import { FooterBar } from "../FooterBar/FooterBar";
import { NSFooterStyle } from "./Footer.style";
import { NSFooterType } from "./Footer.types";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Anlatsin } from "../../assets/icons/anlatsin.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { ReactComponent as LocationPin } from "../../assets/icons/locationPin.svg";

import { TopMenuNavigationDesktop } from "../TopMenuNavigationDesktop/TopMenuNavigationDesktop";

export const Footer = ({
  isMobile,
  footerBar,
  socialLinks,
  mainNavigationLinks,
  careerLinks,
  address,
}: NSFooterType.IFooter) => {
  const singleSocialLinkRenderer = {
    facebook: (
      <Facebook height={isMobile ? 24 : 32} width={isMobile ? 24 : 32} />
    ),
    instagram: (
      <Instagram height={isMobile ? 24 : 32} width={isMobile ? 24 : 32} />
    ),
    linkedin: (
      <Linkedin height={isMobile ? 24 : 32} width={isMobile ? 24 : 32} />
    ),
    youtube: <Youtube height={isMobile ? 24 : 32} width={isMobile ? 24 : 32} />,
    anlatsin: (
      <Anlatsin height={isMobile ? 24 : 32} width={isMobile ? 84 : 118} />
    ),
  };

  const renderSocialLinks = () => {
    return (
      <NSFooterStyle.SocialLinksContainer isMobile={isMobile}>
        {socialLinks.map((link) => (
          <NSFooterStyle.SocialLinkItem
            key={link.name}
            href={link.url}
            target="_blank"
          >
            {singleSocialLinkRenderer[link.name]}
          </NSFooterStyle.SocialLinkItem>
        ))}
      </NSFooterStyle.SocialLinksContainer>
    );
  };

  const renderCareerLinks = () => {
    return (
      <NSFooterStyle.CareerLinkContainer isMobile={isMobile}>
        {careerLinks.map((link) => (
          <NSFooterStyle.CareerLink
            isMobile={isMobile}
            href={link.href}
            key={link.href}
            target="_blank"
          >
            {link.label}
          </NSFooterStyle.CareerLink>
        ))}
      </NSFooterStyle.CareerLinkContainer>
    );
  };

  return (
    <NSFooterStyle.Container>
      <NSFooterStyle.InnerContainer isMobile={isMobile}>
        <NSFooterStyle.LeftContainer isMobile={isMobile}>
          <NSFooterStyle.Title isMobile={isMobile}>
            <Heart height={32} width={32} />
            Takipte Kal
          </NSFooterStyle.Title>
          <NSFooterStyle.Description isMobile={isMobile}>
            Sosyal medya hesaplarımızı takip ederek güncel paylaşımlarımıza
            ulaşabilirsin.
          </NSFooterStyle.Description>
          {renderSocialLinks()}
        </NSFooterStyle.LeftContainer>
        <NSFooterStyle.RightContainer isMobile={isMobile}>
          <TopMenuNavigationDesktop
            isDark={true}
            anchorList={mainNavigationLinks}
            target="_blank"
          />
          <NSFooterStyle.DescriptionDark isMobile={isMobile}>
            Kariyer fırsatlarımıza anlaşmalı platformlarımızdan da
            ulaşabilirsin.
          </NSFooterStyle.DescriptionDark>
          {renderCareerLinks()}
          <NSFooterStyle.AddressContainer>
            <LocationPin
              height={isMobile ? 42 : 24}
              width={isMobile ? 42 : 24}
            />
            <NSFooterStyle.AddressText isMobile={isMobile}>
              {address}
            </NSFooterStyle.AddressText>
          </NSFooterStyle.AddressContainer>
        </NSFooterStyle.RightContainer>
      </NSFooterStyle.InnerContainer>
      <FooterBar {...footerBar} isMobile={isMobile} />
    </NSFooterStyle.Container>
  );
};
