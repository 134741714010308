import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSIconDescriptionListStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    font-family: "ReadexPro";
    svg {
      height: ${({ isMobile }) => (isMobile ? "40px" : "80px")};
      width: ${({ isMobile }) => (isMobile ? "40px" : "80px")};
    }
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    margin-bottom: 40px;
  `;

  export const StatsContainerDesktop = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 40px;
    justify-content: space-between;
  `;
  export const StatsContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;
  export const StatsCard = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ isMobile }) => (isMobile ? "4px" : "24px")};
  `;
  export const OrbIconContainer = styled.div<{ isMobile: boolean }>`
    height: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    width: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    border-radius: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    background-color: ${colors.light.light};
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const ImageWithoutOrb = styled.img`
    max-height: 56px;
    object-fit: contain;
    object-position: left;
  `;
  export const StatTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `;
  export const StatNumber = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph2.size : typography.pharagraph1_5.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.pharagraph2.lineHeight
        : typography.pharagraph1_5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
  `;
  export const StatName = styled.div<{
    isMobile: boolean;
    expanded?: boolean;
  }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph2.size : typography.pharagraph1_5.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.pharagraph2.lineHeight
        : typography.pharagraph1_5.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (!props.expanded && props.isMobile ?"2":"unset")};
    -webkit-box-orient: vertical;
  `;
  export const ShowMoreButton = styled.button<{
    expanded?: boolean;
  }>`
    background: none;
    border: none;
    text-align: left;
    color: ${colors.dark.dark}; // Customize the color as needed
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    margin-top: -8px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    svg {
      transition: all 0.5s;
      ${({ expanded }) =>
        expanded
          ? css`
              transform: rotateX(180deg);
            `
          : css`
              transform: rotateX(0deg);
            `}
    }
  `;

  export const MobileMainContainer = styled.div``;
  export const MobileGridContainer = styled.div`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    display: grid;
    gap: 16px;
  `;
}
