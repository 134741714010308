import { Logo } from "../Logo/Logo";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import { NSMenuMobileStyle } from "./MenuMobile.style";
import { NSMenuMobileType } from "./MenuMobile.types";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Anlatsin } from "../../assets/icons/anlatsin.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import colors from "../../style/colors";

export const MenuMobile = ({
  onCloseClick,
  anchorList,
  socialLinks,
  onItemClicked,
}: NSMenuMobileType.IMenuMobile) => {
  const singleSocialLinkRenderer = {
    facebook: <Facebook height={24} width={24} />,
    instagram: <Instagram height={24} width={24} />,
    linkedin: <Linkedin height={24} width={24} />,
    youtube: <Youtube height={24} width={24} />,
    anlatsin: <Anlatsin height={24} width={84} />,
  };

  const renderAnchors = () =>
    anchorList.map((anchor) => (
      <NSMenuMobileStyle.MenuAnchor
        key={anchor.href}
        onClick={() => {
          onItemClicked();
          setTimeout(() => {
            window.open(anchor.href, "_self");
          }, 500);
        }}
      >
        {anchor.label}
      </NSMenuMobileStyle.MenuAnchor>
    ));

  const renderSocialLinks = () => {
    return (
      <NSMenuMobileStyle.SocialLinksContainer>
        {socialLinks.map((link) => (
          <NSMenuMobileStyle.SocialLinkItem key={link.name} href={link.url}>
            {singleSocialLinkRenderer[link.name]}
          </NSMenuMobileStyle.SocialLinkItem>
        ))}
      </NSMenuMobileStyle.SocialLinksContainer>
    );
  };

  return (
    <NSMenuMobileStyle.Container>
      <NSMenuMobileStyle.Header>
        <Logo isDark={false} isMobile={true} onClick={onCloseClick} />
        <NSMenuMobileStyle.CloseButton onClick={onCloseClick}>
          <Add height={32} width={32} fill={colors.light.light} />
        </NSMenuMobileStyle.CloseButton>
      </NSMenuMobileStyle.Header>
      <NSMenuMobileStyle.Content>{renderAnchors()}</NSMenuMobileStyle.Content>
      <NSMenuMobileStyle.Footer>
        <NSMenuMobileStyle.FooterHeader>
          <Heart /> Takipte Kal
        </NSMenuMobileStyle.FooterHeader>
        {renderSocialLinks()}
      </NSMenuMobileStyle.Footer>
    </NSMenuMobileStyle.Container>
  );
};
