import useDeviceType from "../../../util/isMobile";
import AccountPageDesktop from "./DesktopView/AccountPageDesktop";
import AccountPageMobile from "./MobileView/AccountPageMobile";
import { NSAccountPageType } from "./AccountPage.types";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const accountData: NSAccountPageType.IAccountPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
};

function AccountPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <AccountPageMobile accountData={accountData} />;
  } else {
    return <AccountPageDesktop accountData={accountData} />;
  }
}

export default AccountPage;
