import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSStatsCardStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${({ isMobile }) => (isMobile ? "32px" : "56px 64px")};
    gap: ${({ isMobile }) => (isMobile ? "32px" : "56px")};
    background-color: ${colors.orange.orange01};
    border-radius: 32px;
    font-family: "ReadexPro";
  `;
  export const Title = styled.div<{
    isMobile: boolean;
  }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    text-align: center;
  `;

  export const DesktopGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 40px;
    justify-content: space-between;
  `;
  export const MobileGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 40px;
    justify-content: space-between;
  `;
  export const GridItem = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  `;

  export const IconOrb = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    min-width: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    height: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    min-height: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    border-radius: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    background-color: ${colors.light.light};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const GridTextContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const GridTitle = styled.div<{ isMobile: boolean }>`
    text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
    font-weight: ${typography.fontWeights.medium};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText1.size : typography.pharagraph1.size};
  `;
  export const GridDescription = styled.div<{ isMobile: boolean }>`
    text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.pharagraph2.size};
  `;

  export const FooterText = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.preText.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.preText.lineHeight
        : typography.smallText1.lineHeight};
    text-align: left;
    ${({ isMobile }) =>
      !isMobile &&
      css`
        max-width: 60%;
        text-align: center;
      `}
  `;
}
