import { NSSelectBoxType } from "../components/SelectBox/SelectBox.types";

export const genderArray: NSSelectBoxType.ISelectBoxItem[] = [
  {
    id: "female",
    label: "Kadın",
  },
  {
    id: "male",
    label: "Erkek",
  },
];

export const cities: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "adana", label: "Adana" },
  { id: "adiyaman", label: "Adıyaman" },
  { id: "afyonkarahisar", label: "Afyonkarahisar" },
  { id: "agri", label: "Ağrı" },
  { id: "aksaray", label: "Aksaray" },
  { id: "amasya", label: "Amasya" },
  { id: "ankara", label: "Ankara" },
  { id: "antalya", label: "Antalya" },
  { id: "ardahan", label: "Ardahan" },
  { id: "artvin", label: "Artvin" },
  { id: "aydin", label: "Aydın" },
  { id: "balikesir", label: "Balıkesir" },
  { id: "bartin", label: "Bartın" },
  { id: "batman", label: "Batman" },
  { id: "bayburt", label: "Bayburt" },
  { id: "bilecik", label: "Bilecik" },
  { id: "bingol", label: "Bingöl" },
  { id: "bitlis", label: "Bitlis" },
  { id: "bolu", label: "Bolu" },
  { id: "burdur", label: "Burdur" },
  { id: "bursa", label: "Bursa" },
  { id: "canakkale", label: "Çanakkale" },
  { id: "cankiri", label: "Çankırı" },
  { id: "corum", label: "Çorum" },
  { id: "denizli", label: "Denizli" },
  { id: "diyarbakir", label: "Diyarbakır" },
  { id: "duzce", label: "Düzce" },
  { id: "edirne", label: "Edirne" },
  { id: "elazig", label: "Elazığ" },
  { id: "erzincan", label: "Erzincan" },
  { id: "erzurum", label: "Erzurum" },
  { id: "eskisehir", label: "Eskişehir" },
  { id: "gaziantep", label: "Gaziantep" },
  { id: "giresun", label: "Giresun" },
  { id: "gumushane", label: "Gümüşhane" },
  { id: "hakkari", label: "Hakkari" },
  { id: "hatay", label: "Hatay" },
  { id: "igdir", label: "Iğdır" },
  { id: "isparta", label: "Isparta" },
  { id: "istanbul", label: "İstanbul" },
  { id: "izmir", label: "İzmir" },
  { id: "kahramanmaras", label: "Kahramanmaraş" },
  { id: "karabuk", label: "Karabük" },
  { id: "karaman", label: "Karaman" },
  { id: "kars", label: "Kars" },
  { id: "kastamonu", label: "Kastamonu" },
  { id: "kayseri", label: "Kayseri" },
  { id: "kirikkale", label: "Kırıkkale" },
  { id: "kirklareli", label: "Kırklareli" },
  { id: "kirsehir", label: "Kırşehir" },
  { id: "kilis", label: "Kilis" },
  { id: "kocaeli", label: "Kocaeli" },
  { id: "konya", label: "Konya" },
  { id: "kutahya", label: "Kütahya" },
  { id: "malatya", label: "Malatya" },
  { id: "manisa", label: "Manisa" },
  { id: "mardin", label: "Mardin" },
  { id: "mersin", label: "Mersin" },
  { id: "mugla", label: "Muğla" },
  { id: "mus", label: "Muş" },
  { id: "nevsehir", label: "Nevşehir" },
  { id: "nigde", label: "Niğde" },
  { id: "ordu", label: "Ordu" },
  { id: "osmaniye", label: "Osmaniye" },
  { id: "rize", label: "Rize" },
  { id: "sakarya", label: "Sakarya" },
  { id: "samsun", label: "Samsun" },
  { id: "siirt", label: "Siirt" },
  { id: "sinop", label: "Sinop" },
  { id: "sivas", label: "Sivas" },
  { id: "sanliurfa", label: "Şanlıurfa" },
  { id: "sirnak", label: "Şırnak" },
  { id: "tekirdag", label: "Tekirdağ" },
  { id: "tokat", label: "Tokat" },
  { id: "trabzon", label: "Trabzon" },
  { id: "tunceli", label: "Tunceli" },
  { id: "usak", label: "Uşak" },
  { id: "van", label: "Van" },
  { id: "yalova", label: "Yalova" },
  { id: "yozgat", label: "Yozgat" },
  { id: "zonguldak", label: "Zonguldak" },
];

export const countryAndCities: NSSelectBoxType.ISelectBoxItem[] = [
  {
    id: "other",
    label: "Diğer",
  },
  {
    id: "kktc",
    label: "KKTC",
  },
  ...cities,
];

export const nationalities: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "turkish", label: "Türk" },
  { id: "afghan", label: "Afgan" },
  { id: "albanian", label: "Arnavut" },
  { id: "algerian", label: "Cezayirli" },
  { id: "american", label: "Amerikalı" },
  { id: "andorran", label: "Andorralı" },
  { id: "angolan", label: "Angolalı" },
  { id: "antiguans", label: "Antiguve Barbudalı" },
  { id: "argentine", label: "Arjantinli" },
  { id: "armenian", label: "Ermeni" },
  { id: "australian", label: "Avustralyalı" },
  { id: "austrian", label: "Avusturyalı" },
  { id: "azerbaijani", label: "Azerbaycanlı" },
  { id: "bahamian", label: "Bahamalı" },
  { id: "bahraini", label: "Bahreynli" },
  { id: "bangladeshi", label: "Bangladeşli" },
  { id: "barbadian", label: "Barbadoslu" },
  { id: "barbudans", label: "Barbudalı" },
  { id: "batswana", label: "Batsvanalı" },
  { id: "belarusian", label: "Belaruslu" },
  { id: "belgian", label: "Belçikalı" },
  { id: "belizean", label: "Belize'li" },
  { id: "beninese", label: "Beninli" },
  { id: "bhutanese", label: "Bhutanlı" },
  { id: "bolivian", label: "Bolivyalı" },
  { id: "bosnian", label: "Bosnalı" },
  { id: "brazilian", label: "Brezilyalı" },
  { id: "british", label: "Britanyalı" },
  { id: "bruneian", label: "Bruneili" },
  { id: "bulgarian", label: "Bulgar" },
  { id: "burkinabe", label: "Burkina Fasolu" },
  { id: "burmese", label: "Burmalı" },
  { id: "burundian", label: "Burundili" },
  { id: "cambodian", label: "Kamboçyalı" },
  { id: "cameroonian", label: "Kamerunlu" },
  { id: "canadian", label: "Kanadalı" },
  { id: "capeverdean", label: "Cape Verdeli" },
  { id: "centralafrican", label: "Orta Afrikalı" },
  { id: "chadian", label: "Çadlı" },
  { id: "chilean", label: "Şilili" },
  { id: "chinese", label: "Çinli" },
  { id: "colombian", label: "Kolombiyalı" },
  { id: "comoran", label: "Komorlu" },
  { id: "congolese", label: "Kongo'lu" },
  { id: "costa rican", label: "Kosta Rikalı" },
  { id: "croatian", label: "Hırvat" },
  { id: "cuban", label: "Kübalı" },
  { id: "cypriot", label: "Kıbrıslı" },
  { id: "czech", label: "Çek" },
  { id: "danish", label: "Danimarkalı" },
  { id: "djibouti", label: "Cibutili" },
  { id: "dominican", label: "Dominikli" },
  { id: "dutch", label: "Hollandalı" },
  { id: "east timorese", label: "Doğu Timorlu" },
  { id: "ecuadorean", label: "Ekvadorlu" },
  { id: "egyptian", label: "Mısırlı" },
  { id: "emirian", label: "Emirli" },
  { id: "equatorial guinean", label: "Ekvator Ginesi'li" },
  { id: "eritrean", label: "Eritreli" },
  { id: "estonian", label: "Estonyalı" },
  { id: "ethiopian", label: "Etiyopyalı" },
  { id: "fijian", label: "Fijili" },
  { id: "filipino", label: "Filipinli" },
  { id: "finnish", label: "Fin" },
  { id: "french", label: "Fransız" },
  { id: "gabonese", label: "Gabonlu" },
  { id: "gambian", label: "Gambiyalı" },
  { id: "georgian", label: "Gürcü" },
  { id: "german", label: "Alman" },
  { id: "ghanaian", label: "Ganalı" },
  { id: "greek", label: "Yunanlı" },
  { id: "grenadian", label: "Grenadalı" },
  { id: "guatemalan", label: "Guatemalalı" },
  { id: "guinea-bissauan", label: "Gine-Bissau'lu" },
  { id: "guinean", label: "Gineli" },
  { id: "guyanese", label: "Guyanalı" },
  { id: "haitian", label: "Haitili" },
  { id: "herzegovinian", label: "Hersekli" },
  { id: "honduran", label: "Honduranslı" },
  { id: "hungarian", label: "Macar" },
  { id: "icelander", label: "İzlandalı" },
  { id: "indian", label: "Hintli" },
  { id: "indonesian", label: "Endonezyalı" },
  { id: "iranian", label: "İranlı" },
  { id: "iraqi", label: "Iraklı" },
  { id: "irish", label: "İrlandalı" },
  { id: "israeli", label: "İsrailli" },
  { id: "italian", label: "İtalyan" },
  { id: "ivorian", label: "Fildişi Sahilli" },
  { id: "jamaican", label: "Jamaikalı" },
  { id: "japanese", label: "Japon" },
  { id: "jordanian", label: "Ürdünlü" },
  { id: "kazakhstani", label: "Kazak" },
  { id: "kenyan", label: "Kenyalı" },
  { id: "kittian and nevisian", label: "Kittis ve Nevislili" },
  { id: "kuwaiti", label: "Kuveytli" },
  { id: "kyrgyz", label: "Kırgız" },
  { id: "laotian", label: "Laolu" },
  { id: "latvian", label: "Leton" },
  { id: "lebanese", label: "Lübnanlı" },
  { id: "liberian", label: "Liberyalı" },
  { id: "libyan", label: "Libyalı" },
  { id: "liechtensteiner", label: "Lihtenştaynlı" },
  { id: "lithuanian", label: "Litvanyalı" },
  { id: "luxembourger", label: "Lüksemburglu" },
  { id: "macedonian", label: "Makedon" },
  { id: "malagasy", label: "Madagaskarlı" },
  { id: "malawian", label: "Malavili" },
  { id: "malaysian", label: "Malezyalı" },
  { id: "maldivan", label: "Maldivli" },
  { id: "malian", label: "Mali" },
  { id: "maltese", label: "Maltalı" },
  { id: "marshallese", label: "Marshall Adalı" },
  { id: "mauritanian", label: "Moritanyalı" },
  { id: "mauritian", label: "Mauritiuslu" },
  { id: "mexican", label: "Meksikalı" },
  { id: "micronesian", label: "Mikronezyalı" },
  { id: "moldovan", label: "Moldovalı" },
  { id: "monacan", label: "Monakolu" },
  { id: "mongolian", label: "Moğol" },
  { id: "moroccan", label: "Faslı" },
  { id: "mosotho", label: "Lesotholu" },
  { id: "motswana", label: "Botsvanalı" },
  { id: "mozambican", label: "Mozambikli" },
  { id: "namibian", label: "Namibyalı" },
  { id: "nauruan", label: "Naurulu" },
  { id: "nepalese", label: "Nepalli" },
  { id: "new zealander", label: "Yeni Zelandalı" },
  { id: "nicaraguan", label: "Nikaragualı" },
  { id: "nigerian", label: "Nijeryalı" },
  { id: "nigerien", label: "Nijerli" },
  { id: "north korean", label: "Kuzey Koreli" },
  { id: "northern irish", label: "Kuzey İrlandalı" },
  { id: "norwegian", label: "Norveçli" },
  { id: "omani", label: "Ummanlı" },
  { id: "pakistani", label: "Pakistanlı" },
  { id: "palauan", label: "Palaulu" },
  { id: "panamanian", label: "Panamalı" },
  { id: "papua new guinean", label: "Papua Yeni Gine'li" },
  { id: "paraguayan", label: "Paraguaylı" },
  { id: "peruvian", label: "Perulu" },
  { id: "polish", label: "Polonyalı" },
  { id: "portuguese", label: "Portekizli" },
  { id: "qatari", label: "Katarlı" },
  { id: "romanian", label: "Rumen" },
  { id: "russian", label: "Rus" },
  { id: "rwandan", label: "Ruandalı" },
  { id: "saint lucian", label: "Saint Lucian" },
  { id: "salvadoran", label: "Salvadorlu" },
  { id: "samoan", label: "Samoalı" },
  { id: "san marinese", label: "San Marinolu" },
  { id: "sao tomean", label: "Sao Tome'li" },
  { id: "saudi", label: "Suudi" },
  { id: "scottish", label: "İskoçyalı" },
  { id: "senegalese", label: "Senegalli" },
  { id: "serbian", label: "Sırp" },
  { id: "seychellois", label: "Seyşelliler" },
  { id: "sierra leonean", label: "Sierra Leoneli" },
  { id: "singaporean", label: "Singapurlu" },
  { id: "slovakian", label: "Slovakyalı" },
  { id: "slovenian", label: "Slovenyalı" },
  { id: "solomon islander", label: "Solomon Adalı" },
  { id: "somali", label: "Somalili" },
  { id: "south african", label: "Güney Afrikalı" },
  { id: "south korean", label: "Güney Koreli" },
  { id: "spanish", label: "İspanyol" },
  { id: "sri lankan", label: "Sri Lankalı" },
  { id: "sudanese", label: "Sudanlı" },
  { id: "surinamer", label: "Surinamlı" },
  { id: "swazi", label: "Svazilandalı" },
  { id: "swedish", label: "İsveçli" },
  { id: "swiss", label: "İsviçreli" },
  { id: "syrian", label: "Suriyeli" },
  { id: "taiwanese", label: "Tayvanlı" },
  { id: "tajik", label: "Tacik" },
  { id: "tanzanian", label: "Tanzanyalı" },
  { id: "thai", label: "Taylandlı" },
  { id: "togolese", label: "Togolu" },
  { id: "tongan", label: "Tongalı" },
  { id: "trinidadian or tobagonian", label: "Trinidadli veya Tobagolu" },
  { id: "tunisian", label: "Tunuslu" },
  { id: "tuvaluan", label: "Tuvalu'lu" },
  { id: "ugandan", label: "Ugandalı" },
  { id: "ukrainian", label: "Ukraynalı" },
  { id: "uruguayan", label: "Uruguaylı" },
  { id: "uzbekistani", label: "Özbek" },
  { id: "venezuelan", label: "Venezuelalı" },
  { id: "vietnamese", label: "Vietnamlı" },
  { id: "welsh", label: "Galce" },
  { id: "yemenite", label: "Yemenli" },
  { id: "zambian", label: "Zambiyalı" },
  { id: "zimbabwean", label: "Zimbabveli" },
];

export const areaCodes: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "90", label: "+90" },
  { id: "93", label: "+93" },
  { id: "355", label: "+355" },
  { id: "213", label: "+213" },
  { id: "1-684", label: "+1-684" },
  { id: "376", label: "+376" },
  { id: "244", label: "+244" },
  { id: "1-268", label: "+1-268" },
  { id: "54", label: "+54" },
  { id: "374", label: "+374" },
  { id: "297", label: "+297" },
  { id: "61", label: "+61" },
  { id: "43", label: "+43" },
  { id: "994", label: "+994" },
  { id: "1-242", label: "+1-242" },
  { id: "973", label: "+973" },
  { id: "880", label: "+880" },
  { id: "1-246", label: "+1-246" },
  { id: "375", label: "+375" },
  { id: "32", label: "+32" },
  { id: "501", label: "+501" },
  { id: "229", label: "+229" },
  { id: "975", label: "+975" },
  { id: "591", label: "+591" },
  { id: "387", label: "+387" },
  { id: "267", label: "+267" },
  { id: "55", label: "+55" },
  { id: "246", label: "+246" },
  { id: "1-284", label: "+1-284" },
  { id: "673", label: "+673" },
  { id: "359", label: "+359" },
  { id: "226", label: "+226" },
  { id: "257", label: "+257" },
  { id: "855", label: "+855" },
  { id: "237", label: "+237" },
  { id: "1", label: "+1" },
  { id: "238", label: "+238" },
  { id: "599", label: "+599" },
  { id: "236", label: "+236" },
  { id: "235", label: "+235" },
  { id: "56", label: "+56" },
  { id: "86", label: "+86" },
  { id: "61", label: "+61" },
  { id: "57", label: "+57" },
  { id: "269", label: "+269" },
  { id: "242", label: "+242" },
  { id: "243", label: "+243" },
  { id: "682", label: "+682" },
  { id: "506", label: "+506" },
  { id: "225", label: "+225" },
  { id: "385", label: "+385" },
  { id: "53", label: "+53" },
  { id: "599", label: "+599" },
  { id: "357", label: "+357" },
  { id: "420", label: "+420" },
  { id: "45", label: "+45" },
  { id: "253", label: "+253" },
  { id: "1-767", label: "+1-767" },
  { id: "1-809", label: "+1-809" },
  { id: "670", label: "+670" },
  { id: "593", label: "+593" },
  { id: "20", label: "+20" },
  { id: "503", label: "+503" },
  { id: "240", label: "+240" },
  { id: "291", label: "+291" },
  { id: "372", label: "+372" },
  { id: "251", label: "+251" },
  { id: "500", label: "+500" },
  { id: "298", label: "+298" },
  { id: "679", label: "+679" },
  { id: "358", label: "+358" },
  { id: "33", label: "+33" },
  { id: "594", label: "+594" },
  { id: "689", label: "+689" },
  { id: "241", label: "+241" },
  { id: "220", label: "+220" },
  { id: "995", label: "+995" },
  { id: "49", label: "+49" },
  { id: "233", label: "+233" },
  { id: "350", label: "+350" },
  { id: "30", label: "+30" },
  { id: "299", label: "+299" },
  { id: "1-473", label: "+1-473" },
  { id: "590", label: "+590" },
  { id: "1-671", label: "+1-671" },
  { id: "502", label: "+502" },
  { id: "224", label: "+224" },
  { id: "245", label: "+245" },
  { id: "592", label: "+592" },
  { id: "509", label: "+509" },
  { id: "504", label: "+504" },
  { id: "852", label: "+852" },
  { id: "36", label: "+36" },
  { id: "354", label: "+354" },
  { id: "91", label: "+91" },
  { id: "62", label: "+62" },
  { id: "98", label: "+98" },
  { id: "964", label: "+964" },
  { id: "353", label: "+353" },
  { id: "972", label: "+972" },
  { id: "39", label: "+39" },
  { id: "1-876", label: "+1-876" },
  { id: "81", label: "+81" },
  { id: "962", label: "+962" },
  { id: "7", label: "+7" },
  { id: "254", label: "+254" },
  { id: "686", label: "+686" },
  { id: "383", label: "+383" },
  { id: "965", label: "+965" },
  { id: "996", label: "+996" },
  { id: "856", label: "+856" },
  { id: "371", label: "+371" },
  { id: "961", label: "+961" },
  { id: "266", label: "+266" },
  { id: "231", label: "+231" },
  { id: "218", label: "+218" },
  { id: "423", label: "+423" },
  { id: "370", label: "+370" },
  { id: "352", label: "+352" },
  { id: "853", label: "+853" },
  { id: "389", label: "+389" },
  { id: "261", label: "+261" },
  { id: "265", label: "+265" },
  { id: "60", label: "+60" },
  { id: "960", label: "+960" },
  { id: "223", label: "+223" },
  { id: "356", label: "+356" },
  { id: "692", label: "+692" },
  { id: "596", label: "+596" },
  { id: "222", label: "+222" },
  { id: "230", label: "+230" },
  { id: "262", label: "+262" },
  { id: "52", label: "+52" },
  { id: "691", label: "+691" },
  { id: "373", label: "+373" },
  { id: "377", label: "+377" },
  { id: "976", label: "+976" },
  { id: "382", label: "+382" },
  { id: "1-664", label: "+1-664" },
  { id: "212", label: "+212" },
  { id: "258", label: "+258" },
  { id: "95", label: "+95" },
  { id: "264", label: "+264" },
  { id: "674", label: "+674" },
  { id: "977", label: "+977" },
  { id: "31", label: "+31" },
  { id: "687", label: "+687" },
  { id: "64", label: "+64" },
  { id: "505", label: "+505" },
  { id: "227", label: "+227" },
  { id: "234", label: "+234" },
  { id: "683", label: "+683" },
  { id: "672", label: "+672" },
  { id: "850", label: "+850" },
  { id: "1-670", label: "+1-670" },
  { id: "47", label: "+47" },
  { id: "968", label: "+968" },
  { id: "92", label: "+92" },
  { id: "680", label: "+680" },
  { id: "970", label: "+970" },
  { id: "507", label: "+507" },
  { id: "675", label: "+675" },
  { id: "595", label: "+595" },
  { id: "51", label: "+51" },
  { id: "63", label: "+63" },
  { id: "48", label: "+48" },
  { id: "351", label: "+351" },
  { id: "1-787", label: "+1-787" },
  { id: "974", label: "+974" },
  { id: "262", label: "+262" },
  { id: "40", label: "+40" },
  { id: "7", label: "+7" },
  { id: "250", label: "+250" },
  { id: "590", label: "+590" },
  { id: "290", label: "+290" },
  { id: "1-869", label: "+1-869" },
  { id: "1-758", label: "+1-758" },
  { id: "590", label: "+590" },
  { id: "508", label: "+508" },
  { id: "1-784", label: "+1-784" },
  { id: "685", label: "+685" },
  { id: "378", label: "+378" },
  { id: "239", label: "+239" },
  { id: "966", label: "+966" },
  { id: "221", label: "+221" },
  { id: "381", label: "+381" },
  { id: "248", label: "+248" },
  { id: "232", label: "+232" },
  { id: "65", label: "+65" },
  { id: "599", label: "+599" },
  { id: "421", label: "+421" },
  { id: "386", label: "+386" },
  { id: "677", label: "+677" },
  { id: "252", label: "+252" },
  { id: "27", label: "+27" },
  { id: "82", label: "+82" },
  { id: "211", label: "+211" },
  { id: "34", label: "+34" },
  { id: "94", label: "+94" },
  { id: "249", label: "+249" },
  { id: "597", label: "+597" },
  { id: "47", label: "+47" },
  { id: "268", label: "+268" },
  { id: "46", label: "+46" },
  { id: "41", label: "+41" },
  { id: "963", label: "+963" },
  { id: "886", label: "+886" },
  { id: "992", label: "+992" },
  { id: "255", label: "+255" },
  { id: "66", label: "+66" },
  { id: "670", label: "+670" },
  { id: "228", label: "+228" },
  { id: "690", label: "+690" },
  { id: "676", label: "+676" },
  { id: "1-868", label: "+1-868" },
  { id: "216", label: "+216" },
  { id: "993", label: "+993" },
  { id: "1-649", label: "+1-649" },
  { id: "688", label: "+688" },
  { id: "256", label: "+256" },
  { id: "380", label: "+380" },
  { id: "971", label: "+971" },
  { id: "44", label: "+44" },
  { id: "1", label: "+1" },
  { id: "598", label: "+598" },
  { id: "998", label: "+998" },
  { id: "678", label: "+678" },
  { id: "379", label: "+379" },
  { id: "58", label: "+58" },
  { id: "84", label: "+84" },
  { id: "681", label: "+681" },
  { id: "212", label: "+212" },
  { id: "967", label: "+967" },
];

export const citiesToDistricts: {
  [key: string]: NSSelectBoxType.ISelectBoxItem[];
} = {
  adana: [
    { id: "aladağ", label: "Aladağ" },
    { id: "ceyhan", label: "Ceyhan" },
    { id: "çukurova", label: "Çukurova" },
    { id: "feke", label: "Feke" },
    { id: "karaisalı", label: "Karaisalı" },
    { id: "karataş", label: "Karataş" },
    { id: "kozan", label: "Kozan" },
    { id: "pozantı", label: "Pozantı" },
    { id: "seyhan", label: "Seyhan" },
    { id: "tufanbeyli", label: "Tufanbeyli" },
    { id: "yüreğir", label: "Yüreğir" },
  ],
  adiyaman: [
    { id: "besni", label: "Besni" },
    { id: "gerger", label: "Gerger" },
    { id: "gölbaşı", label: "Gölbaşı" },
    { id: "kahta", label: "Kahta" },
    { id: "samsat", label: "Samsat" },
    { id: "tut", label: "Tut" },
  ],
  afyonkarahisar: [
    { id: "başmakçı", label: "Başmakçı" },
    { id: "bayat", label: "Bayat" },
    { id: "bolvadin", label: "Bolvadin" },
    { id: "çay", label: "Çay" },
    { id: "dazkiri", label: "Dazkırı" },
    { id: "divriği", label: "Divriği" },
    { id: "emirdağ", label: "Emirdağ" },
    { id: "esenköy", label: "Eşenköy" },
    { id: "hocalar", label: "Hocalar" },
    { id: "ihsaniye", label: "İhsaniye" },
    { id: "isekhisar", label: "İscehisar" },
    { id: "kadınhanı", label: "Kadınhanı" },
    { id: "karakoyunlu", label: "Karakoyunlu" },
    { id: "kiziloren", label: "Kızılköy" },
    { id: "sandikli", label: "Sandıklı" },
    { id: "sinanpaşa", label: "Sinanpaşa" },
    { id: "sultandagi", label: "Şuhut" },
  ],
  agri: [
    { id: "dogubeyazit", label: "Doğubeyazıt" },
    { id: "eleşkirt", label: "Eleşkirt" },
    { id: "hamur", label: "Hamur" },
    { id: "patnos", label: "Patnos" },
    { id: "taşlıçay", label: "Taşlıçay" },
    { id: "tutak", label: "Tutak" },
  ],
  aksaray: [
    { id: "aksaray", label: "Aksaray Merkez" },
    { id: "akseki", label: "Ağaçören" },
    { id: "eskil", label: "Eskil" },
    { id: "gülağaç", label: "Gülağaç" },
    { id: "güzelyurt", label: "Güzelyurt" },
    { id: "ortaköy", label: "Ortaköy" },
    { id: "sarıkaya", label: "Sarıkaya" },
    { id: "sultanhanı", label: "Sultanhanı" },
  ],
  amasya: [
    { id: "göynücek", label: "Göynücek" },
    { id: "gümüşhacıköy", label: "Gümüşhacıköy" },
    { id: "hamamözü", label: "Hamamözü" },
    { id: "merzifon", label: "Merzifon" },
    { id: "suluova", label: "Suluova" },
    { id: "taşova", label: "Taşova" },
  ],
  ankara: [
    { id: "akyurt", label: "Akyurt" },
    { id: "altindag", label: "Altındağ" },
    { id: "ayas", label: "Ayaş" },
    { id: "bala", label: "Bala" },
    { id: "beypazari", label: "Beypazarı" },
    { id: "çamlidere", label: "Çamlıdere" },
    { id: "çankaya", label: "Çankaya" },
    { id: "cubuk", label: "Çubuk" },
    { id: "elmadag", label: "Elmadağ" },
    { id: "etimesgut", label: "Etimesgut" },
    { id: "evren", label: "Evren" },
    { id: "golbasi", label: "Gölbaşı" },
    { id: "güdül", label: "Güdül" },
    { id: "haymana", label: "Haymana" },
    { id: "kasimpasa", label: "Kaşpazar" },
    { id: "keciören", label: "Keçiören" },
    { id: "kizilcahamam", label: "Kızılcahamam" },
    { id: "mamak", label: "Mamak" },
    { id: "nallihan", label: "Nallıhan" },
    { id: "polatli", label: "Polatlı" },
    { id: "sereflikochisar", label: "Şereflikoçhisar" },
    { id: "yenimahalle", label: "Yenimahalle" },
  ],
  antalya: [
    { id: "aksu", label: "Aksu" },
    { id: "akseki", label: "Akseki" },
    { id: "aksu", label: "Aksu" },
    { id: "alanya", label: "Alanya" },
    { id: "demre", label: "Demre" },
    { id: "dosemealti", label: "Döşemealtı" },
    { id: "gazipasa", label: "Gazipaşa" },
    { id: "kas", label: "Kaş" },
    { id: "kepez", label: "Kepez" },
    { id: "kemer", label: "Kemer" },
    { id: "konyaalti", label: "Konyaaltı" },
    { id: "korkuteli", label: "Korkuteli" },
    { id: "kumluca", label: "Kumluca" },
    { id: "manavgat", label: "Manavgat" },
    { id: "muratpasa", label: "Muratpaşa" },
    { id: "serik", label: "Serik" },
  ],
  aydin: [
    { id: "bozdogan", label: "Bozdoğan" },
    { id: "buharkent", label: "Buharkent" },
    { id: "çine", label: "Çine" },
    { id: "didim", label: "Didim" },
    { id: "güzelçamlı", label: "Güzelçamlı" },
    { id: "karacasu", label: "Karacasu" },
    { id: "karpuzlu", label: "Karpuzlu" },
    { id: "koçarli", label: "Koçarlı" },
    { id: "köşk", label: "Köşk" },
    { id: "kuşadası", label: "Kuşadası" },
    { id: "kuyucak", label: "Kuyucak" },
    { id: "nazilli", label: "Nazilli" },
    { id: "söke", label: "Söke" },
    { id: "sultanhisar", label: "Sultanhisar" },
    { id: "yenipazar", label: "Yenipazar" },
  ],
  balikesir: [
    { id: "ayvalik", label: "Ayvalık" },
    { id: "balya", label: "Balya" },
    { id: "bandirma", label: "Bandırma" },
    { id: "bigadic", label: "Bigadiç" },
    { id: "burhaniye", label: "Burhaniye" },
    { id: "dursunbey", label: "Dursunbey" },
    { id: "edremit", label: "Edremit" },
    { id: "erdek", label: "Erdek" },
    { id: "gömeç", label: "Gömeç" },
    { id: "gönen", label: "Gönen" },
    { id: "havran", label: "Havran" },
    { id: "ivril", label: "İvrindi" },
    { id: "kepsut", label: "Kepsut" },
    { id: "manyas", label: "Manyas" },
    { id: "marmara", label: "Marmara" },
    { id: "savaştepe", label: "Savaştepe" },
    { id: "sındırgı", label: "Sındırgı" },
    { id: "susurluk", label: "Susurluk" },
  ],
  bilecik: [
    { id: "bozüyük", label: "Bozüyük" },
    { id: "gölpazarı", label: "Gölpazarı" },
    { id: "inönu", label: "İnönü" },
    { id: "osmaneli", label: "Osmaneli" },
    { id: "pazaryeri", label: "Pazaryeri" },
    { id: "söğüt", label: "Söğüt" },
    { id: "yenipazar", label: "Yenipazar" },
  ],
  bolu: [
    { id: "dörtdivan", label: "Dörtdivan" },
    { id: "gerede", label: "Gerede" },
    { id: "göynük", label: "Göynük" },
    { id: "kibriscik", label: "Kıbrıscık" },
    { id: "mengen", label: "Mengen" },
    { id: "mudurnu", label: "Mudurnu" },
    { id: "seben", label: "Seben" },
    { id: "yeniçağa", label: "Yeniçağa" },
  ],
  burdur: [
    { id: "ağlasun", label: "Ağlasun" },
    { id: "bucak", label: "Bucak" },
    { id: "çavdır", label: "Çavdır" },
    { id: "gölhisar", label: "Gölhisar" },
    { id: "karamanli", label: "Karamanlı" },
    { id: "tavas", label: "Tavas" },
  ],
  bursa: [
    { id: "büyükorhan", label: "Büyükorhan" },
    { id: "gemlik", label: "Gemlik" },
    { id: "gürsu", label: "Gürsu" },
    { id: "harmancik", label: "Harmancık" },
    { id: "ina", label: "İnegöl" },
    { id: "iznik", label: "İznik" },
    { id: "karacabey", label: "Karacabey" },
    { id: "keles", label: "Keles" },
    { id: "kestel", label: "Kestel" },
    { id: "mudanya", label: "Mudanya" },
    { id: "mustafakemalpasa", label: "Mustafakemalpaşa" },
    { id: "nilufer", label: "Nilüfer" },
    { id: "orhaneli", label: "Orhaneli" },
    { id: "orhangazi", label: "Orhangazi" },
    { id: "osmangazi", label: "Osmangazi" },
    { id: "yildirim", label: "Yıldırım" },
  ],
  çanakkale: [
    { id: "ayvacik", label: "Ayvacık" },
    { id: "bayramic", label: "Bayramiç" },
    { id: "biga", label: "Biga" },
    { id: "bozcaada", label: "Bozcaada" },
    { id: "çan", label: "Çan" },
    { id: "çanakkale", label: "Çanakkale Merkez" },
    { id: "eceabat", label: "Eceabat" },
    { id: "ezine", label: "Ezine" },
    { id: "gökçeada", label: "Gökçeada" },
    { id: "lapseki", label: "Lapseki" },
    { id: "yalova", label: "Yalova" },
  ],
  çankiri: [
    { id: "atkaracalar", label: "Atkaracalar" },
    { id: "bayramoren", label: "Bayramören" },
    { id: "çerkeş", label: "Çerkeş" },
    { id: "eldivan", label: "Eldivan" },
    { id: "ilgaz", label: "Ilgaz" },
    { id: "kizilirmak", label: "Kızılırmak" },
    { id: "korgun", label: "Korgun" },
    { id: "kursunlu", label: "Kurşunlu" },
    { id: "orhanlar", label: "Orta" },
    { id: "şabanözü", label: "Şabanözü" },
    { id: "yenice", label: "Yenice" },
  ],
  çorum: [
    { id: "alaca", label: "Alaca" },
    { id: "bayat", label: "Bayat" },
    { id: "boğazkale", label: "Boğazkale" },
    { id: "dodurga", label: "Dodurga" },
    { id: "işkilip", label: "İskilip" },
    { id: "kargi", label: "Kargı" },
    { id: "laçin", label: "Laçin" },
    { id: "mecitozu", label: "Mecitözü" },
    { id: "oguzlar", label: "Oğuzlar" },
    { id: "ortaköy", label: "Ortaköy" },
    { id: "osmancik", label: "Osmancık" },
    { id: "sungurlu", label: "Sungurlu" },
    { id: "uluova", label: "Uluova" },
  ],
  denizli: [
    { id: "acipayam", label: "Acıpayam" },
    { id: "babadağ", label: "Babadağ" },
    { id: "baklan", label: "Baklan" },
    { id: "bekilli", label: "Bekilli" },
    { id: "beyağaç", label: "Beyağaç" },
    { id: "bozkurt", label: "Bozkurt" },
    { id: "buldan", label: "Buldan" },
    { id: "çal", label: "Çal" },
    { id: "çameli", label: "Çameli" },
    { id: "çardak", label: "Çardak" },
    { id: "çivril", label: "Çivril" },
    { id: "güney", label: "Güney" },
    { id: "honaz", label: "Honaz" },
    { id: "kale", label: "Kale" },
    { id: "pamukkale", label: "Pamukkale" },
    { id: "sarayköy", label: "Sarayköy" },
    { id: "serinhisar", label: "Serinhisar" },
    { id: "tavas", label: "Tavas" },
  ],
  diyarbakir: [
    { id: "bağlar", label: "Bağlar" },
    { id: "bismil", label: "Bismil" },
    { id: "çermik", label: "Çermik" },
    { id: "çüngüş", label: "Çüngüş" },
    { id: "dicle", label: "Dicle" },
    { id: "eğil", label: "Eğil" },
    { id: "ergani", label: "Ergani" },
    { id: "hani", label: "Hani" },
    { id: "hazro", label: "Hazro" },
    { id: "kayapinar", label: "Kayapınar" },
    { id: "kocaköy", label: "Kocaköy" },
    { id: "kulp", label: "Kulp" },
    { id: "lice", label: "Lice" },
    { id: "silvan", label: "Silvan" },
    { id: "sur", label: "Sur" },
    { id: "yenişehir", label: "Yenişehir" },
  ],
  edirne: [
    { id: "edirne", label: "Edirne Merkez" },
    { id: "enez", label: "Enez" },
    { id: "havsa", label: "Havsa" },
    { id: "ipsala", label: "İpsala" },
    { id: "keşan", label: "Keşan" },
    { id: "lalapaşa", label: "Lalapaşa" },
    { id: "meriç", label: "Meriç" },
    { id: "süloğlu", label: "Süloğlu" },
    { id: "uzunköprü", label: "Uzunköprü" },
  ],
  elazig: [
    { id: "agin", label: "Ağın" },
    { id: "almus", label: "Almus" },
    { id: "arakan", label: "Arıcak" },
    { id: "baskil", label: "Baskil" },
    { id: "karakocan", label: "Karakoçan" },
    { id: "keban", label: "Keban" },
    { id: "kemaliye", label: "Kemaliye" },
    { id: "kocasinan", label: "Kocasinan" },
    { id: "mazgirt", label: "Mazgirt" },
    { id: "merkez", label: "Elazığ Merkez" },
    { id: "palu", label: "Palu" },
    { id: "sivrice", label: "Sivrice" },
  ],
  erzincan: [
    { id: "çayirli", label: "Çayırlı" },
    { id: "iliç", label: "İliç" },
    { id: "kemah", label: "Kemah" },
    { id: "kemaliye", label: "Kemaliye" },
    { id: "merkez", label: "Erzincan Merkez" },
    { id: "otlukbeli", label: "Otlukbeli" },
    { id: "refahiye", label: "Refahiye" },
    { id: "tercan", label: "Tercan" },
    { id: "ülükent", label: "Üzümlü" },
  ],
  erzurum: [
    { id: "aşkale", label: "Aşkale" },
    { id: "aziziye", label: "Aziziye" },
    { id: "çat", label: "Çat" },
    { id: "hınıs", label: "Hınıs" },
    { id: "horasan", label: "Horasan" },
    { id: "ıspir", label: "İspir" },
    { id: "karayazı", label: "Karayazı" },
    { id: "köprüköy", label: "Köprüköy" },
    { id: "narman", label: "Narman" },
    { id: "oltu", label: "Oltu" },
    { id: "olur", label: "Olur" },
    { id: "palandöken", label: "Palandöken" },
    { id: "pasinler", label: "Pasinler" },
    { id: "şenkaya", label: "Şenkaya" },
    { id: "tekman", label: "Tekman" },
    { id: "tortum", label: "Tortum" },
    { id: "uzundere", label: "Uzundere" },
    { id: "yakutiye", label: "Yakutiye" },
  ],
  eskisehir: [
    { id: "alpu", label: "Alpu" },
    { id: "beylikova", label: "Beylikova" },
    { id: "çifteler", label: "Çifteler" },
    { id: "günyüzü", label: "Günyüzü" },
    { id: "han", label: "Han" },
    { id: "inönü", label: "İnönü" },
    { id: "mahmudiye", label: "Mahmudiye" },
    { id: "mihalıççik", label: "Mihalıççık" },
    { id: "mihalgazi", label: "Mihalgazi" },
    { id: "odunpazarı", label: "Odunpazarı" },
    { id: "sarıcakaya", label: "Sarıcakaya" },
    { id: "seyitgazi", label: "Seyitgazi" },
    { id: "sivrihisar", label: "Sivrihisar" },
    { id: "tepebaşı", label: "Tepebaşı" },
  ],
  gaziantep: [
    { id: "araban", label: "Araban" },
    { id: "iskeçe", label: "İskeçe" },
    { id: "karkamis", label: "Karkamış" },
    { id: "nizip", label: "Nizip" },
    { id: "nuh", label: "Nuh" },
    { id: "öncüpınar", label: "Öncüpınar" },
    { id: "şahinbey", label: "Şahinbey" },
    { id: "şehitkamil", label: "Şehitkamil" },
    { id: "yavuzeli", label: "Yavuzeli" },
  ],
  giresun: [
    { id: "alucra", label: "Alucra" },
    { id: "bulancak", label: "Bulancak" },
    { id: "çamoluk", label: "Çamoluk" },
    { id: "çanakçı", label: "Çanakçı" },
    { id: "dereli", label: "Dereli" },
    { id: "doğankent", label: "Doğankent" },
    { id: "espİye", label: "Espiye" },
    { id: "eynesil", label: "Eynesil" },
    { id: "gÖrele", label: "Görele" },
    { id: "güce", label: "Güce" },
    { id: "keşap", label: "Keşap" },
    { id: "piraziz", label: "Piraziz" },
    { id: "Şebinkarahisar", label: "Şebinkarahisar" },
    { id: "tirebolu", label: "Tirebolu" },
    { id: "yağlıdere", label: "Yağlıdere" },
  ],
  gümüşhane: [
    { id: "kelkit", label: "Kelkit" },
    { id: "köse", label: "Köse" },
    { id: "kuşsarayı", label: "Kuşsarayı" },
    { id: "merkez", label: "Gümüşhane Merkez" },
    { id: "şiran", label: "Şiran" },
    { id: "torul", label: "Torul" },
  ],
  hakkari: [
    { id: "çukurca", label: "Çukurca" },
    { id: "derecik", label: "Derecik" },
    { id: "merkez", label: "Hakkari Merkez" },
    { id: "şemdinli", label: "Şemdinli" },
    { id: "yüknsekova", label: "Yüksekova" },
  ],
  hatay: [
    { id: "altinozu", label: "Altınözü" },
    { id: "antakya", label: "Antakya" },
    { id: "arsuz", label: "Arsuz" },
    { id: "belen", label: "Belen" },
    { id: "defne", label: "Defne" },
    { id: "dortyol", label: "Dörtyol" },
    { id: "erzin", label: "Erzin" },
    { id: "hassa", label: "Hassa" },
    { id: "iskenderun", label: "İskenderun" },
    { id: "kirikhan", label: "Kırıkhan" },
    { id: "kumlu", label: "Kumlu" },
    { id: "payas", label: "Payas" },
    { id: "reYhanli", label: "Reyhanlı" },
    { id: "samandag", label: "Samandağ" },
    { id: "yayladagi", label: "Yayladağı" },
  ],
  isparta: [
    { id: "akseki", label: "Akseki" },
    { id: "atesh", label: "Atabey" },
    { id: "eğirdir", label: "Eğirdir" },
    { id: "gelemem", label: "Gelendost" },
    { id: "gönen", label: "Gönen" },
    { id: "keçiborlu", label: "Keçiborlu" },
    { id: "merkez", label: "Isparta Merkez" },
    { id: "senirkent", label: "Senirkent" },
    { id: "sütçüler", label: "Sütçüler" },
    { id: "uluborlu", label: "Uluborlu" },
    { id: "yenisehir", label: "Yenisehir" },
    { id: "yürekli", label: "Yalvaç" },
  ],
  istanbul: [
    { id: "adalar", label: "Adalar" },
    { id: "arnavutkoy", label: "Arnavutköy" },
    { id: "ataşehir", label: "Ataşehir" },
    { id: "avcilar", label: "Avcılar" },
    { id: "bağcilar", label: "Bağcılar" },
    { id: "bahcelievler", label: "Bahçelievler" },
    { id: "bakirkoy", label: "Bakırköy" },
    { id: "basaksehir", label: "Başakşehir" },
    { id: "bayrampasa", label: "Bayrampaşa" },
    { id: "besiktas", label: "Beşiktaş" },
    { id: "beykoz", label: "Beykoz" },
    { id: "beylikduzu", label: "Beylikdüzü" },
    { id: "beyoglu", label: "Beyoğlu" },
    { id: "büyükçekmece", label: "Büyükçekmece" },
    { id: "çatalca", label: "Çatalca" },
    { id: "çekmeköy", label: "Çekmeköy" },
    { id: "esenler", label: "Esenler" },
    { id: "esenyurt", label: "Esenyurt" },
    { id: "eyupsultan", label: "Eyüpsultan" },
    { id: "fatih", label: "Fatih" },
    { id: "gaziosmanpaşa", label: "Gaziosmanpaşa" },
    { id: "güngören", label: "Güngören" },
    { id: "kadikoy", label: "Kadıköy" },
    { id: "kağıthane", label: "Kağıthane" },
    { id: "kartal", label: "Kartal" },
    { id: "küçükçekmece", label: "Küçükçekmece" },
    { id: "maltepe", label: "Maltepe" },
    { id: "pendik", label: "Pendik" },
    { id: "sancaktepe", label: "Sancaktepe" },
    { id: "sarıyer", label: "Sarıyer" },
    { id: "silivri", label: "Silivri" },
    { id: "sultanbeyli", label: "Sultanbeyli" },
    { id: "sultangazi", label: "Sultangazi" },
    { id: "şile", label: "Şile" },
    { id: "şişli", label: "Şişli" },
    { id: "tuzla", label: "Tuzla" },
    { id: "ümraniye", label: "Ümraniye" },
    { id: "üsküdar", label: "Üsküdar" },
    { id: "zeytinburnu", label: "Zeytinburnu" },
  ],
  izmir: [
    { id: "aliağa", label: "Aliağa" },
    { id: "balçova", label: "Balçova" },
    { id: "bayındır", label: "Bayındır" },
    { id: "bayrakli", label: "Bayraklı" },
    { id: "bergama", label: "Bergama" },
    { id: "beydağ", label: "Beydağ" },
    { id: "bornova", label: "Bornova" },
    { id: "buca", label: "Buca" },
    { id: "çeşme", label: "Çeşme" },
    { id: "çiğli", label: "Çiğli" },
    { id: "dikili", label: "Dikili" },
    { id: "foça", label: "Foça" },
    { id: "gaziemir", label: "Gaziemir" },
    { id: "güzelbahce", label: "Güzelbahçe" },
    { id: "karabaglar", label: "Karabağlar" },
    { id: "karşıyaka", label: "Karşıyaka" },
    { id: "kemalpaşa", label: "Kemalpaşa" },
    { id: "kınık", label: "Kınık" },
    { id: "kiraz", label: "Kiraz" },
    { id: "konak", label: "Konak" },
    { id: "menderes", label: "Menderes" },
    { id: "menemen", label: "Menemen" },
    { id: "narlidere", label: "Narlıdere" },
    { id: "odemis", label: "Ödemiş" },
    { id: "seferihisar", label: "Seferihisar" },
    { id: "selçuk", label: "Selçuk" },
    { id: "tire", label: "Tire" },
    { id: "torbalı", label: "Torbalı" },
    { id: "urla", label: "Urla" },
  ],
  kahramanmaras: [
    { id: "afsin", label: "Afşin" },
    { id: "andirin", label: "Andırın" },
    { id: "çaglarca", label: "Çağlarca" },
    { id: "ekinozu", label: "Ekinözü" },
    { id: "elbistan", label: "Elbistan" },
    { id: "göksun", label: "Göksun" },
    { id: "nurhak", label: "Nurhak" },
    { id: "onikişubat", label: "On" },
    { id: "merkez", label: "Karabük Merkez" },
    { id: "ovacik", label: "Ovacık" },
    { id: "safranbolu", label: "Safranbolu" },
    { id: "yalova", label: "Yenice" },
  ],
  karaman: [
    { id: "arıdan", label: "Ayrancı" },
    { id: "başyayla", label: "Başyayla" },
    { id: "ermenek", label: "Ermenek" },
    { id: "kazımkarabekir", label: "Kazımkarabekir" },
    { id: "merkez", label: "Karaman Merkez" },
    { id: "sariveliler", label: "Saraylıer" },
  ],
  kars: [
    { id: "akyaka", label: "Akyaka" },
    { id: "arpaçay", label: "Arpaçay" },
    { id: "digor", label: "Digor" },
    { id: "kağızman", label: "Kağızman" },
    { id: "merkez", label: "Kars Merkez" },
    { id: "sarikamis", label: "Sarikamis" },
    { id: "selim", label: "Selim" },
    { id: "susuz", label: "Susuz" },
  ],
  kastamonu: [
    { id: "abana", label: "Abana" },
    { id: "agli", label: "Ağlı" },
    { id: "araç", label: "Araç" },
    { id: "azdavay", label: "Azdavay" },
    { id: "bozkurt", label: "Bozkurt" },
    { id: "cide", label: "Cide" },
    { id: "çatalzeytin", label: "Çatalzeytin" },
    { id: "daday", label: "Daday" },
    { id: "devrekani", label: "Devrekani" },
    { id: "doğanyurt", label: "Doğanyurt" },
    { id: "hanönü", label: "Hanönü" },
    { id: "ınebolu", label: "İnebolu" },
    { id: "köse", label: "Köse" },
    { id: "küre", label: "Küre" },
    { id: "merkez", label: "Kastamonu Merkez" },
    { id: "pinarbaşı", label: "Pınarbaşı" },
    { id: "semen", label: "Şenpazar" },
    { id: "seydiler", label: "Seydiler" },
    { id: "taşköprü", label: "Taşköprü" },
    { id: "taskopru", label: "Taşköprü" },
    { id: "togay", label: "Tosya" },
  ],
  kayseri: [
    { id: "aksaray", label: "Akkışla" },
    { id: "bunyan", label: "Bünyan" },
    { id: "develi", label: "Develi" },
    { id: "felahiye", label: "Felahiye" },
    { id: "hacılar", label: "Hacılar" },
    { id: "ince", label: "İncesu" },
    { id: "kocasinan", label: "Kocasinan" },
    { id: "melikgazi", label: "Melikgazi" },
    { id: "sarıoğlan", label: "Sarıoğlan" },
    { id: "sarız", label: "Sarız" },
    { id: "talas", label: "Talas" },
    { id: "tomarza", label: "Tomarza" },
    { id: "yahyalı", label: "Yahyalı" },
    { id: "yeşilhisar", label: "Yeşilhisar" },
  ],

  kırıkkale: [
    { id: "merkez", label: "Merkez" },
    { id: "bahşili", label: "Bahşılı" },
    { id: "balışeyh", label: "Balışeyh" },
    { id: "çelebi", label: "Çelebi" },
    { id: "delice", label: "Delice" },
    { id: "karakeçili", label: "Karakeçili" },
    { id: "keskin", label: "Keskin" },
    { id: "sulakyurt", label: "Sulakyurt" },
    { id: "yahşihan", label: "Yahşihan" },
  ],
  kırklareli: [
    { id: "babaeski", label: "Babaeski" },
    { id: "demirköy", label: "Demirköy" },
    { id: "kofcaz", label: "Kofçaz" },
    { id: "luleburgaz", label: "Lüleburgaz" },
    { id: "pehlivanköy", label: "Pehlivanköy" },
    { id: "pinarhisar", label: "Pınarhisar" },
    { id: "vize", label: "Vize" },
  ],
  kırşehir: [
    { id: "merkez", label: "Merkez" },
    { id: "bahşılı", label: "Bahşılı" },
    { id: "balışeyh", label: "Balışeyh" },
    { id: "çelebi", label: "Çelebi" },
    { id: "delice", label: "Delice" },
    { id: "karakeçili", label: "Karakeçili" },
    { id: "keskin", label: "Keskin" },
    { id: "sulakyurt", label: "Sulakyurt" },
    { id: "yahşihan", label: "Yahşihan" },
  ],

  kilis: [
    { id: "merkez", label: "Merkez" },
    { id: "elbeyli", label: "Elbeyli" },
    { id: "musabeyli", label: "Musabeyli" },
    { id: "polateli", label: "Polateli" },
  ],
  kocaeli: [
    { id: "izmit", label: "İzmit" },
    { id: "derince", label: "Derince" },
    { id: "gölcük", label: "Gölcük" },
    { id: "körfez", label: "Körfez" },
    { id: "başiskele", label: "Başiskele" },
    { id: "çayırova", label: "Çayırova" },
    { id: "darıca", label: "Darıca" },
    { id: "dilovası", label: "Dilovası" },
    { id: "gebze", label: "Gebze" },
  ],
  konya: [
    { id: "meram", label: "Meram" },
    { id: "selçuklu", label: "Selçuklu" },
    { id: "karatay", label: "Karatay" },
    { id: "ereğli", label: "Ereğli" },
    { id: "kulu", label: "Kulu" },
    { id: "akşehir", label: "Akşehir" },
    { id: "beyşehir", label: "Beyşehir" },
    { id: "çumra", label: "Çumra" },
    { id: "ilgin", label: "Ilgın" },
  ],
  kütahya: [
    { id: "merkez", label: "Merkez" },
    { id: "tavşanlı", label: "Tavşanlı" },
    { id: "gediz", label: "Gediz" },
    { id: "simav", label: "Simav" },
    { id: "dumlupınar", label: "Dumlupınar" },
    { id: "emet", label: "Emet" },
    { id: "altıntaş", label: "Altıntaş" },
    { id: "aslanapa", label: "Aslanapa" },
    { id: "şaphane", label: "Şaphane" },
  ],
  malatya: [
    { id: "battalgazi", label: "Battalgazi" },
    { id: "yeşilyurt", label: "Yeşilyurt" },
    { id: "doğansu", label: "Doğansu" },
    { id: "akçadağ", label: "Akçadağ" },
    { id: "arguvan", label: "Arguvan" },
    { id: "darende", label: "Darende" },
    { id: "doğanşehir", label: "Doğanşehir" },
    { id: "hekimhan", label: "Hekimhan" },
    { id: "kale", label: "Kale" },
    { id: "kuluncak", label: "Kuluncak" },
    { id: "pütürge", label: "Pütürge" },
    { id: "yeşilyurt", label: "Yeşilyurt" },
  ],
  manisa: [
    { id: "merkez", label: "Merkez" },
    { id: "akhisar", label: "Akhisar" },
    { id: "salihli", label: "Salihli" },
    { id: "soma", label: "Soma" },
    { id: "turgutlu", label: "Turgutlu" },
    { id: "ahmetli", label: "Ahmetli" },
    { id: "alaşehir", label: "Alaşehir" },
    { id: "demirci", label: "Demirci" },
    { id: "gölmarmara", label: "Gölmarmara" },
    { id: "kırkağaç", label: "Kırkağaç" },
    { id: "köprübaşı", label: "Köprübaşı" },
    { id: "saruhanlı", label: "Saruhanlı" },
    { id: "selendi", label: "Selendi" },
    { id: "şehzadeler", label: "Şehzadeler" },
    { id: "yunusemre", label: "Yunusemre" },
  ],

  mardin: [
    { id: "merkez", label: "Merkez" },
    { id: "artuklu", label: "Artuklu" },
    { id: "kızıltepe", label: "Kızıltepe" },
    { id: "midyat", label: "Midyat" },
    { id: "derik", label: "Derik" },
    { id: "nusaybin", label: "Nusaybin" },
    { id: "savur", label: "Savur" },
    { id: "yeşilli", label: "Yeşilli" },
  ],
  mersin: [
    { id: "merkez", label: "Merkez" },
    { id: "akdeniz", label: "Akdeniz" },
    { id: "mezitli", label: "Mezitli" },
    { id: "yenişehir", label: "Yenişehir" },
    { id: "toroslar", label: "Toroslar" },
    { id: "tarsus", label: "Tarsus" },
    { id: "silifke", label: "Silifke" },
    { id: "erdemli", label: "Erdemli" },
    { id: "mut", label: "Mut" },
    { id: "aydıncık", label: "Aydıncık" },
    { id: "bozyazı", label: "Bozyazı" },
  ],
  muğla: [
    { id: "merkez", label: "Merkez" },
    { id: "bodrum", label: "Bodrum" },
    { id: "fethiye", label: "Fethiye" },
    { id: "marmaris", label: "Marmaris" },
    { id: "milas", label: "Milas" },
    { id: "datça", label: "Datça" },
    { id: "köyceğiz", label: "Köyceğiz" },
    { id: "ula", label: "Ula" },
    { id: "yatağan", label: "Yatağan" },
    { id: "dalaman", label: "Dalaman" },
  ],
  muş: [
    { id: "merkez", label: "Merkez" },
    { id: "bulanık", label: "Bulanık" },
    { id: "hasköy", label: "Hasköy" },
    { id: "malazgirt", label: "Malazgirt" },
    { id: "varto", label: "Varto" },
    { id: "korkut", label: "Korkut" },
  ],
  nevşehir: [
    { id: "merkez", label: "Merkez" },
    { id: "acıgöl", label: "Acıgöl" },
    { id: "avanos", label: "Avanos" },
    { id: "derinkuyu", label: "Derinkuyu" },
    { id: "gülşehir", label: "Gülşehir" },
    { id: "hacıbektaş", label: "Hacıbektaş" },
    { id: "kaymaklı", label: "Kaymaklı" },
    { id: "köşk", label: "Köşk" },
    { id: "ürgüp", label: "Ürgüp" },
  ],
  ordu: [
    { id: "merkez", label: "Merkez" },
    { id: "altınordu", label: "Altınordu" },
    { id: "ünye", label: "Ünye" },
    { id: "fatsa", label: "Fatsa" },
    { id: "gölköy", label: "Gölköy" },
    { id: "korgan", label: "Korgan" },
    { id: "kumru", label: "Kumru" },
    { id: "mesudiye", label: "Mesudiye" },
    { id: "perşembe", label: "Perşembe" },
    { id: "çamaş", label: "Çamaş" },
  ],
  osmaniye: [
    { id: "merkez", label: "Merkez" },
    { id: "bahçe", label: "Bahçe" },
    { id: "düziçi", label: "Düziçi" },
    { id: "hasanbeyli", label: "Hasanbeyli" },
    { id: "kadirli", label: "Kadirli" },
    { id: "sumbas", label: "Sumbas" },
  ],
  rize: [
    { id: "merkez", label: "Merkez" },
    { id: "ardanuç", label: "Ardanuç" },
    { id: "arhavi", label: "Arhavi" },
    { id: "çamlıhemşin", label: "Çamlıhemşin" },
    { id: "çayeli", label: "Çayeli" },
    { id: "derepazarı", label: "Derepazarı" },
    { id: "fındıklı", label: "Fındıklı" },
    { id: "güneysu", label: "Güneysu" },
    { id: "hemşin", label: "Hemşin" },
    { id: "ikizdere", label: "İkizdere" },
    { id: "kalkandere", label: "Kalkandere" },
    { id: "pazar", label: "Pazar" },
  ],
  sakarya: [
    { id: "merkez", label: "Merkez" },
    { id: "adiyaman", label: "Adıyaman" },
    { id: "akyazı", label: "Akyazı" },
    { id: "arifiye", label: "Arifiye" },
    { id: "ferizli", label: "Ferizli" },
    { id: "geyve", label: "Geyve" },
    { id: "henek", label: "Henek" },
    { id: "karasu", label: "Karasu" },
    { id: "kaynarca", label: "Kaynarca" },
    { id: "kocaali", label: "Kocaali" },
    { id: "pamukova", label: "Pamukova" },
    { id: "sapanca", label: "Sapanca" },
    { id: "serdivan", label: "Serdivan" },
    { id: "taraklı", label: "Taraklı" },
  ],
  samsun: [
    { id: "merkez", label: "Merkez" },
    { id: "atakum", label: "Atakum" },
    { id: "canik", label: "Canik" },
    { id: "ilkadım", label: "İlkadım" },
    { id: "bafra", label: "Bafra" },
    { id: "çarşamba", label: "Çarşamba" },
    { id: "havza", label: "Havza" },
    { id: "kavak", label: "Kavak" },
    { id: "ladik", label: "Ladik" },
    { id: "19mayıs", label: "19 Mayıs" },
    { id: "vezirköprü", label: "Vezirköprü" },
  ],
  siirt: [
    { id: "merkez", label: "Merkez" },
    { id: "baykan", label: "Baykan" },
    { id: "ermiş", label: "Eruh" },
    { id: "kurtalan", label: "Kurtalan" },
    { id: "perinçek", label: "Pervari" },
    { id: "şirvan", label: "Şirvan" },
  ],
  sinop: [
    { id: "merkez", label: "Merkez" },
    { id: "ayancık", label: "Ayancık" },
    { id: "boyabat", label: "Boyabat" },
    { id: "dikmen", label: "Dikmen" },
    { id: "durağan", label: "Durağan" },
    { id: "gerze", label: "Gerze" },
    { id: "saraydüzü", label: "Saraydüzü" },
    { id: "türkeli", label: "Türkeli" },
  ],
  sivas: [
    { id: "merkez", label: "Merkez" },
    { id: "akıncılar", label: "Akıncılar" },
    { id: "altınyayla", label: "Altınyayla" },
    { id: "divriği", label: "Divriği" },
    { id: "doğanşar", label: "Doğanşar" },
    { id: "gemerek", label: "Gemerek" },
    { id: "gölova", label: "Gölova" },
    { id: "hafik", label: "Hafik" },
    { id: "imranlı", label: "İmranlı" },
    { id: "kangal", label: "Kangal" },
    { id: "koyulhisar", label: "Koyulhisar" },
    { id: "suşehri", label: "Suşehri" },
    { id: "şarkışla", label: "Şarkışla" },
    { id: "ulaş", label: "Ulaş" },
    { id: "yıldızeli", label: "Yıldızeli" },
    { id: "zara", label: "Zara" },
  ],
  şanlıurfa: [
    { id: "merkez", label: "Merkez" },
    { id: "birecik", label: "Birecik" },
    { id: "bozova", label: "Bozova" },
    { id: "ceylanpınar", label: "Ceylanpınar" },
    { id: "halfeti", label: "Halfeti" },
    { id: "hilvan", label: "Hilvan" },
    { id: "siverek", label: "Siverek" },
    { id: "suruç", label: "Suruç" },
    { id: "viranşehir", label: "Viranşehir" },
  ],
  şırnak: [
    { id: "merkez", label: "Merkez" },
    { id: "cizre", label: "Cizre" },
    { id: "silopi", label: "Silopi" },
    { id: "uludere", label: "Uludere" },
    { id: "idil", label: "İdil" },
    { id: "güçlükonak", label: "Güçlükonak" },
  ],
  tekirdağ: [
    { id: "merkez", label: "Merkez" },
    { id: "çorlu", label: "Çorlu" },
    { id: "kapaklı", label: "Kapaklı" },
    { id: "hayrabolu", label: "Hayrabolu" },
    { id: "saray", label: "Saray" },
    { id: "muratlı", label: "Muratlı" },
    { id: "çerkezköy", label: "Çerkezköy" },
    { id: "şarköy", label: "Şarköy" },
    { id: "malkara", label: "Malkara" },
    { id: "marmaraereğlisi", label: "Marmaraereğlisi" },
  ],
  tokat: [
    { id: "merkez", label: "Merkez" },
    { id: "almus", label: "Almus" },
    { id: "artova", label: "Artova" },
    { id: "başçiftlik", label: "Başçiftlik" },
    { id: "erbaa", label: "Erbaa" },
    { id: "niksar", label: "Niksar" },
    { id: "pazar", label: "Pazar" },
    { id: "reşadiye", label: "Reşadiye" },
    { id: "sulusaray", label: "Sulusaray" },
    { id: "turhal", label: "Turhal" },
    { id: "yeşilyurt", label: "Yeşilyurt" },
    { id: "zile", label: "Zile" },
  ],
  trabzon: [
    { id: "merkez", label: "Merkez" },
    { id: "akçaabat", label: "Akçaabat" },
    { id: "araklı", label: "Araklı" },
    { id: "arsin", label: "Arsin" },
    { id: "çarşıbaşı", label: "Çarşıbaşı" },
    { id: "çaykara", label: "Çaykara" },
    { id: "dernekpazarı", label: "Dernekpazarı" },
    { id: "düzköy", label: "Düzköy" },
    { id: "hayrat", label: "Hayrat" },
    { id: "köprübaşı", label: "Köprübaşı" },
    { id: "maçka", label: "Maçka" },
    { id: "ortaçağ", label: "Ortahisar" },
    { id: "sürmene", label: "Sürmene" },
    { id: "tonya", label: "Tonya" },
    { id: "vakfıkebir", label: "Vakfıkebir" },
    { id: "yomra", label: "Yomra" },
  ],
  tunceli: [
    { id: "merkez", label: "Merkez" },
    { id: "çemişgezek", label: "Çemişgezek" },
    { id: "hozat", label: "Hozat" },
    { id: "majat", label: "Mazgirt" },
    { id: "nakşıbendi", label: "Nazımiye" },
    { id: "ovacık", label: "Ovacık" },
    { id: "pertek", label: "Pertek" },
    { id: "pülümür", label: "Pülümür" },
  ],
  uşak: [
    { id: "merkez", label: "Merkez" },
    { id: "banaz", label: "Banaz" },
    { id: "eşme", label: "Eşme" },
    { id: "karahallı", label: "Karahallı" },
    { id: "sivaslı", label: "Sivaslı" },
    { id: "ulubey", label: "Ulubey" },
  ],
  van: [
    { id: "merkez", label: "Merkez" },
    { id: "başkale", label: "Başkale" },
    { id: "çatak", label: "Çatak" },
    { id: "edremit", label: "Edremit" },
    { id: "erdemiş", label: "Erciş" },
    { id: "gürpınar", label: "Gürpınar" },
    { id: "muradiye", label: "Muradiye" },
    { id: "özalp", label: "Özalp" },
  ],
  yalova: [
    { id: "merkez", label: "Merkez" },
    { id: "altınova", label: "Altınova" },
    { id: "armutlu", label: "Armutlu" },
    { id: "çınarcık", label: "Çınarcık" },
    { id: "çiftlikköy", label: "Çiftlikköy" },
    { id: "termal", label: "Termal" },
  ],
  yozgat: [
    { id: "merkez", label: "Merkez" },
    { id: "akdağmadeni", label: "Akdağmadeni" },
    { id: "aydıncık", label: "Aydıncık" },
    { id: "boğazlıyan", label: "Boğazlıyan" },
    { id: "çandır", label: "Çandır" },
    { id: "çayıralan", label: "Çayıralan" },
    { id: "saraykent", label: "Saraykent" },
    { id: "sarıkaya", label: "Sarıkaya" },
    { id: "sorgun", label: "Sorgun" },
    { id: "şefaatli", label: "Şefaatli" },
    { id: "yerköy", label: "Yerköy" },
  ],
  zonguldak: [
    { id: "merkez", label: "Merkez" },
    { id: "alaplı", label: "Alaplı" },
    { id: "devrek", label: "Devrek" },
    { id: "ereğli", label: "Ereğli" },
    { id: "gökçebey", label: "Gökçebey" },
  ],
};

export const countries: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "turkiye", label: "Türkiye" },
  { id: "afganistan", label: "Afganistan" },
  { id: "almanya", label: "Almanya" },
  { id: "andorra", label: "Andorra" },
  { id: "angola", label: "Angola" },
  { id: "antigua-ve-barbuda", label: "Antigua ve Barbuda" },
  { id: "arjantin", label: "Arjantin" },
  { id: "arnavutluk", label: "Arnavutluk" },
  { id: "avustralya", label: "Avustralya" },
  { id: "avusturya", label: "Avusturya" },
  { id: "azerbaycan", label: "Azerbaycan" },
  { id: "bahamalar", label: "Bahamalar" },
  { id: "bahreyn", label: "Bahreyn" },
  { id: "banglades", label: "Bangladeş" },
  { id: "barbados", label: "Barbados" },
  { id: "belarus", label: "Belarus" },
  { id: "belcika", label: "Belçika" },
  { id: "belize", label: "Belize" },
  { id: "benin", label: "Benin" },
  { id: "birlesik-arap-emirlikleri", label: "Birleşik Arap Emirlikleri" },
  { id: "birlesik-krallik", label: "Birleşik Krallık" },
  { id: "bolivya", label: "Bolivya" },
  { id: "bosna-hersek", label: "Bosna-Hersek" },
  { id: "botsvana", label: "Botsvana" },
  { id: "brezilya", label: "Brezilya" },
  { id: "brunei", label: "Brunei" },
  { id: "bulgaristan", label: "Bulgaristan" },
  { id: "burkina-faso", label: "Burkina Faso" },
  { id: "burundi", label: "Burundi" },
  { id: "cibuti", label: "Cibuti" },
  { id: "cile", label: "Şili" },
  { id: "cin", label: "Çin" },
  { id: "danimarka", label: "Danimarka" },
  { id: "demokratik-kongo-cumhuriyeti", label: "Demokratik Kongo Cumhuriyeti" },
  { id: "dominika", label: "Dominika" },
  { id: "dominik-cumhuriyeti", label: "Dominik Cumhuriyeti" },
  { id: "ekvador", label: "Ekvador" },
  { id: "el-salvador", label: "El Salvador" },
  { id: "endonezya", label: "Endonezya" },
  { id: "eritre", label: "Eritre" },
  { id: "ermenistan", label: "Ermenistan" },
  { id: "estonya", label: "Estonya" },
  { id: "etiyopya", label: "Etiyopya" },
  { id: "fiji", label: "Fiji" },
  { id: "filipinler", label: "Filipinler" },
  { id: "fildisi-sahili", label: "Fildişi Sahili" },
  { id: "filistin", label: "Filistin" },
  { id: "finlandiya", label: "Finlandiya" },
  { id: "fransa", label: "Fransa" },
  { id: "gabon", label: "Gabon" },
  { id: "gambiya", label: "Gambiya" },
  { id: "gana", label: "Gana" },
  { id: "gine", label: "Gine" },
  { id: "gine-bissau", label: "Gine-Bissau" },
  { id: "grenada", label: "Grenada" },
  { id: "guatemala", label: "Guatemala" },
  { id: "guyana", label: "Guyana" },
  { id: "guyana-fransiz-guyanasi", label: "Guyana (Fransız Guyanası)" },
  { id: "gurney-gine", label: "Gürcistan" },
  { id: "gurney-gine-bissau", label: "Güney Sudan" },
  { id: "gune", label: "Güney Afrika" },
  { id: "gune-kore", label: "Güney Kore" },
  { id: "haiti", label: "Haiti" },
  { id: "hollanda", label: "Hollanda" },
  { id: "honduras", label: "Honduras" },
  { id: "hindistan", label: "Hindistan" },
  { id: "hirvatistan", label: "Hırvatistan" },
  { id: "ingiltere", label: "İngiltere" },
  { id: "iran", label: "İran" },
  { id: "irlanda", label: "İrlanda" },
  { id: "irak", label: "Irak" },
  { id: "islanda", label: "İzlanda" },
  { id: "ispanya", label: "İspanya" },
  { id: "israil", label: "İsrail" },
  { id: "italya", label: "İtalya" },
  { id: "jamaika", label: "Jamaika" },
  { id: "japonya", label: "Japonya" },
  { id: "kamboçya", label: "Kamboçya" },
  { id: "kamerun", label: "Kamerun" },
  { id: "kanada", label: "Kanada" },
  { id: "karadağ", label: "Karadağ" },
  { id: "katar", label: "Katar" },
  { id: "kazakistan", label: "Kazakistan" },
  { id: "kenya", label: "Kenya" },
  { id: "kibris", label: "Kıbrıs" },
  { id: "kirgizistan", label: "Kırgızistan" },
  { id: "kiribati", label: "Kiribati" },
  { id: "kolombiya", label: "Kolombiya" },
  { id: "komorlar", label: "Komorlar" },
  { id: "kongo", label: "Kongo" },
  { id: "kosova", label: "Kosova" },
  { id: "kostarika", label: "Kosta Rika" },
  { id: "kudus", label: "Kudüs" },
  { id: "küba", label: "Küba" },
  { id: "kuveyt", label: "Kuveyt" },
  { id: "lao", label: "Laos" },
  { id: "latviya", label: "Letonya" },
  { id: "lesotho", label: "Lesotho" },
  { id: "liberya", label: "Liberya" },
  { id: "libya", label: "Libya" },
  { id: "liechtenstein", label: "Liechten" },
  { id: "litvanya", label: "Litvanya" },
  { id: "lübnan", label: "Lübnan" },
  { id: "lüksemburg", label: "Lüksemburg" },
  { id: "macaristan", label: "Macaristan" },
  { id: "madagaskar", label: "Madagaskar" },
  { id: "makedonya", label: "Makedonya" },
  { id: "malavi", label: "Malavi" },
  { id: "maldivler", label: "Maldivler" },
  { id: "malezya", label: "Malezya" },
  { id: "mali", label: "Mali" },
  { id: "malta", label: "Malta" },
  { id: "maritius", label: "Maritius" },
  { id: "meksika", label: "Meksika" },
  { id: "mikronezya", label: "Mikronezya" },
  { id: "moğolistan", label: "Moğolistan" },
  { id: "moldova", label: "Moldova" },
  { id: "monako", label: "Monako" },
  { id: "mozambik", label: "Mozambik" },
  { id: "namibya", label: "Namibya" },
  { id: "nauru", label: "Nauru" },
  { id: "nepal", label: "Nepal" },
  { id: "nijer", label: "Nijer" },
  { id: "nijerya", label: "Nijerya" },
  { id: "nikaragua", label: "Nikaragua" },
  { id: "norvec", label: "Norveç" },
  { id: "ortodoks", label: "Kosta Rika" },
  { id: "orta-afrika-cumhuriyeti", label: "Orta Afrika Cumhuriyeti" },
  { id: "ozbekistan", label: "Özbekistan" },
  { id: "pakistan", label: "Pakistan" },
  { id: "palau", label: "Palau" },
  { id: "palestine", label: "Filistin" },
  { id: "panama", label: "Panama" },
  { id: "papua-yeni-gine", label: "Papua Yeni Gine" },
  { id: "paraguay", label: "Paraguay" },
  { id: "peru", label: "Peru" },
  { id: "pitcairn", label: "Pitcairn" },
  { id: "polonya", label: "Polonya" },
  { id: "portekiz", label: "Portekiz" },
  { id: "porto-rico", label: "Porto Riko" },
  { id: "reunion", label: "Réunion" },
  { id: "romanya", label: "Romanya" },
  { id: "ruanda", label: "Ruanda" },
  { id: "rusya", label: "Rusya" },
  { id: "saint-barthelemy", label: "Saint-Barthélemy" },
  { id: "saint-helena", label: "Saint Helena" },
  { id: "saint-kitts-ve-nevis", label: "Saint Kitts ve Nevis" },
  { id: "saint-lucia", label: "Saint Lucia" },
  { id: "saint-martin", label: "Saint Martin" },
  { id: "saint-pierre-ve-miquelon", label: "Saint Pierre ve Miquelon" },
  { id: "saint-vincent-ve-grenadinler", label: "Saint Vincent ve Grenadinler" },
  { id: "samoa", label: "Samoa" },
  { id: "san-marino", label: "San Marino" },
  { id: "senegal", label: "Senegal" },
  { id: "seychelles", label: "Seyşeller" },
  { id: "sierra-leone", label: "Sierra Leone" },
  { id: "sili", label: "Şili" },
  { id: "singapur", label: "Singapur" },
  { id: "slovakya", label: "Slovakya" },
  { id: "slovenya", label: "Slovenya" },
  { id: "solomon-adalari", label: "Solomon Adaları" },
  { id: "somali", label: "Somali" },
  { id: "somaliland", label: "Somaliland" },
  { id: "srilanka", label: "Sri Lanka" },
  { id: "sudan", label: "Sudan" },
  { id: "surinam", label: "Surinam" },
  { id: "suriye", label: "Suriye" },
  { id: "suudi-arabistan", label: "Suudi Arabistan" },
  { id: "svaziland", label: "Svaziland" },
  { id: "tacikistan", label: "Tacikistan" },
  { id: "taiwan", label: "Tayvan" },
  { id: "tanzanya", label: "Tanzanya" },
  { id: "tayland", label: "Tayland" },
  { id: "togo", label: "Togo" },
  { id: "tokelau", label: "Tokelau" },
  { id: "tonga", label: "Tonga" },
  { id: "trinidad-ve-tobago", label: "Trinidad ve Tobago" },
  { id: "tunus", label: "Tunus" },
  { id: "turkmenistan", label: "Türkmenistan" },
  { id: "turks-ve-caicos-adalari", label: "Turks ve Caicos Adaları" },
  { id: "tuvalu", label: "Tuvalu" },
  { id: "uganda", label: "Uganda" },
  { id: "ukrayna", label: "Ukrayna" },
  { id: "umman", label: "Umman" },
  { id: "uruguay", label: "Uruguay" },
  { id: "vanuatu", label: "Vanuatu" },
  { id: "vatikan", label: "Vatikan" },
  { id: "venezuela", label: "Venezuela" },
  { id: "vietnam", label: "Vietnam" },
  { id: "virgin-adalari-abd", label: "Virgin Adaları (ABD)" },
  { id: "virgin-adalari-ingiliz", label: "Virgin Adaları (İngiliz)" },
  { id: "yemen", label: "Yemen" },
  { id: "yeni-kaledonya", label: "Yeni Kaledonya" },
  { id: "yeni-zelanda", label: "Yeni Zelanda" },
  { id: "yunanistan", label: "Yunanistan" },
  { id: "zambiya", label: "Zambiya" },
  { id: "zimbabve", label: "Zimbabve" },
];

export const educationSituations: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "primarySchool", label: "İlköğretim Okulu" },
  { id: "secondarySchool", label: "Ortaokul" },
  { id: "highSchool", label: "Lise" },
  { id: "associateDegree", label: "Ön Lisans" },
  { id: "degree", label: "Lisans" },
  { id: "masterDegree", label: "Yüksek Lisans" },
  { id: "doctorate", label: "Doktora" },
];

export const years: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "2024", label: "2024" },
  { id: "2023", label: "2023" },
  { id: "2022", label: "2022" },
  { id: "2021", label: "2021" },
  { id: "2020", label: "2020" },
  { id: "2019", label: "2019" },
  { id: "2018", label: "2018" },
  { id: "2017", label: "2017" },
  { id: "2016", label: "2016" },
  { id: "2015", label: "2015" },
  { id: "2014", label: "2014" },
  { id: "2013", label: "2013" },
  { id: "2012", label: "2012" },
  { id: "2011", label: "2011" },
  { id: "2010", label: "2010" },
  { id: "2009", label: "2009" },
  { id: "2008", label: "2008" },
  { id: "2007", label: "2007" },
  { id: "2006", label: "2006" },
  { id: "2005", label: "2005" },
  { id: "2004", label: "2004" },
  { id: "2003", label: "2003" },
  { id: "2002", label: "2002" },
  { id: "2001", label: "2001" },
  { id: "2000", label: "2000" },
  { id: "1999", label: "1999" },
  { id: "1998", label: "1998" },
  { id: "1997", label: "1997" },
  { id: "1996", label: "1996" },
  { id: "1995", label: "1995" },
  { id: "1994", label: "1994" },
  { id: "1993", label: "1993" },
  { id: "1992", label: "1992" },
  { id: "1991", label: "1991" },
  { id: "1990", label: "1990" },
  { id: "1989", label: "1989" },
  { id: "1988", label: "1988" },
  { id: "1987", label: "1987" },
  { id: "1986", label: "1986" },
  { id: "1985", label: "1985" },
  { id: "1984", label: "1984" },
  { id: "1983", label: "1983" },
  { id: "1982", label: "1982" },
  { id: "1981", label: "1981" },
  { id: "1980", label: "1980" },
  { id: "1979", label: "1979" },
  { id: "1978", label: "1978" },
  { id: "1977", label: "1977" },
  { id: "1976", label: "1976" },
  { id: "1975", label: "1975" },
  { id: "1974", label: "1974" },
  { id: "1973", label: "1973" },
  { id: "1972", label: "1972" },
  { id: "1971", label: "1971" },
  { id: "1970", label: "1970" },
  { id: "1969", label: "1969" },
  { id: "1968", label: "1968" },
  { id: "1967", label: "1967" },
  { id: "1966", label: "1966" },
  { id: "1965", label: "1965" },
  { id: "1964", label: "1964" },
  { id: "1963", label: "1963" },
  { id: "1962", label: "1962" },
  { id: "1961", label: "1961" },
  { id: "1960", label: "1960" },
  { id: "1959", label: "1959" },
  { id: "1958", label: "1958" },
  { id: "1957", label: "1957" },
  { id: "1956", label: "1956" },
  { id: "1955", label: "1955" },
  { id: "1954", label: "1954" },
  { id: "1953", label: "1953" },
  { id: "1952", label: "1952" },
  { id: "1951", label: "1951" },
  { id: "1950", label: "1950" },
];

export const gradingSystems: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "1", label: "4'lük not sistemi" },
  { id: "2", label: "100'lük not sistemi" },
];

export const driverClasses: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "A1", label: "A1" },
  { id: "A2", label: "A2" },
  { id: "A", label: "A" },
  { id: "B1", label: "B1" },
  { id: "B", label: "B" },
  { id: "BE", label: "BE" },
  { id: "C1", label: "C1" },
  { id: "C1E", label: "C1E" },
  { id: "C", label: "C" },
  { id: "CE", label: "CE" },
  { id: "D1", label: "D1" },
  { id: "D1E", label: "D1E" },
  { id: "D", label: "D" },
  { id: "DE", label: "DE" },
  { id: "F", label: "F" },
  { id: "G", label: "G" },
  { id: "K", label: "K" },
];

export const durationType: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "saat", label: "Saat" },
  { id: "gün", label: "Gün" },
  { id: "hafta", label: "Hafta" },
  { id: "ay", label: "Ay" },
  { id: "yıl", label: "Yıl" },
];

export const languageOptions: NSSelectBoxType.ISelectBoxItem[] = [
  {
    id: "english",
    label: "İngilizce",
  },
  {
    id: "spanish",
    label: "İspanyolca",
  },
  {
    id: "french",
    label: "Fransızca",
  },
  {
    id: "german",
    label: "Almanca",
  },
  {
    id: "chinese",
    label: "Çince",
  },
  {
    id: "japanese",
    label: "Japonca",
  },
  {
    id: "korean",
    label: "Korece",
  },
  {
    id: "arabic",
    label: "Arapça",
  },
  {
    id: "portuguese",
    label: "Portekizce",
  },
  {
    id: "russian",
    label: "Rusça",
  },
];

export const languageSkillsOptions: NSSelectBoxType.ISelectBoxItem[] = [
  {
    id: "basic",
    label: "Temel",
  },
  {
    id: "conversational",
    label: "Konuşma Düzeyinde",
  },
  {
    id: "fluent",
    label: "Akıcı",
  },
  {
    id: "native",
    label: "Anadil Seviyesinde",
  },
];

export const workingSectorType: NSSelectBoxType.ISelectBoxItem[] = [
  { id: "bankacılık-finans", label: "Bankacılık ve Finans" },
  { id: "bilişim-yazılım", label: "Bilişim ve Yazılım" },
  { id: "bilgi-teknoloji", label: "Bilgi ve İletişim Teknolojileri" },
  { id: "eğitim", label: "Eğitim" },
  { id: "enerji", label: "Enerji" },
  { id: "hizmet", label: "Profesyonel, Bilimsel ve Teknik Hizmetler" },
  { id: "hayvancılık", label: "Hayvancılık" },
  { id: "imalat", label: "İmalat" },
  { id: "inşaat", label: "İnşaat" },
  { id: "madencilik", label: "Madencilik" },
  { id: "medya", label: "Medya ve Yayıncılık" },
  { id: "ormancılık", label: "Ormancılık" },
  { id: "sağlık", label: "Sağlık ve Sosyal Hizmetler" },
  { id: "sanat", label: "Kültür, Sanat ve Eğlence" },
  { id: "tarım", label: "Tarım" },
  { id: "toptan-perakende", label: "Toptan ve Perakende Ticaret" },
  { id: "turizm", label: "Turizm" },
  { id: "ulaşım", label: "Ulaştırma ve Lojistik" },
  { id: "diğer", label: "Diğer" },
];
