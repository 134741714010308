import { NSCvFormStepType } from "../components/CvFormStep/CvFormStep.types";
import colors from "../style/colors";
import { ReactComponent as UserEmpty } from "../assets/icons/userEmpty.svg";
import { ReactComponent as Phone } from "../assets/icons/contactPhone.svg";
import { ReactComponent as DocumentGraduate } from "../assets/icons/document-graduate-hat.svg";
import { ReactComponent as Health } from "../assets/icons/health-2.svg";
import { ReactComponent as Earth } from "../assets/icons/globe-earth-home-world.svg";
import { ReactComponent as Add } from "../assets/icons/add.svg";
export const formSteps: NSCvFormStepType.ICvFormStepItem[] = [
  {
    title: "Kişisel Bilgiler",
    id: "kisisel-bilgiler",
    isRequired: true,
    color: colors.green.green02,
    icon: <UserEmpty />,
    order: 1,
  },
  {
    title: "İletişim Bilgileri",
    id: "iletisim-bilgileri",
    isRequired: true,
    color: colors.blue.blue02,
    icon: <Phone />,
    order: 2,
  },
  {
    title: "Eğitim Bilgileri",
    id: "ogrenim-durumu",
    isRequired: true,
    color: colors.orange.orange02,
    icon: <DocumentGraduate />,
    order: 3,
  },
  {
    title: "Tamamlayıcı Bilgiler",
    id: "tamamlayıcı-bilgiler",
    isRequired: true,
    color: colors.yellow.yellow02,
    icon: <Earth />,
    order: 5,
  },
  {
    title: "Diğer Alanlar",
    id: "diger-alanlar",
    isRequired: false,
    color: colors.dark.dark,
    icon: <Add />,
    order: 6,
  },
];
