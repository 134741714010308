import { NSAccordionStyle } from "./Accordion.style";
import { NSAccordionType } from "./Accordion.types";
import colors from "../../style/colors";

export const Accordion = ({
  title,
  children,
  isOpen,
  setIsOpen,
}: NSAccordionType.IAccordion) => {
  return (
    <NSAccordionStyle.Container>
      <NSAccordionStyle.TitleContainer
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <NSAccordionStyle.IconContainer>
          {isOpen ? (
            <NSAccordionStyle.CustomRemove
              isOpen={isOpen}
              width={32}
              height={32}
              fill={colors.light.light}
            />
          ) : (
            <NSAccordionStyle.CustomAdd
              isOpen={isOpen}
              width={32}
              height={32}
              fill={colors.light.light}
            />
          )}
        </NSAccordionStyle.IconContainer>
        {title}
      </NSAccordionStyle.TitleContainer>
      <NSAccordionStyle.ContentAnimationWrapper isOpen={isOpen}>
        <NSAccordionStyle.ContentContainer>
          {children}
        </NSAccordionStyle.ContentContainer>
      </NSAccordionStyle.ContentAnimationWrapper>
    </NSAccordionStyle.Container>
  );
};
