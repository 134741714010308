import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSCenteredImageGridStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    ${({ isMobile }) =>
      !isMobile &&
      css`
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
        transition: 1s all;
      `}
    ${({ isMobile }) =>
      isMobile &&
      css`
        flex-direction: column;
        gap: 16px;
      `}
  `;
  export const LogoImage = styled.img<{ isMobile: boolean }>`
    border-radius: 32px;
    transition: filter 0.5s;
    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 100%;
      `}
    ${({ isMobile }) =>
      !isMobile &&
      css`
        max-width: 360px;
        height: 280px;
      `}
  `;
  export const HoverOrb = styled.div`
    position: absolute;
    display: flex;
    opacity: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    height: 64px;
    width: 64px;
    background-color: ${colors.light.light};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s opacity;
  `;

  export const ImageContainer = styled.button`
    all: unset;
    cursor: pointer;
    position: relative;

    &:hover {
      ${LogoImage} {
        filter: brightness(76%);
      }
    }
    &:hover {
      ${HoverOrb} {
        opacity: 1;
      }
    }
  `;
}
