import styled from "styled-components";
import colors from "../../../../style/colors";
import { NSButtonStyle } from "../../../../components/Button/Button.style";

export namespace NSLoginPageMobileStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
  `;

  export const Form = styled.form`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    flex-direction: column;
    margin-top: 24px;
  `;

  export const Title = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24.96px;
    color: ${colors.dark.dark};
  `;

  export const InputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-direction: column;
  `;

  export const BottomContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    ${NSButtonStyle.Button} {
      line-height: 22.96px;
    }
  `;
  export const FooterText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22.69px;
    color: ${colors.neutrals.grey05};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    justify-items: center;
    width: 100%;
    ${NSButtonStyle.Button} {
      font-size: 14px;
      line-height: 22.69px;
      font-weight: 700;
    }
  `;
}
