import { z } from "zod";

export namespace NSCVOtherType {
  export type ICVOther = {
    onSubmitOther: (data: CVOtherInfoType) => void;
  };

  export const CVOtherSchema = z.object({
    cvName: z
      .string({
        invalid_type_error: "Özgeçmiş zorunlu",
        required_error: "Özgeçmiş zorunlu",
      })
      .min(1, "Özgeçmiş Zorunlu"),
  });

  export type CVOtherInfoType = z.infer<typeof CVOtherSchema>;
}
