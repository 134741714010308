import { NSAnimatedArrowButtonStyle } from "./AnimatedArrowButton.style";
import { NSAnimatedArrowButtonType } from "./AnimatedArrowButton.types";
import { ReactComponent as LongArrow } from "../../assets/icons/longArrow.svg";
import { ReactComponent as ShortArrow } from "../../assets/icons/shortArrow.svg";
import { useEffect, useState } from "react";

export const AnimatedArrowButton = ({
  label,
  isMobile,
  changingSpeed = 1000,
  onClick,
}: NSAnimatedArrowButtonType.IAnimatedArrowButton) => {
  const [toggleArrow, setToggleArrow] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setToggleArrow((prevState) => !prevState);
    }, changingSpeed);

    return () => clearInterval(interval);
  }, [changingSpeed]);
  return (
    <NSAnimatedArrowButtonStyle.Container isMobile={isMobile} onClick={onClick}>
      <NSAnimatedArrowButtonStyle.Title isMobile={isMobile}>
        {label}
      </NSAnimatedArrowButtonStyle.Title>
      {toggleArrow ? <LongArrow /> : <ShortArrow />}
    </NSAnimatedArrowButtonStyle.Container>
  );
};
