import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSTextAreaStyle {
  export const Container = styled.div`
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    font-family: "ReadexPro";
  `;

  export const BaseTextArea = styled.textarea<{
    error?: boolean;
  }>`
    width: 100%;
    height: 82px;
    border: ${(props) =>
      `2px solid  ${
        props.error ? colors.error.error01 : colors.neutrals.grey03
      } `};
    color: ${colors.dark.dark};
    font-weight: 400;
    line-height: 22.96px;
    font-size: 14px;
    background-color: transparent;
    border-radius: 8px;
    padding: 16px 16px;

    :focus {
      border-color: ${(props) =>
        props.error ? colors.error.error01 : colors.blue.blue01};
    }
    :hover {
      border-color: ${(props) =>
        props.error ? colors.error.error01 : colors.blue.blue01};
    }
    ::placeholder {
      color: ${colors.neutrals.grey05};
      font-family: "ReadexPro";
      font-weight: 400;
      line-height: 22.96px;
      font-size: 14px;
    }
    :disabled {
      background-color: ${colors.neutrals.grey01};
      border-color: ${colors.neutrals.grey03};
      color: ${colors.neutrals.grey05};
    }
  `;

  export const Label = styled.div`
    font-weight: 400;
    line-height: 19.68px;
    font-size: 12px;
    height: 16px;
    top: -10px;
    left: 8px;
    color: ${colors.neutrals.grey06};
    padding: 0 8px;
    background-color: ${colors.light.light};
    position: absolute;
    display: flex;
    gap: 2px;
  `;

  export const SuffixItemContainer = styled.div`
    position: absolute;
    right: 16px;
  `;

  export const PrefixItemContainer = styled.div`
    position: absolute;
    left: 16px;
  `;

  export const ErrorMessage = styled.div`
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    top: 80px;
    color: ${colors.error.error01};
    left: 0;
    background-color: transparent;
    position: absolute;
  `;

  export const InfoText = styled.div`
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    top: 80px;
    color: ${colors.neutrals.grey05};
    left: 0;
    background-color: transparent;
    position: absolute;
  `;

  export const Star = styled.div`
    font-weight: 400;
    line-height: 19.68px;
    font-size: 12px;
    color: ${colors.dark.dark};
  `;
}
