import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSStatusPageDesktopStyle {
  export const Container = styled.div``;

  export const MainContainer = styled.div`
    padding: 84px 112px;
    overflow: hidden;
    background-color: ${colors.background.bg};
    position: relative;
    display: flex;
    gap: 56px;
    padding-bottom: 120px;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 40px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 40px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 40px;
    }
  `;

  export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
  `;

  export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin-top: -5px;
  `;

  export const PageDesc = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.96px;
    text-align: left;
    margin: 16px 0px 32px 0px;
  `;
}
