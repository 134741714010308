import { useRef, useState } from "react";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { NSLearnWithUsMobileStyle } from "./LearnWithUsMobile.style";
import { MenuMobile } from "../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../components/HeaderMobile/HeaderMobile";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { Footer } from "../../../components/Footer/Footer";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import ReactPlayer from "react-player";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { NSLearnWithUsType } from "../LearnWithUs.types";
import { AccordionList } from "../../../components/AccordionList/AccordionList";
import { AccordionOpportunitiesMobile } from "../../../components/AccordionOpportunitiesMobile/AccordionOpportunitiesMobile";
import { RoundedCardDescriptionCarousel } from "../../../components/RoundedCardDescriptionCarousel/RoundedCardDescriptionCarousel";
import { TempSpacer } from "../../../util/tempSpacer";
import { Modal } from "../../../components/Modal/Modal";
import useStickyElement from "../../../util/useStickyElement";
import { MobileSwiperHelper } from "../../../components/MobileSwiperHelper/MobileSwiperHelper";
function LearnWithUsMobile({
  learnWithUsData,
}: NSLearnWithUsType.ILearnWithUs) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <NSLearnWithUsMobileStyle.Container>
      <NSLearnWithUsMobileStyle.LeftContainer>
        <NSLearnWithUsMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={learnWithUsData.anchorList}
            socialLinks={learnWithUsData.footer.socialLinks}
          />
        </NSLearnWithUsMobileStyle.RightContainer>
        {isSticky && (
          <HeaderMobile
            logoClicked={() => {}}
            menuButtonClicked={() => setShowMenu(true)}
            isDark={true}
          />
        )}
        <NSLearnWithUsMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={
              <HeaderMobile
                logoClicked={() => {}}
                menuButtonClicked={() => setShowMenu(true)}
                isDark={false}
              />
            }
            isMobile={isMobile}
            title="Bizimle Öğren"
            backgroundColor={colors.yellow.yellow01}
            iconColor={colors.yellow.yellow04}
          />
        </NSLearnWithUsMobileStyle.StickyHelperContainer>

        <NSLearnWithUsMobileStyle.MainContainer>
          <NSLearnWithUsMobileStyle.Title>
            Sosyal Öğrenme Platformumuz:
          </NSLearnWithUsMobileStyle.Title>
          <NSLearnWithUsMobileStyle.GoLogo src="images/learn-with-us/go-gelisim-okulu.png" />
          <NSLearnWithUsMobileStyle.Description>
            <b>
              Çalışma arkadaşlarımızın farklı kişisel ve mesleki öğrenim
              ihtiyaçları ile yetkinlik gelişim alanları doğrultusunda sürekli
              gelişimlerini destekleyen eğitim programları tasarlamaktayız.
            </b>{" "}
            GO Gelişim Okulu bu amaca özel olarak geliştirilmiş sosyal öğrenme
            platformudur. Bu platformda Yetkinlik ve Liderlik Gelişim
            Programlarının yanı sıra çalışan gelişimine bütünsel yaklaşarak
            planlanan Kişisel Gelişim Programları da yer almaktadır.
          </NSLearnWithUsMobileStyle.Description>
          <NSLearnWithUsMobileStyle.SwiperContainer>
            <MobileSwiperHelper
              childrenList={learnWithUsData.iconDescriptionList.statsList.map(
                (stat) => (
                  <NSLearnWithUsMobileStyle.StatCard>
                    <NSLearnWithUsMobileStyle.StatImage
                      src={stat.icon as string}
                    />
                    {stat.description}
                  </NSLearnWithUsMobileStyle.StatCard>
                )
              )}
            />
          </NSLearnWithUsMobileStyle.SwiperContainer>
          <TempSpacer height={40} />
          <ImageCarousel
            {...learnWithUsData.imageCarouselData}
            isMobile={isMobile}
            imageFit="none"
          />
          <TempSpacer height={80} />
          <AccordionList
            {...learnWithUsData.accordionList}
            isMobile={isMobile}
          />
          <TempSpacer height={40} />

          <SingleRoundedCard
            {...learnWithUsData.singleRoundedCard}
            isMobile={isMobile}
          />
          <TempSpacer height={40} />
          <VideoPlayerCard
            isMobile={isMobile}
            showTitle={false}
            imageUrl={learnWithUsData.videoPlayerImageAndVideo.image}
            height="568px"
            width="568px"
            onClick={onClickHandler}
          />

          <TempSpacer height={80} />
          <AccordionOpportunitiesMobile
            title="Fikirlerin Bizim İçin Değerli"
            opportunityList={learnWithUsData.opportunityList}
          />
          <TempSpacer height={40} />
          <HeadingText
            isMobile={isMobile}
            title="Mavi Direksiyon Ödülleri"
            description="Çalışma arkadaşlarımız Mavi Direksiyon ödülleri adı altında farklı başlıklar altında ödüllendirilmektedir."
          />
          <RoundedCardDescriptionCarousel
            {...learnWithUsData.roundedDescriptionCarousel}
            isMobile={isMobile}
          />
        </NSLearnWithUsMobileStyle.MainContainer>
        <Footer {...learnWithUsData.footer} isMobile={isMobile} />
      </NSLearnWithUsMobileStyle.LeftContainer>

      <CircleButton onClick={scrollToTop} isMobile={isMobile} />

      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <ReactPlayer
          ref={playerRef}
          url={learnWithUsData.videoPlayerImageAndVideo.video}
          controls={true}
          width={"100%"}
        />
      </Modal>
    </NSLearnWithUsMobileStyle.Container>
  );
}

export default LearnWithUsMobile;
