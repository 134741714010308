import { NSDashboardMenuStyle } from "./DashboardMenu.style";
import { NSDashboardMenuType } from "./DashboardMenu.types";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevronRight.svg";
export const DashboardMenu = ({
  menuItem,
}: NSDashboardMenuType.IDashboardMenu) => {
  return (
    <NSDashboardMenuStyle.Container>
      {menuItem.map((item) => (
        <NSDashboardMenuStyle.MenuItemLink to={item.link} key={item.link}>
          {({ isActive }) => (
            <NSDashboardMenuStyle.MenuItem isActive={isActive}>
              <NSDashboardMenuStyle.MenuItemLeft>
                {item.icon}
                {item.label}
              </NSDashboardMenuStyle.MenuItemLeft>
              <ChevronRight />
            </NSDashboardMenuStyle.MenuItem>
          )}
        </NSDashboardMenuStyle.MenuItemLink>
      ))}
    </NSDashboardMenuStyle.Container>
  );
};
