import { z } from "zod";

export namespace NSCvEducationType {
  export type ICvEducation = {
    onSubmitEducation: (data: CVEducationInfoType) => void;
  };

  const primaryAndSecondarySchoolsOptional = z.object({
    schoolName: z.string().optional(),
    startDate: z.object({ id: z.string(), label: z.string() }).optional(),
    endDate: z.object({ id: z.string(), label: z.string() }).optional(),
  });

  const primaryAndSecondarySchools = z.object({
    schoolName: z.string().min(1, "Okul Adı zorunlu"),
    startDate: z
      .object(
        { id: z.string(), label: z.string() },
        {
          invalid_type_error: "Başlangıç tarihi zorunlu",
          required_error: "Başlangıç tarihi zorunlu",
        }
      )
      .refine((data) => data.id && data.label, {
        message: "Başlangıç tarihi zorunlu",
        path: ["startDate"],
      }),
    endDate: z
      .object(
        { id: z.string(), label: z.string() },
        {
          invalid_type_error: "Bitiş tarihi zorunlu",
          required_error: "Bitiş tarihi zorunlu",
        }
      )
      .refine((data) => data.id && data.label, {
        message: "Bitiş tarihi zorunlu",
        path: ["endDate"],
      }),
  });

  export const highScoolAndAssociateDegreeInfoSchema = z
    .object({
      schoolName: z.string().min(1, "Okul Adı zorunlu"),
      department: z.string().min(1, "Bölüm zorunlu"),
      startDate: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Başlangıç tarihi zorunlu",
            required_error: "Başlangıç tarihi zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Başlangıç tarihi zorunlu",
        }),
      endDate: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Bitiş tarihi zorunlu",
            required_error: "Bitiş tarihi zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Bitiş tarihi zorunlu",
        }),

      gradingSystem: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Not Sistemi zorunlu",
            required_error: "Not Sistemi zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Not sistemi zorunlu",
        }),
      gpa: z.string().min(1, "Not ortalaması zorunlu"),
    })

    .refine(
      (data) => {
        if (data.gpa === undefined || data.gpa === null || data.gpa === "") {
          return true;
        }

        if (data.gradingSystem && data.gradingSystem.id === "1") {
          if (data.gpa && /^[0-9](\.[0-9])?$/.test(data.gpa)) {
            const numVal = parseFloat(data.gpa);
            return numVal >= 0 && numVal <= 4;
          } else {
            return false;
          }
        } else if (data.gradingSystem && data.gradingSystem.id === "2") {
          if (
            data.gpa &&
            /^100(\.0)?$|^([0-9]|[1-9][0-9])(\.[0-9])?$/.test(data.gpa)
          ) {
            const numVal = parseFloat(data.gpa);
            return numVal >= 0 && numVal <= 100;
          } else {
            return false;
          }
        }
        return false;
      },
      {
        message: "Not ortalaması doğru formatta değil ",
        path: ["gpa"],
      }
    );

  export const highScoolAndAssociateDegreeInfoSchemaOptionals = z.object({
    schoolName: z.string().optional(),
    department: z.string().optional(),
    startDate: z.object({ id: z.string(), label: z.string() }).optional(),
    endDate: z.object({ id: z.string(), label: z.string() }).optional(),
    gradingSystem: z.object({ id: z.string(), label: z.string() }).optional(),
    gpa: z.string().optional(),
    continue: z.boolean().nullable().optional(),
  });

  export const educationInfoSchema = z
    .object({
      schoolName: z.string().min(1, "Okul Adı zorunlu"),
      department: z.string().min(1, "Bölüm zorunlu"),
      startDate: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Başlangıç tarihi zorunlu",
            required_error: "Başlangıç tarihi zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Başlangıç tarihi zorunlu",
        }),
      endDate: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Bitiş tarihi zorunlu",
            required_error: "Bitiş tarihi zorunlu",
          }
        )
        .optional()
        .nullable(),

      gradingSystem: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Not Sistemi zorunlu",
            required_error: "Not Sistemi zorunlu",
          }
        )
        .optional()
        .nullable(),
      gpa: z.string().optional(),
      continue: z.boolean().nullable().optional(),
    })
    .refine(
      (data) => {
        if (
          (data.continue === false ||
            data.continue === null ||
            data.continue === undefined) &&
          (data.gradingSystem === undefined ||
            data.gradingSystem === null ||
            data.gradingSystem.id === "")
        ) {
          return false;
        }
        return true;
      },
      {
        message: "Not Sistemi zorunlu",
        path: ["gradingSystem"],
      }
    )
    .refine(
      (data) => {
        if (
          (data.continue === false ||
            data.continue === null ||
            data.continue === undefined) &&
          (data.gpa === undefined || data.gpa === null || data.gpa === "")
        ) {
          return false;
        }
        return true;
      },
      {
        message: "Mezuniyet Derecesi zorunlu",
        path: ["gpa"],
      }
    )
    .refine(
      (data) => {
        if (
          (data.continue === false ||
            data.continue === null ||
            data.continue === undefined) &&
          (data.endDate === undefined ||
            data.endDate === null ||
            data.endDate.id === "")
        ) {
          return false;
        }
        return true;
      },
      {
        message: "Bitiş tarihi zorunlu",
        path: ["endDate"],
      }
    )
    .refine(
      (data) => {
        if (data.gpa === undefined || data.gpa === null || data.gpa === "") {
          return true;
        }

        if (data.gradingSystem && data.gradingSystem.id === "1") {
          if (data.gpa && /^[0-9](\.[0-9])?$/.test(data.gpa)) {
            const numVal = parseFloat(data.gpa);
            return numVal >= 0 && numVal <= 4;
          } else {
            return false;
          }
        } else if (data.gradingSystem && data.gradingSystem.id === "2") {
          if (
            data.gpa &&
            /^100(\.0)?$|^([0-9]|[1-9][0-9])(\.[0-9])?$/.test(data.gpa)
          ) {
            const numVal = parseFloat(data.gpa);
            return numVal >= 0 && numVal <= 100;
          } else {
            return false;
          }
        }
        return false;
      },
      {
        message: "Not ortalaması doğru formatta değil ",
        path: ["gpa"],
      }
    );

  export const educationInfoSchemaOptionals = z.object({
    schoolName: z.string().optional(),
    department: z.string().optional(),
    startDate: z.object({ id: z.string(), label: z.string() }).optional(),
    endDate: z.object({ id: z.string(), label: z.string() }).optional(),
    gradingSystem: z.object({ id: z.string(), label: z.string() }).optional(),
    gpa: z.string().optional(),
    continue: z.boolean().nullable().optional(),
  });

  export const CvEducationSchema = z
    .object({
      educationSituation: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Eğitim Durumu zorunlu",
            required_error: "Eğitim Durumu zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Eğitim Durumu zorunlu",
          path: ["education"],
        }),
      associateDegreeInfo: z.string().optional(),
      primarySchool: primaryAndSecondarySchoolsOptional.optional(),
      secondarySchool: primaryAndSecondarySchoolsOptional.optional(),
      highSchool: highScoolAndAssociateDegreeInfoSchemaOptionals.optional(),
      associateDegree: educationInfoSchemaOptionals.optional(),
      degree: z.array(educationInfoSchemaOptionals).optional(),
      masterDegree: z.array(educationInfoSchemaOptionals).optional(),
      doctorate: educationInfoSchemaOptionals.optional(),
    })
    .superRefine((data, ctx) => {
      const createError = (educationStuation: string, educationData: any) => {
        let result;

        if (
          educationStuation === "primarySchool" ||
          educationStuation === "secondarySchool"
        ) {
          result = primaryAndSecondarySchools
            .optional()
            .safeParse(educationData);
        }

        if (
          educationStuation === "highSchool" ||
          educationStuation === "associateDegree"
        ) {
          result = highScoolAndAssociateDegreeInfoSchema
            .optional()
            .safeParse(educationData);
        }

        if (educationStuation === "doctorate") {
          result = educationInfoSchema.optional().safeParse(educationData);
        }

        if (
          educationStuation === "degree" ||
          educationStuation === "masterDegree"
        ) {
          result = z
            .array(educationInfoSchema)
            .optional()
            .safeParse(educationData);
        }

        if (result && !result.success) {
          result.error.issues.forEach((issue) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [educationStuation, ...issue.path],
              message: issue.message,
            });
          });
        }
      };

      if (data.educationSituation.id === "primarySchool") {
        createError("primarySchool", data.primarySchool);
      }
      if (data.educationSituation.id === "secondarySchool") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
      }
      if (data.educationSituation.id === "highSchool") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
        createError("highSchool", data.highSchool);
      }
      if (data.educationSituation.id === "associateDegree") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
        createError("highSchool", data.highSchool);
        createError("associateDegree", data.associateDegree);
      }

      if (data.educationSituation.id === "degree") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
        createError("highSchool", data.highSchool);
        createError("degree", data.degree);
      }
      if (data.educationSituation.id === "masterDegree") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
        createError("highSchool", data.highSchool);
        createError("degree", data.degree);
        createError("masterDegree", data.masterDegree);
      }

      if (data.educationSituation.id === "doctorate") {
        createError("primarySchool", data.primarySchool);
        createError("secondarySchool", data.secondarySchool);
        createError("highSchool", data.highSchool);
        createError("degree", data.degree);
        createError("masterDegree", data.masterDegree);
        createError("doctorate", data.doctorate);
      }

      if (
        (data.educationSituation.id === "degree" ||
          data.educationSituation.id === "masterDegree" ||
          data.educationSituation.id === "doctorate") &&
        data.associateDegreeInfo === "yes"
      ) {
        createError("associateDegree", data.associateDegree);
      }
    });

  export type CVEducationInfoType = z.infer<typeof CvEducationSchema>;
}
