import { NSCVExperiencesStyle } from "./CVExperiences.style";
import { NSCVExperiencesType } from "./CVExperiences.types";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Button } from "../Button/Button";
import { Accordion } from "../Accordion/Accordion";
import colors from "../../style/colors";
import { Input } from "../Input/Input";
import { DatePicker } from "../DatePicker/DatePicker";
import { ReactComponent as Add } from "../../assets/icons/addWithOutline.svg";
import { workingSectorType } from "../../util/formDropdownItems";
import { SelectBox } from "../SelectBox/SelectBox";

interface ExperienceType {
  companyName: string;
  sector: { id: string; label: string };
  position: string;
  startDate: string;
  endDate: string;
}

const defaultValues: ExperienceType = {
  companyName: "",
  sector: { id: "", label: "" },
  position: "",
  startDate: "",
  endDate: "",
};

export const CVExperiences = ({
  onSubmitExperiences,
}: NSCVExperiencesType.ICVExperiences) => {
  const {
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<NSCVExperiencesType.CVExperiencesInfoType>({
    defaultValues: {
      experiences: [defaultValues],
      internship: [defaultValues],
    },
    resolver: zodResolver(NSCVExperiencesType.CVExperiencesSchema),
  });
  const onSubmit: SubmitHandler<NSCVExperiencesType.CVExperiencesInfoType> = (
    data
  ) => {
    onSubmitExperiences(data);
  };

  const [isOpen, setIsOpen] = useState(false);

  const {
    fields: experiencesFields,
    append: appendExperience,
    remove: removeExperience,
  } = useFieldArray({
    control,
    name: "experiences",
  });

  const {
    fields: internshipFields,
    append: appendInternship,
    remove: removeInternship,
  } = useFieldArray({
    control,
    name: "internship",
  });

  const onClickGiveUp = () => {
    removeInternship(internshipFields.map((_, index) => index));
    removeExperience(experiencesFields.map((_, index) => index));
    setValue("experiences", [defaultValues]);
    setValue("internship", [defaultValues]);
    setIsOpen(false);
    clearErrors();
  };
  console.log("error", errors);
  return (
    <Accordion isOpen={isOpen} setIsOpen={setIsOpen} title="İş Tecrübeleri">
      <NSCVExperiencesStyle.FormContainer
        onSubmit={handleSubmit(onSubmit)}
        id="CVExperiences"
      >
        <NSCVExperiencesStyle.AskTitle>
          İş Tecrübesi
        </NSCVExperiencesStyle.AskTitle>
        <NSCVExperiencesStyle.AskLabel>
          İş deneyimlerini sondan başlayarak bizimle paylaş.
        </NSCVExperiencesStyle.AskLabel>

        {experiencesFields.map((field, index) => (
          <NSCVExperiencesStyle.ArrayContainer key={field.id}>
            {index > 0 && (
              <NSCVExperiencesStyle.AskTitle>
                İş Tecrübesi {experiencesFields.length !== 1 ? index + 1 : ""}
                {experiencesFields.length !== 1 && (
                  <Button
                    onClick={() => removeExperience(index)}
                    width={135}
                    height={40}
                    fontSize={16}
                    variant="text"
                    color={colors.blue.blue01}
                  >
                    Kaldır
                  </Button>
                )}
              </NSCVExperiencesStyle.AskTitle>
            )}
            <Controller
              name={`experiences.${index}.companyName`}
              control={control}
              render={({ field }) => (
                <Input
                  label="İş Yeri Adı"
                  placeholder="İş Yeri Adı"
                  isRequired
                  {...field}
                  error={errors.experiences?.[index]?.companyName?.message}
                />
              )}
            />
            <NSCVExperiencesStyle.SideBySideContainer>
              <SelectBox
                label="Sektör"
                placeholder="Sektör"
                items={workingSectorType}
                onSelect={(item) =>
                  setValue(`experiences.${index}.sector`, item)
                }
                error={errors.experiences?.[index]?.sector?.message}
              />
              <Controller
                name={`experiences.${index}.position`}
                control={control}
                render={({ field }) => (
                  <Input
                    label="Görevin / Ünvanın"
                    placeholder="Görevin / Ünvanın"
                    isRequired
                    {...field}
                    error={errors.experiences?.[index]?.position?.message}
                  />
                )}
              />
            </NSCVExperiencesStyle.SideBySideContainer>
            <NSCVExperiencesStyle.SideBySideContainer>
              <DatePicker
                placeholder="Giriş Ay / Yıl"
                dateFormat="MM/yyyy"
                onChange={(date) =>
                  setValue(
                    `experiences.${index}.startDate`,
                    date?.toDateString()
                  )
                }
                error={errors.experiences?.[index]?.startDate?.message}
              />

              <DatePicker
                placeholder="Çıkış Ay / Yıl"
                dateFormat="MM/yyyy"
                onChange={(date) =>
                  setValue(`experiences.${index}.endDate`, date?.toDateString())
                }
                error={errors.experiences?.[index]?.endDate?.message}
              />
            </NSCVExperiencesStyle.SideBySideContainer>

            <NSCVExperiencesStyle.AddButtonContainer>
              <Button
                onClick={() => appendExperience(defaultValues)}
                width={135}
                height={40}
                fontSize={16}
                variant="text"
                color={colors.blue.blue01}
              >
                <Add /> Başka Ekle
              </Button>
            </NSCVExperiencesStyle.AddButtonContainer>

            {index !== experiencesFields.length - 1 && (
              <NSCVExperiencesStyle.Seperator />
            )}
          </NSCVExperiencesStyle.ArrayContainer>
        ))}

        <NSCVExperiencesStyle.AskTitle>
          Staj Tecrübesi
        </NSCVExperiencesStyle.AskTitle>
        <NSCVExperiencesStyle.AskLabel>
          Staj deneyimlerini sondan başlayarak bizimle paylaş.
        </NSCVExperiencesStyle.AskLabel>
        {internshipFields.map((field, index) => (
          <NSCVExperiencesStyle.ArrayContainer key={field.id}>
            {index > 0 && (
              <NSCVExperiencesStyle.AskTitle>
                Staj Tecrübesi {internshipFields.length !== 1 ? index + 1 : ""}
                {internshipFields.length !== 1 && (
                  <Button
                    onClick={() => removeInternship(index)}
                    width={135}
                    height={40}
                    fontSize={16}
                    variant="text"
                    color={colors.blue.blue01}
                  >
                    Kaldır
                  </Button>
                )}
              </NSCVExperiencesStyle.AskTitle>
            )}
            <Controller
              name={`internship.${index}.companyName`}
              control={control}
              render={({ field }) => (
                <Input
                  label="İş Yeri Adı"
                  placeholder="İş Yeri Adı"
                  isRequired
                  {...field}
                  error={errors.internship?.[index]?.companyName?.message}
                />
              )}
            />
            <NSCVExperiencesStyle.SideBySideContainer>
              <SelectBox
                label="Sektör"
                placeholder="Sektör"
                items={workingSectorType}
                onSelect={(item) =>
                  setValue(`internship.${index}.sector`, item)
                }
                error={errors.internship?.[index]?.sector?.message}
              />
              <Controller
                name={`internship.${index}.position`}
                control={control}
                render={({ field }) => (
                  <Input
                    label="Görevin / Ünvanın"
                    placeholder="Görevin / Ünvanın"
                    isRequired
                    {...field}
                    error={errors.internship?.[index]?.position?.message}
                  />
                )}
              />
            </NSCVExperiencesStyle.SideBySideContainer>
            <NSCVExperiencesStyle.SideBySideContainer>
              <DatePicker
                placeholder="Giriş Ay / Yıl"
                dateFormat="MM/yyyy"
                onChange={(date) =>
                  setValue(
                    `internship.${index}.startDate`,
                    date?.toDateString()
                  )
                }
                error={errors.internship?.[index]?.startDate?.message}
              />

              <DatePicker
                placeholder="Çıkış Ay / Yıl"
                dateFormat="MM/yyyy"
                onChange={(date) =>
                  setValue(`internship.${index}.endDate`, date?.toDateString())
                }
                error={errors.internship?.[index]?.endDate?.message}
              />
            </NSCVExperiencesStyle.SideBySideContainer>

            <NSCVExperiencesStyle.AddButtonContainer>
              <Button
                onClick={() => appendInternship(defaultValues)}
                width={135}
                height={40}
                fontSize={16}
                variant="text"
                color={colors.blue.blue01}
              >
                <Add /> Başka Ekle
              </Button>
            </NSCVExperiencesStyle.AddButtonContainer>

            {index !== internshipFields.length - 1 && (
              <NSCVExperiencesStyle.Seperator />
            )}
          </NSCVExperiencesStyle.ArrayContainer>
        ))}

        <NSCVExperiencesStyle.ButtonContainer>
          <Button
            width={105}
            height={56}
            fontSize={16}
            type="submit"
            variant="solid"
          >
            Kaydet
          </Button>

          <Button
            onClick={onClickGiveUp}
            width={105}
            height={56}
            color={colors.blue.blue01}
            fontSize={16}
            variant="ghost"
          >
            Vazgeç
          </Button>
        </NSCVExperiencesStyle.ButtonContainer>
      </NSCVExperiencesStyle.FormContainer>
    </Accordion>
  );
};
