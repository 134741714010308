import colors from "../../style/colors";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { NSModalWithTemplateStyle } from "./ModalWithTemplate.style";
import { NSModalWithTemplateType } from "./ModalWithTemplate.types";
export const ModalWithTemplate = ({
  isMobile,
  children,
  isOpen,
  onCloseHandler,
  headerColor = colors.dark.dark,
  header,
  acceptButton,
  declineButton,
  icon,
}: NSModalWithTemplateType.IModalWithTemplate) => {
  return (
    <Modal isOpen={isOpen} isMobile={isMobile} onCloseHandler={onCloseHandler}>
      <NSModalWithTemplateStyle.Container isMobile={isMobile}>
        {icon && (
          <NSModalWithTemplateStyle.IconContent>
            {icon}
          </NSModalWithTemplateStyle.IconContent>
        )}

        <NSModalWithTemplateStyle.Header headerColor={headerColor}>
          {header}
        </NSModalWithTemplateStyle.Header>
        <NSModalWithTemplateStyle.Content>
          {children}
        </NSModalWithTemplateStyle.Content>
        <NSModalWithTemplateStyle.ButtonContainer>
          <Button
            {...acceptButton}
            onClick={() => {
              onCloseHandler();
              acceptButton?.onClick?.();
            }}
          >
            Onayla
          </Button>
          <Button
            color={colors.blue.blue01}
            variant="ghost"
            {...declineButton}
            onClick={() => {
              onCloseHandler();
              declineButton?.onClick?.();
            }}
          >
            İptal
          </Button>
        </NSModalWithTemplateStyle.ButtonContainer>
      </NSModalWithTemplateStyle.Container>
    </Modal>
  );
};
