import styled, { css } from "styled-components";
import typography from "../../style/typography";

export namespace NSBlockHeaderTitleStyle {
  export const Container = styled.button<{
    isMobile: boolean;
    backgroundColor: string;
    titleColor: string;
    isClickable: boolean;
    fullWidth: boolean;
  }>`
    all: unset;
    display: inline-flex;
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: ${({ fullWidth }) => (fullWidth ? "auto" : "flex-start")};
    padding: ${({ isMobile }) => (isMobile ? "18px 24px" : "22px 24px")};
    font-family: "ReadexPro";
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    text-align: center;
    border-radius: 32px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.titleColor};
    font-size: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.size
        : typography.pharagraph1.size};
    transition: 0.5s all;
    &:hover {
      ${({ isClickable }) =>
        isClickable &&
        css`
          filter: brightness(90%);
        `}
    }
  `;
}
