import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";
import { NSBadgeStyle } from "../Badge/Badge.style";

export namespace NSAccordionOpportunitiesDesktopStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "ReadexPro";
  `;
  export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `;
  export const Title = styled.div`
    font-size: ${typography.titleh4.size};
    line-height: ${typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    margin-bottom: 48px;
  `;
  export const OrbArrow = styled.div`
    height: 64px;
    width: 64px;
    border-radius: 100%;
    background-color: ${colors.orange.orange02};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s, filter 0.3s;
    &:hover {
      filter: brightness(110%);
    }
  `;

  export const OptionItem = styled.button<{ isSelected: boolean }>`
    all: unset;
    cursor: pointer;
    width: ${(props) => (props.isSelected ? "540px" : "508px")};
    transition: width 0.3s;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 12px 32px 40px;
    box-sizing: border-box;
    border-radius: 128px;
    background-color: ${colors.yellow.yellow01};
    margin-bottom: 8px;
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      filter: brightness(98%);
      ${OrbArrow} {
        transform: scale(1.1);
      }
    }
  `;

  export const CurvedCard = styled.div`
    border-top-left-radius: 128px;
    background-color: ${colors.yellow.yellow01};
    padding: 70px 40px;
    padding-bottom: 0px;
    width: 540px;
    min-height: 668px;
    box-sizing: border-box;
    align-self: flex-end;
    transition: 1s all;
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      top: -40px;
      right: -52px;
    }
  `;

  export const IllustrationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 380px;
    border-top-left-radius: 102px;
    overflow: hidden;
  `;
  export const Description = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
    margin-top: 24px;
    margin-bottom: 24px;
  `;
  export const Button = styled.button`
    all: unset;
    cursor: pointer;
    padding: 18px 24px 18px 24px;
    border-radius: 32px;
    background-color: ${colors.orange.orange02};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    line-height: ${typography.pharagraph1_5.lineHeight};
    color: ${colors.light.light};
    font-size: ${typography.pharagraph1_5.size};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    &:hover {
      filter: brightness(90%);
    }
  `;
}
