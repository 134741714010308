import { HeaderDesktop } from "../../../../components/HeaderDesktop/HeaderDesktop";
import { NSAccountPageType } from "../AccountPage.types";
import { Footer } from "../../../../components/Footer/Footer";
import { scrollToTop } from "../../../../util/scrollToTop";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { NSAccountPageDesktopStyle } from "./AccountPageDesktop.style";
import colors from "../../../../style/colors";
import { DashboardMenu } from "../../../../components/DashboardMenu/DashboardMenu";
import { dashbordMenuItems } from "../../../../util/dashboardMenuItem";
import { UserInfo } from "../../../../components/UserInfo/UserInfo";
import { NSUserInfoType } from "../../../../components/UserInfo/UserInfo.types";
import { ChangePassword } from "../../../../components/ChangePassword/ChangePassword";
import { NSChangePasswordType } from "../../../../components/ChangePassword/ChangePassword.types";
import { KvkkPermission } from "../../../../components/KvkkPermission/KvkkPermission";
import { MembershipCancellation } from "../../../../components/MembershipCancellation/MembershipCancellation";

function AccountPageDesktop({
  accountData,
}: Readonly<NSAccountPageType.IAccountPage>) {
  const isMobile = false;

  const onSubmitUserInfo = (data: NSUserInfoType.UserInfoType) => {
    console.log("data", data);
  };

  const onSubmitChangePsw = (data: NSChangePasswordType.ChangePasswordType) => {
    console.log("data", data);
  };

  return (
    <NSAccountPageDesktopStyle.Container>
      <HeaderDesktop
        logoClicked={() => {}}
        anchorList={accountData.anchorList}
        isDark={true}
        backgroundColor={colors.neutrals.grey01}
      />
      <NSAccountPageDesktopStyle.MainContainer>
        <DashboardMenu menuItem={dashbordMenuItems} />
        <NSAccountPageDesktopStyle.Content>
          <NSAccountPageDesktopStyle.PageTitle>
            Üyelik Bilgilerim
          </NSAccountPageDesktopStyle.PageTitle>
          <UserInfo onSubmitUserInfo={onSubmitUserInfo} />
          <NSAccountPageDesktopStyle.Seperator />
          <ChangePassword onSubmitChangePsw={onSubmitChangePsw} />
          <NSAccountPageDesktopStyle.Seperator />
          <KvkkPermission />
          <NSAccountPageDesktopStyle.Seperator />
          <MembershipCancellation />
        </NSAccountPageDesktopStyle.Content>
      </NSAccountPageDesktopStyle.MainContainer>
      <Footer {...accountData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSAccountPageDesktopStyle.Container>
  );
}

export default AccountPageDesktop;
