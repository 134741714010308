import { areaCodes } from "../../util/formDropdownItems";
import { SelectBox } from "../SelectBox/SelectBox";
import { NSCountryCodeSelectBoxStyle } from "./CountryCodeSelectBox.style";
import { NSCountryCodeSelectBoxType } from "./CountryCodeSelectBox.types";

export const CountryCodeSelectBox = ({
  onSelect,
  title,
}: NSCountryCodeSelectBoxType.ICountryCodeSelectBox) => {
  return (
    <NSCountryCodeSelectBoxStyle.Container>
      <SelectBox
        items={areaCodes}
        onSelect={(item) => onSelect(item)}
        customTitle={title}
      />
      <NSCountryCodeSelectBoxStyle.Line />
    </NSCountryCodeSelectBoxStyle.Container>
  );
};
