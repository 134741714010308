import { HeaderDesktop } from "../../../../components/HeaderDesktop/HeaderDesktop";
import { NSStatusPageType } from "../StatusPage.types";
import { Footer } from "../../../../components/Footer/Footer";
import { scrollToTop } from "../../../../util/scrollToTop";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { DashboardMenu } from "../../../../components/DashboardMenu/DashboardMenu";
import { DashboardWelcomeText } from "../../../../components/DashboardWelcomeText/DashboardWelcomeText";
import { dashbordMenuItems } from "../../../../util/dashboardMenuItem";
import { NSStatusPageDesktopStyle } from "./StatusPageDesktop.style";
import colors from "../../../../style/colors";

function StatusPageDesktop({
  statusData,
}: Readonly<NSStatusPageType.IStatusPage>) {
  const isMobile = false;
  return (
    <NSStatusPageDesktopStyle.Container>
      <HeaderDesktop
        logoClicked={() => {}}
        anchorList={statusData.anchorList}
        isDark={true}
        backgroundColor={colors.neutrals.grey01}
      />
      <NSStatusPageDesktopStyle.MainContainer>
        <DashboardMenu menuItem={dashbordMenuItems} />
        <NSStatusPageDesktopStyle.Content>
          <NSStatusPageDesktopStyle.PageTitle>
            Merhaba Gülay,
          </NSStatusPageDesktopStyle.PageTitle>
          <NSStatusPageDesktopStyle.PageDesc>
            Bu sayfalardan başvuralarını takip edebilir, özgeçmişini
            oluşturabilir ve üyelik bilgilerini güncelleyebilirsin.
          </NSStatusPageDesktopStyle.PageDesc>
          <DashboardWelcomeText applicationCount={0} />
        </NSStatusPageDesktopStyle.Content>
      </NSStatusPageDesktopStyle.MainContainer>
      <Footer {...statusData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSStatusPageDesktopStyle.Container>
  );
}

export default StatusPageDesktop;
