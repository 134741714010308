import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSHeaderDesktopStyle {
  export const Container = styled.div<{
    isDark: boolean;
    backgroundColor: string;
  }>`
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;
    padding: 14px 84px;
    align-items: center;
    background-color: ${(props) =>
      props.isDark ? colors.neutrals.grey01 : props.backgroundColor};
    @media (max-width: 1092px) {
      padding: 24px 32px;
    }
    ${(props) =>
      props.isDark &&
      css`
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
      `}
  `;
}
