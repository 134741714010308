import { NSDragDropFileUploadStyle } from "./DragDropFileUpload.style";
import { NSDragDropFileUploadType } from "./DragDropFileUpload.types";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as News } from "../../assets/icons/news.svg";
import colors from "../../style/colors";
import { useCallback, useMemo, useRef } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { Button } from "../Button/Button";

export const DragDropFileUpload = ({
  onUpload,
  onError,
  uploadedFileName,
  uploadedFile,
  error,
  isUploadedFromKariyerNet,
  setUploadedFile,
  onClickTryAgain,
}: NSDragDropFileUploadType.IDragDropFileUpload) => {
  const ref = useRef(null as null | HTMLDivElement);

  const errors = useMemo(() => {
    return {
      "file-too-large": {
        label: "Özgeçmiş yüklenemedi, dosya boyutu yüksek",
      },
      "file-invalid-type": {
        label: "Özgeçmiş yüklenemedi, .pdf uzantılı dosya yüklenmeli ",
      },
    };
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: Blob[], fileRejections?: FileRejection[]) => {
      if (fileRejections && fileRejections.length > 0) {
        onError?.(
          errors[
            fileRejections[0].errors[0]
              .code as NSDragDropFileUploadType.IErrorType
          ]
        );
      }

      const url = acceptedFiles[0];

      onUpload?.(url, acceptedFiles[0] as File);
      setUploadedFile(acceptedFiles[0]);
    },
    [errors, onError, onUpload, setUploadedFile]
  );

  const acceptedFile: Accept | undefined = useMemo(() => {
    return isUploadedFromKariyerNet
      ? ({
          "file/pdf": [".pdf"],
          "image/jpeg": [".jpeg", ".png"],
        } as Accept)
      : ({ "file/pdf": [".pdf"] } as Accept);
  }, [isUploadedFromKariyerNet]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFile,
    maxFiles: 1,
    maxSize: 2097152,
    multiple: false,
  });

  return (
    <NSDragDropFileUploadStyle.Container>
      {(uploadedFileName || uploadedFile) && error === undefined && (
        <NSDragDropFileUploadStyle.UploadedFileArea>
          <News stroke={colors.success.success01} />
          Özgeçmiş Yüklendi
        </NSDragDropFileUploadStyle.UploadedFileArea>
      )}
      {!(uploadedFileName || uploadedFile) && error === undefined && (
        <NSDragDropFileUploadStyle.FileUploadArea
          {...getRootProps({
            className:
              "rounded-lg flex dropzone flex-col items-center content-center w-full h-auto py-[30px] px-[47px] border-2 border-neutrals-grey03 border-dotted bg-light-light",
          })}
          ref={ref}
          id="drag_zone"
          onClick={(e) => e.stopPropagation()}
        >
          <Upload stroke={colors.neutrals.grey04} />
          <input type="file" {...getInputProps()} id="uploadInput" />
          <NSDragDropFileUploadStyle.ButtonContainer>
            <Button
              onClick={open}
              fontSize={16}
              color={colors.blue.blue01}
              fontWeight={500}
              variant="text"
            >
              Özgeçmiş yükleyin
            </Button>
            veya buraya bırakın
          </NSDragDropFileUploadStyle.ButtonContainer>
          <NSDragDropFileUploadStyle.DocumentInfoArea>
            Belge: .pdf<br></br>Dosya Boyutu: Max 2 MB
          </NSDragDropFileUploadStyle.DocumentInfoArea>
        </NSDragDropFileUploadStyle.FileUploadArea>
      )}

      {!!error && (
        <NSDragDropFileUploadStyle.ErrorArea>
          {error}

          <Button
            onClick={onClickTryAgain}
            fontSize={14}
            color={colors.blue.blue01}
            variant="text"
          >
            Tekrar Yükle
          </Button>
        </NSDragDropFileUploadStyle.ErrorArea>
      )}
    </NSDragDropFileUploadStyle.Container>
  );
};
