import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSTextListStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
  `;
  export const GridContainer = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    list-style: disc;
    padding: 0 20px;
    width: 100%;

    margin-top: 16px;
  `;
  export const GridItem = styled.li`
    position: relative;
    padding-left: 20px;
    padding-right: 12px;
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    margin-bottom: 16px;
    text-align: center;
  `;
  export const DescriptionContainer = styled.div`
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    text-align: center;
    margin-bottom: 32px;
  `;

  export const MobileList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-left: 20px;
    margin-top: 12px;
    list-style: disc;
  `;
  export const MobileItem = styled.li`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
  `;
  export const LinkText = styled(DescriptionContainer)`
    margin-top: 24px;
    margin-bottom: 0px;
  `;
  export const Link = styled.a``;
}
