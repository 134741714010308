import { NavLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSDashboardMenuStyle {
  export const Container = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
  `;

  export const MenuItemLink = styled(NavLink)`
    width: 100%;
    text-decoration: none;
    height: fit-content;
  `;

  export const MenuItem = styled.div<{ isActive: boolean }>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    background-color: ${({ isActive }) =>
      isActive ? colors.light.light : colors.neutrals.grey01};
    text-decoration: none;
    color: ${({ isActive }) =>
      isActive ? colors.blue.blue01 : colors.dark.dark};

    :hover {
      background-color: ${colors.light.light};
      color: ${colors.blue.blue01};
      box-shadow: ${({ isActive }) =>
        !isActive ? ` 0px 0px 0px 2px ${colors.blue.blue01} inset` : "unset"};
    }
  `;

  export const MenuItemLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  `;
}
