import styled from "styled-components";
import colors from "../../../style/colors";
import { ReactComponent as Fingerprint } from "../../../assets/icons/fingerprint.svg";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";

export namespace NSHomePageDesktopStyle {
  export const Container = styled.div``;

  export const GrayContainer = styled.div`
    background-color: ${colors.neutrals.grey07};
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
  `;
  export const AnimatedButtonsContainer = styled.div`
    padding: 112px 82px 64px 82px;
    display: flex;
    margin-top: auto;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    @media (max-width: 1400px) {
      padding: 112px 64px 64px 64px;
    }
    @media (max-width: 1200px) {
      padding: 112px 24px 64px 24px;
    }
    @media (max-width: 1000px) {
      padding: 112px 12px 64px 12px;
    }
  `;
  export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
  `;
  export const MainContainer = styled.div`
    padding: 84px 112px;
    padding-bottom: 32px;
    overflow: hidden;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 32px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 32px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 32px;
    }
  `;
  export const HeightPaddingHelperTemp = styled.div`
    height: 3600px;
    width: 1px;
  `;
  export const StyledFingerPrint = styled(Fingerprint)`
    position: absolute;
    align-self: center;
  `;

  export const CarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      right: 40px;
      top: -148px;
      z-index: 1;
    }
  `;
  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      top: -148px;
      z-index: 2;
      left: 19px;
    }
  `;
  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 24px;
    margin-bottom: 24px;
  `;

  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 104px;
    background-color: ${colors.neutrals.grey07};
  `;
}
