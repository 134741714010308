import { useLocation } from "react-router-dom";
import { NSTopMenuNavigationDesktopStyle } from "./TopMenuNavigationDesktop.style";
import { NSTopMenuNavigationDesktopType } from "./TopMenuNavigationDesktop.types";

export const TopMenuNavigationDesktop = ({
  anchorList,
  isDark = false,
  target = "_self",
}: NSTopMenuNavigationDesktopType.ITopMenuNavigationDesktop) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <NSTopMenuNavigationDesktopStyle.Container>
      {anchorList.map((anchor) => (
        <NSTopMenuNavigationDesktopStyle.MenuAnchor
          href={anchor.href}
          isDark={isDark}
          key={anchor.href}
          target={target}
          isSelected={currentUrl === anchor.href}
        >
          <NSTopMenuNavigationDesktopStyle.IconMenuContainer>
              {anchor.label}
          </NSTopMenuNavigationDesktopStyle.IconMenuContainer>
         
        </NSTopMenuNavigationDesktopStyle.MenuAnchor>
      ))}
    </NSTopMenuNavigationDesktopStyle.Container>
  );
};
