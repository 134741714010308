import { useRef, useState } from "react";
import { NsKnowUsPageType } from "../KnowUsPage.types";
import { NSKnowUsPageDesktopStyle } from "./KnowUsPageDesktop.style";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { HeaderDesktop } from "../../../components/HeaderDesktop/HeaderDesktop";
import { TextStats } from "../../../components/TextStats/TextStats";
import { StatsCard } from "../../../components/StatsCard/StatsCard";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { BlockHeaderTitle } from "../../../components/BlockHeaderTitle/BlockHeaderTitle";
import { Badge } from "../../../components/Badge/Badge";
import { VideoPlayerCarousel } from "../../../components/VideoPlayerCarousel/VideoPlayerCarousel";
import { RoundedCardCarousel } from "../../../components/RoundedCardCarousel/RoundedCardCarousel";
import { RolloverImageGallery } from "../../../components/RolloverImageGallery/RolloverImageGallery";
import { Footer } from "../../../components/Footer/Footer";
import { BrandImages } from "../../../components/BrandImages/BrandImages";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import ReactPlayer from "react-player";
import { IconDescriptionList } from "../../../components/IconDescriptionList/IconDescriptionList";
import { IconCard } from "../../../components/IconCard/IconCard";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { TempSpacer } from "../../../util/tempSpacer";
import { Modal } from "../../../components/Modal/Modal";
import useStickyElement from "../../../util/useStickyElement";

function KnowUsPageDesktop({ knowUsPageData }: NsKnowUsPageType.IKnowUsPage) {
  const isMobile = false;
  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const renderHeader = () => (
    <HeaderDesktop
      logoClicked={() => {}}
      anchorList={knowUsPageData.anchorList}
      isDark={isSticky}
      backgroundColor={"transparent"}
    />
  );
  return (
    <NSKnowUsPageDesktopStyle.Container>
      {isSticky && renderHeader()}
      <NSKnowUsPageDesktopStyle.HeaderContainer ref={stickyDeciderDifRef}>
        <InnerPageHeader
          isMobile={isMobile}
          title="Bizi Tanı"
          backgroundColor={colors.blue.blue01}
          children={renderHeader()}
          iconColor={colors.blue.blue05}
        />
      </NSKnowUsPageDesktopStyle.HeaderContainer>
      <NSKnowUsPageDesktopStyle.MainContainer>
        <TextStats {...knowUsPageData.textStats} isMobile={isMobile} />
        <TempSpacer height={120} />
        <IconDescriptionList
          {...knowUsPageData.iconDescriptionListDesktop}
          isMobile={isMobile}
        />

        <TempSpacer height={120} />
        <HeadingText isMobile={isMobile} title="Değerlerimiz" description="" />

        <NSKnowUsPageDesktopStyle.IconCardGrid>
          {knowUsPageData.iconCardList.map((item) => (
            <IconCard {...item} isMobile={isMobile} />
          ))}
        </NSKnowUsPageDesktopStyle.IconCardGrid>
        <TempSpacer height={120} />
        <StatsCard
          statsList={knowUsPageData.statsCard.statsList}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <HeadingText
          isMobile={isMobile}
          title="Çalışma Arkadaşlarımızın Deneyimlerini Dinlemek İster Misin?"
          description=""
          children={
            <BlockHeaderTitle
              isMobile={isMobile}
              title="Tüm Videolar"
              backgroundColor={colors.blue.blue01}
              titleColor={colors.dark.dark}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@dogusotomotiv4354/videos",
                  "_blank"
                )
              }
            />
          }
        />
        <TempSpacer height={102} />
        <NSKnowUsPageDesktopStyle.VideoCarouselBadgeContainer>
          <Badge
            isMobile={isMobile}
            textVariant="LISTENUS"
            color="BLUE"
            iconVariant="ARROW"
            speed={15}
          />
          <VideoPlayerCarousel
            {...knowUsPageData.videoPlayerCarousel}
            isMobile={isMobile}
          />
        </NSKnowUsPageDesktopStyle.VideoCarouselBadgeContainer>
        <TempSpacer height={120} />
        <HeadingText
          isMobile={isMobile}
          isVertical={false}
          title="Çalışma Ofislerimiz"
          description="Ana kampüsümüzde yer alan Genel Müdürlük & Yedek Parça ve Lojistik ofislerimiz dışında diğer perakende noktalarımızın detaylarını inceleyebilirsin."
        />
        <TempSpacer height={120} />
        <ImageCarousel
          {...knowUsPageData.imageCarouselData}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <NSKnowUsPageDesktopStyle.RoundedCardAndVideoCardContainer>
          <SingleRoundedCard
            {...knowUsPageData.singleRoundedCard}
            isMobile={isMobile}
          />
          <VideoPlayerCard
            isMobile={isMobile}
            showTitle={false}
            imageUrl={knowUsPageData.videoPlayerImageAndVideo.image}
            height="568px"
            width="568px"
            onClick={onClickHandler}
          />
        </NSKnowUsPageDesktopStyle.RoundedCardAndVideoCardContainer>
        <TempSpacer height={120} />
        <HeadingText
          isMobile={isMobile}
          marginBottom={52}
          title="Şirket İçi İletişim Çalışmalarımız"
          description="Çalışma arkadaşlarımıza özel olarak tasarladığımız sohbet toplantılarında bir araya gelerek birbirimizi daha yakından tanıyoruz."
        />

        <RoundedCardCarousel
          {...knowUsPageData.roundedCardCarousel}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <RolloverImageGallery
          isMobile={isMobile}
          cardList={knowUsPageData.rolloverImageData}
        />
        <NSKnowUsPageDesktopStyle.HorizontalLine />
        <BrandImages {...knowUsPageData.brandImages} isMobile={isMobile} />

        <CircleButton onClick={scrollToTop} isMobile={isMobile} />
      </NSKnowUsPageDesktopStyle.MainContainer>
      <Footer {...knowUsPageData.footer} isMobile={isMobile} />

      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <ReactPlayer
          ref={playerRef}
          url={knowUsPageData.videoPlayerImageAndVideo.video}
          controls={true}
          width={isMobile ? "100%" : "60vw"}
          height={"auto"}
        />
      </Modal>
    </NSKnowUsPageDesktopStyle.Container>
  );
}

export default KnowUsPageDesktop;
