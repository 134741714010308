import { NSStatementCardStyle } from "./StatementCard.style";
import { NSStatementCardType } from "./StatementCard.types";

export const StatementCard = ({
  isMobile,
  background,
  title,
  description,
}: NSStatementCardType.IStatementCard) => {
  return (
    <NSStatementCardStyle.Container background={background} isMobile={isMobile}>
      <NSStatementCardStyle.Title isMobile={isMobile}>
        {title}
      </NSStatementCardStyle.Title>
      <NSStatementCardStyle.Description isMobile={isMobile}>
        {description}
      </NSStatementCardStyle.Description>
    </NSStatementCardStyle.Container>
  );
};
