import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSHeaderMobileStyle {
  export const getBackgroundColor = (props: {
    isDark: boolean;
    backgroundColor?: string;
  }) => {
    if (props.backgroundColor && props.isDark === false) {
      return props.backgroundColor;
    }
    return props.isDark ? colors.light.light : "transparent";
  };
  export const Container = styled.div<{
    isDark: boolean;
    backgroundColor: string;
  }>`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    width: 100vw;
    z-index: 3;
    background-color: ${(props) => getBackgroundColor(props)};
    transition:
      background-color 0.3s ease,
      position 0.3s ease;
    ${(props) =>
      props.isDark &&
      css`
        position: sticky;
        top: 0;
        z-index: 1000;
      `}
  `;
  export const HamburgerButton = styled.div`
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
  `;
}
