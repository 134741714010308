import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSModalWithTemplateStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    flex-direction: column;
    width: ${({ isMobile }) => (isMobile ? `calc(100vw - 64px)` : "532px")};
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "ReadexPro";
    padding: ${({ isMobile }) => (isMobile ? "16px" : "40px")};
    background-color: ${colors.light.light};
    box-shadow: 0px 0px 16px 0px #0f0f2a14;
    border-radius: 16px;

    @media (max-width: 768px) {
      margin: -8px 16px 36px 16px;
      width: 93%;
    }
  `;
  export const Header = styled.h5<{ headerColor: string }>`
    width: 100%;
    color: ${({ headerColor }) => headerColor};
    font-weight: ${typography.fontWeights.bold};
    font-size: ${typography.titleh5.size};
    line-height: 37.44px;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  `;

  export const IconContent = styled.div`
    margin-bottom: 24px;
  `;

  export const Content = styled.div`
    width: 100%;
    color: ${colors.dark.dark};
    font-weight: ${typography.fontWeights.regular};
    font-size: ${typography.pharagraph1_5.size};
    line-height: 28.08px;
    margin-bottom: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const ButtonContainer = styled.div`
    width: 100%;
    height: fit-content;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `;
}
