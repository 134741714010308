import { z } from "zod";
import { AuthCard } from "../../../../components/AuthCard/AuthCard";
import { Logo } from "../../../../components/Logo/Logo";
import { NSLoginPageType } from "../LoginPage.types";
import { NSLoginPageMobileStyle } from "./LoginPageMobile.style";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SwitchButton } from "../../../../components/SwitchButton/SwitchButton";
import { Input } from "../../../../components/Input/Input";
import { FormError } from "../../../../components/FormError/FormError";
import { Button } from "../../../../components/Button/Button";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import colors from "../../../../style/colors";
import { useState } from "react";

export type LoginSchemaType = z.infer<typeof NSLoginPageType.loginSchema>;

function LoginPageMobile({
  loginSchema,
  onSubmitLogin,
}: Readonly<NSLoginPageType.ILoginPageData>) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema),
  });
  const onSubmit: SubmitHandler<LoginSchemaType> = (data) => {
    onSubmitLogin(data);
  };

  const error = "Error";

  const checked = useWatch({
    control,
    name: "rememberMe",
  });
  const firstButton = {
    children: "Giriş Yap",
    onClick: () => {
      navigate("/auth/login");
    },
  };
  const secondButton = {
    children: "Üye Ol",
    onClick: () => {
      navigate("/auth/register");
    },
  };

  const password = useWatch({
    control,
    name: "password",
  });

  const [showPasswordButton, setShowPasswordButton] = useState(false);

  return (
    <NSLoginPageMobileStyle.Container>
      <Logo isDark={true} isMobile={true} />
      <AuthCard isMobile={true}>
        <SwitchButton
          defaultSelectedButton="first"
          firstButton={firstButton}
          secondButton={secondButton}
        ></SwitchButton>
        <NSLoginPageMobileStyle.Form
          id="loginForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NSLoginPageMobileStyle.Title>
            Farklı fonksiyonlarımızdaki kariyer olanaklarına başvurmak için
            giriş yap.
          </NSLoginPageMobileStyle.Title>
          {error && <FormError message={error} />}
          <NSLoginPageMobileStyle.InputContainer>
            <Input
              label="E-posta"
              val={watch("email")}
              placeholder="E-posta"
              {...register("email")}
              error={errors.email?.message}
            />
            <Input
              label="Şifre"
              placeholder="Şifre"
              type={showPasswordButton ? "text" : "password"}
              val={watch("password")}
              {...register("password")}
              error={errors.password?.message}
              suffixItem={
                password && (
                  <Button
                    onClick={() => setShowPasswordButton(!showPasswordButton)}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {showPasswordButton ? "Gizle" : "Göster"}
                  </Button>
                )
              }
            />
          </NSLoginPageMobileStyle.InputContainer>
          <NSLoginPageMobileStyle.BottomContainer>
            <Checkbox
              id="rememberMe"
              checked={checked ?? false}
              label="Beni Hatırla"
              onChange={(value) => {
                setValue("rememberMe", value);
              }}
            />
            <Button
              onClick={() => console.log("sifremi unuttum")}
              variant="text"
              color={colors.blue.blue01}
              fontSize={14}
            >
              Şifremi Unuttum
            </Button>
          </NSLoginPageMobileStyle.BottomContainer>
          <Button fontSize={16} height={43} type="submit" variant="solid">
            Giriş Yap
          </Button>
        </NSLoginPageMobileStyle.Form>
      </AuthCard>
      <NSLoginPageMobileStyle.FooterText>
        Doğuş Kariyer’e üye olmadın mı?
        <Button
          onClick={() => navigate("/auth/register")}
          variant="text"
          color={colors.information.information01}
          fontSize={14}
        >
          Hemen Üye Ol
        </Button>
      </NSLoginPageMobileStyle.FooterText>
    </NSLoginPageMobileStyle.Container>
  );
}

export default LoginPageMobile;
