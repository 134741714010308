import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Reset } from "styled-reset";
import App from "./App";
import "./assets/fonts/ReadexPro.ttf"; // Import the font file

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Reset />
    <App />
  </React.StrictMode>
);
