import colors from "../../style/colors";
import { Button } from "../Button/Button";
import { SelectBox } from "../SelectBox/SelectBox";
import { NSPaginationStyle } from "./Pagination.style";
import { NSPaginationType } from "./Pagination.types";

const countItems = [
  {
    id: "5",
    label: "5",
  },
  {
    id: "9",
    label: "9",
  },
  {
    id: "10",
    label: "10",
  },
  {
    id: "15",
    label: "15",
  },
  {
    id: "20",
    label: "20",
  },
];

export const Pagination = ({
  pageCount,
  currentPage,
  isMobile,
  onClickNavigation,
  onSelectCount,
}: NSPaginationType.IPagination) => {
  return (
    <NSPaginationStyle.Container isMobile={isMobile}>
      <NSPaginationStyle.CountSelectorContainer>
        <NSPaginationStyle.CountSelectorTitle>
          Görüntülenen ilan sayısı
        </NSPaginationStyle.CountSelectorTitle>
        <SelectBox
          items={countItems}
          onSelect={(item) => {
            onSelectCount?.(parseInt(item.id));
          }}
          defaultSelectedItem={countItems[1]}
        />
      </NSPaginationStyle.CountSelectorContainer>
      <NSPaginationStyle.PaginationContainer>
        <Button
          fontWeight={400}
          fontSize={14}
          color={colors.dark.dark}
          isDisabled={currentPage === 1}
          variant="text"
          onClick={() => onClickNavigation("previous")}
        >
          Previous
        </Button>
        {pageCount > 6 ? (
          <NSPaginationStyle.PageNumberContainer>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <Button
                  backgroundColor={
                    index + 1 === currentPage
                      ? colors.blue.blue01
                      : "transparent"
                  }
                  color={
                    index + 1 === currentPage
                      ? colors.light.light
                      : colors.dark.dark
                  }
                  variant="icon"
                  width={32}
                  height={32}
                  key={index + 1}
                  onClick={() => onClickNavigation(pageCount)}
                >
                  <NSPaginationStyle.Page>{index + 1}</NSPaginationStyle.Page>
                </Button>
              ))}
            <NSPaginationStyle.DotContainer>
              <NSPaginationStyle.Dot />
              <NSPaginationStyle.Dot />
              <NSPaginationStyle.Dot />
            </NSPaginationStyle.DotContainer>

            <Button
              backgroundColor={
                pageCount === currentPage ? colors.blue.blue01 : "transparent"
              }
              color={
                pageCount === currentPage
                  ? colors.light.light
                  : colors.dark.dark
              }
              variant="icon"
              width={32}
              height={32}
              onClick={() => onClickNavigation(pageCount)}
            >
              <NSPaginationStyle.Page>{pageCount}</NSPaginationStyle.Page>
            </Button>
          </NSPaginationStyle.PageNumberContainer>
        ) : (
          <NSPaginationStyle.PageNumberContainer>
            {Array(pageCount)
              .fill(0)
              .map((_, index) => (
                <Button
                  backgroundColor={
                    index + 1 === currentPage
                      ? colors.blue.blue01
                      : "transparent"
                  }
                  color={
                    index + 1 === currentPage
                      ? colors.light.light
                      : colors.dark.dark
                  }
                  variant="icon"
                  width={32}
                  height={32}
                  key={index + 1}
                  onClick={() => console.log("click previous")}
                >
                  <NSPaginationStyle.Page
                    onClick={() => onClickNavigation(index + 1)}
                  >
                    {index + 1}
                  </NSPaginationStyle.Page>
                </Button>
              ))}
          </NSPaginationStyle.PageNumberContainer>
        )}

        <Button
          fontWeight={400}
          color={colors.dark.dark}
          variant="text"
          fontSize={14}
          isDisabled={currentPage === pageCount}
          onClick={() => onClickNavigation("next")}
        >
          Next
        </Button>
      </NSPaginationStyle.PaginationContainer>
    </NSPaginationStyle.Container>
  );
};
