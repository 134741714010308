import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as Remove } from "../../assets/icons/remove.svg";

export namespace NSAccordionListStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props) => (props.isMobile ? "32px 16px" : "64px 0px")};
    background: ${colors.orange.orange03};
    font-family: "ReadexPro";
    color: ${colors.light.light};
    text-align: center;
    border-radius: 32px;
    box-sizing: border-box;
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "64px")};
    ${({ isMobile }) =>
      isMobile &&
      css`
        padding: 0px 16px;
      `}
  `;

  export const Number = styled.div<{ isMobile: boolean }>`
    color: ${colors.shadesOflight.light16};
    transition: 0.5s all;
  `;
  export const ItemTitle = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
    gap: ${({ isMobile }) => (isMobile ? "8px" : "16px")};
    color: ${colors.light.light};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText1.size : typography.titleh5.size};
    font-weight: ${typography.fontWeights.medium};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText1.lineHeight
        : typography.titleh5.lineHeight};
  `;
  export const CustomAdd = styled(Add)`
    opacity: 1;
    transition: 0.5s all;
    position: absolute;
  `;
  export const CustomRemove = styled(Remove)`
    opacity: 0;
    transition: 0.5s all;
    position: absolute;
  `;
  export const IconContainer = styled.div`
    position: relative;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const DescriptionWindow = styled.div`
    position: absolute;
    transition: 0.5s all;
    opacity: 0;
    background-color: ${colors.light.light};
    color: ${colors.dark.dark};
    padding: 24px;
    border-radius: 32px;
    right: 32px;
    width: 45%;
    max-width: 328px;
    z-index: 1;
  `;

  export const HoveredItem = styled.button<{ isMobile: boolean }>`
    all: unset;
    width: ${({ isMobile }) => (isMobile ? "100%" : "70%")};
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${colors.shadesOflight.light16};
    padding: 24px 0px;
    ${({ isMobile }) =>
      !isMobile &&
      css`
        &:hover {
          ${Number} {
            color: ${colors.yellow.yellow01};
            margin-left: 32px;
          }
          ${CustomAdd} {
            opacity: 0;
          }
          ${CustomRemove} {
            opacity: 1;
          }
          ${DescriptionWindow} {
            opacity: 1;
          }
        }
      `};
  `;
  export const CustomUl = styled.ul`
    list-style: disc;
    text-align: left;
    margin: 0px;
    margin-left: 8px;
  `;
  export const CustomLi = styled.li`
    margin-left: 0px;
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
  `;
  export const DescriptionNumber = styled.div<{ isMobile: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? "8px" : "24px")};
    color: ${colors.shadesOfdark.dark08};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh3.size};
    font-weight: ${typography.fontWeights.medium};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh3.lineHeight};
    text-align: right;
  `;
  export const DescriptionWindowMobile = styled.div`
    transition: 0.5s all;
    background-color: ${colors.light.light};
    color: ${colors.dark.dark};
    padding: 16px 24px;
    border-radius: 32px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
  `;
  export const MobileItemTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  `;
}
