import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

export namespace NSUserInfoType {
  export type IUserInfo = {
    onSubmitUserInfo: SubmitHandler<NSUserInfoType.UserInfoType>;
  };

  export const userInfoSchema = z.object({
    name: z.string().min(1, "Ad zorunlu"),
    surname: z.string().min(1, "Soyad zorunlu"),
    email: z.string().min(1, "E-posta zorunlu").email("Geçersiz E-posta"),
    birthDate: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Dogum tarihi zorunlu"),
  });
  export type UserInfoType = z.infer<typeof userInfoSchema>;
}
