import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSTextStatsStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    font-family: "ReadexPro";
    svg {
      height: ${({ isMobile }) => (isMobile ? "40px" : "80px")};
      width: ${({ isMobile }) => (isMobile ? "40px" : "80px")};
    }
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    display: inline;
  `;
  export const BlueTitle = styled(Title)`
    color: ${colors.blue.blue01};
  `;

  export const StatsContainer = styled.div<{ isMobile: boolean }>`
    display: ${({ isMobile }) => (isMobile ? "grid" : "flex")};
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
  `;
  export const StatsCard = styled.div<{
    isMobile: boolean;
    isClickable: boolean;
  }>`
    display: flex;
    flex-direction: row;
    gap: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
    ${({ isClickable }) =>
      isClickable &&
      css`
        animation: pulse-animation-small 1s infinite;
        cursor: pointer;
      `}

    @keyframes pulse-animation-small {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  `;
  export const OrbIconContainer = styled.div<{ isMobile: boolean }>`
    height: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    width: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    border-radius: ${({ isMobile }) => (isMobile ? "64px" : "128px")};
    background-color: ${colors.light.light};
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const StatTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `;
  export const StatNumber = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph1.size : typography.titleh3.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.pharagraph1.lineHeight
        : typography.titleh3.lineHeight};
    font-weight: ${typography.fontWeights.medium};
  `;
  export const StatName = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText1.size : typography.titleh5.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText1.lineHeight
        : typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.regular};
  `;
}
