import styled from "styled-components";
import colors from "../../../style/colors";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";
import typography from "../../../style/typography";
import { NSHeadingTextStyle } from "../../../components/HeadingText/HeadingText.style";
import { NSVideoPlayerCardStyle } from "../../../components/VideoPlayerCard/VideoPlayerCard.style";

export namespace NSLearnWithUsMobileStyle {
  export const Container = styled.div`
    ${NSHeadingTextStyle.Description} {
      margin-top: 16px;
    }
    ${NSVideoPlayerCardStyle.OuterContainer} {
      margin: 0px;
    }
  `;
  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const MainContainer = styled.div`
    padding: 24px 16px 48px 16px;
  `;

  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 32px;
    margin-bottom: 32px;
  `;
  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: ${colors.blue.blue01};
  `;

  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      left: -12px;
      top: -64px;
      z-index: 2;
    }
  `;
  export const RoundedCardAndVideoCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;
  export const IconCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;

  export const Title = styled.div`
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    text-align: left;
  `;
  export const Description = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
    text-align: left;
    margin-top: 12px;
    align-self: left;
    b {
      font-weight: ${typography.fontWeights.bold};
      font-family: ${typography.fontFamily.bold};
    }
  `;
  export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  `;
  export const ImageCard = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 32px;
  `;
  export const GoLogo = styled.img`
    height: 58px;
  `;
  export const StatCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 32px;
    padding: 16px 24px;
    background-color: ${colors.light.light};
    width: 236px;
    height: 192px;
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    box-sizing: border-box;
  `;
  export const StatImage = styled.img`
    max-height: 54px;
    object-fit: contain;
    object-position: left;
  `;
  export const SwiperContainer = styled.div`
    margin-top: 24px;
    .swiper-slide {
      width: 236px;
    }
    .swiper-wrapper {
      padding-bottom: 24px;
    }
  `;
}
