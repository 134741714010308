import { useState } from "react";
import { NSCenteredImageGridStyle } from "./CenteredImageGrid.style";
import { NSCenteredImageGridType } from "./CenteredImageGrid.types";
import { Modal } from "../Modal/Modal";
import { NSVideoPlayerCarouselStyle } from "../VideoPlayerCarousel/VideoPlayerCarousel.style";
import colors from "../../style/colors";
import { NSImageCarouselStyle } from "../ImageCarousel/ImageCarousel.style";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import { MobileSwiperHelper } from "../MobileSwiperHelper/MobileSwiperHelper";

export const CenteredImageGrid = ({
  isMobile,
  imageList,
}: NSCenteredImageGridType.ICenteredImageGrid) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>(imageList[0]);
  const currentIndex = imageList?.indexOf(selectedImage);

  const nextImageHandler = () => {
    if (currentIndex !== imageList.length - 1) {
      setSelectedImage(imageList[currentIndex + 1]);
    }
  };
  const previousImageHandler = () => {
    if (currentIndex !== 0) {
      setSelectedImage(imageList[currentIndex + -1]);
    }
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const imageClickedHandler = (image: string) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const renderLogosDesktop = () =>
    imageList.map((logo) => (
      <NSCenteredImageGridStyle.ImageContainer
        key={logo}
        onClick={() => imageClickedHandler(logo)}
      >
        <NSCenteredImageGridStyle.HoverOrb>
          <Add height={32} width={32} />
        </NSCenteredImageGridStyle.HoverOrb>
        <NSCenteredImageGridStyle.LogoImage
          src={logo}
          alt={logo}
          isMobile={isMobile}
        />
      </NSCenteredImageGridStyle.ImageContainer>
    ));

  const renderLogosMobile = () => {
    return (
      <MobileSwiperHelper
        childrenList={imageList.map((logo) => (
          <NSCenteredImageGridStyle.ImageContainer
            key={logo}
            onClick={() => imageClickedHandler(logo)}
          >
            <NSCenteredImageGridStyle.HoverOrb>
              <Add height={32} width={32} />
            </NSCenteredImageGridStyle.HoverOrb>
            <NSCenteredImageGridStyle.LogoImage
              src={logo}
              alt={logo}
              isMobile={isMobile}
            />
          </NSCenteredImageGridStyle.ImageContainer>
        ))}
      />
    );
  };

  const renderDecider = () => {
    if (isMobile) {
      return renderLogosMobile();
    } else {
      return renderLogosDesktop();
    }
  };

  return (
    <NSCenteredImageGridStyle.Container isMobile={isMobile}>
      {renderDecider()}
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <NSVideoPlayerCarouselStyle.ModalContentContainer isMobile={isMobile}>
          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}

          <NSImageCarouselStyle.DisplayedImage
            src={selectedImage}
            isMobile={isMobile}
          />

          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}
        </NSVideoPlayerCarouselStyle.ModalContentContainer>
        <NSImageCarouselStyle.ImageNumber isMobile={isMobile}>
          {currentIndex + 1}/{imageList.length}
        </NSImageCarouselStyle.ImageNumber>
        {isMobile && (
          <NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          </NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
        )}
      </Modal>
    </NSCenteredImageGridStyle.Container>
  );
};
