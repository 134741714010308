import { NSSelectBoxStyle } from "./SelectBox.style";
import { NSSelectBoxType } from "./SelectBox.types";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevronUp.svg";
import colors from "../../style/colors";
import { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "../Checkbox/Checkbox";

export const SelectBox = ({
  customTitle,
  items,
  onSelect,
  type = "basic",
  onChangeChecked,
  placeholder,
  defaultSelectedItem,
  isRequired,
  disabled = false,
  error,
  label,
  labelShowType = "withValue",
  position = "bottom",
}: NSSelectBoxType.ISelectBox) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [checkBoxItems, setCheckBoxItems] = useState(
    items.map((item) => {
      return {
        ...item,
        checked: false,
      };
    })
  );

  const preparedTitle = useMemo(() => {
    let title = placeholder;
    if (type === "withCheckbox" && checkBoxItems.some((i) => i.checked)) {
      title = "";
      checkBoxItems
        .filter((i) => i.checked)
        .map((item, index) => {
          title += `${item.label}${index !== checkBoxItems.length && ","}`;
        });
    }
    if (selectedItem) {
      title = selectedItem.label;
    }

    return title;
  }, [placeholder, type, checkBoxItems, selectedItem]);

  const itemsToRender = useMemo(() => {
    return {
      basic: (
        <NSSelectBoxStyle.ItemContainer position={position}>
          {items.map((item) => (
            <NSSelectBoxStyle.Item
              key={item.id}
              onClick={() => {
                onSelect?.(item);
                setIsOpen(false);
                setSelectedItem(item);
              }}
            >
              {item.label}
            </NSSelectBoxStyle.Item>
          ))}
        </NSSelectBoxStyle.ItemContainer>
      ),
      withCheckbox: (
        <NSSelectBoxStyle.ItemContainer position={position}>
          {checkBoxItems.map((item) => (
            <NSSelectBoxStyle.Item key={item.id}>
              <Checkbox
                fontSize={14}
                lineHeight={22.96}
                id={item.id}
                checked={item.checked}
                label={item.label}
                onChange={(value) => {
                  const tempCheckedItems = checkBoxItems;
                  const id = tempCheckedItems.findIndex(
                    (i) => i.id === item.id
                  );
                  tempCheckedItems[id].checked = value;
                  setCheckBoxItems([...tempCheckedItems]);
                  onChangeChecked?.(tempCheckedItems.filter((i) => i.checked));
                }}
              />
            </NSSelectBoxStyle.Item>
          ))}
        </NSSelectBoxStyle.ItemContainer>
      ),
    };
  }, [position, items, checkBoxItems, onSelect, onChangeChecked]);

  const showLabel = {
    alwaysShow: !!label,
    hide: false,
    withValue: !!label && !!selectedItem,
  };

  return (
    <NSSelectBoxStyle.Container ref={containerRef}>
      {showLabel[labelShowType] && (
        <NSSelectBoxStyle.Label>
          {label}

          {isRequired && <NSSelectBoxStyle.Star>*</NSSelectBoxStyle.Star>}
        </NSSelectBoxStyle.Label>
      )}
      {customTitle ? (
        <NSSelectBoxStyle.CustomTitleContainer
          onClick={() => disabled === false && setIsOpen(!isOpen)}
        >
          {customTitle}
        </NSSelectBoxStyle.CustomTitleContainer>
      ) : (
        <NSSelectBoxStyle.TitleContainer
          error={!!error && error !== ""}
          onClick={() => disabled === false && setIsOpen(!isOpen)}
          disabled={disabled ?? false}
        >
          <NSSelectBoxStyle.Title isSelected={!!selectedItem}>
            {preparedTitle}
            {selectedItem === undefined && isRequired && (
              <NSSelectBoxStyle.Star>*</NSSelectBoxStyle.Star>
            )}
          </NSSelectBoxStyle.Title>

          {isOpen ? (
            <ChevronUp
              onClick={(e) => {
                e.stopPropagation();
                disabled === false && setIsOpen(!isOpen);
              }}
              stroke={colors.neutrals.grey04}
            />
          ) : (
            <ChevronDown
              onClick={(e) => {
                e.stopPropagation();
                disabled === false && setIsOpen(!isOpen);
              }}
              stroke={colors.neutrals.grey04}
            />
          )}
        </NSSelectBoxStyle.TitleContainer>
      )}

      {error && error !== "" && (
        <NSSelectBoxStyle.ErrorMessage>{error}</NSSelectBoxStyle.ErrorMessage>
      )}

      {isOpen && itemsToRender[type ?? "basic"]}
    </NSSelectBoxStyle.Container>
  );
};
