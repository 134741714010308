import { useRef, useState } from "react";
import { MenuMobile } from "../../../../components/MenuMobile/MenuMobile";
import { NSAccountPageType } from "../AccountPage.types";
import { NSAccountPageMobileStyle } from "./AccountPageMobile.style";
import useStickyElement from "../../../../util/useStickyElement";
import { HeaderMobile } from "../../../../components/HeaderMobile/HeaderMobile";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../../style/colors";
import { Footer } from "../../../../components/Footer/Footer";
import { UserInfo } from "../../../../components/UserInfo/UserInfo";
import { ChangePassword } from "../../../../components/ChangePassword/ChangePassword";
import { KvkkPermission } from "../../../../components/KvkkPermission/KvkkPermission";
import { MembershipCancellation } from "../../../../components/MembershipCancellation/MembershipCancellation";
import { NSUserInfoType } from "../../../../components/UserInfo/UserInfo.types";
import { NSChangePasswordType } from "../../../../components/ChangePassword/ChangePassword.types";

function AccountPageMobile({
  accountData,
}: Readonly<NSAccountPageType.IAccountPage>) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onSubmitUserInfo = (data: NSUserInfoType.UserInfoType) => {
    console.log("data", data);
  };

  const onSubmitChangePsw = (data: NSChangePasswordType.ChangePasswordType) => {
    console.log("data", data);
  };

  return (
    <NSAccountPageMobileStyle.Container>
      <NSAccountPageMobileStyle.LeftContainer>
        <NSAccountPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={accountData.anchorList}
            socialLinks={accountData.footer.socialLinks}
          />
        </NSAccountPageMobileStyle.RightContainer>
         <HeaderMobile
          logoClicked={() => {}}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
          backgroundColor={colors.blue.blue02}
        />
        <NSAccountPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={""}
            isMobile={isMobile}
            title="Üyelik Bilgilerim"
            backgroundColor={colors.blue.blue02}
            isIcon={false}
          />
        </NSAccountPageMobileStyle.StickyHelperContainer>

        <NSAccountPageMobileStyle.MainContainer>
          <NSAccountPageMobileStyle.Content>
            <UserInfo onSubmitUserInfo={onSubmitUserInfo} />
            <NSAccountPageMobileStyle.Seperator />
            <ChangePassword onSubmitChangePsw={onSubmitChangePsw} />
            <NSAccountPageMobileStyle.Seperator />
            <KvkkPermission />
            <NSAccountPageMobileStyle.Seperator />
            <MembershipCancellation isMobile={true} />
          </NSAccountPageMobileStyle.Content>
        </NSAccountPageMobileStyle.MainContainer>
        <Footer {...accountData.footer} isMobile={isMobile} />
      </NSAccountPageMobileStyle.LeftContainer>
    </NSAccountPageMobileStyle.Container>
  );
}

export default AccountPageMobile;
