import { z } from "zod";

export type LoginSchemaType = z.infer<typeof NSLoginPageType.loginSchema>;

export namespace NSLoginPageType {
  export type ILoginPage = {
    loginPageData: ILoginPageData;
  };

  export type ILoginPageData = {
    loginSchema: typeof loginSchema;
    onSubmitLogin: (data: LoginSchemaType) => void;
  };

  export const loginSchema = z.object({
    email: z
      .string()
      .min(1, "E-posta zorunlu")
      .refine(value => /\S+@\S+\.\S+/.test(value), {
        message: "Geçerli bir e-posta adresi giriniz"
      })
      .transform((value) => value.toLowerCase()),
    password: z.string().min(1, "Şifre zorunlu"),
    rememberMe: z.boolean(),
  });
}
