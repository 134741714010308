import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSRoundedCardStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    border-radius: 0px 64px 0px 0px;
    box-sizing: border-box;
    background-color: ${colors.yellow.yellow01};
    font-family: "ReadexPro";
    width: ${({ isMobile }) => (isMobile ? "100%" : "408px")};
  `;

  export const RoundedImage = styled.img<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "100%" : "360px")};
    background-color: ${colors.light.light};
    object-fit: cover;
    border-radius: 0px 40px 40px 40px;
  `;
  export const Title = styled.div`
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    margin-top: 24px;
    margin-bottom: 32px;
  `;
  export const Button = styled.button<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    display: inline-flex;
    background-color: ${colors.orange.orange02};
    border-radius: 32px;
    margin-top: auto;
    padding: ${({ isMobile }) => (isMobile ? "22px 24px" : "24px 25px")};
    color: ${colors.light.light};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph2.size : typography.pharagraph1.size};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    transition: filter 0.5s;
    &:hover {
      filter: brightness(90%);
    }
  `;
}
