import { useEffect, useRef, useState } from "react";
import colors from "../../style/colors";
import { NSDropdownButtonStyle } from "./DropdownButton.style";
import { NSDropdownButtonType } from "./DropdownButton.types";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as PrefixIcon } from "../../assets/icons/prefixIcon.svg";
import { ReactComponent as Layers } from "../../assets/icons/layers.svg";
import { ReactComponent as ResumeIcon } from "../../assets/icons/resumeIcon.svg";
import { ReactComponent as DoubleCheck } from "../../assets/icons/doubleCheck.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";

export const DropdownButton = ({
  children,
  onClick,
  isDark = false,
}: NSDropdownButtonType.IDropdownButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleButtonClick = () => {
    onClick?.();
    setIsOpen(!isOpen);
  };

  const menuItems = [
    {
      href: "/dashboard/status",
      icon: <PrefixIcon stroke={colors.neutrals.grey04} />,
      label: "Güncel Durumum",
    },
    {
      href: "/position/list",
      icon: <Layers stroke={colors.neutrals.grey04} />,
      label: "Açık Pozisyonlar",
    },
    {
      href: "/dashboard/cv",
      icon: <ResumeIcon stroke={colors.neutrals.grey04} />,
      label: "Özgeçmişim",
    },
    {
      href: "/dashboard/applications",
      icon: (
        <DoubleCheck
          stroke={colors.neutrals.grey04}
          fill={colors.neutrals.grey04}
        />
      ),
      label: "Başvurularım",
    },
    {
      href: "/dashboard/account",
      icon: <User stroke={colors.neutrals.grey04} />,
      label: "Üyelik Bilgilerim",
    },
    { isLine: true },
    {
      href: "/#",
      icon: <Logout stroke={colors.neutrals.grey04} />,
      label: "Çıkış Yap",
    },
  ];
  return (
    <div ref={dropdownRef}>
      <NSDropdownButtonStyle.Button isDark={isDark} onClick={handleButtonClick}>
        {children} <ChevronDown stroke={colors.light.light} />
      </NSDropdownButtonStyle.Button>

      {isOpen && (
        <NSDropdownButtonStyle.DropdownContent>
          <NSDropdownButtonStyle.DropdownContentContainer>
            {menuItems.map((menuItem, index) => {
              if (menuItem.isLine) {
                return (
                  <NSDropdownButtonStyle.DropdownContentLine key={index} />
                );
              } else {
                return (
                  <NSDropdownButtonStyle.Menu key={index} href={menuItem.href}>
                    <NSDropdownButtonStyle.DropdownContentItemContainer>
                      {menuItem.icon} {menuItem.label}
                    </NSDropdownButtonStyle.DropdownContentItemContainer>
                  </NSDropdownButtonStyle.Menu>
                );
              }
            })}
          </NSDropdownButtonStyle.DropdownContentContainer>
        </NSDropdownButtonStyle.DropdownContent>
      )}
    </div>
  );
};
