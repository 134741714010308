import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSMobileSwiperHelperStyle {
  export const MobileSwiperContainer = styled.div`
    .swiper {
      padding-bottom: 16px;
    }
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;
}
