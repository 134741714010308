import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSFormErrorStyle {
  export const Container = styled.div`
    background-color: ${colors.neutrals.grey01};
    color: ${colors.error.error01};
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.28px;
    text-align: left;
    padding: 16px;
    border-radius: 8px;
    font-family: "ReadexPro";
  `;
}
