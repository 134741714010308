import styled from "styled-components";
import colors from "../../style/colors";
import { NSSelectBoxStyle } from "../SelectBox/SelectBox.style";

export namespace NSCountryCodeSelectBoxStyle {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    ${NSSelectBoxStyle.CustomTitleContainer} {
      height: fit-content;
      width: 40px;
      padding: 12px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  export const Line = styled.div`
    width: 2px;
    height: 24px;
    background-color: ${colors.neutrals.grey04};
  `;
}
