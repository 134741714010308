import { useState } from "react";
import { NSAutoPlayCarouselStyle } from "./AutoPlayCarousel.style";
import { NSAutoPlayCarouselType } from "./AutoPlayCarousel.types";
import "./AutoPlayCarousel.css";

export default function AutoPlayCarousel({
  isMobile,
  initialSpeed = 20,
  isReverseProp = false,
  imageList,
}: NSAutoPlayCarouselType.IAutoPlayCarousel) {
  const [isLeft, setIsLeft] = useState(isReverseProp);

  return (
    <div className="carousel-container">
      <div
        className="carousel-track"
        style={{
          animation: isLeft
            ? `slideRight ${initialSpeed}s linear`
            : `slideLeft ${initialSpeed}s linear`,
        }}
        onAnimationEnd={() => setIsLeft(!isLeft)}
      >
        {imageList.map((imageItem, index) => (
          <NSAutoPlayCarouselStyle.SlideImage
            key={index}
            src={imageItem}
            alt={imageItem}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
}
