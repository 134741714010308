import { NSModalType } from "./Modal.types";
import { useEffect } from "react";
import { desktopStyles, mobileStyles } from "../../util/modalStyles";
import ReactModal from "react-modal";
import OverlayElement from "../../util/modalOverlayElement";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade";
export const Modal = ({
  isMobile,
  children,
  isOpen,
  onCloseHandler,
}: NSModalType.IModal) => {
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.documentElement);
    } else {
      enableBodyScroll(document.documentElement);
    }
    return () => {
      enableBodyScroll(document.documentElement);
    };
  }, [isOpen]);
  return (
    <ReactModal
      isOpen={isOpen}
      style={isMobile ? mobileStyles : desktopStyles}
      onRequestClose={onCloseHandler}
      overlayElement={OverlayElement}
      closeTimeoutMS={500}
    >
      {children}
    </ReactModal>
  );
};
