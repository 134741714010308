import { useRef } from "react";
import { AnimatedArrowButton } from "../../../components/AnimatedArrowButton/AnimatedArrowButton";
import { AnimatedTextButton } from "../../../components/AnimatedTextButton/AnimatedTextButton";
import { HeaderDesktop } from "../../../components/HeaderDesktop/HeaderDesktop";
import { QuotationCard } from "../../../components/QuotationCard/QuotationCard";
import { NsHomePageType } from "../Homepage.types";
import { NSHomePageDesktopStyle } from "./HomePageDesktop.style";
import { AccordionOpportunitiesDesktop } from "../../../components/AccordionOpportunitiesDesktop/AccordionOpportunitiesDesktop";
import { Badge } from "../../../components/Badge/Badge";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { BlockHeaderTitle } from "../../../components/BlockHeaderTitle/BlockHeaderTitle";
import colors from "../../../style/colors";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { RolloverImageGallery } from "../../../components/RolloverImageGallery/RolloverImageGallery";
import { Footer } from "../../../components/Footer/Footer";
import { VideoPlayerCarousel } from "../../../components/VideoPlayerCarousel/VideoPlayerCarousel";
import { BrandImages } from "../../../components/BrandImages/BrandImages";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { TempSpacer } from "../../../util/tempSpacer";
import useStickyElement from "../../../util/useStickyElement";
import AutoPlayCarousel from "../../../components/AutoPlayCarousel/AutoPlayCarousel";
import { PositionCard } from "../../../components/PositionCard/PositionCard";

function HomePageDesktop({ homePageData }: NsHomePageType.IHomePage) {
  const isMobile = false;

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  return (
    <NSHomePageDesktopStyle.Container>
      <HeaderDesktop
        logoClicked={homePageData.logoClicked}
        anchorList={homePageData.anchorList}
        isDark={isSticky}
        backgroundColor={colors.neutrals.grey07}
      />
      <NSHomePageDesktopStyle.GrayContainer ref={stickyDeciderDifRef}>
        <NSHomePageDesktopStyle.StyledFingerPrint fill={colors.dark.dark} />
        <NSHomePageDesktopStyle.AnimatedButtonsContainer>
          <AnimatedTextButton
            label={homePageData.textButtonLabel}
            changingSpeed={homePageData.textButtonChangingSpeed}
            animatedLabelList={homePageData.animatedLabelList}
            isMobile={isMobile}
          />
          <AnimatedArrowButton
            label={homePageData.animatedArrowButtonLabel}
            isMobile={isMobile}
            onClick={homePageData.animatedArrowButtonClick}
          />
        </NSHomePageDesktopStyle.AnimatedButtonsContainer>
        <NSHomePageDesktopStyle.SliderContainer>
          <AutoPlayCarousel
            imageList={homePageData.slidingBannerData}
            isMobile={isMobile}
            initialSpeed={20}
          />
          <AutoPlayCarousel
            imageList={homePageData.slidingBannerReverseData}
            isMobile={isMobile}
            isReverseProp={true}
            initialSpeed={20}
          />
        </NSHomePageDesktopStyle.SliderContainer>
      </NSHomePageDesktopStyle.GrayContainer>
      <NSHomePageDesktopStyle.MainContainer>
        <QuotationCard
          isMobile={isMobile}
          largeText={homePageData.quoteLargeText}
          subTitle={homePageData.quoteSubTitle}
          secondSubTitle={homePageData.quoteSecondSubTitle}
        />

        <TempSpacer height={120} />
        <HeadingText
          isMobile={isMobile}
          title="Neler Yaptığımızı Bizden Dinlemek İster Misin?"
          description="Farklı fonksiyonlarımızda görev alan çalışma arkadaşlarımızın deneyimlerini dinlemek için videolarımızı izleyebilirsin."
          children={
            <BlockHeaderTitle
              isMobile={isMobile}
              title="Çalışma Arkadaşlarımızın Hikayeleri"
              backgroundColor={colors.blue.blue01}
              titleColor={colors.dark.dark}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@dogusotomotiv4354/videos",
                  "_blank"
                )
              }
            />
          }
        />
        <TempSpacer height={120} />
        <NSHomePageDesktopStyle.VideoCarouselBadgeContainer>
          <Badge
            isMobile={isMobile}
            textVariant="KNOWUS"
            color="BLUE"
            iconVariant="ARROW"
            speed={15}
            onClick={() => window.open("/know-us", "_self")}
          />
          <VideoPlayerCarousel
            {...homePageData.videoPlayerCarousel}
            isMobile={isMobile}
          />
        </NSHomePageDesktopStyle.VideoCarouselBadgeContainer>
        <TempSpacer height={210} />
        <HeadingText
          isMobile={isMobile}
          isVertical={false}
          title="Keyifli Bir Çalışma Ortamı
          Seni Bekliyor."
          description="Yeni nesil paylaşımlı ofis alanlarında hibrit modelde çalışabilir, ortak alanlarda dilediğin gibi sosyalleşebilirsin."
          children={
            <BlockHeaderTitle
              isMobile={isMobile}
              title="Şirkette Yaşam"
              backgroundColor={colors.green.green01}
              titleColor={colors.dark.dark}
              onClick={() => window.open("/know-us", "_self")}
            />
          }
        />
        <TempSpacer height={180} />
        <NSHomePageDesktopStyle.CarouselBadgeContainer>
          <Badge
            isMobile={isMobile}
            textVariant="KNOWUS"
            color="GREEN"
            iconVariant="ASTRIX"
            onClick={() => window.open("/know-us", "_self")}
          />
          <ImageCarousel
            imageList={homePageData.imageCarouselData}
            isMobile={isMobile}
            displayImagePreview={true}
          />
        </NSHomePageDesktopStyle.CarouselBadgeContainer>
        <TempSpacer height={220} />
        <AccordionOpportunitiesDesktop
          title="Bizimle Keşfetmeye Hazır Mısın?"
          opportunityList={homePageData.opportunityList}
        />

        <TempSpacer height={140} />
        <RolloverImageGallery
          isMobile={isMobile}
          cardList={homePageData.rolloverImageData}
        />
        <NSHomePageDesktopStyle.HorizontalLine />
        <BrandImages {...homePageData.brandImages} isMobile={isMobile} />
      </NSHomePageDesktopStyle.MainContainer>
      <Footer {...homePageData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSHomePageDesktopStyle.Container>
  );
}

export default HomePageDesktop;
