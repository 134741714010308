import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSRoundedCardWithDescriptionStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    border-radius: 0px 64px 0px 0px;
    box-sizing: border-box;
    background-color: ${colors.blue.blue01};
    font-family: "ReadexPro";
    width: ${({ isMobile }) => (isMobile ? "100%" : "360px")};
  `;

  export const RoundedImage = styled.img<{ isMobile: boolean }>`
    height: 100%;
    object-fit: cover;
  `;
  export const Title = styled.div`
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    margin-top: 16px;
    margin-bottom: 8px;
  `;
  export const ImageContainer = styled.div`
    height: 216px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 40px 40px 40px;
    background-color: ${colors.light.light};
  `;
  export const Description = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
  `;
}
