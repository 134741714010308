import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSSelectBoxStyle {
  export const Container = styled.div`
    font-family: "ReadexPro";
    position: relative;
    width: 100%;
    box-sizing: border-box;
  `;

  export const Title = styled.div<{ isSelected: boolean }>`
    line-height: 22.96px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    gap: 4px;
    padding-right: 32px;
    color: ${(props) =>
      props.isSelected ? colors.dark.dark : colors.neutrals.grey05};
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

  export const Star = styled.span`
    color: ${colors.dark.dark};
    font-size: ${typography.smallText2.size};
    line-height: 19.68px;
    font-weight: 400;
  `;

  export const TitleContainer = styled.div<{
    disabled: boolean;
    error: boolean;
  }>`
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
    border: ${(props) =>
      `2px solid  ${
        props.error ? colors.error.error01 : colors.neutrals.grey03
      } `};
    background-color: transparent;
    padding: 16px;
    font-size: ${typography.smallText1.size};
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${colors.neutrals.grey01};
        border-color: ${colors.neutrals.grey03};
        color: ${colors.neutrals.grey05};
      `}
  `;

  export const ItemContainer = styled.div<{ position: "top" | "bottom" }>`
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    min-width: fit-content;
    background-color: ${colors.light.light};
    box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
    ${({ position }) =>
      position === "bottom"
        ? css`
            top: 72px;
          `
        : css`
            bottom: 72px;
          `}
  `;

  export const Item = styled.div`
    display: flex;
    color: ${colors.dark.dark};
    font-size: 14px;
    line-height: 22.96px;
    font-weight: 400;
    gap: 8px;
    padding: 8px;
    :hover {
      background-color: ${colors.neutrals.grey01};
    }
  `;

  export const ErrorMessage = styled.div`
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    top: 58px;
    color: ${colors.error.error01};
    left: 0;
    background-color: transparent;
    position: absolute;
  `;

  export const CustomTitleContainer = styled.div`
    width: fit-content;
    height: fit-content;
    cursor: pointer;
  `;

  export const Label = styled.div`
    font-weight: 400;
    line-height: 19.68px;
    font-size: 12px;
    height: 16px;
    top: -10px;
    left: 8px;
    color: ${colors.neutrals.grey06};
    padding: 0 8px;
    background-color: ${colors.light.light};
    position: absolute;
    display: flex;
    gap: 2px;
  `;
}
