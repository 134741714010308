import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { NSTopMenuNavigationDesktopStyle } from "../TopMenuNavigationDesktop/TopMenuNavigationDesktop.style";

export namespace NSFooterStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "ReadexPro";
  `;

  export const InnerContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    background-color: ${({ isMobile }) =>
      isMobile ? "transparent" : colors.neutrals.grey03};
  `;
  export const LeftContainer = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "auto" : "40%")};
    background-color: ${colors.dark.dark};
    color: ${colors.light.light};
    border-radius: ${({ isMobile }) =>
      isMobile ? "32px 32px 0px 0px" : "0px 128px 0px 0px"};

    padding: ${({ isMobile }) => (isMobile ? "32px" : "88px")};
  `;

  export const RightContainer = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "auto" : "60%")};
    padding: ${({ isMobile }) => (isMobile ? "32px" : "88px 88px 54px 88px")};
    background-color: ${colors.neutrals.grey03};
    ${NSTopMenuNavigationDesktopStyle.Container} {
      margin-left: -16px;
      gap: 0px;
      justify-content: space-between;
      ${({ isMobile }) =>
        isMobile &&
        css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          a {
            font-size: ${typography.smallText1.size} !important;
          }
        `}
      a {
        font-size: ${typography.pharagraph1_5.size};
        padding: 6px 16px;
      }
    }
  `;

  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    display: flex;
    align-items: center;
    gap: 12px;
  `;

  export const Description = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText2.lineHeight
        : typography.smallText1.lineHeight};
    font-weight: ${({ isMobile }) =>
      isMobile
        ? typography.fontWeights.regular
        : typography.fontWeights.medium};
    margin-top: 8px;
    margin-bottom: ${({ isMobile }) => (isMobile ? "32px" : "72px")};
  `;

  export const SocialLinksContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ isMobile }) => (isMobile ? "28px" : "40px")};
    margin-left: -2px;
  `;
  export const SocialLinkItem = styled.a`
    all: unset;
    cursor: pointer;
    svg {
      fill: ${colors.light.light};
    }
    &:hover {
      filter: brightness(80%);
    }
  `;

  export const DescriptionDark = styled(Description)`
    color: ${colors.dark.dark};
    margin-top: ${({ isMobile }) => (isMobile ? "32px" : "44px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
    font-weight: ${({ isMobile }) =>
      isMobile ? typography.fontWeights.regular : typography.fontWeights.light};
  `;

  export const CareerLinkContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    gap: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "32px" : "56px")};
  `;

  export const CareerLink = styled.a<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    text-decoration: ${({ isMobile }) => (isMobile ? "none" : "underline")};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText2.lineHeight
        : typography.smallText1.lineHeight};
    font-weight: ${({ isMobile }) =>
      isMobile ? typography.fontWeights.regular : typography.fontWeights.light};
  `;
  export const AddressContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `;
  export const AddressText = styled(DescriptionDark)`
    margin: 0;
  `;
}
