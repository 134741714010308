import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSPasswordConfirmationStyle {
  export const Container = styled.div`
    background-color: ${colors.neutrals.grey01};
    color: ${colors.neutrals.grey05};
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.68px;
    text-align: left;
    padding: 16px;
    border-radius: 16px;
    gap: 4px;
    font-family: "ReadexPro";
  `;

  export const Dot = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${colors.neutrals.grey03};
    border-radius: 8px;
  `;

  export const Item = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.68px;

    ${({ isActive }) =>
      isActive &&
      css`
        ${Dot} {
          background-color: ${colors.success.success01};
        }
      `}
  `;
}
