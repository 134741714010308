import { useState } from "react";
import { NSAccordionListStyle } from "./AccordionList.style";
import { NSAccordionListType } from "./AccordionList.types";
import colors from "../../style/colors";

export const AccordionList = ({
  isMobile,
  title,
  itemList,
}: NSAccordionListType.IAccordionList) => {
  const [selectedItem, setSelectedItem] =
    useState<NSAccordionListType.IAccordionItem | null>();

  const itemSelectionHandler = (icon: NSAccordionListType.IAccordionItem) => {
    if (icon === selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(icon);
    }
  };

  const renderItems = () =>
    itemList.map((item, index) => (
      <NSAccordionListStyle.HoveredItem
        isMobile={isMobile}
        key={item.title}
        onClick={() => itemSelectionHandler(item)}
      >
        <NSAccordionListStyle.MobileItemTitleContainer>
          <NSAccordionListStyle.ItemTitle isMobile={isMobile}>
            <NSAccordionListStyle.Number isMobile={isMobile}>
              {index + 1}
            </NSAccordionListStyle.Number>
            {item.title}
          </NSAccordionListStyle.ItemTitle>
          <NSAccordionListStyle.IconContainer>
            <NSAccordionListStyle.CustomAdd stroke={colors.yellow.yellow01} />
            <NSAccordionListStyle.CustomRemove />
          </NSAccordionListStyle.IconContainer>
        </NSAccordionListStyle.MobileItemTitleContainer>
        <NSAccordionListStyle.DescriptionWindow>
          <NSAccordionListStyle.CustomUl>
            {item.points.map((point) => (
              <NSAccordionListStyle.CustomLi>
                {point}
              </NSAccordionListStyle.CustomLi>
            ))}
          </NSAccordionListStyle.CustomUl>
          <NSAccordionListStyle.DescriptionNumber isMobile={isMobile}>
            0{index + 1}
          </NSAccordionListStyle.DescriptionNumber>
        </NSAccordionListStyle.DescriptionWindow>
        {isMobile && selectedItem === item && (
          <NSAccordionListStyle.DescriptionWindowMobile>
            <NSAccordionListStyle.CustomUl>
              {item.points.map((point) => (
                <NSAccordionListStyle.CustomLi>
                  {point}
                </NSAccordionListStyle.CustomLi>
              ))}
            </NSAccordionListStyle.CustomUl>
            <NSAccordionListStyle.DescriptionNumber isMobile={isMobile}>
              0{index + 1}
            </NSAccordionListStyle.DescriptionNumber>
          </NSAccordionListStyle.DescriptionWindowMobile>
        )}
      </NSAccordionListStyle.HoveredItem>
    ));

  return (
    <NSAccordionListStyle.Container isMobile={isMobile}>
      <NSAccordionListStyle.Title isMobile={isMobile}>
        {title}
      </NSAccordionListStyle.Title>
      {renderItems()}
    </NSAccordionListStyle.Container>
  );
};
