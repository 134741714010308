import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { NSBadgeStyle } from "../Badge/Badge.style";

export namespace NSIconListCardStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: inline-flex;
    transition: 0.5s all;
    flex-direction: column;
    padding: ${(props) => (props.isMobile ? "32px" : "56px 64px")};
    background: ${colors.blue.blue03};
    font-family: "ReadexPro";
    color: ${colors.light.light};
    width: 100%;
    text-align: center;
    border-radius: 32px;
    box-sizing: border-box;
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    position: relative;
    color: ${colors.dark.dark};
    ${NSBadgeStyle.Container} {
      display: ${({ isMobile }) => (isMobile ? "none" : "flex")};
      position: absolute;
      right: 24px;
      top: 24px;
    }
  `;

  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    margin-bottom: 16px;
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
  `;

  export const Description = styled.div<{ isMobile: boolean }>`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    max-width: ${({ isMobile }) => (isMobile ? "100%" : "60%")};
    text-align: center;
    margin-bottom: 32px;
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
  `;
  export const DesktopGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 40px;
    justify-content: space-between;
    margin-top: 36px;
    transition: 0.5s all;
  `;
  export const MobileGrid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 32px;
  `;
  export const IconOrb = styled.div`
    height: 80px;
    width: 80px;
    min-width: 80px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.light.light};
    color: ${colors.dark.dark};
    text-align: left;
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    transition: 0.3s all;
    overflow: hidden;
    ul {
      list-style: disc;
      margin-left: 18px;
    }
  `;
  export const IconDescription = styled.div`
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s;
    height: 100%;
  `;
  export const InnerIcon = styled.div`
    display: flex;
    position: absolute;
  `;
  export const DescriptionHelper = styled.div`
    width: 100%;
    position: absolute;
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    text-align: left;
    color: ${colors.dark.dark};
    padding: 0px 16px;
    opacity: 0;
    pointer-events: none;
    ul {
      list-style: disc;
      margin-left: 18px;
    }
  `;
  export const IconTitle = styled.div`
    text-align: left;
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    color: ${colors.dark.dark};
    font-weight: ${typography.fontWeights.medium};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
  export const IconContainer = styled.div<{
    height: number;
  }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    transition: 0.1s all;
    position: relative;
    &:hover {
      ${DescriptionHelper} {
        display: block;
        opacity: 1;
        transition: opacity 1s;
      }
      ${IconOrb} {
        width: 100%;
        height: ${({ height }) => `${height + 48}px`};
        padding: 16px;
        border-radius: 32px;
      }
      ${IconTitle} {
        display: none;
      }
      ${InnerIcon} {
        display: none;
      }
    }
  `;

  export const MobileCard = styled.button`
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 2px solid ${colors.shadesOflight.light32};
    :last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  `;
  export const MobileOrb = styled.div`
    height: 64px;
    width: 64px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.light.light};
    margin-bottom: 16px;
  `;

  export const DescriptionMobile = styled.div<{ isOpen: boolean }>`
    width: 100%;
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    text-align: left;
    color: ${colors.dark.dark};
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    margin-top: ${({ isOpen }) => (isOpen ? "8px" : "0px")};
    ul {
      list-style: disc;
      margin-left: 18px;
    }
  `;
}
