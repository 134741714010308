import useDeviceType from "../../../util/isMobile";
import RegisterPageDesktop from "./DesktopView/RegisterPageDesktop";
import RegisterPageMobile from "./MobileView/RegisterPageMobile";
import { NSRegisterPageType } from "./RegisterPage.types";

const registerPageData: NSRegisterPageType.IRegisterPageData = {
  registerSchema: NSRegisterPageType.registerSchema,
  onSubmitRegister: (data) => {
    console.log("data", data);
  },
};

function RegisterPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <RegisterPageMobile
        onSubmitRegister={registerPageData.onSubmitRegister}
        registerSchema={registerPageData.registerSchema}
      />
    );
  } else {
    return (
      <RegisterPageDesktop
        onSubmitRegister={registerPageData.onSubmitRegister}
        registerSchema={registerPageData.registerSchema}
      />
    );
  }
}

export default RegisterPage;
