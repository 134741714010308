import { useMemo } from "react";
import { NSPasswordConfirmationStyle } from "./PasswordConfirmation.style";
import { NSPasswordConfirmationType } from "./PasswordConfirmation.types";

export const PasswordConfirmation = ({
  password,
}: NSPasswordConfirmationType.IPasswordConfirmation) => {
  const passwordStrength = useMemo(() => {
    const items = [
      {
        label: "Şifren en az 8 karakterden oluşmalı",
        isActive: !!password && password.length >= 8,
      },
      {
        label: "En az 1 adet rakam içermeli",
        isActive: !!password && /\d/.test(password),
      },
      {
        label:
          "En az 1 adet özel karakter (?, @, !, #, %, +, -, *, %) içermeli",
        isActive:
          !!password &&
          /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(password),
      },
      {
        label: "En az 1 adet büyük harf içermeli",
        isActive: !!password && /[A-Z]/.test(password),
      },

      {
        label: "En az 1 adet küçük harf içermeli",
        isActive: !!password && /[a-z]/.test(password),
      },
    ];

    return items;
  }, [password]);

  return (
    <NSPasswordConfirmationStyle.Container>
      {passwordStrength.map((item) => (
        <NSPasswordConfirmationStyle.Item
          isActive={item.isActive}
          key={item.label}
        >
          <NSPasswordConfirmationStyle.Dot />
          {item.label}
        </NSPasswordConfirmationStyle.Item>
      ))}
    </NSPasswordConfirmationStyle.Container>
  );
};
