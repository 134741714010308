import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSAccountPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 16px 16px 32px 16px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  `;

  export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: ${colors.light.light};
    padding: 32px;
    border-radius: 16px;

    @media (max-width: 768px) {
      border-radius: 0px;
      padding: 24px 16px 11px 16px;
      margin-bottom: 40px;
    }
  `;

  export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin-bottom: 24px;
  `;

  export const Seperator = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.neutrals.grey02};
  `;
}
