import styled from "styled-components";

export namespace NSDatePickerStyle {
  export const Container = styled.div`
    font-family: "ReadexPro";
    width: 100%;

    .react-datepicker-wrapper {
      width: 100%;
    }
  `;
}
