import useDeviceType from "../../../util/isMobile";
import CVPageDesktop from "./DesktopView/CVPageDesktop";
import CVPageMobile from "./MobileView/CVPageMobile";
import { NSCVPageType } from "./CVPage.types";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const cvData: NSCVPageType.ICVPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
};

function CVPage() {
  const { isMobile, isTablet } = useDeviceType();

  if (isMobile || isTablet) {
    return <CVPageMobile cvData={cvData} />;
  } else {
    return <CVPageDesktop cvData={cvData} />;
  }
}

export default CVPage;
