import { useEffect, useState, useRef } from "react";
import { NSAnimatedTextButtonStyle } from "./AnimatedTextButton.style";
import { NSAnimatedTextButtonType } from "./AnimatedTextButton.types";

export const AnimatedTextButton = ({
  label,
  animatedLabelList,
  changingSpeed = 3000,
  isSmall = false,
  isVertical = false,
  isMobile,
}: NSAnimatedTextButtonType.IAnimatedTextButton) => {
  const [selectedLabel, setSelectedLabel] = useState(animatedLabelList[0]);
  const [animatedWidth, setAnimatedWidth] = useState<number>(0);

  const labelRef = useRef<HTMLDivElement>(null);
  const animatedTextRef = useRef<HTMLDivElement>(null);

  const totalWidth = (labelRef.current?.clientWidth ?? 0) + animatedWidth;

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % animatedLabelList.length;

      setSelectedLabel(animatedLabelList[nextIndex]);

      setTimeout(() => {
        setAnimatedWidth(animatedTextRef.current?.clientWidth ?? 0);
      }, 100);

      currentIndex = nextIndex;
    }, changingSpeed);
    return () => {
      clearInterval(interval);
    };
  }, [animatedLabelList, changingSpeed]);

  const innerContainer = () => (
    <NSAnimatedTextButtonStyle.TitleLabelContainer>
      <NSAnimatedTextButtonStyle.Title isVertical={isVertical} ref={labelRef}>
        {label}
      </NSAnimatedTextButtonStyle.Title>
      {isMobile && !isVertical && mobileLabel()}
      {isMobile && isVertical && mobileLabelVertical()}
      {!isMobile && (
        <NSAnimatedTextButtonStyle.AnimatedText
          color={selectedLabel.color}
          ref={animatedTextRef}
        >
          {selectedLabel.label}
        </NSAnimatedTextButtonStyle.AnimatedText>
      )}
    </NSAnimatedTextButtonStyle.TitleLabelContainer>
  );

  const mobileLabel = () =>
    animatedLabelList.map((label) => (
      <NSAnimatedTextButtonStyle.MobileText
        color={label.color}
        isSelected={label === selectedLabel}
      >
        {label.label}
      </NSAnimatedTextButtonStyle.MobileText>
    ));
  const mobileLabelVertical = () =>
    animatedLabelList.map((label) => (
      <NSAnimatedTextButtonStyle.MobileTextVertical
        color={label.color}
        isSelected={label === selectedLabel}
      >
        {label.label}
      </NSAnimatedTextButtonStyle.MobileTextVertical>
    ));

  if (isMobile) {
    return (
      <NSAnimatedTextButtonStyle.MobileContainer isSmall={isSmall}>
        {innerContainer()}
      </NSAnimatedTextButtonStyle.MobileContainer>
    );
  } else {
    return (
      <NSAnimatedTextButtonStyle.DesktopContainer
        isSmall={isSmall}
        width={totalWidth}
      >
        {innerContainer()}
      </NSAnimatedTextButtonStyle.DesktopContainer>
    );
  }
};
