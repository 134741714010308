import { NSCircleButtonStyle } from "./CircleButton.style";
import { NSCircleButtonType } from "./CircleButton.types";
import { ReactComponent as UpArrow } from "../../assets/icons/upArrow.svg";

export const CircleButton = ({
  isMobile,
  onClick,
}: NSCircleButtonType.ICircleButton) => {
  return (
    <NSCircleButtonStyle.Container isMobile={isMobile} onClick={onClick}>
      <UpArrow />
    </NSCircleButtonStyle.Container>
  );
};
