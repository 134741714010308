import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { NSDropdownButtonStyle } from "../DropdownButton/DropdownButton.style";

export namespace NSTopMenuNavigationDesktopStyle {
  export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
      align-items: center;
    }
  `;
  export const MenuAnchor = styled.a<{ isDark: boolean; isSelected: boolean }>`
    all: unset;
    cursor: pointer;
    font-family: "ReadexPro";
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    color: ${(props) => (props.isDark ? colors.dark.dark : colors.light.light)};
    padding: 12px 16px;
    border-radius: 16px;
    transition: background-color 0.5s, color 0.2s;
    &:hover {
      background-color: ${({ isDark }) =>
        isDark ? colors.blue.blue01 : colors.shadesOflight.light08};
      color: ${colors.light.light};
      ${NSDropdownButtonStyle.Button} {
        color: ${colors.light.light};
        svg {
          stroke: ${colors.light.light};
        }
      }
    }
    ${(props) =>
      props.isSelected &&
      props.isDark &&
      css`
        background-color: ${colors.blue.blue04};
        color: ${colors.light.light};
      `}
    ${(props) =>
      props.isSelected &&
      !props.isDark &&
      css`
        background-color: ${colors.shadesOflight.light16};
        color: ${colors.light.light};
      `}

    ${NSDropdownButtonStyle.Button} {
      color: ${(props) =>
        props.isDark ? colors.dark.dark : colors.light.light};
      svg {
        stroke: ${(props) =>
          props.isDark ? colors.dark.dark : colors.light.light};
      }
    }

    @media (max-width: 1000px) {
      padding: 12px 1vw;
      font-size: ${typography.smallText1.size};
      line-height: ${typography.smallText1.lineHeight};
    }
  `;

  export const IconMenuContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
    }
  `;

  export const IconMenu = styled.div`
    display: flex;
    align-items: center;

    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
    }
  `;
}
