import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSInfoCardStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 12px 0px;
  `;

  export const Separator = styled.div`
    flex: 1;
    margin-bottom: 12px;
    border: 1px solid ${colors.neutrals.grey01};
  `;

  export const Title = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.neutrals.grey06};
    font-family: "ReadexPro";
    width: 50%;
  `;

  export const Desc = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: 26.24px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
  `;

  export const LocationItem = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: 26.24px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
    ::after {
      content: ", ";
    }

    :last-child:after {
      content: "";
    }
  `;
}
