import { NSTextListStyle } from "./TextList.style";
import { NSTextListType } from "./TextList.types";

export const TextList = ({
  isMobile,
  title,
  list,
  description,
}: NSTextListType.ITextList) => {
  const renderGrid = () => (
    <NSTextListStyle.GridContainer>
      {list?.map((item) => (
        <NSTextListStyle.GridItem key={item}>{item}</NSTextListStyle.GridItem>
      ))}
    </NSTextListStyle.GridContainer>
  );

  const renderList = () => (
    <NSTextListStyle.MobileList>
      {list?.map((item) => (
        <NSTextListStyle.MobileItem key={item}>
          {item}
        </NSTextListStyle.MobileItem>
      ))}
    </NSTextListStyle.MobileList>
  );
  const chooseRender = () => {
    if (isMobile) {
      return renderList();
    } else {
      return renderGrid();
    }
  };

  return (
    <NSTextListStyle.Container isMobile={isMobile}>
      <NSTextListStyle.Title isMobile={isMobile}>{title}</NSTextListStyle.Title>
      <NSTextListStyle.DescriptionContainer>
        {description}
      </NSTextListStyle.DescriptionContainer>
      {chooseRender()}

      <NSTextListStyle.LinkText>
        Ve tüm sürdürülebilirlik süreçlerimizi incelemek için{" "}
        <NSTextListStyle.Link
          target="_blank"
          href="https://www.dogusotomotiv.com.tr/tr/surdurulebilirlik/surdurulebilirlik-1/surdurulebilirlik-raporlari"
          rel="noreferrer"
        >
          tıklayabilirsin.
        </NSTextListStyle.Link>
      </NSTextListStyle.LinkText>
    </NSTextListStyle.Container>
  );
};
