import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSJobDescriptionStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    box-sizing: border-box;
    width: ${(props) => (props.isMobile ? "100%" : "823px")};
    height: 100%;
    background-color: ${colors.light.light};
    border-radius: 16px;
    font-family: "ReadexPro";
    gap: 32px;
    padding: 32px;

    @media (max-width: 768px) {
      border-radius: 0px;
      padding: 24px 16px;
    }
  `;

  export const CoverImage = styled.img<{ isMobile: boolean }>`
    height: ${(props) => (props.isMobile ? "100%" : "100%")};
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  `;

  export const Content = styled.div`
    margin: 32px 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.96px;
    color: ${colors.dark.dark};

    & > strong {
      font-weight: 700;
    }

    & > div > ul {
      margin: 24px 0px 0px 15px;
      list-style: disc;
    }
  `;

  export const SocialMediaDiv = styled.div`
    width: auto;
    height: 100%;
    border-radius: 16px;
    background-color: ${colors.neutrals.grey01};
    padding: 16px 24px;
    margin-top: 32px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

  export const SocialMediaShareText = styled.p`
    font-size: 16px;
    line-height: 24.96px;
    color: ${colors.dark.dark};
  `;
}
