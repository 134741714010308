import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSAuthCardStyle {
  export const Container = styled.div<{
    isMobile: boolean;
  }>`
    width: ${(props) => (props.isMobile ? "100%" : "532px")};
    height: fit-content;
    background-color: ${colors.light.light};
    padding: ${(props) => (props.isMobile ? "24px 16px" : "40px")};
    border-radius: ${(props) => (props.isMobile ? "0" : "16px")};
    box-shadow: 0px 0px 16px rgba(15, 15, 42, 0.08);
  `;
}
