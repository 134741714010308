import { NSAuthCardStyle } from "./AuthCard.style";
import { NSAuthCardType } from "./AuthCard.types";

export const AuthCard = ({
  isMobile = false,
  children,
}: NSAuthCardType.IAuthCard) => {
  return (
    <NSAuthCardStyle.Container isMobile={isMobile}>
      {children}
    </NSAuthCardStyle.Container>
  );
};
