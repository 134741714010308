import { NSCVOtherStyle } from "./CVOther.style";
import { NSCVOtherType } from "./CVOther.types";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { Accordion } from "../Accordion/Accordion";
import colors from "../../style/colors";
import { DragDropFileUpload } from "../DragDropFileUpload/DragDropFileUpload";

export const CVOther = ({ onSubmitOther }: NSCVOtherType.ICVOther) => {
  const {
    setValue,
    handleSubmit,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm<NSCVOtherType.CVOtherInfoType>({
    resolver: zodResolver(NSCVOtherType.CVOtherSchema),
  });
  const onSubmit: SubmitHandler<NSCVOtherType.CVOtherInfoType> = (data) => {
    onSubmitOther(data);
  };

  const [isOpen, setIsOpen] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<Blob | undefined>(undefined);

  const onClickGiveUp = () => {
    setIsOpen(false);
    resetField("cvName");
    setUploadedFile(undefined);
    clearErrors();
  };

  return (
    <Accordion isOpen={isOpen} setIsOpen={setIsOpen} title="Ek Bilgiler">
      <NSCVOtherStyle.FormContainer
        onSubmit={handleSubmit(onSubmit)}
        id="CVOther"
      >
        <NSCVOtherStyle.DragAndDropContainer>
          <DragDropFileUpload
            onClickTryAgain={() => clearErrors()}
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            uploadedFileName={(uploadedFile as File)?.name ?? undefined}
            error={errors.cvName?.message}
            onUpload={(_, acceptedFile) => {
              setUploadedFile(acceptedFile);
              setValue("cvName", (acceptedFile as File).name);
            }}
          />
        </NSCVOtherStyle.DragAndDropContainer>

        <NSCVOtherStyle.ButtonContainer>
          <Button
            width={105}
            height={56}
            fontSize={16}
            type="submit"
            variant="solid"
          >
            Kaydet
          </Button>

          <Button
            onClick={onClickGiveUp}
            width={105}
            height={56}
            color={colors.blue.blue01}
            fontSize={16}
            variant="ghost"
          >
            Vazgeç
          </Button>
        </NSCVOtherStyle.ButtonContainer>
      </NSCVOtherStyle.FormContainer>
    </Accordion>
  );
};
