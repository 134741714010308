import { useRef, useState } from "react";
import { NSPositionListPageType } from "../PositionListPage.types";
import { NSPositionListPageMobileStyle } from "./PositionListPageMobile.style";
import useStickyElement from "../../../../util/useStickyElement";
import { MenuMobile } from "../../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../../components/HeaderMobile/HeaderMobile";
import colors from "../../../../style/colors";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import { Footer } from "../../../../components/Footer/Footer";
import { CareerDropdown } from "../../../../components/CareerDropdown/CareerDropdown";
import { PositionCard } from "../../../../components/PositionCard/PositionCard";
import { Pagination } from "../../../../components/Pagination/Pagination";

function PositionListPageMobile({
  positionListData,
}: Readonly<NSPositionListPageType.IPositionListPage>) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  return (
    <NSPositionListPageMobileStyle.Container>
      <NSPositionListPageMobileStyle.LeftContainer>
        <NSPositionListPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={positionListData.anchorList}
            socialLinks={positionListData.footer.socialLinks}
          />
        </NSPositionListPageMobileStyle.RightContainer>
        <HeaderMobile
          logoClicked={() => {}}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
          backgroundColor={colors.blue.blue02}
        />
        <NSPositionListPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            isInJobPage={true}
            children={""}
            iconColor={colors.blue.blue06}
            isMobile={isMobile}
            title="Kariyer Fırsatları"
            backgroundColor={colors.blue.blue02}
          />
        </NSPositionListPageMobileStyle.StickyHelperContainer>

        <NSPositionListPageMobileStyle.MainContainer>
          <NSPositionListPageMobileStyle.PageTitle isMobile={isMobile}>
            {`${positionListData.listCount} Açık pozisyon Listeleniyor`}
            <CareerDropdown onSelect={(item) => console.log(item)} />
          </NSPositionListPageMobileStyle.PageTitle>
          <NSPositionListPageMobileStyle.StyledContainer>
            <NSPositionListPageMobileStyle.StyledRow justify="between">
              {positionListData.positionList.map((position) => (
                <NSPositionListPageMobileStyle.StyledCol key={position.id}>
                  <PositionCard
                    id={position.id}
                    tag={position.tag}
                    title={position.title}
                    locations={position.locations}
                    date={position.date}
                    isMobile={true}
                  />
                </NSPositionListPageMobileStyle.StyledCol>
              ))}
            </NSPositionListPageMobileStyle.StyledRow>
          </NSPositionListPageMobileStyle.StyledContainer>
          <NSPositionListPageMobileStyle.PaginationContainer>
            <Pagination
              currentPage={1}
              onClickNavigation={(position) => console.log(position)}
              pageCount={20}
              isMobile={true}
            />
          </NSPositionListPageMobileStyle.PaginationContainer>
        </NSPositionListPageMobileStyle.MainContainer>
        <Footer {...positionListData.footer} isMobile={isMobile} />
      </NSPositionListPageMobileStyle.LeftContainer>
    </NSPositionListPageMobileStyle.Container>
  );
}

export default PositionListPageMobile;
