import styled from "styled-components";

export namespace NSAutoPlayCarouselStyle {
  export const SlideImage = styled.img<{ isMobile: boolean }>`
    height: ${(props) => (props.isMobile ? "20vh" : "176px")};
    width: ${(props) => (props.isMobile ? "240px" : "300px")};
    border-radius: ${(props) => (props.isMobile ? "16px" : "32px")};
    object-fit: cover;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  `;
}
