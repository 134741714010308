import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSAnimatedArrowButtonStyle {
  export const Container = styled.button<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    background-color: ${colors.blue.blue01};
    padding: ${(props) => (props.isMobile ? "16px 24px" : "16px 32px")};
    border-radius: ${(props) => (props.isMobile ? "32px" : "62px")};
    height: ${({ isMobile }) => (isMobile ? "64px" : "96px")};
    z-index: ${({ isMobile }) => (isMobile ? "unset" : 10)};
    display: inline-flex;
    align-items: center;
    font-family: "ReadexPro";
    box-sizing: border-box;
    color: ${colors.light.light};
    &:hover {
      background-color: ${colors.blue.blue04};
    }
  `;

  export const Title = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${(props) =>
      props.isMobile ? typography.pharagraph1.size : typography.titleh5.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.pharagraph1.lineHeight
        : typography.titleh5.lineHeight};

    margin-right: 12px;
  `;
}
