import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSFooterBarStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? "16px" : "24px 84px")};
    background-color: ${colors.background.bg};
    font-family: "ReadexPro";
    display: flex;
    justify-content: ${({ isMobile }) =>
      isMobile ? "center" : "space-between"};
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    align-items: center;
    font-size: ${({ isMobile }) =>
      isMobile ? typography.preText.size : typography.smallText2.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.preText.lineHeight
        : typography.smallText2.lineHeight};
    font-weight: ${typography.fontWeights.light};
  `;
  export const LeftContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    gap: ${({ isMobile }) => (isMobile ? "16px" : "8px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "0px")};
    ${({ isMobile }) =>
      isMobile &&
      css`
        align-items: center;
      `}
  `;
  export const RightContainer = styled.div`
    display: flex;
    gap: 8px;
  `;
  export const CopyRightText = styled.div``;
  export const LinksContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    gap: ${({ isMobile }) => (isMobile ? "4px" : "8px")};
  `;
  export const CopyRightLinks = styled.a<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    text-decoration: ${({ isMobile }) => (isMobile ? "none" : "underline")};
  `;
}
