import { useRef, useState } from "react";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { NsKnowUsPageType } from "../KnowUsPage.types";
import { NSKnowUsPageMobileStyle } from "./KnowUsPageMobile.style";
import { MenuMobile } from "../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../components/HeaderMobile/HeaderMobile";
import { TextStats } from "../../../components/TextStats/TextStats";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { StatsCard } from "../../../components/StatsCard/StatsCard";
import { Badge } from "../../../components/Badge/Badge";
import { VideoPlayerCarousel } from "../../../components/VideoPlayerCarousel/VideoPlayerCarousel";
import { RoundedCardCarousel } from "../../../components/RoundedCardCarousel/RoundedCardCarousel";
import { Footer } from "../../../components/Footer/Footer";
import { BrandImages } from "../../../components/BrandImages/BrandImages";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { desktopStyles, mobileStyles } from "../../../util/modalStyles";
import OverlayElement from "../../../util/modalOverlayElement";
import { RolloverImageGallery } from "../../../components/RolloverImageGallery/RolloverImageGallery";
import { IconDescriptionList } from "../../../components/IconDescriptionList/IconDescriptionList";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { IconCard } from "../../../components/IconCard/IconCard";
import { BlockHeaderTitle } from "../../../components/BlockHeaderTitle/BlockHeaderTitle";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { TempSpacer } from "../../../util/tempSpacer";
import useStickyElement from "../../../util/useStickyElement";
import { MobileSwiperHelper } from "../../../components/MobileSwiperHelper/MobileSwiperHelper";
function KnowUsPageMobile({ knowUsPageData }: NsKnowUsPageType.IKnowUsPage) {
  const isMobile = true;

  const playerRef = useRef<ReactPlayer | null>(null);

  const [showMenu, setShowMenu] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <NSKnowUsPageMobileStyle.Container>
      <NSKnowUsPageMobileStyle.LeftContainer>
        <NSKnowUsPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={knowUsPageData.anchorList}
            socialLinks={knowUsPageData.footer.socialLinks}
          />
        </NSKnowUsPageMobileStyle.RightContainer>
        {isSticky && (
          <HeaderMobile
            logoClicked={() => {}}
            menuButtonClicked={() => setShowMenu(true)}
            isDark={true}
          />
        )}
        <NSKnowUsPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={
              <HeaderMobile
                logoClicked={() => {}}
                menuButtonClicked={() => setShowMenu(true)}
                isDark={false}
              />
            }
            iconColor={colors.blue.blue05}
            isMobile={isMobile}
            title="Bizi Tanı"
            backgroundColor={colors.blue.blue01}
          />
        </NSKnowUsPageMobileStyle.StickyHelperContainer>

        <NSKnowUsPageMobileStyle.MainContainer>
          <TextStats {...knowUsPageData.textStats} isMobile={isMobile} />
          <TempSpacer height={60} />
          <IconDescriptionList
            {...knowUsPageData.iconDescriptionListMobile}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Değerlerimiz"
            description=""
          />
          <NSKnowUsPageMobileStyle.IconCardContainer>
            <MobileSwiperHelper
              childrenList={knowUsPageData.iconCardList.map((item) => (
                <IconCard {...item} isMobile={isMobile} />
              ))}
            />
          </NSKnowUsPageMobileStyle.IconCardContainer>
          <TempSpacer height={56} />
          <StatsCard
            statsList={knowUsPageData.statsCard.statsList}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Çalışma Arkadaşlarımızın Deneyimlerini Dinlemek İster Misin?"
            description=""
            children={
              <BlockHeaderTitle
                isMobile={isMobile}
                title="Tüm Videolar"
                backgroundColor={colors.blue.blue01}
                titleColor={colors.dark.dark}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/@dogusotomotiv4354/videos",
                    "_blank"
                  )
                }
              />
            }
          />
          <TempSpacer height={120} />
          <NSKnowUsPageMobileStyle.VideoCarouselBadgeContainer>
            <Badge
              isMobile={isMobile}
              textVariant="LISTENUS"
              color="BLUE"
              iconVariant="ARROW"
              speed={15}
            />
            <VideoPlayerCarousel
              {...knowUsPageData.videoPlayerCarousel}
              isMobile={isMobile}
            />
          </NSKnowUsPageMobileStyle.VideoCarouselBadgeContainer>
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Çalışma Ofislerimiz"
            description="Ana kampüsümüzde yer alan Genel Müdürlük & Yedek Parça ve Lojistik ofislerimiz dışında diğer perakende noktalarımızın detaylarını inceleyebilirsin."
          />
          <TempSpacer height={32} />
          <ImageCarousel
            {...knowUsPageData.imageCarouselData}
            isMobile={isMobile}
            displayImagePreview={true}
          />
          <TempSpacer height={80} />
          <NSKnowUsPageMobileStyle.RoundedCardAndVideoCardContainer>
            <SingleRoundedCard
              {...knowUsPageData.singleRoundedCard}
              isMobile={isMobile}
            />

            <VideoPlayerCard
              isMobile={isMobile}
              showTitle={false}
              imageUrl={knowUsPageData.videoPlayerImageAndVideo.image}
              onClick={onClickHandler}
            />
          </NSKnowUsPageMobileStyle.RoundedCardAndVideoCardContainer>
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Şirket İçi İletişim Çalışmalarımız"
            description="Çalışma arkadaşlarımıza özel olarak tasarladığımız sohbet toplantılarında bir araya gelerek birbirimizi daha yakından tanıyoruz."
          />
          <RoundedCardCarousel
            {...knowUsPageData.roundedCardCarousel}
            isMobile={isMobile}
          />
          <TempSpacer height={120} />
          <RolloverImageGallery
            isMobile={isMobile}
            cardList={knowUsPageData.rolloverImageData}
          />

          <NSKnowUsPageMobileStyle.HorizontalLine />
          <BrandImages {...knowUsPageData.brandImages} isMobile={isMobile} />
        </NSKnowUsPageMobileStyle.MainContainer>
        <Footer {...knowUsPageData.footer} isMobile={isMobile} />
      </NSKnowUsPageMobileStyle.LeftContainer>

      <CircleButton onClick={scrollToTop} isMobile={isMobile} />

      <Modal
        isOpen={modalIsOpen}
        style={isMobile ? mobileStyles : desktopStyles}
        onRequestClose={onRequestCloseHandler}
        overlayElement={OverlayElement}
      >
        <ReactPlayer
          ref={playerRef}
          url={knowUsPageData.videoPlayerImageAndVideo.video}
          controls={true}
          width={isMobile ? "100%" : "60vw"}
          height={"auto"}
        />
      </Modal>
    </NSKnowUsPageMobileStyle.Container>
  );
}

export default KnowUsPageMobile;
