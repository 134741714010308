import { useRef, useState } from "react";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";

import { NSJoinUsPageMobileStyle } from "./JoinUsPageMobile.style";
import { MenuMobile } from "../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../components/HeaderMobile/HeaderMobile";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { Footer } from "../../../components/Footer/Footer";
import { IconListCard } from "../../../components/IconListCard/IconListCard";
import { NsJoinUsPageType } from "../JoinUsPage.types";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { ArrowNumberedList } from "../../../components/ArrowNumberedList/ArrowNumberedList";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import ReactPlayer from "react-player";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { TempSpacer } from "../../../util/tempSpacer";
import { Modal } from "../../../components/Modal/Modal";
import useStickyElement from "../../../util/useStickyElement";
import { CenteredImageGrid } from "../../../components/CenteredImageGrid/CenteredImageGrid";

function JoinUsPageMobile({ joinUsPageData }: NsJoinUsPageType.IJoinUsPage) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <NSJoinUsPageMobileStyle.Container>
      <NSJoinUsPageMobileStyle.LeftContainer>
        <NSJoinUsPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={joinUsPageData.anchorList}
            socialLinks={joinUsPageData.footer.socialLinks}
          />
        </NSJoinUsPageMobileStyle.RightContainer>
        {isSticky && (
          <HeaderMobile
            logoClicked={() => {}}
            menuButtonClicked={() => setShowMenu(true)}
            isDark={true}
          />
        )}
        <NSJoinUsPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={
              <HeaderMobile
                logoClicked={() => {}}
                menuButtonClicked={() => setShowMenu(true)}
                isDark={false}
              />
            }
            iconColor={colors.orange.orange04}
            isMobile={isMobile}
            title="Bize Katıl"
            backgroundColor={colors.orange.orange01}
          />
        </NSJoinUsPageMobileStyle.StickyHelperContainer>

        <NSJoinUsPageMobileStyle.MainContainer>
          <IconListCard {...joinUsPageData.iconListCard} isMobile={isMobile} />
          <TempSpacer height={146} />
          <HeadingText
            isMobile={isMobile}
            title="İşe alım Sürecimizdeki Aşamalar"
            description=""
          />
          <ArrowNumberedList
            {...joinUsPageData.arrowList}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <SingleRoundedCard
            {...joinUsPageData.singleRoundedCard}
            isMobile={isMobile}
          />
          <TempSpacer height={16} />
          <VideoPlayerCard
            isMobile={isMobile}
            showTitle={false}
            imageUrl={joinUsPageData.videoPlayerImageAndVideo.image}
            onClick={onClickHandler}
          />
          <TempSpacer height={80} />
          <NSJoinUsPageMobileStyle.IconContainer>
            <NSJoinUsPageMobileStyle.ImageIcon
              src={"/images/join-us/gelecekSensin.png"}
            />
          </NSJoinUsPageMobileStyle.IconContainer>
          <NSJoinUsPageMobileStyle.Title>
            Gelecek Sensin Yeni Mezun Programı
          </NSJoinUsPageMobileStyle.Title>
          <NSJoinUsPageMobileStyle.Description>
            Gelecek Sensin Yeni Mezun Programı ile Doğuş Otomotiv’de 2007
            yılından beri geliştirilerek uygulamaya alınan Gelecek Sensin Yeni
            Mezun Programı ile adaylarımız değerlendirmeye alınarak, genel
            yetenek sınavı, vaka analizi çalışması, bireysel sunum, profil
            envanterleri ve İngilizce sınavları gibi farklı aşamaları
            tamamlayarak yeni mezun programımızla çeşitli fonksiyonlarımızda
            görevlerine başlarlar.
            <br />
            <br />
            Bu programla aramıza katılan çalışma arkadaşlarımız kişisel
            gelişimlerini destekleyecek farklı eğitim içerikleri ile yaklaşık
            160 saatlik bir eğitim programına dahil edilerek farklı
            sürdürebilirlik projelerini tamamlarlar.
          </NSJoinUsPageMobileStyle.Description>
          <TempSpacer height={32} />

          <CenteredImageGrid
            {...joinUsPageData.lowerImageList}
            isMobile={isMobile}
          />
          <TempSpacer height={120} />

          <NSJoinUsPageMobileStyle.Title>
            Üniversite İş Birlikleri
          </NSJoinUsPageMobileStyle.Title>
          <NSJoinUsPageMobileStyle.Description>
            Üniversitelerde yüksek lisans ya da sertifika programlarına
            katılarak eğitim hayatına devam etmek isteyen çalışma arkadaşlarımız
            için üniversitelerle anlaşmalarımız bulunmaktadır.
          </NSJoinUsPageMobileStyle.Description>
          <TempSpacer height={32} />
          <ImageCarousel
            {...joinUsPageData.imageCarouselData}
            isMobile={isMobile}
            imageFit="none"
            displayImagePreview={false}
          />
        </NSJoinUsPageMobileStyle.MainContainer>
        <Footer {...joinUsPageData.footer} isMobile={isMobile} />
      </NSJoinUsPageMobileStyle.LeftContainer>

      <CircleButton onClick={scrollToTop} isMobile={isMobile} />

      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <ReactPlayer
          ref={playerRef}
          url={joinUsPageData.videoPlayerImageAndVideo.video}
          controls={true}
          width={isMobile ? "100%" : "60vw"}
          height={"auto"}
        />
      </Modal>
    </NSJoinUsPageMobileStyle.Container>
  );
}

export default JoinUsPageMobile;
