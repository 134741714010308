import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { NSMobileSwiperHelperType } from "./MobileSwiperHelper.types";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper";
import { useRef } from "react";
import { NSMobileSwiperHelperStyle } from "./MobileSwiperHelper.style";

export const MobileSwiperHelper = ({
  childrenList,
  props,
}: NSMobileSwiperHelperType.IMobileSwiperHelper) => {
  const sliderRef = useRef<SwiperRef>(null);

  return (
    <NSMobileSwiperHelperStyle.MobileSwiperContainer>
      <Swiper
        modules={[Scrollbar]}
        spaceBetween={32}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        ref={sliderRef}
        {...props}
      >
        {childrenList.map((children) => (
          <SwiperSlide key={children?.toString()}>{children}</SwiperSlide>
        ))}
      </Swiper>
    </NSMobileSwiperHelperStyle.MobileSwiperContainer>
  );
};
