import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSDragDropFileUploadStyle {
  export const Container = styled.div`
    font-family: "ReadexPro";
    height: fit-content;
    width: 100%;
    position: relative;
  `;

  export const FileUploadArea = styled.div`
    border: 2px solid ${colors.blue.blue01};
    background-color: transparent;
    padding: 31px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-style: dashed;
    border-radius: 8px;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: ${typography.pharagraph2.size};
    line-height: 24px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.dark.dark};

    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;

  export const DocumentInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${typography.smallText2.size};
    line-height: 18px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.neutrals.grey05};
  `;

  export const UploadedFileArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    color: ${colors.success.success01};
    background-color: ${colors.shadesOfdark.dark04};
    padding: 16px;
    border-radius: 8px;
  `;

  export const ErrorArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    color: ${colors.error.error01};
    background-color: ${colors.shadesOfdark.dark04};
    padding: 16px;
    border-radius: 8px;
  `;
}
