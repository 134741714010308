import { forwardRef, useRef } from "react";
import { NSInputStyle } from "./Input.style";
import { NSInputType } from "./Input.types";

const Input = forwardRef<HTMLInputElement, NSInputType.IInput>(
  (
    {
      error,
      label,
      suffixItem,
      prefixItem,
      labelShowType = "withValue",
      val,
      infoText,
      isRequired,
      suffixPrefixItemPadding,
      placeholder,
      ...props
    },
    ref
  ) => {
    const prefixRef = useRef<HTMLDivElement>(null);
    const suffixRef = useRef<HTMLDivElement>(null);

    const showLabel = {
      alwaysShow: !!label,
      hide: false,
      withValue: !!label && !!val,
    };

    return (
      <NSInputStyle.Container>
        {showLabel[labelShowType] && (
          <NSInputStyle.Label>
            {label}

            {isRequired && <NSInputStyle.Star>*</NSInputStyle.Star>}
          </NSInputStyle.Label>
        )}
        {prefixItem && (
          <NSInputStyle.PrefixItemContainer ref={prefixRef}>
            {prefixItem}
          </NSInputStyle.PrefixItemContainer>
        )}
        <NSInputStyle.BaseInput
          ref={ref}
          type={props.type ?? "text"}
          prefixItemSize={
            prefixRef.current !== null
              ? `${prefixRef.current?.clientWidth}px`
              : `${suffixPrefixItemPadding}px`
          }
          suffixItemSize={
            suffixRef.current !== null
              ? `${suffixRef.current?.clientWidth}px`
              : `${suffixPrefixItemPadding}px`
          }
          error={!!error}
          isContainSuffix={!!suffixItem}
          isContainPrefix={!!prefixItem}
          placeholder={isRequired ? `${placeholder}*` : placeholder}
          {...props}
        />
        {suffixItem && (
          <NSInputStyle.SuffixItemContainer ref={suffixRef}>
            {suffixItem}
          </NSInputStyle.SuffixItemContainer>
        )}

        {error && (
          <NSInputStyle.ErrorMessage>{error}</NSInputStyle.ErrorMessage>
        )}

        {!error && infoText && (
          <NSInputStyle.InfoText>{infoText}</NSInputStyle.InfoText>
        )}
      </NSInputStyle.Container>
    );
  }
);

Input.displayName = "Input";

export { Input };
