import { useState, useEffect } from "react";

function useStickyElement(stickyDeciderRef: React.RefObject<HTMLElement>) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyDeciderRef.current) {
        const { top, bottom } =
          stickyDeciderRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const threshold = 0;
        if (top > windowHeight - threshold || bottom < threshold) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyDeciderRef]);

  return isSticky;
}

export default useStickyElement;
