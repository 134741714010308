import { HeaderDesktop } from "../../../../components/HeaderDesktop/HeaderDesktop";
import { NSApplicationsPageType } from "../ApplicationsPage.types";
import { Footer } from "../../../../components/Footer/Footer";
import { scrollToTop } from "../../../../util/scrollToTop";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { DashboardMenu } from "../../../../components/DashboardMenu/DashboardMenu";
import { dashbordMenuItems } from "../../../../util/dashboardMenuItem";
import { NSApplicationsPageDesktopStyle } from "./ApplicationsPageDesktop.style";
import colors from "../../../../style/colors";
import { PositionCard } from "../../../../components/PositionCard/PositionCard";

function ApplicationsPageDesktop({
  applicationsData,
}: Readonly<NSApplicationsPageType.IApplicationsPage>) {
  const isMobile = false;

  const applicationCards = [
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça ve VW Binek Ürün Pazarlama Birim Yöneticisi",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "1",
      isActive: true,
    },
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça Pazarlama Birim Yöneticisi",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "2",
      isActive: false,
    },
  ];

  return (
    <NSApplicationsPageDesktopStyle.PageContainer>
      <HeaderDesktop
        logoClicked={() => {}}
        anchorList={applicationsData.anchorList}
        isDark={true}
        backgroundColor={colors.neutrals.grey01}
      />
      <NSApplicationsPageDesktopStyle.MainContainer>
        <DashboardMenu menuItem={dashbordMenuItems} />
        <NSApplicationsPageDesktopStyle.Content>
          <NSApplicationsPageDesktopStyle.PageTitle>
            Başvurularım
          </NSApplicationsPageDesktopStyle.PageTitle>
          <NSApplicationsPageDesktopStyle.StyledContainer>
            <NSApplicationsPageDesktopStyle.StyledRow justify="between">
              {applicationCards.map((application) => (
                <NSApplicationsPageDesktopStyle.StyledCol key={application.id}>
                  <PositionCard
                    isApplied
                    key={application.id}
                    tag={application.tag}
                    title={application.title}
                    locations={application.locations}
                    date={application.date}
                    id={application.id}
                    isActive={application.isActive}
                  />
                </NSApplicationsPageDesktopStyle.StyledCol>
              ))}
            </NSApplicationsPageDesktopStyle.StyledRow>
          </NSApplicationsPageDesktopStyle.StyledContainer>
        </NSApplicationsPageDesktopStyle.Content>
      </NSApplicationsPageDesktopStyle.MainContainer>
      <Footer {...applicationsData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSApplicationsPageDesktopStyle.PageContainer>
  );
}

export default ApplicationsPageDesktop;
