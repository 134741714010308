import { useRef, useState } from "react";
import { NSStatusPageType } from "../StatusPage.types";
import { NSStatusPageMobileStyle } from "./StatusPageMobile.style";
import useStickyElement from "../../../../util/useStickyElement";
import { MenuMobile } from "../../../../components/MenuMobile/MenuMobile";
import { Footer } from "../../../../components/Footer/Footer";
import colors from "../../../../style/colors";
import { HeaderMobile } from "../../../../components/HeaderMobile/HeaderMobile";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import { DashboardWelcomeText } from "../../../../components/DashboardWelcomeText/DashboardWelcomeText";

function StatusPageMobile({
  statusData,
}: Readonly<NSStatusPageType.IStatusPage>) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  return (
    <NSStatusPageMobileStyle.Container>
      <NSStatusPageMobileStyle.LeftContainer>
        <NSStatusPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={statusData.anchorList}
            socialLinks={statusData.footer.socialLinks}
          />
        </NSStatusPageMobileStyle.RightContainer>
        <HeaderMobile
          logoClicked={() => {}}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
          backgroundColor={colors.blue.blue02}
        />
        <NSStatusPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={""}
            isIcon={false}
            isMobile={isMobile}
            title="Güncel Durumum"
            backgroundColor={colors.blue.blue02}
          />
        </NSStatusPageMobileStyle.StickyHelperContainer>

        <NSStatusPageMobileStyle.MainContainer>
          <NSStatusPageMobileStyle.Content>
            <NSStatusPageMobileStyle.PageTitle>
              Merhaba Burak,
            </NSStatusPageMobileStyle.PageTitle>
            <NSStatusPageMobileStyle.PageDesc>
              Bu sayfalardan başvuralarını takip edebilir, özgeçmişini
              oluşturabilir ve üyelik bilgilerini güncelleyebilirsin.
            </NSStatusPageMobileStyle.PageDesc>
          </NSStatusPageMobileStyle.Content>
          <DashboardWelcomeText isMobile={true} applicationCount={12} />
        </NSStatusPageMobileStyle.MainContainer>
        <Footer {...statusData.footer} isMobile={isMobile} />
      </NSStatusPageMobileStyle.LeftContainer>
    </NSStatusPageMobileStyle.Container>
  );
}

export default StatusPageMobile;
