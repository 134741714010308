import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSMembershipCancellationStyle {
  export const CancelTitle = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 28.08px;
    color: ${colors.error.error01};
    font-family: "ReadexPro";
  `;

  export const TitleDesc = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22.96px;
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
    margin: 4px 0px 0px 0px;
  `;

  export const Kvkk = styled.div`
    gap: 24px;
    display: flex;
    flex-direction: column;
    width: 65%;

    @media (max-width: 1200px) {
      width: 100%;
    }
  `;

  export const KVKKButton = styled.div`
    & > button {
      padding-left: 0px;
    }
  `;

  export const ModalCard = styled.div`
    background-color: ${colors.light.light};
    padding: 40px;
    width: 60%;
    height: 100%;
    margin: 0px 20%;
    border-radius: 16px;

    @media (max-width: 768px) {
      margin: 108px 16px 36px 16px;
      width: 93%;
    }
  `;

  export const ModalTitle = styled.div`
    font-size: ${typography.pharagraph1_5.size};
    line-height: 28.08px;
    font-weight: ${typography.fontWeights.bold};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    text-align: center;
  `;

  export const ModalContent = styled.div`
    padding-top: 16px;
  `;

  export const ModalContentDiv = styled.div`
    height: 500px;
    overflow-y: auto;
    padding-right: 8px;
  `;

  export const ModalContentDesc = styled.div`
    font-size: ${typography.smallText2.size};
    line-height: 19.68px;
    font-weight: ${typography.fontWeights.regular};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
  `;

  export const ModalContentDescTitle = styled.div`
    font-size: ${typography.smallText2.size};
    line-height: 19.68px;
    font-weight: ${typography.fontWeights.bold};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    padding: 10px 0px;
  `;

  export const ModalContentDescStrong = styled.strong`
    font-weight: ${typography.fontWeights.bold};
  `;

  export const ModalContentDescLink = styled.a`
    text-decoration: none;
    font-size: ${typography.smallText2.size};
    line-height: 19.68px;
    font-weight: ${typography.fontWeights.regular};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
  `;
}
