import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSActiveBagdeStyle {
  export const Dot = styled.div<{ isActive: boolean }>`
    width: 8px;
    height: 8px;
    border-radius: 16px;
    background-color: ${(props) =>
      props.isActive ? colors.success.success01 : colors.error.error01};
  `;

  export const Container = styled.div`
    width: fit-content;
    display: flex;
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: ${typography.fontWeights.medium};
    border-radius: 8px;
    background-color: ${colors.neutrals.grey01};
    padding: 0 8px;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: ${colors.dark.dark};
    gap: 8px;
  `;
}
