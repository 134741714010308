import colors from "../style/colors";

export const desktopStyles = {
  content: {
    zIndex: 3,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "transparent",
    overflow: "visible",
  },
  overlay: {
    zIndex: 5,
    background: "rgba(10, 17, 23, 0.8)",
    backdropFilter: "blur(10px)",
  },
};

export const mobileStyles = {
  content: {
    zIndex: 3,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "transparent",
    overflow: "visible",
  },
  overlay: {
    zIndex: 5,
    background: "rgba(10, 17, 23, 0.8)",
    backdropFilter: "blur(10px)",
  },
};

export const mobileStylesDrawer = {
  overlay: {
    zIndex: 10,
    background: "rgba(10, 17, 23, 0.80)",
    backdropFilter: "blur(10px)",
  },
  content: {
    zIndex: 20,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    overflow: "auto",
    border: "none",
    padding: "0px",
    background: "transparent",
    height: "60vh",
    marginTop: "auto",
  },
};

export const desktopStylesDrawer = {
  content: {
    background: colors.yellow.yellow01,
    zIndex: 3,
    marginLeft: "auto",
    width: "44%",
    height: "100%",
    padding: 0,
    right: 0,
    top: 0,
    bottom: 0,
    border: "none",
    borderTopLeftRadius: "64px",
  },
  overlay: {
    zIndex: 5,
    background: "rgba(10, 17, 23, 0.8)",
    backdropFilter: "blur(10px)",
  },
};
