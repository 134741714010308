import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSCVExperiencesStyle {
  export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: "ReadexPro";

    @media (max-width: 768px) {
      gap: 40px !important;
    }
  `;

  export const AskTitle = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 500;
    color: ${colors.dark.dark};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  `;

  export const SideBySideContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 40px;
    }
  `;

  export const SideBySingleInputContainer = styled.div`
    width: 47%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  export const AskLabel = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 400;
    color: ${colors.dark.dark};
  `;

  export const ArrayContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 768px) {
      gap: 40px !important;
    }
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  `;

  export const AddButtonContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  `;

  export const Seperator = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.neutrals.grey02};
  `;
}
