import React from "react";
import { Button } from "../Button/Button";
import { NSJDStickyHeaderStyle } from "./JDStickyHeader.style";
import { NSJDStickyHeaderType } from "./JDStickyHeader.types";
import { ReactComponent as ArrowLeft2 } from "../../assets/icons/arrowLeft2.svg";
import colors from "../../style/colors";

export const JDStickyHeader = ({
  title,
  url,
  isMobile = false,
}: NSJDStickyHeaderType.IJDStickyHeader) => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <NSJDStickyHeaderStyle.JDStickyHeaderContainer isMobile={isMobile}>
      <NSJDStickyHeaderStyle.Container>
        <NSJDStickyHeaderStyle.ItemDiv>
          <Button
            children={
              <>
                <ArrowLeft2 stroke={colors.light.light} />
                <>Geri</>
              </>
            }
            variant="text"
            backgroundColor={colors.light.light}
            color={colors.light.light}
            onClick={handleGoBack}
          />
          <NSJDStickyHeaderStyle.Title>{title}</NSJDStickyHeaderStyle.Title>
        </NSJDStickyHeaderStyle.ItemDiv>
        {isMobile ? (
          ""
        ) : (
          <Button
            children="Şimdi Başvur"
            variant="solid"
            backgroundColor={colors.light.light}
            color={colors.blue.blue01}
            width={304}
            onClick={() => window.open(url, "_self")}
          />
        )}
      </NSJDStickyHeaderStyle.Container>
    </NSJDStickyHeaderStyle.JDStickyHeaderContainer>
  );
};
