import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSDrawerStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    background-color: ${colors.yellow.yellow01};

    padding: 48px 32px 48px 48px;
    display: flex;
    ${({ isMobile }) =>
      isMobile &&
      css`
        padding: 48px 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        position:"relative",
        width: 100vw;
        height: 60vh;
        box-sizing: border-box;
        position: relative;
        border-radius: 32px 32px 0px 0px;
      `}
  `;
  export const CloseButton = styled.button<{ isMobile: boolean }>`
    all: unset;
    background-color: ${colors.light.light};
    height: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    min-width: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    right: 24px;
    top: 24px;
    margin-left: auto;
    transform: rotate(45deg);
    cursor: pointer;
    transition: filter 1s, transform 0.5s;
    border-radius: 100%;
    ${({ isMobile }) =>
      isMobile &&
      css`
        right: 12px;
        margin: 0px;
        top: 12px;
      `}
    &:hover {
      filter: brightness(90%);
      transform: rotate(225deg);
    }
  `;
}
