import { NSHeadingTextStyle } from "./HeadingText.style";
import { NSHeadingTextType } from "./HeadingText.types";

export const HeadingText = ({
  isMobile,
  title,
  description,
  children,
  isVertical = true,
  marginBottom,
}: NSHeadingTextType.IHeadingText) => {
  const MobileView = () => (
    <NSHeadingTextStyle.ContainerMobile>
      <NSHeadingTextStyle.InnerContainerMobile>
        <NSHeadingTextStyle.Title isMobile={isMobile}>
          {title}
        </NSHeadingTextStyle.Title>
        <NSHeadingTextStyle.Description isMobile={isMobile}>
          {description}
        </NSHeadingTextStyle.Description>
      </NSHeadingTextStyle.InnerContainerMobile>
      {children}
    </NSHeadingTextStyle.ContainerMobile>
  );

  const DesktopViewVertical = () => (
    <NSHeadingTextStyle.ContainerDesktop>
      <NSHeadingTextStyle.InnerContainer
        isVertical={isVertical}
        marginBottom={marginBottom}
      >
        <NSHeadingTextStyle.Title isMobile={isMobile}>
          {title}
        </NSHeadingTextStyle.Title>
        <NSHeadingTextStyle.DescriptionVertical isMobile={isMobile}>
          {description}
        </NSHeadingTextStyle.DescriptionVertical>
      </NSHeadingTextStyle.InnerContainer>
      {children}
    </NSHeadingTextStyle.ContainerDesktop>
  );
  const DesktopViewHorizontal = () => (
    <NSHeadingTextStyle.ContainerDesktop>
      <NSHeadingTextStyle.InnerContainer isVertical={isVertical}>
        <NSHeadingTextStyle.HorizontalTitle isMobile={isMobile}>
          {title}
        </NSHeadingTextStyle.HorizontalTitle>
        <NSHeadingTextStyle.Description isMobile={isMobile}>
          {description}
          <NSHeadingTextStyle.HorizontalChildrenContainer>
            {children}
          </NSHeadingTextStyle.HorizontalChildrenContainer>
        </NSHeadingTextStyle.Description>
      </NSHeadingTextStyle.InnerContainer>
    </NSHeadingTextStyle.ContainerDesktop>
  );

  const renderHelper = () => {
    if (isMobile) {
      return MobileView();
    } else if (isVertical) {
      return DesktopViewVertical();
    } else {
      return DesktopViewHorizontal();
    }
  };

  return renderHelper();
};
