import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { NSPositionCardStyle } from "./PositionCard.style";
import { NSPositionCardType } from "./PositionCard.types";

import colors from "../../style/colors";
import { ActiveBagde } from "../ActiveBagde/ActiveBagde";

export const PositionCard = ({
  title,
  tag,
  locations,
  date,
  id,
  isActive,
  isMobile,
  isApplied = false,
  buttonColorTagAndBorder,
}: NSPositionCardType.IPositionCard) => {
  const navigate = useNavigate();
  return (
    <NSPositionCardStyle.Container isApplied={isApplied} isMobile={isMobile}>
      <NSPositionCardStyle.TagContainer>
        <NSPositionCardStyle.Tag>{tag}</NSPositionCardStyle.Tag>
        {isActive !== undefined && <ActiveBagde isActive={isActive} />}
      </NSPositionCardStyle.TagContainer>

      <NSPositionCardStyle.Header>
        <NSPositionCardStyle.Title>{title}</NSPositionCardStyle.Title>
        <NSPositionCardStyle.Location>
          {locations.map((location) => (
            <NSPositionCardStyle.LocationItem key={location}>
              {location}
            </NSPositionCardStyle.LocationItem>
          ))}
        </NSPositionCardStyle.Location>
      </NSPositionCardStyle.Header>
      {isApplied ? (
        <NSPositionCardStyle.Bottom>
          <Button
            variant="outline"
            height={40}
            fontWeight={700}
            fontSize={16}
            color={buttonColorTagAndBorder ?? colors.information.information02}
            borderColor={
              buttonColorTagAndBorder ?? colors.information.information02
            }
            onClick={() => {
              navigate(`/position/detail/${id}`);
            }}
          >
            İncele
          </Button>
        </NSPositionCardStyle.Bottom>
      ) : (
        <NSPositionCardStyle.Bottom>
          <NSPositionCardStyle.DateContainer>
            <NSPositionCardStyle.DateTitle>
              Son Başvuru
            </NSPositionCardStyle.DateTitle>
            <NSPositionCardStyle.DateText>{date}</NSPositionCardStyle.DateText>
          </NSPositionCardStyle.DateContainer>
          <Button
            variant="outline"
            width={88}
            height={40}
            fontWeight={700}
            fontSize={16}
            color={colors.information.information02}
            borderColor={colors.information.information02}
            onClick={() => {
              navigate(`/position/detail/${id}`);
            }}
          >
            Başvur
          </Button>
        </NSPositionCardStyle.Bottom>
      )}
    </NSPositionCardStyle.Container>
  );
};
