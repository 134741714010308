import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSPositionCardStyle {
  export const Container = styled.div<{
    isApplied?: boolean;
    isMobile?: boolean;
  }>`
    box-sizing: border-box;
    width: ${(props) => (props.isMobile ? "100%" : "300px")} !important;
    background-color: ${colors.light.light};
    border-radius: 16px;
    font-family: "ReadexPro";
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 24px;
    ${(props) =>
      !props.isApplied &&
      css`
        & > div {
          padding-bottom: 16px;
          border-bottom: 1px solid ${colors.neutrals.grey02};
        }
      `}

    & > div:last-child {
      padding-bottom: 0px;
      border-bottom: 0px;
    }
    & > div:first-child {
      padding-bottom: 0px;
      border-bottom: 0px;
    }
  `;

  export const Header = styled.div`
    width: 100%;
    height: 130.5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  `;

  export const Tag = styled.div`
    width: fit-content;
    display: flex;
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: ${typography.fontWeights.medium};
    border-radius: 8px;
    background-color: ${colors.shadesOfdark.dark04};
    padding: 0 8px;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: ${colors.orange.orange02};
  `;

  export const TagContainer = styled.div`
    width: 100%;
    height: fit-content;
    height: fit-content;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  `;

  export const Title = styled.div`
    width: 100%;
    font-size: ${typography.pharagraph1_5.size};
    line-height: 28.08px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.dark.dark};
    height: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  export const Location = styled.div`
    width: 100%;
    font-size: ${typography.smallText1.size};
    line-height: 22.69px;
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.neutrals.grey06};
    padding-inline-start: 0;
    display: flex;
    gap: 4px;
  `;

  export const LocationItem = styled.div`
    ::after {
      content: ",";
    }

    :last-child:after {
      content: "";
    }
  `;

  export const DateText = styled.div`
    width: 100%;
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
  `;

  export const DateTitle = styled(DateText)`
    color: ${colors.neutrals.grey05};
  `;

  export const DateContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  `;

  export const Bottom = styled.div`
    width: 100%;
    height: fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `;
}
