import { NSRoundedCardStyle } from "./RoundedCard.style";
import { NSRoundedCardType } from "./RoundedCard.types";

export const RoundedCard = ({
  isMobile,
  title,
  image,
  onClick,
}: NSRoundedCardType.IRoundedCard) => {
  return (
    <NSRoundedCardStyle.Container isMobile={isMobile}>
      <NSRoundedCardStyle.RoundedImage src={image} isMobile={isMobile} />
      <NSRoundedCardStyle.Title>{title}</NSRoundedCardStyle.Title>
      <NSRoundedCardStyle.Button isMobile={isMobile} onClick={onClick}>
        Daha Fazlası
      </NSRoundedCardStyle.Button>
    </NSRoundedCardStyle.Container>
  );
};
