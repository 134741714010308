import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSRoundedCardCarouselStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    .swiper {
      padding-bottom: ${(props) => (props.isMobile ? "24px" : "64px")};
    }
    .swiper-slide {
      width: fit-content;
      height: auto;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;

  export const NavigationButton = styled.button`
    all: unset;
    cursor: pointer;
    padding: 8px;

    margin-bottom: 64px;
    &:first-child {
      margin-right: 16px;
      margin-left: -64px;
    }
    &:last-child {
      margin-left: 16px;
      margin-right: -64px;
    }
  `;

  export const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  `;
}
