import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSRadioButtonStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    position: relative;
  `;

  export const RadioButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
  `;

  export const RadioButton = styled.input.attrs({ type: "radio" })<{
    error?: boolean;
  }>`
    width: 24px;
    height: 24px;
    gap: 8px;
    &:checked {
      border-width: 2px;
    }
    ${(props) => props.error && `border: 2px solid ${colors.error.error01}`};
  `;

  export const RadioButtonLabel = styled.label`
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    color: #111113;
  `;

  export const ErrorMessage = styled.div`
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    top: 22px;
    color: ${colors.error.error01};
    left: 0;
    background-color: transparent;
    position: absolute;
  `;
}
