import styled from "styled-components";

export namespace NSBrandImagesStyle {
  export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(102px, 1fr));
    grid-gap: 36px;
    justify-items: center;
    align-items: center;
  `;
  export const LogoImage = styled.img`
    max-width: 102px;
    object-fit: cover;
  `;
}
