/* c8 ignore start */
import colors from "./colors";
import typography from "./typography";

export const defaultTheme = {
  default: colors.dark.dark,
  placeholder: colors.neutrals.grey05,
  disable: colors.neutrals.grey05,
  error: colors.error.error01,
  colors,
  typography,
};

export const darkTheme = {
  default: colors.light.light,
  placeholder: colors.shadesOflight.light64,
  disable: colors.shadesOflight.light48,
  error: colors.error.error01,
  colors,
  typography,
};
/* c8 ignore stop */
