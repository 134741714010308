import useDeviceType from "../../../util/isMobile";
import LoginPageDesktop from "./DesktopView/LoginPageDesktop";
import { NSLoginPageType } from "./LoginPage.types";
import LoginPageMobile from "./MobileView/LoginPageMobile";

const loginPageData: NSLoginPageType.ILoginPageData = {
  loginSchema: NSLoginPageType.loginSchema,
  onSubmitLogin: (data) => {
    console.log("data", data);
  },
};
function LoginPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <LoginPageMobile
        onSubmitLogin={loginPageData.onSubmitLogin}
        loginSchema={loginPageData.loginSchema}
      />
    );
  } else {
    return (
      <LoginPageDesktop
        onSubmitLogin={loginPageData.onSubmitLogin}
        loginSchema={loginPageData.loginSchema}
      />
    );
  }
}

export default LoginPage;
