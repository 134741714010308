import { darken } from "polished";
import colors from "../../style/colors";
import { NSButtonStyle } from "./Button.style";
import { NSButtonType } from "./Button.types";
export const Button = ({
  width,
  height,
  backgroundColor = colors.blue.blue01,
  children,
  onClick,
  borderRadius = 32,
  fontWeight = 700,
  isDisabled = false,
  color = colors.light.light,
  borderColor = colors.blue.blue01,
  variant = "solid",
  type = "button",
  textDecoration,
  fontSize = 18,
}: NSButtonType.IButton) => {
  const buttonVariant = {
    solid: {
      backgroundColor: isDisabled ? colors.background.bg : backgroundColor,
      border: "0px solid transparent",
      color: isDisabled ? colors.neutrals.grey05 : color,
      width: width,
      height: height,
      hoverBackgroundColor: undefined,
      hoverTextDecoration: undefined,
      hoverBorder: undefined,
    },
    outline: {
      backgroundColor: "transparent",
      border: `2px solid ${isDisabled ? colors.background.bg : borderColor} `,
      color: isDisabled ? colors.neutrals.grey05 : color,
      width: width,
      height: height,
      hoverBackgroundColor: undefined,
      hoverTextDecoration: undefined,
      hoverBorder: `2px solid ${
        isDisabled ? colors.background.bg : darken(borderColor)
      } `,
    },
    ghost: {
      backgroundColor: "transparent",
      border: "0px solid transparent",
      color: isDisabled ? colors.neutrals.grey05 : color,
      width: width,
      height: height,
      hoverBackgroundColor: colors.shadesOfdark.dark04,
      hoverTextDecoration: undefined,
      hoverBorder: undefined,
    },
    text: {
      backgroundColor: "transparent",
      border: "0px solid transparent",
      color: isDisabled ? colors.neutrals.grey05 : color,
      width: "fit-content",
      height: "fit-content",
      hoverBackgroundColor: undefined,
      hoverTextDecoration: "underline",
      hoverBorder: " 0px solidtransparent",
      TextDecoration: "underline",
    },
    icon: {
      backgroundColor: backgroundColor,
      border: "0px solid transparent",
      color: isDisabled ? colors.neutrals.grey05 : color,
      width: width ?? 64,
      height: height ?? 64,
      hoverBackgroundColor: undefined,
      hoverTextDecoration: undefined,
      hoverBorder: undefined,
    },
  };

  return (
    <NSButtonStyle.Button
      fontSize={fontSize}
      type={type}
      disabled={isDisabled}
      fontWeight={fontWeight}
      width={buttonVariant[variant].width}
      height={buttonVariant[variant].height}
      backgroundColor={buttonVariant[variant].backgroundColor}
      onClick={onClick}
      borderRadius={borderRadius}
      color={buttonVariant[variant].color}
      border={buttonVariant[variant].border}
      hoverBackgroundColor={buttonVariant[variant]?.hoverBackgroundColor}
      hoverTextDecoration={buttonVariant[variant]?.hoverTextDecoration}
      hoverBorder={buttonVariant[variant]?.hoverBorder}
      textDecoration={textDecoration}
    >
      {children}
    </NSButtonStyle.Button>
  );
};
