import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 
  body {
    margin: 0;
    background-color: #e6e9ec;
    font-family: "ReadexPro"
  }
  html, body {
    box-sizing: border-box;

  }

  *, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  video{
    border-radius:32px;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}


*::-webkit-scrollbar {
	width: 8px;
	background: transparent;
}

/* Track */
*::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 32px;
}
`;

export default GlobalStyle;
