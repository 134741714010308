import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSStatementCardStyle {
  export const Container = styled.div<{
    isMobile: boolean;
    background: string;
  }>`
    display: flex;
    padding: ${({ isMobile }) => (isMobile ? "32px" : "64px")};
    border-radius: 32px;
    background-color: ${({ background }) => background};
    font-family: "ReadexPro";
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    flex-direction: column;
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    color: ${colors.light.light};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
  `;
  export const Description = styled.div<{ isMobile: boolean }>`
    color: ${colors.light.light};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph2.size : typography.pharagraph1_5.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.pharagraph2.lineHeight
        : typography.pharagraph1_5.lineHeight};
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
    width: ${({ isMobile }) => (isMobile ? "100%" : "70%")};
    margin-top: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  `;
}
