import { NSCheckboxStyle } from "./Checkbox.style";
import { NSCheckboxType } from "./Checkbox.types";

export const Checkbox = ({
  onChange,
  checked,
  id,
  label,
  error,
  fontSize,
  lineHeight,
}: NSCheckboxType.ICheckbox) => {
  return (
    <NSCheckboxStyle.Label
      fontSize={fontSize}
      lineHeight={lineHeight}
      htmlFor={id}
    >
      <NSCheckboxStyle.CheckboxContainer>
        <NSCheckboxStyle.HiddenCheckbox
          id={id}
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
        <NSCheckboxStyle.StyledCheckbox error={error} checked={checked}>
          <NSCheckboxStyle.Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </NSCheckboxStyle.Icon>
        </NSCheckboxStyle.StyledCheckbox>
        {label}
      </NSCheckboxStyle.CheckboxContainer>
      {error && (
        <NSCheckboxStyle.ErrorMessage>{error}</NSCheckboxStyle.ErrorMessage>
      )}
    </NSCheckboxStyle.Label>
  );
};
