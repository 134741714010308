import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import { NSCvEducationStyle } from "./CvEducation.style";
import { NSCvEducationType } from "./CvEducation.types";
import { zodResolver } from "@hookform/resolvers/zod";
import { SelectBox } from "../SelectBox/SelectBox";
import { Button } from "../Button/Button";
import { TextArea } from "../TextArea/TextArea";
import { RadioButton } from "../RadioButton/RadioButton";
import { Input } from "../Input/Input";
import { Checkbox } from "../Checkbox/Checkbox";

import {
  educationSituations,
  gradingSystems,
  years,
} from "../../util/formDropdownItems";
import { ReactComponent as Add } from "../../assets/icons/addWithOutline.svg";
import colors from "../../style/colors";
import { scrollToTop } from "../../util/scrollToTop";
import {
  defaultValues,
  prepareEndDateArray,
  shownLogic,
} from "../../util/cvEducationHelper";

export const CvEducation = ({
  onSubmitEducation,
}: NSCvEducationType.ICvEducation) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<NSCvEducationType.CVEducationInfoType>({
    defaultValues: {
      degree: [defaultValues],
      masterDegree: [defaultValues],
      doctorate: defaultValues,
    },
    resolver: zodResolver(NSCvEducationType.CvEducationSchema),
  });

  const onSubmit: SubmitHandler<NSCvEducationType.CVEducationInfoType> = (
    data
  ) => {
    const doctorate =
      data.doctorate?.schoolName === "" ? undefined : data.doctorate;
    const masterDegree =
      data.masterDegree?.filter((i) => i.schoolName !== "") ?? undefined;
    const degree = data.degree?.filter((i) => i.schoolName !== "") ?? undefined;

    const prevData = { ...data, masterDegree, degree, doctorate };

    onSubmitEducation(prevData);
  };

  const associateDegreeInfo = useWatch({
    control,
    name: "associateDegreeInfo",
  });

  const educationSituation = useWatch({
    control,
    name: "educationSituation",
  });

  const primarySchoolStartDate = useWatch({
    control,
    name: "primarySchool.startDate",
  });
  const secondarySchoolStartDate = useWatch({
    control,
    name: "secondarySchool.startDate",
  });
  const highSchoolStartDate = useWatch({
    control,
    name: "highSchool.startDate",
  });
  const associateDegreeStartDate = useWatch({
    control,
    name: "associateDegree.startDate",
  });

  const doctorateDegreeStartDate = useWatch({
    control,
    name: "doctorate.startDate",
  });

  const { fields: degreeFields, append: appendDegree } = useFieldArray({
    control,
    name: "degree",
  });

  const { fields: masterDegreeFields, append: appendMasterDegree } =
    useFieldArray({
      control,
      name: "masterDegree",
    });

  return (
    <NSCvEducationStyle.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      id="CvEducation"
    >
      <SelectBox
        label="Öğrenim Durumu"
        placeholder="Öğrenim Durumu"
        isRequired
        items={educationSituations}
        onSelect={(item) => setValue("educationSituation", item)}
        error={errors.educationSituation?.message}
      />
      {educationSituation &&
        shownLogic[educationSituation.id].includes("degree") && (
          <NSCvEducationStyle.AskContainer>
            <NSCvEducationStyle.AskTitle>
              Ön Lisans Mezuniyetin Var mı?
            </NSCvEducationStyle.AskTitle>
            <RadioButton
              options={[
                { label: "Evet", value: "yes", id: "yes" },
                { label: "Hayır", value: "no", id: "no" },
              ]}
              onChange={(item) => setValue("associateDegreeInfo", item.value)}
            />
          </NSCvEducationStyle.AskContainer>
        )}

      {educationSituation &&
        shownLogic[educationSituation.id].includes("primarySchool") && (
          <>
            <NSCvEducationStyle.AskContainer>
              <NSCvEducationStyle.AskTitle>
                İlköğretim Okulu
              </NSCvEducationStyle.AskTitle>
              <Input
                label="Okul Adı"
                val={watch("primarySchool.schoolName")}
                placeholder="Okul Adı"
                isRequired
                {...register("primarySchool.schoolName")}
                error={errors.primarySchool?.schoolName?.message}
              />
            </NSCvEducationStyle.AskContainer>

            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Başlangıç Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={years}
                onSelect={(item) => setValue("primarySchool.startDate", item)}
                error={errors.primarySchool?.startDate?.message}
              />
              <SelectBox
                label="Bitiş Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={
                  primarySchoolStartDate
                    ? prepareEndDateArray(primarySchoolStartDate)
                    : []
                }
                disabled={!primarySchoolStartDate}
                onSelect={(item) => setValue("primarySchool.endDate", item)}
                error={errors.primarySchool?.endDate?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
          </>
        )}
      {educationSituation &&
        shownLogic[educationSituation.id].includes("secondarySchool") && (
          <>
            <NSCvEducationStyle.AskContainer>
              <NSCvEducationStyle.AskTitle>
                Ortaokul
              </NSCvEducationStyle.AskTitle>
              <Input
                label="Okul Adı"
                val={watch("secondarySchool.schoolName")}
                placeholder="Okul Adı"
                isRequired
                {...register("secondarySchool.schoolName")}
                error={errors.secondarySchool?.schoolName?.message}
              />
            </NSCvEducationStyle.AskContainer>
            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Başlangıç Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={years}
                onSelect={(item) => setValue("secondarySchool.startDate", item)}
                error={errors.secondarySchool?.startDate?.message}
              />

              <SelectBox
                label="Bitiş Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={
                  secondarySchoolStartDate
                    ? prepareEndDateArray(secondarySchoolStartDate)
                    : []
                }
                disabled={!secondarySchoolStartDate}
                onSelect={(item) => setValue("secondarySchool.endDate", item)}
                error={errors.secondarySchool?.endDate?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
          </>
        )}
      {educationSituation &&
        shownLogic[educationSituation.id].includes("highSchool") && (
          <>
            {" "}
            <NSCvEducationStyle.AskContainer>
              <NSCvEducationStyle.AskTitle>Lise</NSCvEducationStyle.AskTitle>

              <Input
                label="Okul Adı"
                val={watch("highSchool.schoolName")}
                placeholder="Okul Adı"
                isRequired
                {...register("highSchool.schoolName")}
                error={errors.highSchool?.schoolName?.message}
              />
            </NSCvEducationStyle.AskContainer>
            <Input
              label="Bölüm"
              val={watch("highSchool.department")}
              placeholder="Bölüm"
              isRequired
              {...register("highSchool.department")}
              error={errors.highSchool?.department?.message}
            />
            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Başlangıç Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={years}
                onSelect={(item) => setValue("highSchool.startDate", item)}
                error={errors.highSchool?.startDate?.message}
              />
              <SelectBox
                label="Bitiş Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={
                  highSchoolStartDate
                    ? prepareEndDateArray(highSchoolStartDate)
                    : []
                }
                disabled={!highSchoolStartDate}
                onSelect={(item) => setValue("highSchool.endDate", item)}
                error={errors.highSchool?.endDate?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Not Sistemi"
                placeholder="Not Sistemi"
                isRequired
                items={gradingSystems}
                onSelect={(item) => setValue("highSchool.gradingSystem", item)}
                error={errors.highSchool?.gradingSystem?.message}
              />

              <Input
                label="Mezuniyet Derecesi"
                val={watch("highSchool.gpa")}
                placeholder="Mezuniyet Derecesi"
                isRequired
                {...register("highSchool.gpa")}
                error={errors.highSchool?.gpa?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
          </>
        )}
      {((educationSituation &&
        shownLogic[educationSituation.id].includes("associateDegree") &&
        associateDegreeInfo === "yes") ||
        educationSituation?.id === "associateDegree") && (
        <>
          <NSCvEducationStyle.AskContainer>
            <NSCvEducationStyle.AskTitle>Ön Lisans</NSCvEducationStyle.AskTitle>

            <Input
              label="Okul Adı"
              val={watch("associateDegree.schoolName")}
              placeholder="Okul Adı"
              isRequired
              {...register("associateDegree.schoolName")}
              error={errors.associateDegree?.schoolName?.message}
            />
          </NSCvEducationStyle.AskContainer>

          <Input
            label="Bölüm"
            val={watch("associateDegree.department")}
            placeholder="Bölüm"
            isRequired
            {...register("associateDegree.department")}
            error={errors.associateDegree?.department?.message}
          />

          <NSCvEducationStyle.SideBySideContainer>
            <SelectBox
              label="Başlangıç Yılı"
              labelShowType="alwaysShow"
              placeholder="YYYY"
              isRequired
              items={years}
              onSelect={(item) => setValue("associateDegree.startDate", item)}
              error={errors.associateDegree?.startDate?.message}
            />
            <SelectBox
              label="Bitiş Yılı"
              labelShowType="alwaysShow"
              placeholder="YYYY"
              isRequired
              items={
                associateDegreeStartDate
                  ? prepareEndDateArray(associateDegreeStartDate)
                  : []
              }
              disabled={!associateDegreeStartDate}
              onSelect={(item) => setValue("associateDegree.endDate", item)}
              error={errors.associateDegree?.endDate?.message}
            />
          </NSCvEducationStyle.SideBySideContainer>

          <NSCvEducationStyle.SideBySideContainer>
            <SelectBox
              label="Not Sistemi"
              placeholder="Not Sistemi"
              isRequired
              items={gradingSystems}
              onSelect={(item) =>
                setValue("associateDegree.gradingSystem", item)
              }
              error={errors.associateDegree?.gradingSystem?.message}
            />

            <Input
              label="Mezuniyet Derecesi"
              val={watch("associateDegree.gpa")}
              placeholder="Mezuniyet Derecesi"
              isRequired
              {...register("associateDegree.gpa")}
              error={errors.associateDegree?.gpa?.message}
            />
          </NSCvEducationStyle.SideBySideContainer>
        </>
      )}

      {educationSituation &&
        shownLogic[educationSituation.id].includes("degree") && (
          <>
            {degreeFields.map((field, index) => (
              <NSCvEducationStyle.EducationArrayContainer key={field.id}>
                <NSCvEducationStyle.AskContainer>
                  <NSCvEducationStyle.AskTitle>
                    {`Lisans  ${index !== 0 ? index + 1 : ""}`}
                  </NSCvEducationStyle.AskTitle>
                  <Controller
                    name={`degree.${index}.schoolName`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Okul Adı"
                        placeholder="Okul Adı"
                        isRequired
                        {...field}
                        error={errors.degree?.[index]?.schoolName?.message}
                      />
                    )}
                  />
                </NSCvEducationStyle.AskContainer>

                <Controller
                  name={`degree.${index}.department`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Bölüm"
                      placeholder="Bölüm"
                      isRequired
                      {...field}
                      error={errors.degree?.[index]?.department?.message}
                    />
                  )}
                />
                <Checkbox
                  onChange={(value) =>
                    setValue(`degree.${index}.continue`, value)
                  }
                  checked={watch(`degree.${index}.continue`) ?? false}
                  id={`degree[${index}]Continue`}
                  label="Devam Ediyor"
                />
                <NSCvEducationStyle.SideBySideContainer>
                  <SelectBox
                    label="Başlangıç Yılı"
                    labelShowType="alwaysShow"
                    placeholder="YYYY"
                    isRequired
                    items={years}
                    onSelect={(item) =>
                      setValue(`degree.${index}.startDate`, item)
                    }
                    error={errors.degree?.[index]?.startDate?.message}
                  />
                  <SelectBox
                    label="Bitiş Yılı"
                    labelShowType="alwaysShow"
                    placeholder="YYYY"
                    isRequired={watch(`degree.${index}.continue`) !== true}
                    disabled={
                      watch(`degree.${index}.continue`) === true ||
                      watch(`degree.${index}.startDate`)?.id === ""
                    }
                    items={
                      watch(`degree.${index}.startDate`)
                        ? prepareEndDateArray(
                            watch(`degree.${index}.startDate`)
                          )
                        : []
                    }
                    onSelect={(item) =>
                      setValue(`degree.${index}.endDate`, item)
                    }
                    error={errors.degree?.[index]?.endDate?.message}
                  />
                </NSCvEducationStyle.SideBySideContainer>
                <NSCvEducationStyle.SideBySideContainer>
                  <SelectBox
                    label="Not Sistemi"
                    placeholder="Not Sistemi"
                    isRequired={watch(`degree.${index}.continue`) !== true}
                    items={gradingSystems}
                    disabled={watch(`degree.${index}.continue`) === true}
                    onSelect={(item) =>
                      setValue(`degree.${index}.gradingSystem`, item)
                    }
                    error={errors.degree?.[index]?.gradingSystem?.message}
                  />

                  <Controller
                    name={`degree.${index}.gpa`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Mezuniyet Derecesi"
                        placeholder="Mezuniyet Derecesi"
                        isRequired={watch(`degree.${index}.continue`) !== true}
                        disabled={watch(`degree.${index}.continue`) === true}
                        {...field}
                        error={errors.degree?.[index]?.gpa?.message}
                      />
                    )}
                  />
                </NSCvEducationStyle.SideBySideContainer>
                <NSCvEducationStyle.AddButtonContainer>
                  <Button
                    onClick={() => appendDegree(defaultValues)}
                    width={135}
                    height={40}
                    variant="text"
                    color={colors.blue.blue01}
                  >
                    <Add /> Başka Ekle
                  </Button>
                </NSCvEducationStyle.AddButtonContainer>
                {index !== degreeFields.length - 1 && (
                  <NSCvEducationStyle.Seperator />
                )}
              </NSCvEducationStyle.EducationArrayContainer>
            ))}
          </>
        )}
      {educationSituation &&
        shownLogic[educationSituation.id].includes("masterDegree") && (
          <>
            {masterDegreeFields.map((field, index) => (
              <NSCvEducationStyle.EducationArrayContainer key={field.id}>
                <NSCvEducationStyle.AskContainer>
                  <NSCvEducationStyle.AskTitle>
                    Yüksek Lisans
                  </NSCvEducationStyle.AskTitle>

                  <Controller
                    name={`masterDegree.${index}.schoolName`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Okul Adı"
                        placeholder="Okul Adı"
                        isRequired
                        {...field}
                        error={
                          errors.masterDegree?.[index]?.schoolName?.message
                        }
                      />
                    )}
                  />
                </NSCvEducationStyle.AskContainer>

                <Controller
                  name={`masterDegree.${index}.department`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Bölüm"
                      placeholder="Bölüm"
                      isRequired
                      {...field}
                      error={errors.masterDegree?.[index]?.department?.message}
                    />
                  )}
                />

                <Checkbox
                  onChange={(value) =>
                    setValue(`masterDegree.${index}.continue`, value)
                  }
                  checked={watch(`masterDegree.${index}.continue`) ?? false}
                  id={`masterDegree[${index}]Continue`}
                  label="Devam Ediyor"
                />
                <NSCvEducationStyle.SideBySideContainer>
                  <SelectBox
                    label="Başlangıç Yılı"
                    labelShowType="alwaysShow"
                    placeholder="YYYY"
                    isRequired
                    items={years}
                    onSelect={(item) =>
                      setValue(`masterDegree.${index}.startDate`, item)
                    }
                    error={errors.masterDegree?.[index]?.startDate?.message}
                  />
                  <SelectBox
                    label="Bitiş Yılı"
                    labelShowType="alwaysShow"
                    placeholder="YYYY"
                    isRequired={
                      watch(`masterDegree.${index}.continue`) !== true
                    }
                    disabled={
                      watch(`masterDegree.${index}.continue`) === true ||
                      watch(`masterDegree.${index}.startDate`)?.id === ""
                    }
                    items={
                      watch(`masterDegree.${index}.startDate`)
                        ? prepareEndDateArray(
                            watch(`masterDegree.${index}.startDate`)
                          )
                        : []
                    }
                    onSelect={(item) =>
                      setValue(`masterDegree.${index}.endDate`, item)
                    }
                    error={errors.masterDegree?.[index]?.endDate?.message}
                  />
                </NSCvEducationStyle.SideBySideContainer>
                <NSCvEducationStyle.SideBySideContainer>
                  <SelectBox
                    label="Not Sistemi"
                    placeholder="Not Sistemi"
                    isRequired={
                      watch(`masterDegree.${index}.continue`) !== true
                    }
                    disabled={watch(`masterDegree.${index}.continue`) === true}
                    items={gradingSystems}
                    onSelect={(item) =>
                      setValue(`masterDegree.${index}.gradingSystem`, item)
                    }
                    error={errors.masterDegree?.[index]?.gradingSystem?.message}
                  />

                  <Controller
                    name={`masterDegree.${index}.gpa`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Mezuniyet Derecesi"
                        placeholder="Mezuniyet Derecesi"
                        isRequired={
                          watch(`masterDegree.${index}.continue`) !== true
                        }
                        disabled={
                          watch(`masterDegree.${index}.continue`) === true
                        }
                        {...field}
                        error={errors.masterDegree?.[index]?.gpa?.message}
                      />
                    )}
                  />
                </NSCvEducationStyle.SideBySideContainer>
                <NSCvEducationStyle.AddButtonContainer>
                  <Button
                    onClick={() => appendMasterDegree(defaultValues)}
                    width={135}
                    height={40}
                    variant="text"
                    color={colors.blue.blue01}
                  >
                    <Add /> Başka Ekle
                  </Button>
                </NSCvEducationStyle.AddButtonContainer>

                {index !== masterDegreeFields.length - 1 && (
                  <NSCvEducationStyle.Seperator />
                )}
              </NSCvEducationStyle.EducationArrayContainer>
            ))}
          </>
        )}
      {educationSituation &&
        shownLogic[educationSituation.id].includes("doctorate") && (
          <>
            <NSCvEducationStyle.AskContainer>
              <NSCvEducationStyle.AskTitle>Doktora</NSCvEducationStyle.AskTitle>

              <Input
                label="Okul Adı"
                val={watch("doctorate.schoolName")}
                placeholder="Okul Adı"
                isRequired
                {...register("doctorate.schoolName")}
                error={errors.doctorate?.schoolName?.message}
              />
            </NSCvEducationStyle.AskContainer>
            <Input
              label="Bölüm"
              val={watch("doctorate.department")}
              placeholder="Bölüm"
              isRequired
              {...register("doctorate.department")}
              error={errors.doctorate?.department?.message}
            />
            <Checkbox
              onChange={(value) => setValue("doctorate.continue", value)}
              checked={watch("doctorate.continue") ?? false}
              id="doctorateContinue"
              label="Devam Ediyor"
            />
            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Başlangıç Yılı"
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired
                items={years}
                onSelect={(item) => setValue("doctorate.startDate", item)}
                error={errors.doctorate?.startDate?.message}
              />
              <SelectBox
                label="Bitiş Yılı"
                disabled={
                  watch("doctorate.continue") === true ||
                  watch("doctorate.startDate") === undefined
                }
                labelShowType="alwaysShow"
                placeholder="YYYY"
                isRequired={watch("doctorate.continue") !== true}
                items={
                  doctorateDegreeStartDate
                    ? prepareEndDateArray(doctorateDegreeStartDate)
                    : []
                }
                onSelect={(item) => setValue("doctorate.endDate", item)}
                error={errors.doctorate?.endDate?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
            <NSCvEducationStyle.SideBySideContainer>
              <SelectBox
                label="Not Sistemi"
                placeholder="Not Sistemi"
                isRequired={watch("doctorate.continue") !== true}
                disabled={watch("doctorate.continue") === true}
                items={gradingSystems}
                onSelect={(item) => setValue("doctorate.gradingSystem", item)}
                error={errors.doctorate?.gradingSystem?.message}
              />

              <Input
                label="Mezuniyet Derecesi"
                val={watch("doctorate.gpa")}
                placeholder="Mezuniyet Derecesi"
                isRequired={watch("doctorate.continue") !== true}
                disabled={watch("doctorate.continue") === true}
                {...register("doctorate.gpa")}
                error={errors.doctorate?.gpa?.message}
              />
            </NSCvEducationStyle.SideBySideContainer>
          </>
        )}

      <Button
        width={105}
        height={56}
        type="submit"
        variant="solid"
        onClick={scrollToTop}
      >
        Kaydet
      </Button>
    </NSCvEducationStyle.FormContainer>
  );
};
