import styled from "styled-components";
import colors from "../../../style/colors";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";
import typography from "../../../style/typography";

export namespace NSJoinUsPageDesktopStyle {
  export const Container = styled.div`
    overflow-x: clip;
  `;
  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 104px;
    background-color: ${colors.blue.blue01};
  `;
  export const MainContainer = styled.div`
    padding: 84px 112px;
    overflow: hidden;
    background-color: ${colors.neutrals.grey02};
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 120px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 120px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 120px;
    }
  `;

  export const HeaderContainer = styled.div`
    position: relative;
  `;
  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      left: 40px;
      top: -148px;
      z-index: 2;
    }
  `;
  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 32px;
    margin-bottom: 32px;
  `;
  export const RoundedCardAndVideoCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
  `;
  export const VideoPlayerCardContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      right: -64px;
      top: -64px;
    }
  `;
  export const IconCardGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 40px;
    justify-content: space-between;
  `;

  export const Title = styled.div`
    font-size: ${typography.titleh4.size};
    line-height: ${typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    text-align: center;
  `;
  export const Description = styled.div`
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
    text-align: center;
    max-width: 75%;
    margin-top: 16px;
    align-self: center;
  `;
  export const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
  `;
  export const ImageCard = styled.img`
    height: 280px;
    width: 360px;
    object-fit: cover;
    border-radius: 32px;
  `;
  export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  `;
  export const ImageIcon = styled.img`
    width: 274px;
    height: 108px;
  `;
}
