import { NSIconCardStyle } from "./IconCard.style";
import { NSIconCardType } from "./IconCard.types";

export const IconCard = ({
  isMobile,
  icon,
  title,
  description,
}: NSIconCardType.IIconCard) => {
  return (
    <NSIconCardStyle.Container isMobile={isMobile}>
      <NSIconCardStyle.IconContainer isMobile={isMobile}>
        {icon}
      </NSIconCardStyle.IconContainer>
      <NSIconCardStyle.Title>{title}</NSIconCardStyle.Title>
      <NSIconCardStyle.Description>{description}</NSIconCardStyle.Description>
    </NSIconCardStyle.Container>
  );
};
