import { useRef, useState } from "react";

import { NSJoinUsPageDesktopStyle } from "./JoinUsPageDesktop.style";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { HeaderDesktop } from "../../../components/HeaderDesktop/HeaderDesktop";
import { Footer } from "../../../components/Footer/Footer";
import { IconListCard } from "../../../components/IconListCard/IconListCard";
import { NsJoinUsPageType } from "../JoinUsPage.types";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { ArrowNumberedList } from "../../../components/ArrowNumberedList/ArrowNumberedList";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import { Badge } from "../../../components/Badge/Badge";
import ReactPlayer from "react-player";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { TempSpacer } from "../../../util/tempSpacer";
import { Modal } from "../../../components/Modal/Modal";
import useStickyElement from "../../../util/useStickyElement";
import { CenteredImageGrid } from "../../../components/CenteredImageGrid/CenteredImageGrid";

function JoinUsPageDesktop({ joinUsPageData }: NsJoinUsPageType.IJoinUsPage) {
  const isMobile = false;
  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const isSticky = useStickyElement(stickyDeciderDifRef);
  const [modalIsOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const renderHeader = () => (
    <HeaderDesktop
      logoClicked={() => {}}
      anchorList={joinUsPageData.anchorList}
      isDark={isSticky}
      backgroundColor={"transparent"}
    />
  );
  return (
    <NSJoinUsPageDesktopStyle.Container>
      {isSticky && renderHeader()}
      <NSJoinUsPageDesktopStyle.HeaderContainer ref={stickyDeciderDifRef}>
        <InnerPageHeader
          isMobile={isMobile}
          title="Bize Katıl"
          backgroundColor={colors.orange.orange01}
          children={renderHeader()}
          iconColor={colors.orange.orange04}
        />
      </NSJoinUsPageDesktopStyle.HeaderContainer>
      <NSJoinUsPageDesktopStyle.MainContainer>
        <IconListCard {...joinUsPageData.iconListCard} isMobile={isMobile} />
        <TempSpacer height={184} />
        <HeadingText
          isMobile={isMobile}
          title="İşe alım Sürecimizdeki Aşamalar"
          description=""
          marginBottom={64}
        />
        <ArrowNumberedList {...joinUsPageData.arrowList} isMobile={isMobile} />
        <TempSpacer height={120} />
        <NSJoinUsPageDesktopStyle.RoundedCardAndVideoCardContainer>
          <SingleRoundedCard
            {...joinUsPageData.singleRoundedCard}
            isMobile={isMobile}
          />
          <NSJoinUsPageDesktopStyle.VideoPlayerCardContainer>
            <VideoPlayerCard
              isMobile={isMobile}
              showTitle={false}
              imageUrl={joinUsPageData.videoPlayerImageAndVideo.image}
              height="568px"
              width="568px"
              onClick={onClickHandler}
            />
            <Badge
              color="BLUE"
              isMobile={isMobile}
              iconVariant="ADD"
              textVariant="JOINUS"
            />
          </NSJoinUsPageDesktopStyle.VideoPlayerCardContainer>
        </NSJoinUsPageDesktopStyle.RoundedCardAndVideoCardContainer>
        <TempSpacer height={120} />
        <NSJoinUsPageDesktopStyle.IconContainer>
          <NSJoinUsPageDesktopStyle.ImageIcon
            src={"/images/join-us/gelecekSensin.png"}
          />
        </NSJoinUsPageDesktopStyle.IconContainer>
        <NSJoinUsPageDesktopStyle.Title>
          Gelecek Sensin Yeni Mezun Programı
        </NSJoinUsPageDesktopStyle.Title>
        <NSJoinUsPageDesktopStyle.Description>
          Gelecek Sensin Yeni Mezun Programı ile Doğuş Otomotiv’de 2007 yılından
          beri geliştirilerek uygulamaya alınan Gelecek Sensin Yeni Mezun
          Programı ile adaylarımız değerlendirmeye alınarak, genel yetenek
          sınavı, vaka analizi çalışması, bireysel sunum, profil envanterleri ve
          İngilizce sınavları gibi farklı aşamaları tamamlayarak yeni mezun
          programımızla çeşitli fonksiyonlarımızda görevlerine başlarlar.
          <br />
          <br />
          Bu programla aramıza katılan çalışma arkadaşlarımız kişisel
          gelişimlerini destekleyecek farklı eğitim içerikleri ile yaklaşık 160
          saatlik bir eğitim programına dahil edilerek farklı sürdürebilirlik
          projelerini tamamlarlar.
        </NSJoinUsPageDesktopStyle.Description>
        <TempSpacer height={64} />

        <CenteredImageGrid
          {...joinUsPageData.lowerImageList}
          isMobile={isMobile}
        />

        <TempSpacer height={120} />
        <NSJoinUsPageDesktopStyle.Title>
          Üniversite İş Birlikleri
        </NSJoinUsPageDesktopStyle.Title>
        <NSJoinUsPageDesktopStyle.Description>
          Üniversitelerde yüksek lisans ya da sertifika programlarına katılarak
          eğitim hayatına devam etmek isteyen çalışma arkadaşlarımız için
          üniversitelerle anlaşmalarımız bulunmaktadır.
        </NSJoinUsPageDesktopStyle.Description>
        <TempSpacer height={64} />

        <ImageCarousel
          {...joinUsPageData.imageCarouselData}
          isMobile={isMobile}
          imageFit="none"
          displayImagePreview={false}
        />
        <CircleButton onClick={scrollToTop} isMobile={isMobile} />
      </NSJoinUsPageDesktopStyle.MainContainer>
      <Footer {...joinUsPageData.footer} isMobile={isMobile} />
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <ReactPlayer
          ref={playerRef}
          url={joinUsPageData.videoPlayerImageAndVideo.video}
          controls={true}
          width={isMobile ? "100%" : "60vw"}
          height={"auto"}
        />
      </Modal>
    </NSJoinUsPageDesktopStyle.Container>
  );
}

export default JoinUsPageDesktop;
