import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSSwitchButtonStyle {
  export const Container = styled.div`
    background-color: ${colors.neutrals.grey01};
    border-radius: 16px;
    display: flex;
    padding: 4px;
  `;
}
