import { NSKvkkPermissionStyle } from "../KvkkPermission/KvkkPermission.style";
import { Button } from "../Button/Button";
import { NSKvkkPermissionType } from "../KvkkPermission/KvkkPermission.types";
import colors from "../../style/colors";
import { Modal } from "../Modal/Modal";
import { useState } from "react";

export const KvkkPermission = ({
  isMobile = false,
}: NSKvkkPermissionType.IKvkkPermission) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalKVKKIsOpen, setModalKVKKIsOpen] = useState(false);
  const onRequestCloseHandler = () => {
    setModalKVKKIsOpen(false);
    setModalIsOpen(false);
  };

  return (
    <NSKvkkPermissionStyle.FormContainer>
      <NSKvkkPermissionStyle.Kvkk>
        <NSKvkkPermissionStyle.Title>
          KVKK İznim
          <NSKvkkPermissionStyle.TitleDesc>
            Onay verdiğiniz metinler.
          </NSKvkkPermissionStyle.TitleDesc>
          <NSKvkkPermissionStyle.TitleDesc>
            KVKK izninizi 2 yıl içerisinde yenileyebilirsiniz.
          </NSKvkkPermissionStyle.TitleDesc>
        </NSKvkkPermissionStyle.Title>

        <NSKvkkPermissionStyle.KVKKButton>
          <Button
            onClick={() => setModalIsOpen(true)}
            variant="text"
            color={colors.blue.blue01}
            fontSize={14}
          >
            Kullanım Şartları ve Gizlilik
          </Button>
          <Button
            onClick={() => setModalKVKKIsOpen(true)}
            variant="text"
            color={colors.blue.blue01}
            fontSize={14}
          >
            Kişisel Verilerin Korunması
          </Button>
          <Modal
            isOpen={modalKVKKIsOpen}
            isMobile={isMobile}
            onCloseHandler={onRequestCloseHandler}
          >
            <NSKvkkPermissionStyle.ModalCard>
              <NSKvkkPermissionStyle.ModalTitle>
                DOĞUŞ OTOMOTİV SERVİS VE TİC. A.Ş.
              </NSKvkkPermissionStyle.ModalTitle>
              <NSKvkkPermissionStyle.ModalTitle>
                ÇALIŞAN ADAYI KİŞİSEL VERİLERİNİN İŞLENMESİ AYDINLATMA METNİ
              </NSKvkkPermissionStyle.ModalTitle>
              <NSKvkkPermissionStyle.ModalContent>
                <NSKvkkPermissionStyle.ModalContentDiv>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    Değerli çalışan adayı,<br></br>6698 sayılı Kişisel Verilerin
                    Korunması Kanunu (KVKK) uyarınca; tarafınızı aydınlatmak
                    üzere hazırlanan bu belgeyi sonuna kadar dikkatlice okumanız
                    gerekmektedir.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDescTitle>
                    1. Veri Sorumlusu ve İrtibat Kişisi
                  </NSKvkkPermissionStyle.ModalContentDescTitle>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı
                    Kanun”) uyarınca, kişisel verileriniz; Veri Sorumlusu olarak
                    DOĞUŞ OTOMOTİV SERVİS VE TİCARET A.Ş (“Şirket” veya “Doğuş
                    Otomotiv”) tarafından aşağıda açıklanan kapsamda
                    işlenebilecektir. İrtibat kişisi:{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="mailto:kisiselverilerim@dogusotomotiv.com.tr">
                      kisiselverilerim@dogusotomotiv.com.tr{" "}
                    </NSKvkkPermissionStyle.ModalContentDescLink>
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDescTitle>
                    2. Kişisel Verilerinizin Toplandığı Kanallar ve Toplanma
                    Amaçları
                  </NSKvkkPermissionStyle.ModalContentDescTitle>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    Kişisel verileriniz, Şirketimizin politikalarının
                    uygulanması ve yürütülmesi ve aşağıda belirtilen diğer
                    amaçlarla toplanmaktadır. Bu süreçte toplanan kişisel
                    verileriniz; 6698 sayılı Kanun’un 5. ve 6. maddelerinde
                    belirtilen kişisel veri işleme şartları kapsamında,
                    Şirketimiz politikaları çerçevesinde bu Aydınlatma Metni
                    kapsamında aşağıda belirtilen hukuki sebeplere dayanılarak
                    işlenebilmekte ve aktarılabilmektedir.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    Kişisel verileriniz, Şirketimizce fiziksel veya elektronik
                    ortamdan;<br></br>• Şirketimize ait veya Şirketimizin iş
                    birliğinde olduğu kariyer portalları üzerinden iş ilanlarına
                    başvurunuz sırasında,<br></br> • Mülakat esnasında,<br></br>{" "}
                    • Aday başvuru formları aracılığı ile,
                    <br></br> • İnsan kaynakları operasyonlarının
                    gerçekleştirildiği sırada doğrudan sizlerden,<br></br> •
                    Matbu formlar ve resmî belgeler üzerinden,<br></br>elde
                    edilebilmektedir.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDescTitle>
                    3. Kişisel Verilerin Hangi Amaçla İşleneceği ve Hukuki
                    Sebebi
                  </NSKvkkPermissionStyle.ModalContentDescTitle>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    Şirketimiz ile işçi-işveren ilişkinize istinaden ve bu
                    ilişkinin devamı esnasında kimlik, iletişim, özlük kişisel
                    verileriniz aşağıdaki amaçlarla ve bu amaç kapsamında
                    karşılık gelen kanuni veri işleme şartına (“Hukuki Sebep”)
                    dayanılarak işlenecektir:<br></br>
                    <NSKvkkPermissionStyle.ModalContentDescStrong>
                      • Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                      ilgili olması kaydıyla, sözleşmenin taraflarına ait
                      kişisel verilerin işlenmesinin gerekli olması hukuki
                      sebebine dayanarak;
                    </NSKvkkPermissionStyle.ModalContentDescStrong>
                    <br></br>
                    Şirketimize yapmış olduğunuz iş başvurunuzun alınması,
                    talebinizin ve ilgili pozisyona uygunluğunuzun
                    değerlendirilmesi ve sonuçlandırılması kapsamında gerekli
                    süreçlerin yürütülmesi, •Veri sorumlusunun hukuki
                    yükümlülüğünü yerine getirebilmesi için kişisel verilerin
                    işlenmesinin zorunlu olması hukuki sebebine dayanarak; Resmi
                    kurum taleplerinin yerine getirilmesi, Yetkili kuruluşlara
                    mevzuattan kaynaklı bilgi verilmesi, Mevzuatta öngörülen
                    saklama yükümlülüklerine uygunluğun sağlanması, İlgili kişi
                    başvurularının mevzuata uygun olarak yanıtlanması ve gerekli
                    işlemlerin gerçekleştirilmesi,
                    <br></br>
                    <NSKvkkPermissionStyle.ModalContentDescStrong>
                      • İlgili kişinin temel hak ve özgürlüklerine zarar
                      vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                      için veri işlenmesinin zorunlu olması hukuki sebebine
                      dayanarak;
                    </NSKvkkPermissionStyle.ModalContentDescStrong>
                    <br></br>
                    Şirketimize yapmış olduğunuz iş başvurunuzun alınması,
                    talebinizin ve ilgili pozisyona uygunluğunuzun
                    değerlendirilmesi ve sonuçlandırılması kapsamında gerekli
                    süreçlerin yürütülmesi, Şirketimiz insan kaynakları
                    politikaları çerçevesinde işe alım süreçlerinin yürütülmesi,
                    <br></br>
                    <NSKvkkPermissionStyle.ModalContentDescStrong>
                      • Açık rızanızın bulunması halinde;
                    </NSKvkkPermissionStyle.ModalContentDescStrong>
                    <br></br>
                    Şirketimize yapmış olduğunuz iş başvurunuzun alınması,
                    talebinizin ve ilgili pozisyona uygunluğunuzun
                    değerlendirilmesi ve sonuçlandırılması kapsamında gerekli
                    süreçlerin yürütülmesi amacıyla engellilik bilgileriniz
                    dolayısıyla sağlık verilerinizin işlenmesi, Şirketimize
                    yapmış olduğunuz iş başvurunuzun alınması, talebinizin ve
                    ilgili pozisyona uygunluğunuzun değerlendirilmesi ve
                    sonuçlandırılması kapsamında gerekli süreçlerin yürütülmesi
                    amacıyla ceza mahkumiyeti ve güvenlik tedbirlerine ilişkin
                    verilerinizin işlenmesi, İş başvurunuzun olumsuz
                    sonuçlanması halinde ise ileride deneyim ve niteliklerinize
                    uygun açık pozisyon bulunması durumunda CV’nizin insan
                    kaynakları uygulamalarımız dahilinde değerlendirmeye
                    alınması.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDescTitle>
                    4. İşlenen Kişisel Verilerinizin Aktarılabileceği Alıcı
                    Grupları ve ve Hukuki Sebebi{" "}
                  </NSKvkkPermissionStyle.ModalContentDescTitle>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    Yukarıda yer alan Amaçlar’ın yerine getirilmesi
                    doğrultusunda toplanan kişisel verileriniz; iş
                    ortaklarımıza, tedarikçilere, kanunlarda açıkça öngörülmesi
                    ve hukuki yükümlülüklerimizin yerine getirilmesi kapsamında
                    kanunen yetkili kamu kurumları ve kanunen yetkili özel
                    kişilere Kanun’un 5. ve 6. maddelerinde düzenlenen veri
                    işleme şartları kapsamında Kanun’un 8. maddesinde belirtilen
                    kişisel verilerin aktarılmasına ilişkin kurallara uygun
                    olarak aktarılabilecektir.<br></br> Kişisel veriliniz bu
                    kapsamda belirtilen kişilere 6698 sayılı Kanun’un 8. ve 9.
                    maddelerinde belirtilen kişisel veri işleme şartları ve
                    amaçları çerçevesinde{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink
                      href="https://www.dogusotomotiv.com.tr/tr/kullanim-sartlari-ve-gizlilik-kisisel-verilerin-korunmasi"
                      target="_blank"
                    >
                      (Politikanın detayı için tıklayınız)
                    </NSKvkkPermissionStyle.ModalContentDescLink>
                    yer alan Doğuş Otomotiv Çalışan Kişisel Verilerin Korunması
                    ve İşlenmesi Politikasında belirtilen amaçlarla sınırlı
                    olarak aktarılabilecektir.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                  <NSKvkkPermissionStyle.ModalContentDescTitle>
                    5. İlgili Kişi Olarak 6698 sayılı Kanun’un 11. maddesinde
                    Sayılan Haklarınız
                  </NSKvkkPermissionStyle.ModalContentDescTitle>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    • Kişisel veri işlenip işlenmediğini öğrenme,<br></br> •
                    Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                    <br></br> • Kişisel verilerin işlenme amacını ve bunların
                    amacına uygun kullanılıp kullanılmadığını öğrenme,
                    <br></br> • Yurt içinde veya yurt dışında kişisel verilerin
                    aktarıldığı üçüncü kişileri bilme,<br></br> • Kişisel
                    verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
                    düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                    verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                    <br></br> • 6698 sayılı Kanun ve ilgili diğer kanun
                    hükümlerine uygun olarak işlenmiş olmasına rağmen,
                    işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
                    kişisel verilerin silinmesini veya yok edilmesini isteme ve
                    bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
                    üçüncü kişilere bildirilmesini isteme,<br></br> • İşlenen
                    verilerin münhasıran otomatik sistemler vasıtasıyla analiz
                    edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                    ortaya çıkmasına itiraz etme,<br></br> • Kişisel verilerin
                    kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması
                    hâlinde zararın giderilmesini talep etme haklarına
                    sahipsiniz.<br></br>
                    <br></br> İlgili kişi olarak, haklarınıza ilişkin
                    taleplerinizi{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="mailto:kisiselverilerim@dogusotomotiv.com.tr">
                      kisiselverilerim@dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    adresi üzerinden şirketimize iletmeniz durumunda, Doğuş
                    Otomotiv talebin niteliğine göre talebi, en kısa sürede ve
                    en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
                    Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde (10
                    sayfanın üzerinde kağıt ortamda, CD ve harici bellek olarak
                    verilen cevaplar), Doğuş Otomotiv tarafından Kişisel
                    Verileri Koruma Kurulu’nca belirlenen tarifedeki ücret
                    alınacaktır.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                </NSKvkkPermissionStyle.ModalContentDiv>
              </NSKvkkPermissionStyle.ModalContent>
            </NSKvkkPermissionStyle.ModalCard>
          </Modal>

          <Modal
            isOpen={modalIsOpen}
            isMobile={isMobile}
            onCloseHandler={onRequestCloseHandler}
          >
            <NSKvkkPermissionStyle.ModalCard>
              <NSKvkkPermissionStyle.ModalTitle>
                KULLANIM ŞARTLARI
              </NSKvkkPermissionStyle.ModalTitle>
              <NSKvkkPermissionStyle.ModalTitle>
                KULLANIM ŞARTLARI, ERİŞİM, RİSK VE SORUMLULUĞUN
                SINIRLANDIRILMASI, IP ADRESLERİ VE COOKİELER (ÇEREZLER)
              </NSKvkkPermissionStyle.ModalTitle>
              <NSKvkkPermissionStyle.ModalContent>
                <NSKvkkPermissionStyle.ModalContentDiv>
                  <NSKvkkPermissionStyle.ModalContentDesc>
                    1.{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesi ve sitedeki tüm dokümanlar Doğuş Otomotiv'in
                    mülkiyetindedir. Bu dökümanlar, on-line sisteminde görülmesi
                    için gerekli olması hali haricinde kopyalanamaz, yeniden
                    üretilemez. Yukarıda belirtilenden bağımsız olarak, sitenin
                    sayfalarının yazıcı çıktısını kişisel kullanımınız için
                    alabilirsiniz. Yukarıda belirtilen genel prensipten bağımsız
                    olarak Doğuş Otomotiv, siteden wallpaper, screen saver ve
                    diğer programlarını indirme imkanını verebilir. Bu sitede
                    yer alan dokümanlar ile ilgili fikri ve sınai mülkiyet
                    haklarını ihlal edecek nitelikteki her türlü kullanım
                    sonrasında ortaya çıkabilecek yasal sorumluluklar
                    kullanıcıya aittir. <br></br>2. Site ile link oluşturmak
                    isterseniz, bu link{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    ana sayfasına yönlendirilmelidir.{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      (http://www.dogusotomotiv.com.tr)
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    <br></br>3. Doğuş Otomotiv tarafından web sitesinin virüs ve
                    benzeri amaçlı yazılımlardan arındırılmış olması için mevcut
                    imkanlar dahilinde tedbir alınmış olmakla birlikte, nihai
                    güvenliğin sağlanması için kullanıcı, kendi virüs koruma
                    sistemini tedarik etmek ve gerekli korumayı sağlamakla
                    yükümlüdür. Bu çerçevede kullanıcı,{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesine girmesi nedeniyle, kendi yazılım ve işletim
                    sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya
                    da dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul
                    eder.
                    <br></br> 4.{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesinde yer alan her türlü fiyat, bilgi, resim,
                    açıklama ve haber (kısaca "bilgi") sadece tanıtım ve bilgi
                    verme amaçlıdır. Kullanıcı hiçbir şekilde web sitesinde yer
                    alan "bilgi"lerin hatalı olduğu ya da bu bilgilere istinaden
                    zarara uğradığı iddiasında bulunamaz. Kullanıcı, bilgileri
                    referans alarak bir işlem yapmak niyetinde olduğunda, nihai
                    ve güvenilir enformasyonu, Doğuş Otomotiv ve Doğuş Otomotiv
                    yetkili satıcılarından temin etmekle yükümlü olduğunu ve web
                    sitesinde yayınlanan bilgilerin güncel olmaması nedeniyle
                    Doğuş Otomotiv'in herhangi bir sorumluluğu olmadığını kabul
                    eder. <br></br>5. Doğuş Otomotiv, kendi takdirinde olmak
                    üzere sitenin içeriğini dilediği zaman değiştirme,
                    kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya da
                    sona erdirme ve{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesinde kayıtlı kullanıcı bilgi ve verilerini silme
                    hakkını saklı tutar. Doğuş Otomotiv, web sitesinin hatasız
                    olması için her türlü tedbir almış olmakla birlikte, sitede
                    mevcut ya da oluşabilecek hatalar ile ilgili herhangi bir
                    garanti vermemektedir.<br></br> 6.{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesindeki; Doğuş Otomotiv yetkili satıcıları, Doğuş
                    Otomotiv’in ithalatını yaptığı araçların standart ve
                    opsiyonel özellikleri ve yetkili satıcıya tavsiye edilen
                    satış fiyatları ile ilgili bilgiler son güncelleme tarihinde
                    geçerli olan bilgilerdir. Araçlar ve Doğuş Otomotiv yetkili
                    satıcıları ile ilgili nihai bilgi temini için Doğuş Otomotiv
                    ve/veya Doğuş Otomotiv yetkili satıcıları ile temasa
                    geçilmesini önemle rica ederiz. Bu çerçevede Doğuş Otomotiv,
                    web sitesinde yer alan bilgilerin (aksi açık bir şekilde
                    belirtilmedikçe) bir satış teklifi olmadığını ve Doğuş
                    Otomotiv adına bir taahhüt teşkil etmediğini beyan eder.
                    <br></br> 7. Resmi makamlardan kullanıcıya yönelik bir suç
                    duyurusu ya da resmi soruşturma talebi gelmesi ve/veya
                    kullanıcının Doğuş Otomotiv sistemlerinin çalışmasına engel
                    olacak ya da işleyişini değiştirecek herhangi bir elektronik
                    sabotaj veya saldırı yaptığının tespit edilmesi halinde,
                    Doğuş Otomotiv, kullanıcının kimlik bilgilerini araştırma ve
                    yasal mercilere bildirimde bulunma hakkına sahiptir.
                    <br></br> Doğuş Otomotiv web sitesi ile sahip olduğu tüm alt
                    sitelerinin ve diğer bilgi sistemlerinin güvenliğini
                    sağlamak Doğuş Otomotiv sorumluluğundadır. Ticari amaçlı,
                    iyi niyetli ya da başka bir amaçla, web siteleri ve diğer
                    bilgi teknolojileri sistemlerinde bulunabilecek zafiyetlerin
                    tespitine yönelik taramalar ve tespit edilebilecek
                    zafiyetlerin suiistimal edilerek sistemlere zarar verilmesi,
                    bilgi sızdırma vb faaliyetler yasa dışıdır. Bu gibi
                    faaliyetlerin gerçekleştirilmesi durumunda 5651 sayılı
                    İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu
                    Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında
                    Kanun uyarınca yaptırım uygulanabilir. Bu gibi girişimlerde
                    bulunulması halinde, Doğuş Otomotiv yapılan saldırının tipi,
                    saldırı zamanı, saldırgana ilişkin yasa kapsamında toplanan
                    verilerle birlikte hukuki süreç başlatma ve süreç sonucunda
                    para cezası ya da hapis cezasına varacak cezaları talep etme
                    hakkını saklı tutar.<br></br>
                    8. Bazı durumlarda, şahsınıza özel olmayan bilgiler
                    toplanabilir. Bu tip bilgilere örnek olarak kullandığınız
                    internet tarayıcısının türü, işletim sisteminiz, sitemize
                    linkle ya da ilanla ulaştığınız sitenin domain ismi
                    verilebilir.<br></br> 9. 5846 Sayılı Fikir Ve Sanat Eserleri
                    Kanunu ve 5651 Sayılı İnternet Ortamında Yapılan Yayınların
                    Düzenlenmesi Ve Bu Yayınlar Yoluyla İşlenen Suçlarla
                    Mücadele Edilmesi Hakkında Kanun Uyarınca Dikkat Edilecek
                    Hususlar<br></br> Kullanıcı/Kullanıcılar, web sitesi
                    üzerinde bir başka web sitesine veya içeriğe erişim imkanı
                    tanıyan link'ler bulunabileceğini, bunların kontrolünün veya
                    doğruluğunun veya yeterliliğinin Doğuş Otomotiv ile ilgisi
                    olmadığını ve Doğuş Otomotiv’in bu siteleri herhangi bir
                    şekilde desteklemediğini veya içerdiği bilgilerin
                    doğruluğunu garanti etmediğini ve bu sitelerde yer alan
                    içeriklerden dolayı herhangi bir sorumluluğu bulunmadığını,
                    bu sitelerin ve/veya içeriklerin kullanımından veya bu
                    içeriklere dayalı olarak almış oldukları kararların
                    sonuçlarından, sitenin kullanım şartlarını okumamış veya
                    okumuş ancak bunlara uygun davranmamış olmaları sebebiyle
                    uğramış olduğu maddi veya manevi, müsbet veya menfi ve olası
                    herhangi bir zarardan veya masraftan Doğuş Otomotiv veya ona
                    bağlı herhangi bir grup şirketinin veya çalışanlarının,
                    yöneticilerinin, yetkilendirdikleri kişi veya
                    kuruluşlarının, hukuki veya cezai hiçbir sorumluluğu
                    olmadığını, sitede yer alan bilgilerin doğruluğu ve/veya
                    yeterliliğinin Doğuş Otomotiv tarafından garanti
                    edilmediğini kabul ve beyan etmişlerdir.<br></br>{" "}
                    Kullanıcı/Kullanıcılar, web sitesi üzerinde Doğuş Otomotiv’e
                    veya üçüncü bir kişi veya kuruluşa ait herhangi bir yazı,
                    bilgi, yorum, görüş, haber, görüntü, resim, şekil, grafik ve
                    sair bir materyal bulunabileceğini, Doğuş Otomotiv’in bu
                    materyallerle ilgili olarak Sınai Mülkiyet Kanunu'nun
                    düzenlediği yasal haklara sahip olduğunu, bunların Doğuş
                    Otomotiv izni olmadan kopyalanamayacağını,
                    değiştirilemeyeceğini, çoğaltılamayacağını,
                    yayınlanamayacağını, satılamayacağını veya
                    kullandırılamayacağını veya bunlara müdahale edilemeyeceğini
                    kabul ve beyan etmişlerdir.<br></br> Bu sitede yayınlanan
                    tüm logolar, marka ve kurumsal amblemler ve araç üstündeki
                    model yazıları, Sınai Mülkiyet Kanunu hükümleri vd. ilgili
                    mevzuat ile korunmaktadırlar. Logolar hiçbir şekilde
                    kopyalanamaz ve kullanılamazlar. Kullanıcı/Kullanıcılar,
                    Doğuş Otomotiv logosunun veya web sitesine ait sayfaların
                    bir başka web sitesinde herhangi bir şekilde yer almasından
                    veya web sitesine üçüncü kişiler veya ziyaretçiler
                    tarafından yapılabilecek herhangi bir içerik yüklemesinden
                    dolayı elde edilecek bilgilerin kullanımından veya
                    internetin genel uygulamalar çerçevesinde kullanımı
                    dolayısıyla sorumluluk doğurmayan herhangi bir içerikten
                    Doğuş Otomotiv’in veya ona bağlı veya onun bağlı olduğu
                    herhangi bir grup şirketinin veya çalışanlarının,
                    yöneticilerinin, yetkilendirdikleri kişi veya
                    kuruluşlarının, hukuki veya cezai hiçbir sorumluluğu
                    olmadığını kabul ve beyan etmişlerdir.<br></br>10. Doğuş
                    Otomotiv,{" "}
                    <NSKvkkPermissionStyle.ModalContentDescLink href="http://www.dogusotomotiv.com.tr">
                      http://www.dogusotomotiv.com.tr
                    </NSKvkkPermissionStyle.ModalContentDescLink>{" "}
                    web sitesinde, "Kullanım Şartları"nın herhangi bir
                    maddesini, bildirimde bulunmaksızın değiştirme, yenileme
                    veya iptal etme hakkına sahiptir. Değiştirilen, yenilenen ya
                    da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm
                    kullanıcılar bakımından hüküm ifade edecektir. <br></br>11.
                    Uygulanacak Hukuk, Yetkili Mahkeme Ve İcra Daireleri İşbu
                    “Kullanım Şartları Ve Gizlilik Metni” Türkiye Cumhuriyeti
                    Kanunlarına tabidir. Burada yer alan hususlarla ilgili
                    olarak herhangi bir uyuşmazlık veya ihtilaf iddia veya talep
                    olduğu takdirde, İstanbul (Çağlayan) Mahkeme ve İcra
                    Dairelerinin yetkili olacaktır.
                    <br></br>132 Son Hüküm
                    <br></br>Kullanıcı/Kullanıcılar, web sitesine girmeden önce
                    yukarıda yer alan “Kullanım Şartları ve Gizlilik Metni”nı
                    okuduklarını, yukarıda belirtilen tüm hususlara uyacaklarını
                    gayrıkabili rücu olarak kabul, beyan ve taahhüt etmişlerdir.
                  </NSKvkkPermissionStyle.ModalContentDesc>
                </NSKvkkPermissionStyle.ModalContentDiv>
              </NSKvkkPermissionStyle.ModalContent>
            </NSKvkkPermissionStyle.ModalCard>
          </Modal>
        </NSKvkkPermissionStyle.KVKKButton>
      </NSKvkkPermissionStyle.Kvkk>
    </NSKvkkPermissionStyle.FormContainer>
  );
};
