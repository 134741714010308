import { NSTextStatsStyle } from "./TextStats.style";
import { NSTextStatsType } from "./TextStats.types";
import { Modal } from "../Modal/Modal";
import { useEffect, useState } from "react";
import { NSVideoPlayerCarouselStyle } from "../VideoPlayerCarousel/VideoPlayerCarousel.style";
import colors from "../../style/colors";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import { NSImageCarouselStyle } from "../ImageCarousel/ImageCarousel.style";

export const TextStats = ({
  isMobile,
  blueTitle,
  mainTitle,
  statsList,
}: NSTextStatsType.ITextStats) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedStat, setSelectedStat] = useState<string>("");
  const [largeImagesArray, setLargeImagesArray] = useState<string[]>([]);

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };
  const currentIndex = largeImagesArray?.indexOf(selectedStat);

  const nextImageHandler = () => {
    if (currentIndex !== largeImagesArray.length - 1) {
      setSelectedStat(largeImagesArray[currentIndex + 1]);
    }
  };
  const previousImageHandler = () => {
    if (currentIndex !== 0) {
      setSelectedStat(largeImagesArray[currentIndex + -1]);
    }
  };

  useEffect(() => {
    const filteredImages = statsList
      .filter((item) => item.largeImage !== undefined)
      .map((item) => item.largeImage || "");

    setLargeImagesArray(filteredImages);
  }, [statsList]);

  const statClickedHandler = (stat: NSTextStatsType.IStatItem) => {
    if (stat.largeImage) {
      setSelectedStat(stat.largeImage);
      setIsOpen(true);
    }
  };

  const renderStatsList = () =>
    statsList.map((stat) => (
      <NSTextStatsStyle.StatsCard
        isMobile={isMobile}
        key={stat.name}
        isClickable={stat.largeImage !== undefined}
        onClick={() => statClickedHandler(stat)}
      >
        <NSTextStatsStyle.OrbIconContainer isMobile={isMobile}>
          {stat.icon}
        </NSTextStatsStyle.OrbIconContainer>
        <NSTextStatsStyle.StatTextContainer>
          <NSTextStatsStyle.StatNumber isMobile={isMobile}>
            {stat.number}
          </NSTextStatsStyle.StatNumber>
          <NSTextStatsStyle.StatName isMobile={isMobile}>
            {stat.name}
          </NSTextStatsStyle.StatName>
        </NSTextStatsStyle.StatTextContainer>
      </NSTextStatsStyle.StatsCard>
    ));

  return (
    <NSTextStatsStyle.Container isMobile={isMobile}>
      <NSTextStatsStyle.Title isMobile={isMobile}>
        <NSTextStatsStyle.BlueTitle isMobile={isMobile}>
          {blueTitle}
        </NSTextStatsStyle.BlueTitle>
        {mainTitle}
      </NSTextStatsStyle.Title>
      <NSTextStatsStyle.StatsContainer isMobile={isMobile}>
        {renderStatsList()}
      </NSTextStatsStyle.StatsContainer>
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <NSVideoPlayerCarouselStyle.ModalContentContainer isMobile={isMobile}>
          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}

          <NSImageCarouselStyle.DisplayedImage
            src={selectedStat}
            isMobile={isMobile}
          />

          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== largeImagesArray.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}
        </NSVideoPlayerCarouselStyle.ModalContentContainer>

        {isMobile && (
          <NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== largeImagesArray.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          </NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
        )}
      </Modal>
    </NSTextStatsStyle.Container>
  );
};
