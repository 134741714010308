import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Input } from "../Input/Input";
import { NSCvContactStyle } from "./CvContact.style";
import { NSCvContactType } from "./CvContact.types";
import { zodResolver } from "@hookform/resolvers/zod";
import { SelectBox } from "../SelectBox/SelectBox";
import { Button } from "../Button/Button";
import { TextArea } from "../TextArea/TextArea";
import { useState } from "react";
import { CountryCodeSelectBox } from "../CountryCodeSelectBox/CountryCodeSelectBox";
import {
  areaCodes,
  cities,
  citiesToDistricts,
  countries,
} from "../../util/formDropdownItems";
import { scrollToTop } from "../../util/scrollToTop";

export const CvContact = ({ onSubmitContact }: NSCvContactType.ICvContact) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm<NSCvContactType.CVContactType>({
    resolver: zodResolver(NSCvContactType.CvContactSchema),
  });

  const onSubmit: SubmitHandler<NSCvContactType.CVContactType> = (data) => {
    const phoneData = `${countryCode}${data.phone}`;
    const relativePhoneData = `${relativeCountryCode}${data.relativePhone}`;
    const prevData = {
      ...data,
      phone: phoneData,
      relativePhone: relativePhoneData,
    };

    onSubmitContact(prevData);
  };

  const [countryCode, setCountryCode] = useState(areaCodes[0].label);
  const [relativeCountryCode, setRelativeCountryCode] = useState(
    areaCodes[0].label
  );

  const country = useWatch({
    control,
    name: "country",
  });

  const province = useWatch({
    control,
    name: "province",
  });

  const district = useWatch({
    control,
    name: "district",
  });

  return (
    <NSCvContactStyle.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      id="CvContact"
    >
      <Input
        label="E-Mail Adresin"
        val={watch("email")}
        placeholder="E-Mail Adresin"
        {...register("email")}
        isRequired
        error={errors.email?.message}
      />

      <Input
        label="Cep Telefonu Numarası"
        val={watch("phone")}
        placeholder="Cep Telefonu Numarası"
        {...register("phone")}
        maxLength={15}
        isRequired
        type="number"
        suffixPrefixItemPadding={58}
        onChange={(e) => setValue("phone", e.target.value)}
        prefixItem={
          <CountryCodeSelectBox
            onSelect={(item) => setCountryCode(item.label)}
            title={countryCode}
          />
        }
        error={errors.phone?.message}
      />

      <NSCvContactStyle.SideBySideContainer>
        <SelectBox
          label="Ülke"
          placeholder="Ülke"
          isRequired
          items={countries}
          onSelect={(item) => {
            setValue("country", item);

            if (item.id !== "turkiye") {
              setValue("province", undefined);
              setValue("district", undefined);
            }
          }}
          error={errors.country?.message}
        />

        <SelectBox
          label="İl"
          placeholder="İl"
          isRequired={country && country.id === "turkiye"}
          disabled={!country || country.id !== "turkiye"}
          items={cities}
          onSelect={(item) => setValue("province", item)}
          error={errors.province?.message}
          defaultSelectedItem={province ?? undefined}
        />

        <SelectBox
          label="İlçe"
          placeholder="İlçe"
          isRequired={country && country.id === "turkiye" && !!province}
          disabled={!province || country.id !== "turkiye"}
          items={province ? citiesToDistricts[province.id as string] : []}
          onSelect={(item) => setValue("district", item)}
          error={errors.district?.message}
          defaultSelectedItem={district ?? undefined}
        />
      </NSCvContactStyle.SideBySideContainer>
      <TextArea
        isRequired
        label="Ev Adresin"
        val={watch("address")}
        placeholder="Ev Adresin"
        {...register("address")}
        infoText="Mahalle, sokak, cadde"
        error={errors.address?.message}
      />

      <NSCvContactStyle.BottomToTopContainer>
        <NSCvContactStyle.AskTitle>
          Yakınının Bilgileri
        </NSCvContactStyle.AskTitle>
        <NSCvContactStyle.SideBySideContainer>
          <Input
            label="Yakınının Adı"
            val={watch("relativeName")}
            placeholder="Yakınının Adı"
            isRequired
            {...register("relativeName")}
            error={errors.relativeName?.message}
          />

          <Input
            label="Yakının Soyadı"
            val={watch("relativeSurname")}
            placeholder="Yakının Soyadı"
            {...register("relativeSurname")}
            isRequired
            error={errors.relativeSurname?.message}
          />
        </NSCvContactStyle.SideBySideContainer>
      </NSCvContactStyle.BottomToTopContainer>
      <Input
        label="Cep Telefonu Numarası"
        val={watch("relativePhone")}
        placeholder="Cep Telefonu Numarası"
        {...register("relativePhone")}
        type="number"
        suffixPrefixItemPadding={58}
        prefixItem={
          <CountryCodeSelectBox
            onSelect={(item) => setRelativeCountryCode(item.label)}
            title={relativeCountryCode}
          />
        }
        isRequired
        error={errors.relativePhone?.message}
      />

      <Button
        width={105}
        height={56}
        type="submit"
        variant="solid"
        onClick={scrollToTop}
      >
        Kaydet
      </Button>
    </NSCvContactStyle.FormContainer>
  );
};
