import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSCircleButtonStyle {
  export const Container = styled.button<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    background-color: ${colors.light.light};
    height: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    width: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
    bottom: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
    z-index: 5;
    transition: 1s all;
    ${({ isMobile }) =>
      !isMobile &&
      css`
        &:hover {
          transform: scale(1.1);
        }
      `}
  `;
}
