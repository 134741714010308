import colors from "../../style/colors";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { NSJobDescriptionStyle } from "./JobDescription.style";
import { NSJobDescriptionType } from "./JobDescription.types";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";

export const JobDescription = ({
  image,
  content,
  id,
  isMobile = false,
}: NSJobDescriptionType.IJobDescription) => {
  const navigate = useNavigate();
  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator
      .share({ url: currentUrl })
      .then(() => console.log("Link paylaşıldı"))
      .catch((error) => console.error("Paylaşım hatası:", error));
  };

  const socialMediaList = [
    { icon: <Send stroke={colors.dark.dark} /> },
    {
      icon: <Linkedin stroke={colors.dark.dark} />,
    },
    {
      icon: <Facebook stroke={colors.dark.dark} />,
    },
  ];

  return (
    <NSJobDescriptionStyle.Container isMobile={isMobile}>
      <NSJobDescriptionStyle.CoverImage
        src={image}
        alt={image}
        isMobile={isMobile}
      />
      <NSJobDescriptionStyle.Content>
        {typeof content === "string" ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <>{content}</>
        )}
      </NSJobDescriptionStyle.Content>
      <Button
        children="Şimdi Başvur"
        variant="solid"
        backgroundColor={colors.blue.blue01}
        color={colors.light.light}
        width={304}
        onClick={() => {
          navigate(`/position/detail/${id}`);
        }}
      />
      <NSJobDescriptionStyle.SocialMediaDiv>
        <NSJobDescriptionStyle.SocialMediaShareText>
          İlanı Paylaş:
        </NSJobDescriptionStyle.SocialMediaShareText>
        {socialMediaList.map((socialMedia, index) => (
          <Button
            key={`social-media-button-${index}`}
            width={48}
            height={48}
            variant="icon"
            onClick={handleShare}
            backgroundColor={colors.neutrals.grey01}
          >
            {socialMedia.icon}
          </Button>
        ))}
      </NSJobDescriptionStyle.SocialMediaDiv>
    </NSJobDescriptionStyle.Container>
  );
};
