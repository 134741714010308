import colors from "../../style/colors";
import { Logo } from "../Logo/Logo";
import { TopMenuNavigationDesktop } from "../TopMenuNavigationDesktop/TopMenuNavigationDesktop";
import { NSHeaderDesktopStyle } from "./HeaderDesktop.style";
import { NSHeaderDesktopType } from "./HeaderDesktop.types";

export const HeaderDesktop = ({
  logoClicked,
  anchorList,
  isDark = false,
  backgroundColor = colors.neutrals.grey07,
}: NSHeaderDesktopType.IHeaderDesktop) => {
  return (
    <NSHeaderDesktopStyle.Container
      isDark={isDark}
      backgroundColor={backgroundColor}
    >
      <Logo isMobile={false} onClick={logoClicked} isDark={isDark} />
      <TopMenuNavigationDesktop anchorList={anchorList} isDark={isDark} />
    </NSHeaderDesktopStyle.Container>
  );
};
