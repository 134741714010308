import { NSCompanyInfoStyle } from "./CompanyInfo.style";
import { NSCompanyInfoType } from "./CompanyInfo.types";
import { VideoPlayerCard } from "../VideoPlayerCard/VideoPlayerCard";
import { JobInfoCardContainer } from "../JobInfoCardContainer/JobInfoCardContainer";

export const CompanyInfo = ({
  title,
  content,
  image,
  videoUrl,
  isMobile,
}: NSCompanyInfoType.ICompanyInfo) => {
  return (
    <JobInfoCardContainer isMobile={isMobile}>
      <NSCompanyInfoStyle.CompanyInfoContainer>
        <NSCompanyInfoStyle.Title>{title}</NSCompanyInfoStyle.Title>
        <NSCompanyInfoStyle.Desc>{content}</NSCompanyInfoStyle.Desc>
      </NSCompanyInfoStyle.CompanyInfoContainer>
      <VideoPlayerCard
        isMobile={false}
        imageUrl={image}
        videoUrl={videoUrl}
        width={"100%"}
        height={"auto"}
        showTitle={false}
      ></VideoPlayerCard>
    </JobInfoCardContainer>
  );
};
