import { footerData, headerAnchorList } from "../../../util/headerFooterData";
import useDeviceType from "../../../util/isMobile";
import PositionDetailPageDesktop from "./DesktopView/PositionDetailPageDesktop";
import PositionDetailPageMobile from "./MobileView/PositionDetailPageMobile";
import { NSPositionDetailPageType } from "./PositionDetailPage.types";

const positionDetailData: NSPositionDetailPageType.IPositionDetailPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
};

function PositionDetailPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <PositionDetailPageMobile positionDetailData={positionDetailData} />;
  } else {
    return (
      <PositionDetailPageDesktop positionDetailData={positionDetailData} />
    );
  }
}

export default PositionDetailPage;
