import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSUserInfoStyle {
  export const Form = styled.form`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    flex-direction: column;
  `;

  export const FormContainer = styled.div`
    width: 65%;

    @media (max-width: 1200px) {
      width: 100%;
    }
  `;

  export const Title = styled.div`
    width: 369px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28.08px;
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
  `;

  export const CancelTitle = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 28.08px;
    color: ${colors.error.error01};
    font-family: "ReadexPro";
  `;

  export const TitleDesc = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22.96px;
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
    margin: 4px 0px 0px 0px;
  `;

  export const InnerForm = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-direction: column;

    @media (max-width: 768px) {
      gap: 24px;
    }
  `;

  export const SideBySideInputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }
  `;
}
