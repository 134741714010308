import { useRef, useState } from "react";
import { NSCVPageType } from "../CVPage.types";
import { NSCVPageMobileStyle } from "./CVPageMobile.style";
import useStickyElement from "../../../../util/useStickyElement";
import { MenuMobile } from "../../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../../components/HeaderMobile/HeaderMobile";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../../style/colors";
import { Footer } from "../../../../components/Footer/Footer";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { CvUploadModal } from "../../../../components/CvUploadModal/CvUploadModal";
import { scrollToTop } from "../../../../util/scrollToTop";
import { CvPersonalInfo } from "../../../../components/CvPersonalInfo/CvPersonalInfo";
import { formSteps } from "../../../../util/formStep";
import { NSCvFormStepType } from "../../../../components/CvFormStep/CvFormStep.types";
import { CvContact } from "../../../../components/CvContact/CvContact";
import { CvEducation } from "../../../../components/CvEducation/CvEducation";
import { CvSupplementary } from "../../../../components/CvSupplementary/CvSupplementary";
import { CVExperiences } from "../../../../components/CVExperiences/CVExperiences";
import { CVOther } from "../../../../components/CVOther/CVOther";
import { CvFormStep } from "../../../../components/CvFormStep/CvFormStep";

function CVPageMobile({ cvData }: Readonly<NSCVPageType.ICVPage>) {
  const isMobile = true;
  const [activeStep, setActiveStep] =
    useState<NSCvFormStepType.ICvFormStepItem>(
      formSteps.find(
        (item) => item.id === "kisisel-bilgiler"
      ) as NSCvFormStepType.ICvFormStepItem
    );

  const [showMenu, setShowMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const stepToForm: { [key: string]: JSX.Element } = {
    "kisisel-bilgiler": (
      <CvPersonalInfo
        onSubmitPersonalInfo={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "iletisim-bilgileri"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "iletisim-bilgileri": (
      <CvContact
        onSubmitContact={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "ogrenim-durumu"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "ogrenim-durumu": (
      <CvEducation
        onSubmitEducation={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "saglik-durumu"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),

    "tamamlayıcı-bilgiler": (
      <CvSupplementary
        onSubmitSupplementary={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "diger-alanlar"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "diger-alanlar": (
      <NSCVPageMobileStyle.OtherContainer>
        <CVExperiences
          onSubmitExperiences={(data) => {
            console.log("data", data);
          }}
        />
        <CVOther
          onSubmitOther={(data) => {
            console.log("data", data);
          }}
        />
      </NSCVPageMobileStyle.OtherContainer>
    ),
  };

  return (
    <NSCVPageMobileStyle.Container>
      <NSCVPageMobileStyle.LeftContainer>
        <NSCVPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={cvData.anchorList}
            socialLinks={cvData.footer.socialLinks}
          />
        </NSCVPageMobileStyle.RightContainer>
        <HeaderMobile
          logoClicked={() => {}}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
          backgroundColor={colors.blue.blue02}
        />
        <NSCVPageMobileStyle.StickyHelperContainer ref={stickyDeciderDifRef}>
          <InnerPageHeader
            children={""}
            isIcon={false}
            isMobile={isMobile}
            title="Özgeçmişim"
            backgroundColor={colors.blue.blue02}
          />
        </NSCVPageMobileStyle.StickyHelperContainer>

        <NSCVPageMobileStyle.MainContainer>
          <NSCVPageMobileStyle.PageTitle>
            Merhaba Burak,
          </NSCVPageMobileStyle.PageTitle>
          <NSCVPageMobileStyle.PageLabel>
            Aşağıdaki formu yıldızlı (*) alanlara dikkat ederek doldur ve
            özgeçmişini oluştur.
          </NSCVPageMobileStyle.PageLabel>
        </NSCVPageMobileStyle.MainContainer>
        <NSCVPageMobileStyle.Content>
          <CvFormStep
            activeStep={activeStep}
            onChangeStep={(value) => setActiveStep(value)}
          />

          {activeStep.id === "diger-alanlar" ? (
            <>
              <NSCVPageMobileStyle.OtherTitle>
                Ekleyebileceğin Diğer Alanlar
              </NSCVPageMobileStyle.OtherTitle>
              {stepToForm[activeStep.id]}
            </>
          ) : (
            <NSCVPageMobileStyle.FormContainer>
              <NSCVPageMobileStyle.FormTitle>
                {activeStep.title}
                {activeStep.isRequired && (
                  <NSCVPageMobileStyle.Star>*</NSCVPageMobileStyle.Star>
                )}
              </NSCVPageMobileStyle.FormTitle>

              {stepToForm[activeStep.id]}
            </NSCVPageMobileStyle.FormContainer>
          )}
        </NSCVPageMobileStyle.Content>

        <Footer {...cvData.footer} isMobile={isMobile} />
        <CircleButton onClick={scrollToTop} isMobile={isMobile} />
        <CvUploadModal
          onCloseHandler={() => setIsModalOpen(false)}
          isMobile={isMobile}
          onAcceptHandler={(file) => console.log("file", file)}
          onDeclineHandler={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        />
      </NSCVPageMobileStyle.LeftContainer>
    </NSCVPageMobileStyle.Container>
  );
}

export default CVPageMobile;
