import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./style/theme";
import GlobalStyle from "./style/globalStyle";
import Router from "./router/index";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
}

export default App;
