/* c8 ignore start */
const typography = {
  titleh1: {
    size: "64px",
    lineHeight: "140%",
  },
  titleh2: {
    size: "48px",
    lineHeight: "140%",
  },
  titleh3: {
    size: "40px",
    lineHeight: "140%",
  },
  titleh4: {
    size: "32px",
    lineHeight: "156%",
  },
  titleh5: {
    size: "24px",
    lineHeight: "156%",
  },
  pharagraph1: {
    size: "20px",
    lineHeight: "156%",
  },
  pharagraph1_5: {
    size: "18px",
    lineHeight: "156%",
  },
  pharagraph2: {
    size: "16px",
    lineHeight: "164%",
  },
  smallText1: {
    size: "14px",
    lineHeight: "164%",
  },
  smallText2: {
    size: "12px",
    lineHeight: "164%",
  },
  preText: {
    size: "10px",
    lineHeight: "164%",
  },
  fontWeights: {
    light: "300",
    regular: "400",
    medium: "500",
    bold: "700",
  },
  fontFamily: {
    bold: "ReadexProBold",
    regular: "ReadexPro",
  },
};

export default typography;
/* c8 ignore stop */
