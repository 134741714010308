import styled from "styled-components";
import colors from "../../../style/colors";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";
import { NSVideoPlayerCardStyle } from "../../../components/VideoPlayerCard/VideoPlayerCard.style";

export namespace NSKnowUsPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;

  export const MainContainer = styled.div`
    padding: 16px 16px 80px 16px;
    padding-bottom: 32px;
  `;

  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 32px;
    margin-bottom: 32px;
  `;
  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: ${colors.blue.blue01};
  `;

  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      left: -8px;
      top: -64px;
      z-index: 2;
    }
  `;
  export const RoundedCardAndVideoCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    ${NSVideoPlayerCardStyle.OuterContainer} {
      margin: 0px;
    }
  `;
  export const IconCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;
}
