import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSDrawerCardStyle {
  export const Container = styled.div`
    font-family: "ReadexPro";
    text-align: left;
  `;
  export const Title = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.pharagraph1_5.size : typography.titleh5.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.pharagraph1_5.lineHeight
        : typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    padding-right: ${({ isMobile }) => (isMobile ? "72px" : "64px")};
    margin-bottom: 24px;
  `;
  export const Description = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText2.lineHeight
        : typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    padding-bottom: 16px;
  `;
  export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  `;
  export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: ${typography.fontWeights.regular};
  `;
  export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const InnerTitle = styled.div`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    margin-bottom: 12px;
  `;
  export const ListItemContainer = styled.div<{ isMobile: boolean }>`
    padding: 24px 0px;
    border-bottom: 1px solid ${colors.orange.orange01};
    color: ${colors.dark.dark};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText2.lineHeight
        : typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
  `;
  export const ListText = styled.div``;
}
