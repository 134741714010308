import { useRef } from "react";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { HeaderDesktop } from "../../../../components/HeaderDesktop/HeaderDesktop";
import { NSPositionListPageDesktopStyle } from "./PositionListPageDesktop.style";
import useStickyElement from "../../../../util/useStickyElement";
import colors from "../../../../style/colors";
import { NSPositionListPageType } from "../PositionListPage.types";
import { Footer } from "../../../../components/Footer/Footer";
import { scrollToTop } from "../../../../util/scrollToTop";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import { PositionCard } from "../../../../components/PositionCard/PositionCard";
import { CareerDropdown } from "../../../../components/CareerDropdown/CareerDropdown";
import { Pagination } from "../../../../components/Pagination/Pagination";

function PositionListPageDesktop({
  positionListData,
}: Readonly<NSPositionListPageType.IPositionListPage>) {
  const isMobile = false;

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const renderHeader = () => (
    <HeaderDesktop
      logoClicked={() => {}}
      anchorList={positionListData.anchorList}
      isDark={isSticky}
      backgroundColor={"transparent"}
    />
  );

  return (
    <NSPositionListPageDesktopStyle.PageContainer>
      {isSticky && renderHeader()}
      <NSPositionListPageDesktopStyle.HeaderContainer ref={stickyDeciderDifRef}>
        <InnerPageHeader
          isMobile={isMobile}
          title="Kariyer Fırsatları"
          backgroundColor={colors.blue.blue02}
          iconColor={colors.blue.blue06}
        >
          {renderHeader()}
        </InnerPageHeader>
      </NSPositionListPageDesktopStyle.HeaderContainer>
      <NSPositionListPageDesktopStyle.MainContainer>
        <NSPositionListPageDesktopStyle.PageTitle>
          {`${positionListData.listCount} Açık pozisyon Listeleniyor`}
          <CareerDropdown onSelect={(item) => console.log(item)} />
        </NSPositionListPageDesktopStyle.PageTitle>
        <NSPositionListPageDesktopStyle.StyledContainer>
          <NSPositionListPageDesktopStyle.StyledRow justify="between">
            {positionListData.positionList.map((position) => (
              <NSPositionListPageDesktopStyle.StyledCol key={position.id}>
                <PositionCard
                  id={position.id}
                  tag={position.tag}
                  title={position.title}
                  locations={position.locations}
                  date={position.date}
                />
              </NSPositionListPageDesktopStyle.StyledCol>
            ))}
          </NSPositionListPageDesktopStyle.StyledRow>
        </NSPositionListPageDesktopStyle.StyledContainer>
        <Pagination
          currentPage={1}
          onClickNavigation={(position) => console.log(position)}
          pageCount={20}
        />
      </NSPositionListPageDesktopStyle.MainContainer>
      <Footer {...positionListData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSPositionListPageDesktopStyle.PageContainer>
  );
}

export default PositionListPageDesktop;
