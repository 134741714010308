import { useRef } from "react";

import { NSSustainWithUsDesktopStyle } from "./SustainWithUsDesktop.style";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { HeaderDesktop } from "../../../components/HeaderDesktop/HeaderDesktop";
import { Footer } from "../../../components/Footer/Footer";
import { NSSustainWithUsType } from "../SustainWithUs.types";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { AnimatedTextButton } from "../../../components/AnimatedTextButton/AnimatedTextButton";
import { Badge } from "../../../components/Badge/Badge";
import { StatementCard } from "../../../components/StatementCard/StatementCard";
import { TempSpacer } from "../../../util/tempSpacer";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { TextList } from "../../../components/TextList/TextList";
import { RoundedCardCarousel } from "../../../components/RoundedCardCarousel/RoundedCardCarousel";
import useStickyElement from "../../../util/useStickyElement";

function SustainWithUsDesktop({
  sustainWithUsData,
}: NSSustainWithUsType.ISustainWithUs) {
  const isMobile = false;
  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);

  const isSticky = useStickyElement(stickyDeciderDifRef);

  const renderHeader = () => (
    <HeaderDesktop
      logoClicked={() => {}}
      anchorList={sustainWithUsData.anchorList}
      isDark={isSticky}
      backgroundColor={"transparent"}
    />
  );
  return (
    <NSSustainWithUsDesktopStyle.Container>
      {isSticky && renderHeader()}
      <NSSustainWithUsDesktopStyle.HeaderContainer ref={stickyDeciderDifRef}>
        <InnerPageHeader
          isMobile={isMobile}
          title="Bizimle Sürdür"
          backgroundColor={colors.green.green01}
          iconColor={colors.green.green04}
          children={renderHeader()}
        />
      </NSSustainWithUsDesktopStyle.HeaderContainer>
      <NSSustainWithUsDesktopStyle.MainContainer>
        <NSSustainWithUsDesktopStyle.TitleBadgeContainer>
          <NSSustainWithUsDesktopStyle.TitleDescriptionContainer>
            <AnimatedTextButton
              {...sustainWithUsData.animatedTextButton}
              isMobile={isMobile}
            />
            <NSSustainWithUsDesktopStyle.Description>
              {sustainWithUsData.description1}
            </NSSustainWithUsDesktopStyle.Description>
          </NSSustainWithUsDesktopStyle.TitleDescriptionContainer>
          <Badge
            isMobile={isMobile}
            iconVariant="RECTANGLE"
            textVariant="SUSTAINWITHUS"
            color="GREEN"
          />
        </NSSustainWithUsDesktopStyle.TitleBadgeContainer>
        <TempSpacer height={98} />
        <StatementCard
          {...sustainWithUsData.statementCard}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <NSSustainWithUsDesktopStyle.TextContainer>
          <NSSustainWithUsDesktopStyle.Title>
            {sustainWithUsData.title2}
          </NSSustainWithUsDesktopStyle.Title>
          <NSSustainWithUsDesktopStyle.LowerDescription>
            {sustainWithUsData.description2}
          </NSSustainWithUsDesktopStyle.LowerDescription>
        </NSSustainWithUsDesktopStyle.TextContainer>
        <TempSpacer height={64} />
        <NSSustainWithUsDesktopStyle.TextContainer>
          <NSSustainWithUsDesktopStyle.Title>
            {sustainWithUsData.title3}
          </NSSustainWithUsDesktopStyle.Title>
          <NSSustainWithUsDesktopStyle.LowerDescription>
            {sustainWithUsData.description3}
          </NSSustainWithUsDesktopStyle.LowerDescription>
        </NSSustainWithUsDesktopStyle.TextContainer>
        <TempSpacer height={64} />
        <ImageCarousel
          {...sustainWithUsData.imageCarousel1}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <StatementCard
          {...sustainWithUsData.statementCard2}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <NSSustainWithUsDesktopStyle.TextContainer>
          <NSSustainWithUsDesktopStyle.Title>
            {sustainWithUsData.title4}
          </NSSustainWithUsDesktopStyle.Title>
          <NSSustainWithUsDesktopStyle.LowerDescription>
            {sustainWithUsData.description4}
          </NSSustainWithUsDesktopStyle.LowerDescription>
        </NSSustainWithUsDesktopStyle.TextContainer>
        <TempSpacer height={64} />
        <NSSustainWithUsDesktopStyle.TextContainer>
          <NSSustainWithUsDesktopStyle.Title>
            {sustainWithUsData.title5}
          </NSSustainWithUsDesktopStyle.Title>
          <NSSustainWithUsDesktopStyle.LowerDescription>
            {sustainWithUsData.description5}
          </NSSustainWithUsDesktopStyle.LowerDescription>
        </NSSustainWithUsDesktopStyle.TextContainer>
        <TempSpacer height={64} />
        <ImageCarousel
          {...sustainWithUsData.imageCarousel2}
          isMobile={isMobile}

        />
        <TempSpacer height={120} />
        <TextList {...sustainWithUsData.textList1} isMobile={isMobile} />

        <TempSpacer height={64} />
        <ImageCarousel
          {...sustainWithUsData.imageCarousel4}
          isMobile={isMobile}
        />
        <TempSpacer height={120} />
        <NSSustainWithUsDesktopStyle.TextContainer>
          <NSSustainWithUsDesktopStyle.Title>
            {sustainWithUsData.title6}
          </NSSustainWithUsDesktopStyle.Title>
          <NSSustainWithUsDesktopStyle.LowerDescription>
            {sustainWithUsData.description6}
          </NSSustainWithUsDesktopStyle.LowerDescription>
          <TempSpacer height={40} />
        </NSSustainWithUsDesktopStyle.TextContainer>
        <RoundedCardCarousel
          {...sustainWithUsData.roundedCardCarousel}
          isMobile={isMobile}
        />
        {/* <TempSpacer height={64} /> */}
        {/* <ImageCarousel
          {...sustainWithUsData.imageCarousel3}
          isMobile={isMobile}
        /> */}
        <CircleButton onClick={scrollToTop} isMobile={isMobile} />
      </NSSustainWithUsDesktopStyle.MainContainer>
      <Footer {...sustainWithUsData.footer} isMobile={isMobile} />
    </NSSustainWithUsDesktopStyle.Container>
  );
}

export default SustainWithUsDesktop;
