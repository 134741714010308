import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import { NSRoundedCardCarouselStyle } from "./RoundedCardCarousel.style";
import { NSRoundedCardCarouselType } from "./RoundedCardCarousel.types";
import { useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import colors from "../../style/colors";
import { RoundedCard } from "../RoundedCard/RoundedCard";
import "swiper/css";
import "swiper/css/scrollbar";
import { Drawer } from "../Drawer/Drawer";
import { NSRoundedCardType } from "../RoundedCard/RoundedCard.types";
import { MobileSwiperHelper } from "../MobileSwiperHelper/MobileSwiperHelper";

export const RoundedCardCarousel = ({
  isMobile,
  cardList,
}: NSRoundedCardCarouselType.IRoundedCardCarousel) => {
  const sliderRef = useRef<SwiperRef>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] =
    useState<NSRoundedCardType.IRoundedCard>(cardList[0]);

  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  const cardClickedHandler = (clickedCard: NSRoundedCardType.IRoundedCard) => {
    setSelectedCard(clickedCard);
    setIsDrawerOpen(true);
  };

  const renderCarousel = () => {
    if (isMobile) {
      return (
        <MobileSwiperHelper
          childrenList={cardList.map((card, index) => (
            <RoundedCard
              {...card}
              isMobile={isMobile}
              key={index}
              onClick={() =>
                card.onClick ? card.onClick() : cardClickedHandler(card)
              }
            />
          ))}
        />
      );
    } else {
      return (
        <NSRoundedCardCarouselStyle.Container isMobile={isMobile}>
          {!isMobile && (
            <NSRoundedCardCarouselStyle.NavigationButton onClick={handlePrev}>
              <ArrowLeft fill={colors.dark.dark} />
            </NSRoundedCardCarouselStyle.NavigationButton>
          )}
          <Swiper
            modules={[Navigation, Scrollbar]}
            spaceBetween={isMobile ? 8 : 20}
            slidesPerView={"auto"}
            scrollbar={{ draggable: true }}
            ref={sliderRef}
          >
            {cardList.map((card, index) => (
              <SwiperSlide key={index}>
                <RoundedCard
                  {...card}
                  isMobile={isMobile}
                  onClick={() =>
                    card.onClick ? card.onClick() : cardClickedHandler(card)
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {!isMobile && (
            <NSRoundedCardCarouselStyle.NavigationButton onClick={handleNext}>
              <ArrowRight fill={colors.dark.dark} />
            </NSRoundedCardCarouselStyle.NavigationButton>
          )}
        </NSRoundedCardCarouselStyle.Container>
      );
    }
  };

  return (
    <>
      {renderCarousel()}
      <Drawer
        isMobile={isMobile}
        isOpen={isDrawerOpen}
        children={selectedCard.children}
        onCloseHandler={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
