import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSCheckboxStyle {
  export const CheckboxContainer = styled.div`
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-family: "ReadexPro";
  `;

  export const Label = styled.label<{ fontSize?: number; lineHeight?: number }>`
    font-weight: 400;
    font-size: ${(props) => props.fontSize ?? 12}px;
    line-height: ${(props) => props.fontSize ?? 19.68}px;
    color: ${colors.dark.dark};
    cursor: pointer;
    display: flex;
    align-items: center;
  `;

  export const Icon = styled.svg`
    fill: none;
    stroke: ${colors.light.light};
    stroke-width: 2px;
  `;

  export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `;

  export const StyledCheckbox = styled.div<{
    checked: boolean;
    error?: boolean;
  }>`
    @keyframes dissolve {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    display: inline-block;
    width: 24px;
    height: 24px;
    background: ${(props) =>
      props.checked ? colors.blue.blue01 : "transparent"};
    border-radius: 8px;
    transition: background 150ms ease-out;
    cursor: pointer;
    animation: ${(props) => (props.checked ? "dissolve" : "none")} 300ms
      ease-out;

    border: ${(props) =>
      props.checked
        ? "2px solid " + colors.blue.blue01
        : "2px solid " + colors.neutrals.grey04};
    ${Icon} {
      visibility: ${(props) => (props.checked ? "visible" : "hidden")};
      opacity: ${(props) => (props.checked ? 1 : 0)};
      transition: opacity 300ms ease-out;
    }

    margin-right: 8px;
    ${(props) =>
      props.error &&
      !props.checked &&
      `border: 2px solid ${colors.error.error01}`};
  `;

  export const ErrorMessage = styled.div`
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    top: 58px;
    color: ${colors.error.error01};
    left: 0;
    background-color: transparent;
    position: absolute;
  `;
}
