import styled from "styled-components";
import typography from "../../style/typography";

export namespace NSSingleRoundedCardStyle {
  export const Container = styled.div<{
    isMobile: boolean;
    backgroundColor: string;
    textColor: string;
  }>`
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    padding: ${({ isMobile }) => (isMobile ? "32px" : "48px 64px")};
    background: ${({ backgroundColor }) => backgroundColor};
    font-family: "ReadexPro";
    width: ${({ isMobile }) => (isMobile ? "100%" : "50%")};
    color: ${({ textColor }) => textColor};
    border-radius: ${({ isMobile }) =>
      isMobile ? "32px" : "0px 128px 0px 0px"};

    font-size: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.size
        : typography.pharagraph1_5.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.pharagraph1_5.lineHeight
        : typography.pharagraph2.lineHeight};
    ul {
      list-style: disc;
      margin-left: 18px;
    }
    b {
      font-weight: ${typography.fontWeights.bold};
      font-family: ${typography.fontFamily.bold};
    }
    br {
      margin: 2.5em 0; /* FF for instance */
      line-height: 5em; /* chrome for instance */
    }
  `;

  export const Title = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${(props) =>
      props.isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.titleh5.lineHeight
        : typography.titleh4.lineHeight};
    margin-bottom: 16px;
  `;
  export const TitleIcon = styled.img`
    width: 160px;
    height: 112px;
  `;
}
