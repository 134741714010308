import { footerData, headerAnchorList } from "../../../util/headerFooterData";
import useDeviceType from "../../../util/isMobile";
import StatusPageDesktop from "./DesktopView/StatusPageDesktop";
import StatusPageMobile from "./MobileView/StatusPageMobile";
import { NSStatusPageType } from "./StatusPage.types";

const statusData: NSStatusPageType.IStatusPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
};

function StatusPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <StatusPageMobile statusData={statusData} />;
  } else {
    return <StatusPageDesktop statusData={statusData} />;
  }
}

export default StatusPage;
