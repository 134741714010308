import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { AuthCard } from "../../../../components/AuthCard/AuthCard";
import { Logo } from "../../../../components/Logo/Logo";
import { NSRegisterPageDesktopStyle } from "./RegisterPageDesktop.style";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "../../../../components/Input/Input";
import { PasswordConfirmation } from "../../../../components/PasswordConfirmation/PasswordConfirmation";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { Button } from "../../../../components/Button/Button";
import colors from "../../../../style/colors";
import { useNavigate } from "react-router-dom";
import { NSRegisterPageType } from "../RegisterPage.types";
import { SwitchButton } from "../../../../components/SwitchButton/SwitchButton";
import { useState } from "react";

type RegisterSchemaType = z.infer<typeof NSRegisterPageType.registerSchema>;

function RegisterPageDesktop({
  registerSchema,
  onSubmitRegister,
}: Readonly<NSRegisterPageType.IRegisterPageData>) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm<RegisterSchemaType>({
    resolver: zodResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<RegisterSchemaType> = (data) => {
    console.log("data", data);
    onSubmitRegister(data);
  };

  const password = useWatch({
    control,
    name: "password",
  });

  const confirmPassword = useWatch({
    control,
    name: "confirmPassword",
  });

  const kvkk = useWatch({
    control,
    name: "kvkk",
  });
  const termsOfUse = useWatch({
    control,
    name: "termsOfUse",
  });
  const referenceCommitment = useWatch({
    control,
    name: "referenceCommitment",
  });

  const firstButton = {
    children: "Giriş Yap",
    onClick: () => {
      navigate("/auth/login");
    },
  };
  const secondButton = {
    children: "Üye Ol",
    onClick: () => {
      navigate("/auth/register");
    },
  };

  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [showConfirmPasswordButton, setShowConfirmPasswordButton] =
    useState(false);

  return (
    <NSRegisterPageDesktopStyle.Container>
      <Logo isDark={true} isMobile={false} />
      <AuthCard isMobile={false}>
        <SwitchButton
          defaultSelectedButton="second"
          firstButton={firstButton}
          secondButton={secondButton}
        ></SwitchButton>
        <NSRegisterPageDesktopStyle.Form
          id="registerForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NSRegisterPageDesktopStyle.Title>
            Farklı fonksiyonlarımızdaki kariyer olanaklarına başvurmak için
            giriş yap.
          </NSRegisterPageDesktopStyle.Title>
          <NSRegisterPageDesktopStyle.InnerForm>
            <NSRegisterPageDesktopStyle.SideBySideInputContainer>
              <Input
                label="Ad"
                placeholder="Ad"
                val={watch("name")}
                {...register("name")}
                error={errors.name?.message}
              />
              <Input
                label="Soyad"
                placeholder="Soyad"
                val={watch("surname")}
                {...register("surname")}
                error={errors.surname?.message}
              />
            </NSRegisterPageDesktopStyle.SideBySideInputContainer>
            <Input
              label="E-posta"
              placeholder="E-posta"
              id="email"
              val={watch("email")}
              {...register("email")}
              error={errors.email?.message}
            />
            <NSRegisterPageDesktopStyle.SideBySideInputContainer>
              <Input
                label="Şifre"
                type={showPasswordButton ? "text" : "password"}
                suffixItem={
                  password && (
                    <Button
                      onClick={() => setShowPasswordButton(!showPasswordButton)}
                      variant="text"
                      color={colors.blue.blue01}
                      fontSize={12}
                      fontWeight={400}
                    >
                      {showPasswordButton ? "Gizle" : "Göster"}
                    </Button>
                  )
                }
                placeholder="Şifre"
                val={watch("password")}
                {...register("password")}
                error={errors.password?.message}
              />
              <Input
                label="Şifre Tekrarı"
                type={showConfirmPasswordButton ? "text" : "password"}
                suffixItem={
                  confirmPassword && (
                    <Button
                      onClick={() =>
                        setShowConfirmPasswordButton(!showConfirmPasswordButton)
                      }
                      variant="text"
                      color={colors.blue.blue01}
                      fontSize={12}
                      fontWeight={400}
                    >
                      {showConfirmPasswordButton ? "Gizle" : "Göster"}
                    </Button>
                  )
                }
                placeholder="Şifre Tekrarı"
                val={watch("confirmPassword")}
                {...register("confirmPassword")}
                error={errors.confirmPassword?.message}
              />
            </NSRegisterPageDesktopStyle.SideBySideInputContainer>
          </NSRegisterPageDesktopStyle.InnerForm>
          <PasswordConfirmation password={password} />
          <NSRegisterPageDesktopStyle.CheckboxContainer>
            <Checkbox
              error={!!errors.kvkk?.message}
              checked={kvkk ?? false}
              onChange={(value) => setValue("kvkk", value)}
              label={
                <NSRegisterPageDesktopStyle.CheckboxLabel>
                  <Button
                    onClick={() =>
                      console.log("Kişisel Verilerin Korunması Kanunu")
                    }
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Kişisel Verilerin Korunması Kanunu
                  </Button>
                  metnini okudum, onaylıyorum.
                </NSRegisterPageDesktopStyle.CheckboxLabel>
              }
              id="kvkk"
            />
            <Checkbox
              error={!!errors.termsOfUse?.message}
              checked={termsOfUse ?? false}
              onChange={(value) => setValue("termsOfUse", value)}
              label={
                <NSRegisterPageDesktopStyle.CheckboxLabel>
                  <Button
                    onClick={() => console.log("Kullanım Koşulları")}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Kullanım Koşulları
                  </Button>
                  metnini okudum, onaylıyorum.
                </NSRegisterPageDesktopStyle.CheckboxLabel>
              }
              id="termsOfUse"
            />
            <Checkbox
              error={!!errors.referenceCommitment?.message}
              checked={referenceCommitment ?? false}
              onChange={(value) => setValue("referenceCommitment", value)}
              label={
                <NSRegisterPageDesktopStyle.CheckboxLabel>
                  <Button
                    onClick={() => console.log("Referans Taahhüdünü")}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Referans taahhüdünü
                  </Button>
                  okudum, onaylıyorum
                </NSRegisterPageDesktopStyle.CheckboxLabel>
              }
              id="referenceCommitment"
            />
          </NSRegisterPageDesktopStyle.CheckboxContainer>
          <Button type="submit" variant="solid">
            Üye Ol
          </Button>
        </NSRegisterPageDesktopStyle.Form>
      </AuthCard>
      <NSRegisterPageDesktopStyle.FooterText>
        Doğuş Kariyer’e üyeysen eğer
        <Button
          onClick={() => navigate("/auth/login")}
          variant="text"
          color={colors.blue.blue01}
          fontSize={14}
        >
          Giriş Yap
        </Button>
      </NSRegisterPageDesktopStyle.FooterText>
    </NSRegisterPageDesktopStyle.Container>
  );
}

export default RegisterPageDesktop;
