import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSCvEducationStyle {
  export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: "ReadexPro";

    @media (max-width: 768px) {
      gap: 40px;
    }
  `;

  export const AskTitle = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 500;
    color: ${colors.dark.dark};
  `;

  export const Star = styled.span`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 400;
    color: ${colors.dark.dark};
  `;

  export const AskContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

  export const AskTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `;

  export const AskLabel = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 400;
    color: ${colors.neutrals.grey07};
  `;

  export const SideBySideContainer = styled.form`
    width: 100%;
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 40px;
    }
  `;

  export const EducationArrayContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  `;
  export const AddButtonContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  `;

  export const Seperator = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.neutrals.grey02};
  `;
}
