import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSMenuMobileStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: ${colors.blue.blue02};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    padding: 16px;
    box-sizing: border-box;
    color: ${colors.light.light};
    font-family: "ReadexPro";
    z-index: 4;
  `;
  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
  `;
  export const CloseButton = styled.button`
    all: unset;
    cursor: pointer;
    padding: 4px;
    svg {
      transform: rotate(45deg);
    }
  `;
  export const Footer = styled.div`
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    gap: 16px;
    display: flex;
    flex-direction: column;
  `;
  export const Content = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 32px;
    padding: 40px 0px 0px 32px;
    box-sizing: border-box;
  `;

  export const MenuAnchor = styled.button`
    all: unset;
    cursor: pointer;
    font-weight: 700;
    font-size: ${typography.titleh4.size};
    line-height: ${typography.titleh4.lineHeight};
  `;
  export const FooterHeader = styled.div`
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    display: flex;
    align-items: center;
    gap: 8px;
  `;

  export const SocialLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
  `;
  export const SocialLinkItem = styled.a`
    all: unset;
    cursor: pointer;
    svg {
      fill: ${colors.light.light};
    }
  `;
}
