import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSCvContactStyle {
  export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: "ReadexPro";
  `;

  export const SideBySideContainer = styled.form`
    width: 100%;
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 40px;
    }
  `;

  export const BottomToTopContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

  export const AskTitle = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 500;
    color: ${colors.dark.dark};
  `;

  export const Star = styled.span`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: 400;
    color: ${colors.dark.dark};
  `;
}
