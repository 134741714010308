import { z } from "zod";

export namespace NSCVExperiencesType {
  export type ICVExperiences = {
    onSubmitExperiences: (data: CVExperiencesInfoType) => void;
  };

  export const CVExperiencesInfoSchema = z
    .object({
      companyName: z.string().min(1, "İş Yeri adı zorunlu"),
      sector: z
        .object(
          {
            id: z.string(),
            label: z.string(),
          },
          {
            invalid_type_error: "Sektör zorunlu",
            required_error: "Sektör zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Sektör zorunlu",
        }),
      position: z.string().min(1, "Görev/Unvan zorunlu"),
      startDate: z.string().min(1, "Giriş Yılı zorunlu"),
      endDate: z.string().optional(),
    })
    .refine(
      (data) => {
        if (!data.endDate || !data.startDate) return true;
        return new Date(data.endDate) >= new Date(data.startDate);
      },
      {
        message: "Bitiş tarihi başlangıç tarihinden önce olamaz",
        path: ["endDate"],
      }
    );

  export const CVExperiencesSchema = z.object({
    experiences: z.array(CVExperiencesInfoSchema).optional(),
    internship: z.array(CVExperiencesInfoSchema).optional(),
  });

  export type CVExperiencesInfoType = z.infer<typeof CVExperiencesSchema>;
}
