import { useRef, useState } from "react";
import { AnimatedArrowButton } from "../../../components/AnimatedArrowButton/AnimatedArrowButton";
import { AnimatedTextButton } from "../../../components/AnimatedTextButton/AnimatedTextButton";
import { QuotationCard } from "../../../components/QuotationCard/QuotationCard";
import { NsHomePageType } from "../Homepage.types";
import { NSHomePageMobileStyle } from "./HomePageMobile.style";
import { Badge } from "../../../components/Badge/Badge";
import { HeadingText } from "../../../components/HeadingText/HeadingText";
import { BlockHeaderTitle } from "../../../components/BlockHeaderTitle/BlockHeaderTitle";
import colors from "../../../style/colors";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { RolloverImageGallery } from "../../../components/RolloverImageGallery/RolloverImageGallery";
import { Footer } from "../../../components/Footer/Footer";
import { HeaderMobile } from "../../../components/HeaderMobile/HeaderMobile";
import { AccordionOpportunitiesMobile } from "../../../components/AccordionOpportunitiesMobile/AccordionOpportunitiesMobile";
import { MenuMobile } from "../../../components/MenuMobile/MenuMobile";
import { VideoPlayerCarousel } from "../../../components/VideoPlayerCarousel/VideoPlayerCarousel";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { BrandImages } from "../../../components/BrandImages/BrandImages";
import { TempSpacer } from "../../../util/tempSpacer";
import useStickyElement from "../../../util/useStickyElement";
import AutoPlayCarousel from "../../../components/AutoPlayCarousel/AutoPlayCarousel";

function HomePageMobile({ homePageData }: NsHomePageType.IHomePage) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const onChangePage = () => {
    setShowMenu(false);
  };

  return (
    <NSHomePageMobileStyle.Container>
      <NSHomePageMobileStyle.LeftContainer>
        <NSHomePageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={onChangePage}
            onCloseClick={() => setShowMenu(false)}
            anchorList={homePageData.anchorList}
            socialLinks={homePageData.footer.socialLinks}
          />
        </NSHomePageMobileStyle.RightContainer>
        <NSHomePageMobileStyle.HeaderBackgroundHelper />
        <HeaderMobile
          logoClicked={homePageData.logoClicked}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
        />

        <NSHomePageMobileStyle.GrayContainer ref={stickyDeciderDifRef}>
          <NSHomePageMobileStyle.StyledFingerPrint fill={colors.dark.dark} />
          <NSHomePageMobileStyle.AnimatedButtonsContainer>
            <NSHomePageMobileStyle.AnimatedArrowButtonContainer>
              <AnimatedArrowButton
                label={homePageData.animatedArrowButtonLabel}
                isMobile={isMobile}
                onClick={homePageData.animatedArrowButtonClick}
              />
            </NSHomePageMobileStyle.AnimatedArrowButtonContainer>

            <AnimatedTextButton
              label={homePageData.textButtonLabel}
              changingSpeed={homePageData.textButtonChangingSpeed}
              animatedLabelList={homePageData.animatedLabelList}
              isMobile={isMobile}
              isVertical={true}
            />
          </NSHomePageMobileStyle.AnimatedButtonsContainer>

          <NSHomePageMobileStyle.SliderContainer>
            <AutoPlayCarousel
              imageList={homePageData.slidingBannerData}
              isMobile={isMobile}
            />
            <AutoPlayCarousel
              imageList={homePageData.slidingBannerReverseData}
              isMobile={isMobile}
              isReverseProp={true}
            />
          </NSHomePageMobileStyle.SliderContainer>
        </NSHomePageMobileStyle.GrayContainer>
        <NSHomePageMobileStyle.MainContainer>
          <QuotationCard
            isMobile={isMobile}
            largeText={homePageData.quoteLargeText}
            subTitle={homePageData.quoteSubTitle}
            secondSubTitle={homePageData.quoteSecondSubTitle}
          />
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Neler Yaptığımızı Bizden Dinlemek İster Misin?"
            description="Farklı fonksiyonlarımızda görev alan çalışma arkadaşlarımızın deneyimlerini dinlemek için videolarımızı izleyebilirsin."
            children={
              <BlockHeaderTitle
                isMobile={isMobile}
                title="Çalışma Arkadaşlarımızın Hikayeleri"
                backgroundColor={colors.blue.blue01}
                titleColor={colors.dark.dark}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/@dogusotomotiv4354/videos",
                    "_blank"
                  )
                }
              />
            }
          />
          <TempSpacer height={120} />
          <NSHomePageMobileStyle.VideoCarouselBadgeContainer>
            <Badge
              isMobile={isMobile}
              textVariant="KNOWUS"
              color="BLUE"
              iconVariant="ARROW"
              speed={15}
              onClick={() => window.open("/know-us", "_self")}
            />
            <VideoPlayerCarousel
              {...homePageData.videoPlayerCarousel}
              isMobile={isMobile}
            />
          </NSHomePageMobileStyle.VideoCarouselBadgeContainer>
          <TempSpacer height={80} />
          <HeadingText
            isMobile={isMobile}
            title="Keyifli Bir Çalışma Ortamı Seni Bekliyor."
            description="Yeni nesil paylaşımlı ofis alanlarında hibrit modelde çalışabilir, ortak alanlarda dilediğin gibi sosyalleşebilirsin."
            children={
              <BlockHeaderTitle
                isMobile={isMobile}
                title="Şirkette Yaşam"
                backgroundColor={colors.green.green01}
                titleColor={colors.dark.dark}
                onClick={() => window.open("/know-us", "_self")}
                fullWidth={false}
              />
            }
          />
          <TempSpacer height={120} />
          <NSHomePageMobileStyle.CarouselBadgeContainer>
            <Badge
              isMobile={isMobile}
              textVariant="KNOWUS"
              color="GREEN"
              iconVariant="ASTRIX"
              onClick={() => window.open("/know-us", "_self")}
            />
            <ImageCarousel
              imageList={homePageData.imageCarouselData}
              isMobile={isMobile}
              displayImagePreview={true}
            />
          </NSHomePageMobileStyle.CarouselBadgeContainer>
          <TempSpacer height={80} />
          <AccordionOpportunitiesMobile
            title="Bizimle Keşfetmeye Hazır Mısın?"
            opportunityList={homePageData.opportunityList}
          />
          <TempSpacer height={80} />
          <RolloverImageGallery
            isMobile={isMobile}
            cardList={homePageData.rolloverImageData}
          />
          <NSHomePageMobileStyle.HorizontalLine />
          <BrandImages {...homePageData.brandImages} isMobile={isMobile} />
        </NSHomePageMobileStyle.MainContainer>

        <Footer {...homePageData.footer} isMobile={isMobile} />
      </NSHomePageMobileStyle.LeftContainer>

      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSHomePageMobileStyle.Container>
  );
}

export default HomePageMobile;
