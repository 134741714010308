import styled from "styled-components";
import colors from "../../../style/colors";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";

export namespace NSKnowUsPageDesktopStyle {
  export const Container = styled.div`
    overflow-x: clip;
  `;
  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 104px;
    background-color: ${colors.blue.blue01};
  `;
  export const MainContainer = styled.div`
    padding: 84px 112px;
    background-color: ${colors.background.bg};
    position: relative;
    overflow: hidden;
    padding-bottom: 32px;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 32px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 32px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 32px;
    }
  `;

  export const HeaderContainer = styled.div`
    position: relative;
  `;
  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      left: 19px;
      top: -148px;
      z-index: 2;
    }
  `;
  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 32px;
    margin-bottom: 32px;
  `;
  export const RoundedCardAndVideoCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
  `;
  export const IconCardGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 40px;
    justify-content: space-between;
  `;
}
