import styled from "styled-components";
import colors from "../../../style/colors";
import { ReactComponent as Fingerprint } from "../../../assets/icons/fingerprint.svg";
import { NSBadgeStyle } from "../../../components/Badge/Badge.style";

export namespace NSHomePageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const GrayContainer = styled.div`
    background-color: ${colors.neutrals.grey07};
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 63px);
  `;
  export const AnimatedButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 16px 48px 16px;
    z-index: 4;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    gap: 72px;
  `;
  export const AnimatedArrowButtonContainer = styled.div`
    margin-left: auto;
  `;
  export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 32px;
  `;
  export const MainContainer = styled.div`
    padding: 80px 16px;
    padding-bottom: 32px;
  `;
  export const HeightPaddingHelperTemp = styled.div`
    height: 3600px;
    width: 1px;
  `;
  export const StyledFingerPrint = styled(Fingerprint)`
    position: absolute;
    align-self: center;
    top: 16px;
    width: 70%;
    max-height: 40vh;
  `;

  export const CarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      right: 0px;
      top: -102px;
      z-index: 1;
    }
  `;
  export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.neutrals.grey04};
    margin-top: 32px;
    margin-bottom: 32px;
  `;

  export const HeaderBackgroundHelper = styled.div`
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: ${colors.neutrals.grey07};
    z-index: -1;
  `;
  export const VideoCarouselBadgeContainer = styled.div`
    position: relative;
    ${NSBadgeStyle.Container} {
      position: absolute;
      left: -12px;
      top: -64px;
      z-index: 2;
    }
  `;
}
