import { MobileSwiperHelper } from "../MobileSwiperHelper/MobileSwiperHelper";
import { NSArrowNumberedListStyle } from "./ArrowNumberedList.style";
import { NSArrowNumberedListType } from "./ArrowNumberedList.types";
export const ArrowNumberedList = ({
  cardList,
  isMobile,
}: NSArrowNumberedListType.IArrowNumberedList) => {
  const renderListDesktop = () =>
    cardList.map((card, index) => (
      <NSArrowNumberedListStyle.Container>
        <NSArrowNumberedListStyle.IconContainer>
          {card.number}
        </NSArrowNumberedListStyle.IconContainer>
        <NSArrowNumberedListStyle.Title>
          {card.title}
        </NSArrowNumberedListStyle.Title>
        <NSArrowNumberedListStyle.Description>
          {card.description}
        </NSArrowNumberedListStyle.Description>

        <>
          {index === 0 && <NSArrowNumberedListStyle.StyledFirstArrow />}
          {index === 4 && <NSArrowNumberedListStyle.StyledSecondArrow />}
          {index === 4 && <NSArrowNumberedListStyle.StyledThirdArrow />}
        </>
      </NSArrowNumberedListStyle.Container>
    ));

  const renderSwiperMobile = () => {
    return (
      <MobileSwiperHelper
        childrenList={cardList.map((card) => (
          <NSArrowNumberedListStyle.Container>
            <NSArrowNumberedListStyle.IconContainer>
              {card.number}
            </NSArrowNumberedListStyle.IconContainer>
            <NSArrowNumberedListStyle.Title>
              {card.title}
            </NSArrowNumberedListStyle.Title>
            <NSArrowNumberedListStyle.Description>
              {card.description}
            </NSArrowNumberedListStyle.Description>
          </NSArrowNumberedListStyle.Container>
        ))}
      />
    );
  };

  const renderDecider = () => {
    if (isMobile) {
      return renderSwiperMobile();
    } else {
      return renderListDesktop();
    }
  };

  return (
    <NSArrowNumberedListStyle.MainContainer isMobile={isMobile}>
      {renderDecider()}
    </NSArrowNumberedListStyle.MainContainer>
  );
};
