import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSStatusPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 16px 16px 32px 16px;

    @media (max-width: 1200px) {
      padding: 0px;
    }
  `;

  export const PageTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
  `;

  export const PageDesc = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.96px;
    text-align: left;
    margin: 4px 0px 0px 0px;
  `;

  export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${colors.light.light};
    flex-direction: column;
    padding: 24px 16px 0px 16px;
  `;
}
