import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "../Input/Input";
import { NSCvSupplementaryStyle } from "./CvSupplementary.style";
import { NSCvSupplementaryType } from "./CvSupplementary.types";
import { zodResolver } from "@hookform/resolvers/zod";
import { SelectBox } from "../SelectBox/SelectBox";
import { Button } from "../Button/Button";
import { RadioButton } from "../RadioButton/RadioButton";
import { DatePicker } from "../DatePicker/DatePicker";
import { cities } from "../../util/formDropdownItems";
import { scrollToTop } from "../../util/scrollToTop";

export const CvSupplementary = ({
  onSubmitSupplementary,
}: NSCvSupplementaryType.ICvSupplementary) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<NSCvSupplementaryType.CvSupplementaryInfoType>({
    resolver: zodResolver(NSCvSupplementaryType.CvSupplementarySchema),
  });

  const onSubmit: SubmitHandler<
    NSCvSupplementaryType.CvSupplementaryInfoType
  > = (data) => {
    onSubmitSupplementary(data);
  };

  return (
    <NSCvSupplementaryStyle.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      id="CvSupplementary"
    >
      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          Ücret beklentin (TL / Ay - Net)
        </NSCvSupplementaryStyle.AskTitle>

        <Input
          label="Ücret"
          val={watch("expectedSalary")}
          placeholder="Ücret"
          {...register("expectedSalary")}
          error={errors.expectedSalary?.message}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          Çalışmayı tercih ettiğin şehirler (En fazla 5 şehir)
        </NSCvSupplementaryStyle.AskTitle>

        <SelectBox
          placeholder="Seçiniz"
          type="withCheckbox"
          items={cities}
          onChangeChecked={(items) => setValue("preferredCity", items)}
          error={errors.preferredCity?.message}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          İş gereği seyahat etmek konusunda bir kısıtın var mı?
        </NSCvSupplementaryStyle.AskTitle>

        <RadioButton
          options={[
            { label: "Evet", value: "yes", id: "yes" },
            { label: "Hayır", value: "no", id: "no" },
          ]}
          error={errors.travelRestrictions?.message}
          onChange={(item) => setValue("travelRestrictions", item.value)}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          İşkur kaydın var mı?
        </NSCvSupplementaryStyle.AskTitle>

        <RadioButton
          options={[
            { label: "Evet", value: "yes", id: "yes" },
            { label: "Hayır", value: "no", id: "no" },
          ]}
          error={errors.iskurRegistration?.message}
          onChange={(item) => setValue("iskurRegistration", item.value)}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          İşe başlayabileceğin tarih
        </NSCvSupplementaryStyle.AskTitle>
        <DatePicker
          placeholder="GG / AA / YYYY"
          minDate={new Date()}
          maxDate={null}
          onChange={(date) =>
            setValue("availableStartingDate", date?.toDateString())
          }
          error={errors.availableStartingDate?.message}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <NSCvSupplementaryStyle.AskContainer>
        <NSCvSupplementaryStyle.AskTitle>
          Başvuru talebine göre diğer Doğuş Grubu şirketlerine gönderilebilir
          mi? <NSCvSupplementaryStyle.Star>*</NSCvSupplementaryStyle.Star>
        </NSCvSupplementaryStyle.AskTitle>

        <RadioButton
          error={errors.sentToOtherCompany?.message}
          options={[
            { label: "Evet", value: "yes", id: "yes" },
            { label: "Hayır", value: "no", id: "no" },
          ]}
          onChange={(item) => setValue("sentToOtherCompany", item.value)}
        />
      </NSCvSupplementaryStyle.AskContainer>

      <Button
        width={105}
        height={56}
        type="submit"
        variant="solid"
        onClick={scrollToTop}
      >
        Kaydet
      </Button>
    </NSCvSupplementaryStyle.FormContainer>
  );
};
