import React from "react";
import { NSInfoCardStyle } from "./InfoCard.style";
import { NSInfoCardType } from "./InfoCard.types";
import { JobInfoCardContainer } from "../JobInfoCardContainer/JobInfoCardContainer";

export const InfoCard = ({
  work,
  company,
  positionLevel,
  locations,
  referansNo,
  date,
  isMobile,
}: NSInfoCardType.IInfoCard) => {
  const InfoItems = [
    {
      title: "İş Alanı",
      desc: work,
    },
    {
      title: "Şirket",
      desc: company,
    },
    {
      title: "Pozisyon Seviyesi",
      desc: positionLevel,
    },
    {
      title: "Şehir",
      desc: locations,
    },
    {
      title: "Referans No",
      desc: referansNo,
    },
    {
      title: "Son Başvuru",
      desc: date,
    },
  ];
  return (
    <JobInfoCardContainer isMobile={isMobile}>
      <NSInfoCardStyle.Container>
        {InfoItems.map((InfoItem, index) => (
          <React.Fragment key={index}>
            {InfoItem.title === "Şehir" && Array.isArray(InfoItem.desc) ? (
              <>
                <NSInfoCardStyle.Item>
                  <NSInfoCardStyle.Title>
                    {InfoItem.title}:
                  </NSInfoCardStyle.Title>
                  {InfoItem.desc.map((location, idx) => (
                    <NSInfoCardStyle.LocationItem key={idx}>
                      {location}
                    </NSInfoCardStyle.LocationItem>
                  ))}
                </NSInfoCardStyle.Item>
                {index !== InfoItems.length - 1 && (
                  <NSInfoCardStyle.Separator />
                )}
              </>
            ) : (
              <>
                <NSInfoCardStyle.Item>
                  <NSInfoCardStyle.Title>
                    {InfoItem.title}
                  </NSInfoCardStyle.Title>
                  <NSInfoCardStyle.Desc>{InfoItem.desc}</NSInfoCardStyle.Desc>
                </NSInfoCardStyle.Item>
                {index !== InfoItems.length - 1 && (
                  <NSInfoCardStyle.Separator />
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </NSInfoCardStyle.Container>
    </JobInfoCardContainer>
  );
};
