import { useState } from "react";
import { NSAccordionOpportunitiesDesktopStyle } from "./AccordionOpportunitiesDesktop.style";
import { NSAccordionOpportunitiesDesktopType } from "./AccordionOpportunitiesDesktop.types";
import { ReactComponent as Arrow } from "../../assets/icons/pointedArrowRight.svg";
import colors from "../../style/colors";
import { Badge } from "../Badge/Badge";

export const AccordionOpportunitiesDesktop = ({
  opportunityList,
  title,
}: NSAccordionOpportunitiesDesktopType.IAccordionOpportunitiesDesktop) => {
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<NSAccordionOpportunitiesDesktopType.IOpportunityItem>(
      opportunityList[0]
    );

  const onOptionClickedHandler = (
    selectedItem: NSAccordionOpportunitiesDesktopType.IOpportunityItem
  ) => {
    setSelectedOpportunity(selectedItem);
  };

  const onClickHelper = {
    KNOWUS: () => window.open("/know-us", "_self"),
    JOINUS: () => window.open("/join-us", "_self"),
    LEARNWITHUS: () => window.open("/learn-with-us", "_self"),
    SUSTAINWITHUS: () => window.open("/sustain-with-us", "_self"),
    LISTENUS: undefined,
  };

  const renderOpportunityOptionList = () => {
    return opportunityList.map((item) => (
      <NSAccordionOpportunitiesDesktopStyle.OptionItem
        isSelected={item === selectedOpportunity}
        key={item.title}
        onClick={() => onOptionClickedHandler(item)}
      >
        {item.title}
        <NSAccordionOpportunitiesDesktopStyle.OrbArrow>
          <Arrow fill={colors.light.light} />
        </NSAccordionOpportunitiesDesktopStyle.OrbArrow>
      </NSAccordionOpportunitiesDesktopStyle.OptionItem>
    ));
  };

  return (
    <NSAccordionOpportunitiesDesktopStyle.Container>
      <NSAccordionOpportunitiesDesktopStyle.Column>
        <NSAccordionOpportunitiesDesktopStyle.Title>
          {title}
        </NSAccordionOpportunitiesDesktopStyle.Title>
        {renderOpportunityOptionList()}
      </NSAccordionOpportunitiesDesktopStyle.Column>
      <NSAccordionOpportunitiesDesktopStyle.Column>
        <NSAccordionOpportunitiesDesktopStyle.CurvedCard>
          {selectedOpportunity.badge && (
            <Badge
              speed={selectedOpportunity.badge.speed}
              iconVariant={selectedOpportunity.badge.iconVariant}
              textVariant={selectedOpportunity.badge.textVariant}
              color={selectedOpportunity.badge.color}
              isMobile={selectedOpportunity.badge.isMobile}
              onClick={onClickHelper[selectedOpportunity.badge.textVariant]}
            />
          )}
          <NSAccordionOpportunitiesDesktopStyle.IllustrationContainer>
            {selectedOpportunity.image}
          </NSAccordionOpportunitiesDesktopStyle.IllustrationContainer>
          <NSAccordionOpportunitiesDesktopStyle.Description>
            {selectedOpportunity.description}
          </NSAccordionOpportunitiesDesktopStyle.Description>
          {selectedOpportunity.button && (
            <NSAccordionOpportunitiesDesktopStyle.Button
              onClick={selectedOpportunity.button.onClick}
            >
              {selectedOpportunity.button.label}
            </NSAccordionOpportunitiesDesktopStyle.Button>
          )}
        </NSAccordionOpportunitiesDesktopStyle.CurvedCard>
      </NSAccordionOpportunitiesDesktopStyle.Column>
    </NSAccordionOpportunitiesDesktopStyle.Container>
  );
};
