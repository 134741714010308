import { useRef, useState } from "react";
import { NSIconDescriptionListStyle } from "./IconDescriptionList.style";
import { NSIconDescriptionListType } from "./IconDescriptionList.types";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import colors from "../../style/colors";
export const IconDescriptionList = ({
  isMobile,
  mainTitle,
  statsList,
  showOrb = true,
}: NSIconDescriptionListType.IIconDescriptionList) => {
  const [expanded, setExpanded] = useState(false);
  const statNameRef = useRef<HTMLDivElement>(null);

  const handleToggleExpansion = () => {
    setExpanded(!expanded);
  };
  const renderStatsList = () =>
    statsList.map((stat) => (
      <NSIconDescriptionListStyle.StatsCard
        isMobile={isMobile}
        key={stat.title}
      >
        {showOrb && (
          <NSIconDescriptionListStyle.OrbIconContainer isMobile={isMobile}>
            {stat.icon}
          </NSIconDescriptionListStyle.OrbIconContainer>
        )}
        {!showOrb && typeof stat.icon === "string" && (
          <NSIconDescriptionListStyle.ImageWithoutOrb src={stat.icon} />
        )}
        <NSIconDescriptionListStyle.StatTextContainer>
          <NSIconDescriptionListStyle.StatNumber isMobile={isMobile}>
            {stat.title}
          </NSIconDescriptionListStyle.StatNumber>
          <NSIconDescriptionListStyle.StatName isMobile={isMobile}>
            {stat.description}
          </NSIconDescriptionListStyle.StatName>
        </NSIconDescriptionListStyle.StatTextContainer>
      </NSIconDescriptionListStyle.StatsCard>
    ));

  const renderStatsListMobile = () => (
    <NSIconDescriptionListStyle.MobileMainContainer>
      <NSIconDescriptionListStyle.MobileGridContainer>
        {renderStatsListItemsMobile(0, 3)}
      </NSIconDescriptionListStyle.MobileGridContainer>
      <NSIconDescriptionListStyle.StatsContainerMobile>
        {renderStatsListItemsMobile(4, 5)}

        <NSIconDescriptionListStyle.ShowMoreButton
          expanded={expanded}
          onClick={handleToggleExpansion}
        >
          {expanded ? "Daha az göster" : "Daha fazla göster"}
          <ChevronDown color={colors.dark.dark} />
        </NSIconDescriptionListStyle.ShowMoreButton>
      </NSIconDescriptionListStyle.StatsContainerMobile>
    </NSIconDescriptionListStyle.MobileMainContainer>
  );

  const renderStatsListItemsMobile = (start: number, end: number) => {
    return statsList.map(
      (stat, index) =>
        index >= start &&
        index <= end && (
          <NSIconDescriptionListStyle.StatsCard
            isMobile={isMobile}
            key={stat.title}
          >
            {showOrb && (
              <NSIconDescriptionListStyle.OrbIconContainer isMobile={isMobile}>
                {stat.icon}
              </NSIconDescriptionListStyle.OrbIconContainer>
            )}
            {!showOrb && typeof stat.icon === "string" && (
              <NSIconDescriptionListStyle.ImageWithoutOrb src={stat.icon} />
            )}
            <NSIconDescriptionListStyle.StatTextContainer>
              <NSIconDescriptionListStyle.StatNumber isMobile={isMobile}>
                {stat.title}
              </NSIconDescriptionListStyle.StatNumber>
              <NSIconDescriptionListStyle.StatName
                isMobile={isMobile}
                expanded={expanded}
                ref={statNameRef}
              >
                {stat.description}
              </NSIconDescriptionListStyle.StatName>
            </NSIconDescriptionListStyle.StatTextContainer>
          </NSIconDescriptionListStyle.StatsCard>
        )
    );
  };

  return (
    <NSIconDescriptionListStyle.Container isMobile={isMobile}>
      <NSIconDescriptionListStyle.Title isMobile={isMobile}>
        {mainTitle}
      </NSIconDescriptionListStyle.Title>
      {isMobile && renderStatsListMobile()}
      {!isMobile && (
        <NSIconDescriptionListStyle.StatsContainerDesktop>
          {renderStatsList()}
        </NSIconDescriptionListStyle.StatsContainerDesktop>
      )}
    </NSIconDescriptionListStyle.Container>
  );
};
