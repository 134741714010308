import styled from "styled-components";
import colors from "../../../../style/colors";
import { Container, Row, Col } from "react-grid-system";

export namespace NSApplicationsPageDesktopStyle {
  export const PageContainer = styled.div`
    box-sizing: border-box;
    font-family: "ReadexPro";
  `;

  export const StyledContainer = styled(Container)`
    margin: 0;
  `;

  export const StyledRow = styled(Row)`
    justify-content: flex-start !important;
    gap: 24px;
    margin: 0;
    padding: 0;
  `;

  export const StyledCol = styled(Col)`
    width: 300px !important;
    padding: 0;
    flex: unset !important;
    max-width: 300px !important ;
    padding: 0 !important;
  `;

  export const MainContainer = styled.div`
    padding: 84px 112px;
    overflow: hidden;
    background-color: ${colors.background.bg};
    position: relative;
    display: flex;
    flex-direction: row;
    padding-bottom: 120px;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 40px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 40px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 40px;
    }
  `;

  export const Content = styled.div`
    margin-left: 32px;
  `;

  export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin: -5px 0px 32px 0px;
  `;
}
