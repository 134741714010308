import { useState } from "react";
import { NSAccordionOpportunitiesMobileStyle } from "./AccordionOpportunitiesMobile.style";
import { NSAccordionOpportunitiesMobileType } from "./AccordionOpportunitiesMobile.types";
import { ReactComponent as Arrow } from "../../assets/icons/arrowRight.svg";

import colors from "../../style/colors";

export const AccordionOpportunitiesMobile = ({
  opportunityList,
  title,
}: NSAccordionOpportunitiesMobileType.IAccordionOpportunitiesMobile) => {
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<NSAccordionOpportunitiesMobileType.IOpportunityItem | null>();

  const onOptionClickedHandler = (
    selectedItem: NSAccordionOpportunitiesMobileType.IOpportunityItem
  ) => {
    if (selectedItem === selectedOpportunity) {
      setSelectedOpportunity(null);
    } else {
      setSelectedOpportunity(selectedItem);
    }
  };

  const renderOpportunityOptionList = () => {
    return opportunityList.map((item) => (
      <>
        <NSAccordionOpportunitiesMobileStyle.OptionItem
          isSelected={item === selectedOpportunity}
          key={item.title}
          onClick={() => onOptionClickedHandler(item)}
        >
          {item.title}
          <NSAccordionOpportunitiesMobileStyle.OrbArrow>
            <Arrow fill={colors.light.light} />
          </NSAccordionOpportunitiesMobileStyle.OrbArrow>
        </NSAccordionOpportunitiesMobileStyle.OptionItem>

        <NSAccordionOpportunitiesMobileStyle.CurvedCard
          isSelected={item === selectedOpportunity}
        >
          {item === selectedOpportunity && (
            <>
              <NSAccordionOpportunitiesMobileStyle.IllustrationContainer>
                {selectedOpportunity?.image}
              </NSAccordionOpportunitiesMobileStyle.IllustrationContainer>
              <NSAccordionOpportunitiesMobileStyle.Description>
                {selectedOpportunity?.description}
              </NSAccordionOpportunitiesMobileStyle.Description>
              {selectedOpportunity.button && (
                <NSAccordionOpportunitiesMobileStyle.Button
                  onClick={selectedOpportunity.button.onClick}
                >
                  {selectedOpportunity.button.label}
                </NSAccordionOpportunitiesMobileStyle.Button>
              )}
            </>
          )}
        </NSAccordionOpportunitiesMobileStyle.CurvedCard>
      </>
    ));
  };

  return (
    <NSAccordionOpportunitiesMobileStyle.Container>
      <NSAccordionOpportunitiesMobileStyle.Column>
        <NSAccordionOpportunitiesMobileStyle.Title>
          {title}
        </NSAccordionOpportunitiesMobileStyle.Title>
        {renderOpportunityOptionList()}
      </NSAccordionOpportunitiesMobileStyle.Column>
    </NSAccordionOpportunitiesMobileStyle.Container>
  );
};
