import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

export namespace NSChangePasswordType {
  export type IChangePassword = {
    onSubmitChangePsw: SubmitHandler<NSChangePasswordType.ChangePasswordType>;
  };

  export const changePasswordSchema = z
    .object({
      password: z
        .string()
        .min(1, "Şifre zorunlu")
        .min(8, "Şifren en az 8 karakterden oluşmalı")
        .regex(new RegExp(".*[A-Z].*"), "En az 1 adet büyük harf içermeli")
        .regex(new RegExp(".*[a-z].*"), "En az 1 adet küçük harf içermeli")
        .regex(new RegExp(".*\\d.*"), "En az 1 adet rakam içermeli")
        .regex(
          new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
          "En az 1 özel karakter içermeli"
        ),
      confirmPassword: z.string().min(1, "Şifre tekrarı zorunlu"),
      currentPassword: z.string().min(1, "Mevcut şifre zorunlu"),
    })
    .refine((data) => data.password !== data.currentPassword, {
      path: ["password"],
      message: "Mevcut şifreniz ile yeni şifreniz aynı olamaz",
    })
    .refine((data) => data.password === data.confirmPassword, {
      path: ["confirmPassword"],
      message: "Şifreler uyuşmuyor",
    });

  export type ChangePasswordType = z.infer<typeof changePasswordSchema>;
}
