import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { NSFooterBarStyle } from "./FooterBar.style";
import { NSFooterBarType } from "./FooterBar.types";

export const FooterBar = ({
  copyRightTitle,
  termsUrl,
  cookiePolicyUrl,
  isMobile,
}: NSFooterBarType.IFooterBar) => {
  return (
    <NSFooterBarStyle.Container isMobile={isMobile}>
      <NSFooterBarStyle.LeftContainer isMobile={isMobile}>
        <NSFooterBarStyle.CopyRightText>
          {copyRightTitle}
        </NSFooterBarStyle.CopyRightText>
        <NSFooterBarStyle.LinksContainer isMobile={isMobile}>
          <NSFooterBarStyle.CopyRightLinks
            isMobile={isMobile}
            href={termsUrl}
            target="_blank"
          >
            Kullanım Koşulları
          </NSFooterBarStyle.CopyRightLinks>
          {"  "}|{"  "}
          <NSFooterBarStyle.CopyRightLinks
            href={cookiePolicyUrl}
            target="_blank"
            isMobile={isMobile}
          >
            Çerez Politikası
          </NSFooterBarStyle.CopyRightLinks>
        </NSFooterBarStyle.LinksContainer>
      </NSFooterBarStyle.LeftContainer>
      <NSFooterBarStyle.RightContainer>
        Doğuş Kariyer
        <Logo width={isMobile ? 54 : 68} height={isMobile ? 18 : 22} />
        markasıdır.
      </NSFooterBarStyle.RightContainer>
    </NSFooterBarStyle.Container>
  );
};
