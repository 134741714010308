import { NSLogoStyle } from "./Logo.style";
import { NSLogoType } from "./Logo.types";
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg";
import colors from "../../style/colors";

export const Logo = ({
  isMobile,
  onClick,
  isDark = false,
}: NSLogoType.ILogo) => {
  return (
    <NSLogoStyle.Container isMobile={isMobile} onClick={onClick} href="/">
      <LogoSvg
        height={isMobile ? 31 : 38}
        width={isMobile ? 97 : 118}
        fill={isDark ? colors.dark.dark : colors.light.light}
      />
      <NSLogoStyle.VerticalLine isMobile={isMobile} isDark={isDark} />
      <NSLogoStyle.Title isMobile={isMobile} isDark={isDark}>
        Kariyer
      </NSLogoStyle.Title>
    </NSLogoStyle.Container>
  );
};
