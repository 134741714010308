import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSAnimatedTextButtonStyle {
  export const DesktopContainer = styled.div<{
    width: number;
    isSmall: boolean;
  }>`
    background-color: ${colors.light.light};
    box-shadow: 0px 4px 32px rgba(82, 198, 236, 0.24);
    padding: 0px 32px;
    border-radius: 128px;
    display: inline-flex;
    position: relative;
    height: 96px;
    align-items: center;
    font-size: ${typography.titleh3.size};
    line-height: ${typography.titleh3.lineHeight};
    font-family: "ReadexPro";
    width: ${(props) =>
      props.width === 0 ? "fit-content" : `${props.width + 84}px`};
    transition: width 0.5s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    ${({ isSmall }) =>
      isSmall &&
      css`
        height: 88px;
        font-size: ${typography.titleh4.size};
        line-height: ${typography.titleh4.lineHeight};
      `}
  `;
  export const MobileContainer = styled.div<{ isSmall: boolean }>`
    font-family: "ReadexPro";
    font-size: ${typography.titleh4.size};
    ${({ isSmall }) =>
      !isSmall &&
      css`
        color: ${colors.light.light};
        display: inline-flex;
        flex-direction: column;
        gap: 16px;
      `}
    ${({ isSmall }) =>
      isSmall &&
      css`
        color: ${colors.dark.dark};
        display: inline-flex;
      `}
  `;

  export const Title = styled.div<{ isVertical: boolean }>`
    font-weight: ${typography.fontWeights.regular};
    margin-right: 12px;
  `;
  export const AnimatedText = styled.div<{
    color: string;
  }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${(props) => props.color};
    transition: color 0.4s;
  `;
  export const MobileText = styled(AnimatedText)<{ isSelected: boolean }>`
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transition: opacity 0.5s;
    position: absolute;
    top: 0;
    left: 76px;
  `;
  export const MobileTextVertical = styled(MobileText)`
    left: 0;
    top: unset;
    margin-top: 48px;
    width: max-content;
  `;
  export const TitleLabelContainer = styled.div`
    position: relative;
    display: flex;
  `;
}
