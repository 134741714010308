import { NSActiveBagdeStyle } from "./ActiveBagde.style";
import { NSActiveBagdeType } from "./ActiveBagde.types";

export const ActiveBagde = ({ isActive }: NSActiveBagdeType.IActiveBagde) => {
  return (
    <NSActiveBagdeStyle.Container>
      <NSActiveBagdeStyle.Dot isActive />
      {isActive ? "İlan Aktif" : "İlan Yayında Değil"}
    </NSActiveBagdeStyle.Container>
  );
};
