import styled from "styled-components";
import colors from "../../style/colors";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import { ReactComponent as Remove } from "../../assets/icons/removeLarge.svg";

export namespace NSAccordionStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "ReadexPro";
    border-radius: 16px;
    background-color: ${colors.light.light};
    padding: 32px;
    height: fit-content;
  `;

  export const TitleContainer = styled.div<{ isOpen: boolean }>`
    width: 100%;
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    cursor: pointer;
    color: ${colors.dark.dark};
  `;

  export const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.blue.blue01};
    border-radius: 64px;
  `;

  export const ContentAnimationWrapper = styled.div<{
    isOpen: boolean;
  }>`
    width: 100%;
    overflow: hidden;
    transition:
      max-height 0.5s ease,
      opacity 0.5s ease,
      transform 0.5s ease;
    max-height: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transform: ${({ isOpen }) => (isOpen ? "scaleY(1)" : "scaleY(0.9)")};
    transform-origin: top;
  `;

  export const ContentContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    background: ${colors.light.light};
  `;

  export const CustomAdd = styled(Add)<{ isOpen: boolean }>`
    opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    transition: opacity 1s ease;
  `;
  export const CustomRemove = styled(Remove)<{ isOpen: boolean }>`
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition: opacity 1s ease;
  `;
}
