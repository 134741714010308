import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSRolloverImageGalleryStyle {
  export const DesktopContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 8px;
    justify-items: center;
    align-items: center;
    @media (max-width: 1320px) {
      display: none !important;
    }
  `;

  export const LogoDesktop = styled.img`
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: opacity 0.5s;
    object-fit: contain;
  `;
  export const MobileContainer = styled.div`
    display: none;
    @media (max-width: 1320px) {
      display: block;
    }
  `;

  export const ImageDesktop = styled.img`
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s;
    object-fit: cover;
  `;

  export const CardDesktop = styled.a`
    height: 160px;
    min-width: 190px;
    background-color: ${colors.light.light};
    position: relative;
    ${ImageDesktop} {
      opacity: 1;
    }
    ${LogoDesktop} {
      opacity: 0;
    }
    &:hover {
      ${LogoDesktop} {
        opacity: 1;
      }
      ${ImageDesktop} {
        opacity: 0;
      }
    }
  `;

  export const CardMobile = styled.a`
    all: unset;
    width: 108px;
    height: 134px;
    background-color: ${colors.light.light};
    margin-left: auto;
    margin-right: auto;
  `;
  export const ImageMobile = styled.img`
    height: 74px;
    width: 108px;
    background-color: ${colors.light.light};
    object-fit: cover;
  `;
  export const LogoMobile = styled.img`
    height: 60px;
    width: 108px;
    background-color: ${colors.light.light};
    object-fit: cover;
  `;
  export const SwiperContainer = styled.div`
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-wrapper {
      padding-bottom: 24px;
    }

    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;
}
