import styled from "styled-components";
import colors from "../../style/colors";
import { NSHeaderDesktopStyle } from "../../components/HeaderDesktop/HeaderDesktop.style";

export namespace NSJDStickyHeaderStyle {
  export const JDStickyHeaderContainer = styled.div<{ isMobile: boolean }>`
    position: sticky;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    width: 100%;
    min-height: 96px;
    height: 100%;
    background-color: ${colors.blue.blue02};
    gap: 40px;
    padding: ${({ isMobile }) => (isMobile ? "16px 24px" : "16px 84px")};
    padding: 16px 84px;
    @media (max-width: 1092px) {
      padding: 16px 24px;
    }
  `;

  export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

  export const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;

  export const ItemReturnButton = styled.a`
    color: ${colors.light.light};
  `;

  export const Title = styled.p`
    color: ${colors.light.light};
    font-size: 24px;
    line-height: 37.44px;
    font-family: "ReadexPro";
    margin: 0px 0px 0px 8px;
  `;
}
