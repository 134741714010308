import { NSBadgeStyle } from "./Badge.style";
import { NSBadgeType } from "./Badge.types";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrowLarge.svg";
import { ReactComponent as Astrix } from "../../assets/icons/astrxLarge.svg";
import { ReactComponent as Rectangle } from "../../assets/icons/rectangle.svg";
import { ReactComponent as JoinUsText } from "../../assets/icons/joinUsText.svg";
import { ReactComponent as KnowUsText } from "../../assets/icons/knowUsText.svg";
import { ReactComponent as LearnWithUsText } from "../../assets/icons/learnWithUsText.svg";
import { ReactComponent as SustainWithUsText } from "../../assets/icons/sustainWithUsText.svg";
import { ReactComponent as ListenUsText } from "../../assets/icons/listenUsText.svg";
import colors from "../../style/colors";

export const Badge = ({
  isMobile,
  iconVariant,
  color,
  textVariant,
  onClick,
  speed = 10,
}: NSBadgeType.IBadge) => {
  const iconList = {
    ADD: <Add />,
    ARROW: <Arrow />,
    ASTRIX: <Astrix />,
    RECTANGLE: <Rectangle />,
  };
  const colorList = {
    BLUE: colors.blue.blue01,
    ORANGE: colors.orange.orange02,
    YELLOW: colors.yellow.yellow01,
    GREEN: colors.green.green01,
  };
  const textList = {
    JOINUS: <JoinUsText />,
    KNOWUS: <KnowUsText />,
    LEARNWITHUS: <LearnWithUsText />,
    SUSTAINWITHUS: <SustainWithUsText />,
    LISTENUS: <ListenUsText />,
  };

  return (
    <NSBadgeStyle.Container
      isMobile={isMobile}
      backgroundColor={colorList[color]}
      onClick={onClick}
      isClickable={typeof onClick === "function"}
    >
      <NSBadgeStyle.TextContainer isMobile={isMobile} speed={speed}>
        {textList[textVariant]}
      </NSBadgeStyle.TextContainer>
      <NSBadgeStyle.IconContainer isMobile={isMobile}>
        {iconList[iconVariant]}
      </NSBadgeStyle.IconContainer>
    </NSBadgeStyle.Container>
  );
};
