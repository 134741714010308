import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSVideoPlayerCarouselStyle {
  export const DesktopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .swiper {
      padding-bottom: 64px;
      padding-left: 22px;
    }
    .swiper-slide {
      width: fit-content;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;

  export const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  `;
  export const MobileSwiperContainer = styled.div`
    .swiper {
      padding-bottom: 16px;
    }
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;

  export const NavigationButton = styled.button`
    all: unset;
    cursor: pointer;
    padding: 8px;

    margin-bottom: 64px;
    &:first-child {
      margin-right: 16px;
      margin-left: -64px;
    }
    &:last-child {
      margin-left: 16px;
      margin-right: -64px;
    }
  `;

  export const ModalCloseButton = styled.button<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    height: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    width: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    border-radius: 64px;
    display: flex;
    align-items: center;
    z-index: 4;
    justify-content: center;
    position: absolute;
    right: ${({ isMobile }) => (isMobile ? "16px" : "32px")};
    top: ${({ isMobile }) => (isMobile ? "16px" : "32px")};
    background-color: ${colors.light.light};
    transition: 0.5s all;
    transform: rotate(45deg);
    &:hover {
      filter: brightness(90%);
      transform: rotate(225deg);
    }
  `;
  export const ModalNavigationButton = styled.button<{ isClickable: boolean }>`
    all: unset;
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};
    padding: 8px;
    margin-bottom: 64px;
    transition: 0.5s all;
    &:hover {
      filter: brightness(110%);
      transform: scale(1.2);
    }
    ${({ isClickable }) =>
      !isClickable &&
      css`
        filter: brightness(50%) !important;
      `}
  `;

  export const ModalContentContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ isMobile }) =>
      isMobile ? "center" : "space-between"};
    width: 100vw;
    padding: ${({ isMobile }) => (isMobile ? "0" : "0px 84px")};
  `;
  export const ModalNavigationMobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px;
    margin-top: 8px;
    ${ModalNavigationButton} {
      &:hover {
        filter: brightness(100%);
        transform: scale(1);
      }
    }
  `;
}
