import { SubmitHandler, useForm } from "react-hook-form";
import { NSUserInfoStyle } from "../UserInfo/UserInfo.style";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import { NSUserInfoType } from "../UserInfo/UserInfo.types";
import { DatePicker } from "../DatePicker/DatePicker";

export const UserInfo: React.FC<NSUserInfoType.IUserInfo> = ({
  onSubmitUserInfo,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<NSUserInfoType.UserInfoType>({
    resolver: zodResolver(NSUserInfoType.userInfoSchema),
    defaultValues: {
      name: "Gülay",
      surname: "Tekneci",
      email: "gulaytekneci@gmail.com",
      birthDate: "",
    },
  });

  const onSubmit: SubmitHandler<NSUserInfoType.UserInfoType> = (data) => {
    onSubmitUserInfo(data);
  };

  return (
    <NSUserInfoStyle.FormContainer>
      <NSUserInfoStyle.Form id="userInfoForm" onSubmit={handleSubmit(onSubmit)}>
        <NSUserInfoStyle.Title>Hesap Bilgileri</NSUserInfoStyle.Title>
        <NSUserInfoStyle.InnerForm>
          <NSUserInfoStyle.SideBySideInputContainer>
            <Input
              label="Ad"
              placeholder="Ad"
              val={watch("name")}
              {...register("name")}
              error={errors.name?.message}
            />
            <Input
              label="Soyad"
              placeholder="Soyad"
              val={watch("surname")}
              {...register("surname")}
              error={errors.surname?.message}
            />
          </NSUserInfoStyle.SideBySideInputContainer>
          <NSUserInfoStyle.SideBySideInputContainer>
            <Input
              label="E-posta"
              placeholder="E-posta"
              id="email"
              val={watch("email")}
              {...register("email")}
              error={errors.email?.message}
            />
            <DatePicker
              placeholder="Doğum Tarihi"
              infoText="GG/AA/YYYY biçiminde."
              onChange={(date) => setValue("birthDate", date?.toDateString())}
              error={errors.birthDate?.message}
            />
          </NSUserInfoStyle.SideBySideInputContainer>
          <Button
            type="submit"
            variant="solid"
            width={119}
            height={56}
            fontSize={16}
          >
            Güncelle
          </Button>
        </NSUserInfoStyle.InnerForm>
      </NSUserInfoStyle.Form>
    </NSUserInfoStyle.FormContainer>
  );
};
