import useDeviceType from "../../../util/isMobile";
import ApplicationsPageDesktop from "./DesktopView/ApplicationsPageDesktop";
import ApplicationsPageMobile from "./MobileView/ApplicationsPageMobile";
import { NSApplicationsPageType } from "./ApplicationsPage.types";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const applicationsData: NSApplicationsPageType.IApplicationsPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
};

function ApplicationsPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <ApplicationsPageMobile applicationsData={applicationsData} />;
  } else {
    return <ApplicationsPageDesktop applicationsData={applicationsData} />;
  }
}

export default ApplicationsPage;
