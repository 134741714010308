import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSIconCardStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: inline-flex;
    flex-direction: column;
    max-width: ${({ isMobile }) => (isMobile ? "auto" : "406px")};
    align-items: flex-start;
    padding: ${(props) => (props.isMobile ? "32px" : "40px")};
    background: ${colors.blue.blue01};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    text-align: left;
    border-radius: 32px;
    box-sizing: border-box;
    height: ${({ isMobile }) => (isMobile ? "100%" : "unset")};
  `;
  export const IconContainer = styled.div<{ isMobile: boolean }>`
    height: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    width: ${({ isMobile }) => (isMobile ? "64px" : "80px")};
    background-color: ${colors.light.light};
    color: ${colors.dark.dark};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const Title = styled.div`
    font-size: ${typography.titleh5.size};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    margin-top: 24px;
  `;
  export const Description = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    margin-top: 24px;
    text-align: left;
  `;
}
