import { useRef, useState } from "react";
import { MenuMobile } from "../../../../components/MenuMobile/MenuMobile";
import { NSApplicationsPageType } from "../ApplicationsPage.types";
import { NSApplicationsPageMobileStyle } from "./ApplicationsPageMobile.style";
import useStickyElement from "../../../../util/useStickyElement";
import { HeaderMobile } from "../../../../components/HeaderMobile/HeaderMobile";
import { InnerPageHeader } from "../../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../../style/colors";
import { Footer } from "../../../../components/Footer/Footer";
import { PositionCard } from "../../../../components/PositionCard/PositionCard";

function ApplicationsPageMobile({
  applicationsData,
}: Readonly<NSApplicationsPageType.IApplicationsPage>) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  const applicationCards = [
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça ve VW Binek Ürün Pazarlama Birim Yöneticisi",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "1",
      isActive: true,
    },
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça Pazarlama Birim Yöneticisi",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "2",
      isActive: false,
    },
  ];

  return (
    <NSApplicationsPageMobileStyle.Container>
      <NSApplicationsPageMobileStyle.LeftContainer>
        <NSApplicationsPageMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={applicationsData.anchorList}
            socialLinks={applicationsData.footer.socialLinks}
          />
        </NSApplicationsPageMobileStyle.RightContainer>
        <HeaderMobile
          logoClicked={() => {}}
          menuButtonClicked={() => setShowMenu(true)}
          isDark={isSticky}
          backgroundColor={colors.blue.blue02}
        />
        <NSApplicationsPageMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={""}
            isIcon={false}
            isMobile={isMobile}
            title="Başvurularım"
            backgroundColor={colors.blue.blue02}
          />
        </NSApplicationsPageMobileStyle.StickyHelperContainer>

        <NSApplicationsPageMobileStyle.MainContainer>
          <NSApplicationsPageMobileStyle.StyledContainer>
            {applicationCards.map((application) => (
              <PositionCard
                isApplied
                key={application.id}
                tag={application.tag}
                title={application.title}
                locations={application.locations}
                date={application.date}
                id={application.id}
                isActive={application.isActive}
                isMobile={true}
              />
            ))}
          </NSApplicationsPageMobileStyle.StyledContainer>
        </NSApplicationsPageMobileStyle.MainContainer>
        <Footer {...applicationsData.footer} isMobile={isMobile} />
      </NSApplicationsPageMobileStyle.LeftContainer>
    </NSApplicationsPageMobileStyle.Container>
  );
}

export default ApplicationsPageMobile;
