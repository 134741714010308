import { ReactNode, useEffect, useRef, useState } from "react";
import { NSIconListCardStyle } from "./IconListCard.style";

interface IIconCardHelper {
  icon: ReactNode;
  title: string;
  description: ReactNode;
}

export const IconCardHelper = ({
  icon,
  title,
  description,
}: IIconCardHelper) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setTimeout(() => {
        setHeight(ref?.current?.clientHeight ?? 0);
      }, 100);
    }
  }, [ref?.current?.clientHeight]);

  return (
    <NSIconListCardStyle.IconContainer height={height}>
      <NSIconListCardStyle.DescriptionHelper ref={ref}>
        {description}
      </NSIconListCardStyle.DescriptionHelper>
      <NSIconListCardStyle.IconOrb>
        <NSIconListCardStyle.InnerIcon>{icon}</NSIconListCardStyle.InnerIcon>
      </NSIconListCardStyle.IconOrb>
      <NSIconListCardStyle.IconTitle>{title}</NSIconListCardStyle.IconTitle>
    </NSIconListCardStyle.IconContainer>
  );
};
