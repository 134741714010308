import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../demoPages/HomePage/HomePage";
import KnowUsPage from "../demoPages/KnowUsPage/KnowUsPage";
import JoinUsPage from "../demoPages/JoinUsPage/JoinUsPage";
import LearnWithUs from "../demoPages/LearnWithUs/LearnWithUs";
import SustainWithUs from "../demoPages/SustainWithUs/SustainWithUs";
import LoginPage from "../demoPages/AuthPages/LoginPage/LoginPage";
import RegisterPage from "../demoPages/AuthPages/RegisterPage/RegisterPage";
import PositionListPage from "../demoPages/PositionPages/PositionList/PositionListPage";
import PositionDetailPage from "../demoPages/PositionPages/PositionDetailPage/PositionDetailPage";
import StatusPage from "../demoPages/DashboardPages/Status/StatusPage";
import CVPage from "../demoPages/DashboardPages/CV/CVPage";
import ApplicationsPage from "../demoPages/DashboardPages/Applications/ApplicationsPage";
import AccountPage from "../demoPages/DashboardPages/Account/AccountPage";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/know-us" element={<KnowUsPage />} />
        <Route path="/join-us" element={<JoinUsPage />} />
        <Route path="/learn-with-us" element={<LearnWithUs />} />
        <Route path="/sustain-with-us" element={<SustainWithUs />} />
        <Route path="auth/login" element={<LoginPage />} />
        <Route path="auth/register" element={<RegisterPage />} />
        <Route path="position/list" element={<PositionListPage />} />
        <Route
          path="position/detail/:positionId"
          element={<PositionDetailPage />}
        />
        <Route path="dashboard/status" element={<StatusPage />} />
        <Route path="dashboard/cv" element={<CVPage />} />
        <Route path="dashboard/applications" element={<ApplicationsPage />} />
        <Route path="dashboard/account" element={<AccountPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
