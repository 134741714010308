import { NSCvFormStepStyle } from "./CvFormStep.style";
import { NSCvFormStepType } from "./CvFormStep.types";
import { formSteps } from "../../util/formStep";
import { ReactComponent as Road } from "../../assets/icons/road.svg";
import { ReactComponent as Car } from "../../assets/icons/progress-car.svg";
export const CvFormStep = ({
  onChangeStep,
  activeStep,
}: NSCvFormStepType.ICvFormStep) => {
  return (
    <NSCvFormStepStyle.Wrapper>
      <NSCvFormStepStyle.StepWrapper>
        <NSCvFormStepStyle.Container>
          {formSteps.map((item) => (
            <NSCvFormStepStyle.StepContainer
              onClick={() => onChangeStep(item)}
              key={item.id}
            >
              <NSCvFormStepStyle.Step
                color={item.color}
                active={activeStep === item}
              >
                <span>
                  {item.title}
                  {item.id !== "diger-alanlar" && (
                    <NSCvFormStepStyle.StepStar
                      color={item.color}
                      active={activeStep === item}
                    >
                      *
                    </NSCvFormStepStyle.StepStar>
                  )}
                </span>

                {item.icon}
              </NSCvFormStepStyle.Step>
            </NSCvFormStepStyle.StepContainer>
          ))}
        </NSCvFormStepStyle.Container>

        <NSCvFormStepStyle.ProgressBar max={6} value={activeStep.order} />
      </NSCvFormStepStyle.StepWrapper>

      <NSCvFormStepStyle.RoadPath>
        <Road />
        <NSCvFormStepStyle.IndicatorContainer
          id={activeStep.id}
          style={{
            transform: `translateX(${
              (activeStep.order - 1) * 161 - activeStep.order * 3
            }px)`,
          }}
        >
          <Car />
        </NSCvFormStepStyle.IndicatorContainer>
      </NSCvFormStepStyle.RoadPath>
    </NSCvFormStepStyle.Wrapper>
  );
};
