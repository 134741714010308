import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSVideoPlayerCardStyle {
  export const PlayOrb = styled.div`
    height: 64px;
    width: 64px;
    border-radius: 100%;
    background-color: ${colors.light.light};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    bottom: 48px;
    transition: all 0.5s;
    animation: pulse-animation 1s infinite;
    animation-play-state: paused;
    @keyframes pulse-animation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  `;

  export const TextContainer = styled.div<{ isMobile: boolean }>`
    position: absolute;
    left: ${({ isMobile }) => (isMobile ? "-6px" : "-16px")};
    bottom: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 1;
    transition: opacity 0.5s;
  `;

  export const TitleContainer = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText1.size : typography.pharagraph2.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText1.lineHeight
        : typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    background-color: ${colors.blue.blue01};
    height: 36px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
  `;
  export const DescriptionContainer = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.smallText2.size : typography.smallText1.size};
    line-height: ${({ isMobile }) =>
      isMobile
        ? typography.smallText2.lineHeight
        : typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    background-color: ${colors.blue.blue01};
    min-height: 36px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 16px;
    max-width: 80%;
  `;

  export const Container = styled.button<{
    isMobile: boolean;
    showTitle: boolean;
    width?: string;
    height?: string;
  }>`
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${({ isMobile }) => (isMobile ? "auto" : "400px")};
    width: ${({ isMobile }) => (isMobile ? "100%" : "384px")};

    position: relative;
    overflow: hidden;
    border-radius: 32px;
    ${({ showTitle }) =>
      !showTitle &&
      css`
        ${PlayOrb} {
          animation-play-state: running;
          right: calc(50% - 32px);
          bottom: calc(50% - 32px);
        }
        img {
          filter: brightness(80%);
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      `}

    ${({ isMobile }) =>
      isMobile &&
      css`
        justify-content: flex-end;
      `}

    ${(props) =>
      props.width &&
      props.height &&
      !props.isMobile &&
      css`
        width: ${props.width} !important;
        height: ${props.height} !important;
      `}

    &:hover {
      img {
        transform: scale(1.1);
        filter: brightness(80%);
      }

      ${PlayOrb} {
        animation-play-state: running;
        right: calc(50% - 32px);
        bottom: calc(50% - 32px);
      }
    }
  `;
  export const Image = styled.img<{ isMobile: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s all;
    ${({ isMobile }) =>
      isMobile &&
      css`
        border-radius: 32px;
      `}
  `;

  export const OuterContainer = styled.div<{ isMobile: boolean }>`
    font-family: "ReadexPro";
    position: relative;
    display: inline-flex;
    overflow: visible;
    margin: ${({ isMobile }) => (isMobile ? "8px" : "0px")};
    &:hover {
      ${TextContainer} {
        opacity: 0;
      }
    }
  `;
}
