import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSBadgeStyle {
  export const Container = styled.div<{
    isMobile: boolean;
    backgroundColor: string;
    isClickable: boolean;
  }>`
    background-color: ${(props) => props.backgroundColor};
    height: ${(props) => (props.isMobile ? "128px" : "180px")};
    width: ${(props) => (props.isMobile ? "128px" : "180px")};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
  `;

  export const TextContainer = styled.div<{ isMobile: boolean; speed: number }>`
    @keyframes rotate-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
    animation: ${(props) =>
      `rotate-animation ${props.speed}s linear 0s infinite`};

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: ${(props) => (props.isMobile ? "scale(0.72)" : "scale(1)")};
    }
  `;

  export const IconContainer = styled.div<{ isMobile: boolean }>`
    margin-top: 2px;
    svg {
      transform: ${(props) => (props.isMobile ? "scale(0.6)" : "scale(1)")};
      fill: ${colors.dark.dark};
    }
  `;
}
