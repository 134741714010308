import { z } from "zod";

export namespace NSCvSupplementaryType {
  export type ICvSupplementary = {
    onSubmitSupplementary: (data: CvSupplementaryInfoType) => void;
  };

  export const CvSupplementarySchema = z.object({
    expectedSalary: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Ücret beklentisi zorunlu")
      .refine((value) => /^[0-9]+$/.test(value), "Sadece sayı girilebilir"),
    preferredCity: z
      .array(z.object({ id: z.string(), label: z.string() }), {
        invalid_type_error: "Tercih edilen il bilgisi zorunlu",
        required_error: "Tercih edilen il bilgisi  zorunlu",
      })
      .max(5, "En fazla 5 tane seçebilirsiniz")
      .min(1, "Tercih edilen il bilgisi zorunlu"),
    travelRestrictions: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Zorunlu"),
    iskurRegistration: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Zorunlu"),
    availableStartingDate: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Zorunlu"),
    sentToOtherCompany: z
      .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
      .min(1, "Zorunlu"),
  });

  export type CvSupplementaryInfoType = z.infer<typeof CvSupplementarySchema>;
}
