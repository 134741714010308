import { NSCompanyConditionsStyle } from "./CompanyConditions.style";
import { NSCompanyConditionsType } from "./CompanyConditions.types";
import { JobInfoCardContainer } from "../JobInfoCardContainer/JobInfoCardContainer";

export const CompanyConditions = ({
  title,
  content,
  items,
  isMobile,
}: NSCompanyConditionsType.ICompanyConditions) => {
  return (
    <JobInfoCardContainer isMobile={isMobile}>
      <NSCompanyConditionsStyle.CompanyInfoContainer>
        <NSCompanyConditionsStyle.Title>{title}</NSCompanyConditionsStyle.Title>
        <NSCompanyConditionsStyle.Desc>
          {content}
          {items && items.length > 0 && (
            <NSCompanyConditionsStyle.ItemsList>
              {items.map((item) => (
                <NSCompanyConditionsStyle.ListItem key={item}>
                  {item}
                </NSCompanyConditionsStyle.ListItem>
              ))}
            </NSCompanyConditionsStyle.ItemsList>
          )}
        </NSCompanyConditionsStyle.Desc>
      </NSCompanyConditionsStyle.CompanyInfoContainer>
    </JobInfoCardContainer>
  );
};
