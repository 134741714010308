import { darken } from "polished";
import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSButtonStyle {
  export const Button = styled.button<{
    backgroundColor: string;
    width?: number | string;
    height?: number | string;
    borderRadius: number;
    fontWeight: number;
    disabled: boolean;
    color?: string;
    border?: string;
    fontSize: number;
    hoverBackgroundColor?: string;
    hoverTextDecoration?: string;
    hoverBorder?: string;
    textDecoration?: boolean;
  }>`
    font-family: "ReadexPro";
    background-color: ${(props) => props.backgroundColor};
    width: ${(props) => (props.width ? `${props.width}px` : "100%")};
    height: ${(props) => (props.height ? `${props.height}px` : "64px")};
    border-radius: ${(props) => `${props.borderRadius}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => `${props.fontSize}px`};
    font-weight: ${(props) => props.fontWeight};
    line-height: 28.08px;
    text-align: center;
    border: ${(props) => props.border};
    color: ${(props) => props.color};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    gap: 8px;
    text-decoration: ${(props) =>
      props.textDecoration ? "underline" : "none"};

    :hover {
      background-color: ${(props) =>
        props.hoverBackgroundColor ?? darken(0.08, props.backgroundColor)};
      border-color: ${(props) =>
        props.hoverBorder ?? `2px solid  ${colors.blue.blue02}`};
      text-decoration: ${(props) => props.hoverTextDecoration};
    }
    :focus {
      background-color: ${(props) =>
        props.hoverBackgroundColor ?? darken(0.08, props.backgroundColor)};
      border: ${(props) =>
        props.hoverBorder ?? `2px solid  ${colors.blue.blue01}`};
      text-decoration: ${(props) => props.hoverTextDecoration};
    }
  `;
}
