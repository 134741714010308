import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSRegisterPageDesktopStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 64px;
    margin-bottom: 64px;
    flex-direction: column;
    gap: 48px;
  `;

  export const Form = styled.form`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    flex-direction: column;
    margin-top: 40px;
  `;

  export const Title = styled.div`
    width: 369px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28.08px;
    color: ${colors.dark.dark};
  `;

  export const InnerForm = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-direction: column;
  `;

  export const SideBySideInputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  `;

  export const CheckboxContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
  `;

  export const CheckboxLabel = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-weight: 400;
    font-size: 12px;
  `;

  export const FooterText = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 22.69px;
    color: ${colors.neutrals.grey05};
    display: flex;
    align-items: center;
    gap: 6px;
  `;
}
