import { NSVideoPlayerCardStyle } from "./VideoPlayerCard.style";
import { NSVideoPlayerCardType } from "./VideoPlayerCard.types";
import { ReactComponent as Play } from "../../assets/icons/play.svg";

export const VideoPlayerCard = ({
  isMobile,
  title,
  description,
  imageUrl,
  onClick,
  showTitle = true,
  width,
  height,
}: NSVideoPlayerCardType.IVideoPlayerCard) => {
  return (
    <>
      <NSVideoPlayerCardStyle.OuterContainer isMobile={isMobile}>
        <NSVideoPlayerCardStyle.Container
          width={width}
          height={height}
          isMobile={isMobile}
          onClick={onClick}
          showTitle={showTitle}
        >
          <NSVideoPlayerCardStyle.Image src={imageUrl} isMobile={isMobile} />

          <NSVideoPlayerCardStyle.PlayOrb>
            <Play />
          </NSVideoPlayerCardStyle.PlayOrb>
        </NSVideoPlayerCardStyle.Container>
        {showTitle && (
          <NSVideoPlayerCardStyle.TextContainer isMobile={isMobile}>
            <NSVideoPlayerCardStyle.TitleContainer isMobile={isMobile}>
              {title}
            </NSVideoPlayerCardStyle.TitleContainer>
            <NSVideoPlayerCardStyle.DescriptionContainer isMobile={isMobile}>
              {description}
            </NSVideoPlayerCardStyle.DescriptionContainer>
          </NSVideoPlayerCardStyle.TextContainer>
        )}
      </NSVideoPlayerCardStyle.OuterContainer>
    </>
  );
};
