import styled from "styled-components";
import colors from "../../../../style/colors";
import { NSButtonStyle } from "../../../../components/Button/Button.style";

export namespace NSRegisterPageMobileStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
  `;

  export const Form = styled.form`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    flex-direction: column;
    margin-top: 24px;
  `;

  export const Title = styled.div`
    width: 369px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28.08px;
    color: ${colors.dark.dark};
  `;

  export const InnerForm = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-direction: column;
  `;

  export const CheckboxContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
  `;

  export const CheckboxLabel = styled.div`
    width: 100%;
    height: fit-content;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    column-gap: 4px;
    flex-wrap: wrap;
  `;

  export const FooterText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22.69px;
    color: ${colors.neutrals.grey05};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    justify-items: center;
    width: 100%;
    ${NSButtonStyle.Button} {
      font-size: 14px;
      line-height: 22.69px;
      font-weight: 700;
    }
    padding-bottom: 24px;
  `;
}
