import { useState } from "react";
import { NSRadioButtonStyle } from "./RadioButton.style";
import { NSRadioButtonType } from "./RadioButton.types";

export const RadioButton = ({
  options,
  checkedValue,
  onChange,
  error,
}: NSRadioButtonType.IRadioButton) => {
  const [selectedOption, setSelectedOption] = useState(checkedValue);

  const handleOptionChange = (
    selected: NSRadioButtonType.RadioButtonOption
  ) => {
    setSelectedOption(selected.value);
    onChange(selected);
  };

  return (
    <NSRadioButtonStyle.Container>
      {options.map((item, index) => (
        <NSRadioButtonStyle.RadioButtonContainer key={index}>
          <NSRadioButtonStyle.RadioButton
            id={item.id}
            value={item.value}
            checked={selectedOption === item.value}
            onChange={() => handleOptionChange(item)}
            error={!!error}
          />
          <NSRadioButtonStyle.RadioButtonLabel htmlFor={item.id}>
            {item.label}
          </NSRadioButtonStyle.RadioButtonLabel>
        </NSRadioButtonStyle.RadioButtonContainer>
      ))}
      {!!error && (
        <NSRadioButtonStyle.ErrorMessage>
          {error}
        </NSRadioButtonStyle.ErrorMessage>
      )}
    </NSRadioButtonStyle.Container>
  );
};
