import styled from "styled-components";
import colors from "../../../../style/colors";
import { Col, Row } from "react-grid-system";

export namespace NSPositionListPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 24px 16px;
  `;

  export const PageTitle = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: space-between;
    color: ${colors.dark.dark};
    font-weight: 700;
    font-size: 24px;
    line-height: 37.44px;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    gap: 16px;
  `;

  export const StyledContainer = styled(Container)`
    padding: 24px 16px 0px 16px;
  `;

  export const StyledRow = styled(Row)`
    gap: 24px;
    margin: 0;
    padding: 0;
  `;

  export const StyledCol = styled(Col)`
    width: 100% !important;
    padding: 0;
    flex: unset !important;
    max-width: 100% !important ;
    padding: 0 !important;
  `;

  export const PaginationContainer = styled.div`
    padding: 40px 0px 24px 0px;
  `;
}
