import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSJobInfoCardContainerStyle {
  export const Container = styled.div<{
    isMobile: boolean;
  }>`
    width: ${(props) => (props.isMobile ? "100%" : "417px")};
    height: fit-content;
    background-color: ${colors.light.light};
    padding: 24px;
    border-radius: ${(props) => (props.isMobile ? "0px" : "16px")};
    gap: 32px;
  `;
}
