import { useEffect, useRef, useState } from "react";
import { NSDatePickerStyle } from "./DatePicker.style";
import { NSDatePickerType } from "./DatePicker.types";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Input } from "../Input/Input";
import { ReactComponent as CalendarEmpty } from "../../assets/icons/calendarEmpty.svg";

import { tr } from "date-fns/locale/tr";

registerLocale("tr", tr);

export const DatePicker = ({
  placeholder,
  infoText,
  dateFormat,
  error,
  onChange,
  defaultDate,
  isRequired,
  minDate,
  maxDate = new Date(),
}: NSDatePickerType.IDatePicker) => {
  const [isOpen, setIsOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date | undefined>(defaultDate);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const prepareDateString = (date: Date | undefined) => {
    if (!date) return "";

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear();

    if (dateFormat === "MM/yyyy") {
      return `${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  const onChangeHandler = (date: Date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <NSDatePickerStyle.Container ref={containerRef}>
      <ReactDatePicker
        id="datePicker"
        maxDate={maxDate}
        minDate={minDate}
        locale="tr"
        selected={startDate}
        onChange={onChangeHandler}
        onInputClick={() => setIsOpen(true)}
        open={isOpen}
        dateFormat={dateFormat ?? "dd/MM/yyyy"}
        placeholderText={placeholder}
        customInput={
          <Input
            isRequired={isRequired}
            className="custom-date-input"
            infoText={infoText}
            val={prepareDateString(startDate)}
            value={prepareDateString(startDate)}
            error={error}
            suffixItem={<CalendarEmpty onClick={() => setIsOpen(!isOpen)} />}
          />
        }
        showPopperArrow={false}
      ></ReactDatePicker>
    </NSDatePickerStyle.Container>
  );
};
