import { useRef, useState } from "react";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { NSSustainWithUsMobileStyle } from "./SustainWithUsMobile.style";
import { MenuMobile } from "../../../components/MenuMobile/MenuMobile";
import { HeaderMobile } from "../../../components/HeaderMobile/HeaderMobile";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { Footer } from "../../../components/Footer/Footer";
import { NSSustainWithUsType } from "../SustainWithUs.types";
import { AnimatedTextButton } from "../../../components/AnimatedTextButton/AnimatedTextButton";
import { TempSpacer } from "../../../util/tempSpacer";
import { StatementCard } from "../../../components/StatementCard/StatementCard";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { TextList } from "../../../components/TextList/TextList";
import { RoundedCardCarousel } from "../../../components/RoundedCardCarousel/RoundedCardCarousel";
import useStickyElement from "../../../util/useStickyElement";
function SustainWithUsMobile({
  sustainWithUsData,
}: NSSustainWithUsType.ISustainWithUs) {
  const isMobile = true;

  const [showMenu, setShowMenu] = useState(false);

  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(stickyDeciderDifRef);

  return (
    <NSSustainWithUsMobileStyle.Container>
      <NSSustainWithUsMobileStyle.LeftContainer>
        <NSSustainWithUsMobileStyle.RightContainer showMenu={showMenu}>
          <MenuMobile
            onItemClicked={() => setShowMenu(false)}
            onCloseClick={() => setShowMenu(false)}
            anchorList={sustainWithUsData.anchorList}
            socialLinks={sustainWithUsData.footer.socialLinks}
          />
        </NSSustainWithUsMobileStyle.RightContainer>
        {isSticky && (
          <HeaderMobile
            logoClicked={() => {}}
            menuButtonClicked={() => setShowMenu(true)}
            isDark={true}
          />
        )}
        <NSSustainWithUsMobileStyle.StickyHelperContainer
          ref={stickyDeciderDifRef}
        >
          <InnerPageHeader
            children={
              <HeaderMobile
                logoClicked={() => {}}
                menuButtonClicked={() => setShowMenu(true)}
                isDark={false}
              />
            }
            iconColor={colors.green.green04}
            isMobile={isMobile}
            title="Bizimle Sürdür"
            backgroundColor={colors.green.green01}
          />
        </NSSustainWithUsMobileStyle.StickyHelperContainer>

        <NSSustainWithUsMobileStyle.MainContainer>
          <AnimatedTextButton
            {...sustainWithUsData.animatedTextButton}
            isMobile={isMobile}
          />
          <NSSustainWithUsMobileStyle.UpperDescription>
            {sustainWithUsData.description1}
          </NSSustainWithUsMobileStyle.UpperDescription>
          <TempSpacer height={72} />
          <StatementCard
            {...sustainWithUsData.statementCard}
            isMobile={isMobile}
          />
          <TempSpacer height={72} />
          <NSSustainWithUsMobileStyle.Title>
            {sustainWithUsData.title2}
          </NSSustainWithUsMobileStyle.Title>
          <NSSustainWithUsMobileStyle.Description>
            {sustainWithUsData.description2}
          </NSSustainWithUsMobileStyle.Description>
          <TempSpacer height={32} />
          <NSSustainWithUsMobileStyle.Title>
            {sustainWithUsData.title3}
          </NSSustainWithUsMobileStyle.Title>
          <NSSustainWithUsMobileStyle.Description>
            {sustainWithUsData.description3}
          </NSSustainWithUsMobileStyle.Description>
          <TempSpacer height={32} />
          <ImageCarousel
            {...sustainWithUsData.imageCarousel1}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <StatementCard
            {...sustainWithUsData.statementCard2}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <NSSustainWithUsMobileStyle.Title>
            {sustainWithUsData.title4}
          </NSSustainWithUsMobileStyle.Title>
          <NSSustainWithUsMobileStyle.Description>
            {sustainWithUsData.description4}
          </NSSustainWithUsMobileStyle.Description>
          <TempSpacer height={32} />
          <NSSustainWithUsMobileStyle.Title>
            {sustainWithUsData.title5}
          </NSSustainWithUsMobileStyle.Title>
          <NSSustainWithUsMobileStyle.Description>
            {sustainWithUsData.description5}
          </NSSustainWithUsMobileStyle.Description>
          <TempSpacer height={32} />
          <ImageCarousel
            {...sustainWithUsData.imageCarousel2}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <TextList {...sustainWithUsData.textList1} isMobile={isMobile} />
          <TempSpacer height={32} />
          <ImageCarousel
            {...sustainWithUsData.imageCarousel4}
            isMobile={isMobile}
          />
          <TempSpacer height={80} />
          <NSSustainWithUsMobileStyle.Title>
            {sustainWithUsData.title6}
          </NSSustainWithUsMobileStyle.Title>
          <NSSustainWithUsMobileStyle.Description>
            {sustainWithUsData.description6}
          </NSSustainWithUsMobileStyle.Description>{" "}
          <TempSpacer height={32} />
          <RoundedCardCarousel
            {...sustainWithUsData.roundedCardCarousel}
            isMobile={isMobile}
          />
        </NSSustainWithUsMobileStyle.MainContainer>
        <Footer {...sustainWithUsData.footer} isMobile={isMobile} />
      </NSSustainWithUsMobileStyle.LeftContainer>

      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
    </NSSustainWithUsMobileStyle.Container>
  );
}

export default SustainWithUsMobile;
