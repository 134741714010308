import styled, { css } from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSHeadingTextStyle {
  export const ContainerDesktop = styled.div`
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const ContainerMobile = styled.div`
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    display: flex;
    flex-direction: column;
  `;

  export const InnerContainer = styled.div<{
    isVertical: boolean;
    marginBottom?: number;
  }>`
    display: flex;

    ${(props) =>
      props.isVertical &&
      css`
        flex-direction: column;
        text-align: center;
        width: 75%;
        margin-bottom: 44px;
      `}
    ${(props) =>
      !props.isVertical &&
      css`
        flex-direction: row;
        text-align: left;
      `}
      ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${marginBottom}px;
      `}
  `;

  export const InnerContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 32px;
  `;

  export const Title = styled.div<{
    isMobile: boolean;
  }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${(props) =>
      props.isMobile ? typography.titleh5.size : typography.titleh4.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.titleh5.lineHeight
        : typography.titleh4.lineHeight};
  `;

  export const HorizontalTitle = styled(Title)`
    padding-right: 20%;
    ${({ isMobile }) =>
      !isMobile &&
      css`
        min-width: 50%;
        padding-right: 10% !important;
      `}
  `;

  export const Description = styled.div<{
    isMobile: boolean;
  }>`
    font-weight: ${typography.fontWeights.regular};

    font-size: ${(props) =>
      props.isMobile ? typography.pharagraph2.size : "18px"};
    line-height: ${typography.pharagraph1.lineHeight};
    margin-top: ${({ isMobile }) => (isMobile ? "12px" : "0px")};
  `;

  export const DescriptionVertical = styled(Description)`
    margin-top: 16px;
  `;

  export const HorizontalChildrenContainer = styled.div`
    margin-top: 32px;
  `;
}
