import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSDashboardWelcomeTextStyle {
  export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    background-color: ${colors.light.light};
    gap: 24px;
    border-radius: 16px;
    padding: 32px;
    margin-right: 30px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      margin-right: 0px;
      border-radius: 0px;
    }
  `;

  export const Item = styled.div<{ last?: any }>`
    width: 100%;
    min-height: 393px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: ${colors.light.light};
    gap: 24px;

    svg {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 1200px) {
      flex-direction: row;
      ${(props) =>
        !props.last &&
        css`
          border-bottom: 1px solid ${colors.neutrals.grey03};
        `}
      min-height: 136px;
      gap: 16px;
      padding-bottom: 16px;

      svg {
        width: 120px;
        height: 120px;
      }
    }

    @media (min-width: 1200px) {
      ${(props) =>
        !props.last &&
        css`
          border-right: 1px solid ${colors.neutrals.grey03};
        `}
    }
  `;

  export const ItemContainer = styled.div`
    flex: 1;
  `;

  export const Title = styled.p`
    font-size: 18px;
    line-height: 28.08px;
    font-family: ReadexPro;
    color: ${colors.blue.blue01};

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 22.96px;
      font-weight: 700;
      font-family: "ReadexPro";
      color: ${colors.dark.dark};
    }
  `;

  export const Label = styled.p`
    font-size: 16px;
    line-height: 24.96px;
    font-family: ReadexPro;
    color: ${colors.dark.dark};
    min-height: 75px;
    padding-right: 24px;

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 22.96px;
      font-weight: 700;
      font-family: "ReadexPro";
      color: ${colors.dark.dark};
      margin-bottom: 5px;
      min-height: auto;
      padding-right: 0px;
    }
  `;

  export const ButtonDiv = styled.div`
    margin: 24px 17px 0px 17px;
    @media (max-width: 1200px) {
      margin: 0px;
    }
  `;

  export const ContainerLine = styled.div`
    border-right: 1px solid ${colors.neutrals.grey03};
    height: 440px;
  `;
  export const ApplicationInfo = styled.div`
    padding: 22.5px 0px;
    font-size: 16px;
    line-height: 26.24px;
    font-weight: 700;
    color: ${colors.dark.dark};

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 22.96px;
      font-weight: 700;
      font-family: "ReadexPro";
      color: ${colors.dark.dark};
      min-height: auto;
    }
  `;
}
