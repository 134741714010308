import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { NSChangePasswordStyle } from "../ChangePassword/ChangePassword.style";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../Input/Input";
import { PasswordConfirmation } from "../PasswordConfirmation/PasswordConfirmation";
import { Button } from "../Button/Button";
import colors from "../../style/colors";
import { NSChangePasswordType } from "../ChangePassword/ChangePassword.types";
import { useState } from "react";

export const ChangePassword: React.FC<NSChangePasswordType.IChangePassword> = ({
  onSubmitChangePsw,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<NSChangePasswordType.ChangePasswordType>({
    resolver: zodResolver(NSChangePasswordType.changePasswordSchema),
  });

  const onSubmit: SubmitHandler<NSChangePasswordType.ChangePasswordType> = (
    data
  ) => {
    onSubmitChangePsw(data);
  };

  const currentPassword = useWatch({
    control,
    name: "currentPassword",
  });

  const password = useWatch({
    control,
    name: "password",
  });

  const confirmPassword = useWatch({
    control,
    name: "confirmPassword",
  });

  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [showCurrentPasswordButton, setShowCurrentPasswordButton] =
    useState(false);
  const [showConfirmPasswordButton, setShowConfirmPasswordButton] =
    useState(false);

  return (
    <NSChangePasswordStyle.Form
      id="changePasswordForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <NSChangePasswordStyle.Title>
        Şifre Değişikliği
        <NSChangePasswordStyle.TitleDesc>
          Şifreni değiştirmek için mevcut şifreni ve yeni şifreni girmelisiniz.
        </NSChangePasswordStyle.TitleDesc>
      </NSChangePasswordStyle.Title>
      <NSChangePasswordStyle.InnerForm>
        <NSChangePasswordStyle.SideBySingleInputContainer>
          <Input
            label="Mevcut Şifre"
            type={showCurrentPasswordButton ? "text" : "password"}
            suffixItem={
              currentPassword && (
                <Button
                  onClick={() =>
                    setShowCurrentPasswordButton(!showCurrentPasswordButton)
                  }
                  variant="text"
                  color={colors.blue.blue01}
                  fontSize={12}
                  fontWeight={400}
                >
                  {showCurrentPasswordButton ? "Gizle" : "Göster"}
                </Button>
              )
            }
            placeholder="Mevcut Şifre"
            val={watch("currentPassword")}
            {...register("currentPassword")}
            error={errors.currentPassword?.message}
          />
        </NSChangePasswordStyle.SideBySingleInputContainer>
        <NSChangePasswordStyle.SideBySideInputContainer>
          <Input
            label="Şifre"
            type={showPasswordButton ? "text" : "password"}
            suffixItem={
              password && (
                <Button
                  onClick={() => setShowPasswordButton(!showPasswordButton)}
                  variant="text"
                  color={colors.blue.blue01}
                  fontSize={12}
                  fontWeight={400}
                >
                  {showPasswordButton ? "Gizle" : "Göster"}
                </Button>
              )
            }
            placeholder="Şifre"
            val={watch("password")}
            {...register("password")}
            error={errors.password?.message}
          />
          <Input
            label="Şifre Tekrarı"
            type={showConfirmPasswordButton ? "text" : "password"}
            suffixItem={
              confirmPassword && (
                <Button
                  onClick={() =>
                    setShowConfirmPasswordButton(!showConfirmPasswordButton)
                  }
                  variant="text"
                  color={colors.blue.blue01}
                  fontSize={12}
                  fontWeight={400}
                >
                  {showConfirmPasswordButton ? "Gizle" : "Göster"}
                </Button>
              )
            }
            placeholder="Şifre Tekrarı"
            val={watch("confirmPassword")}
            {...register("confirmPassword")}
            error={errors.confirmPassword?.message}
          />
        </NSChangePasswordStyle.SideBySideInputContainer>
        <PasswordConfirmation password={password} />
      </NSChangePasswordStyle.InnerForm>
      <Button
        type="submit"
        variant="solid"
        width={119}
        height={56}
        fontSize={16}
      >
        Güncelle
      </Button>
    </NSChangePasswordStyle.Form>
  );
};
