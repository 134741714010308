import { NSRoundedCardWithDescriptionStyle } from "./RoundedCardWithDescription.style";
import { NSRoundedCardWithDescriptionType } from "./RoundedCardWithDescription.types";

export const RoundedCardWithDescription = ({
  isMobile,
  title,
  image,
  description,
}: NSRoundedCardWithDescriptionType.IRoundedCardWithDescription) => {
  return (
    <NSRoundedCardWithDescriptionStyle.Container isMobile={isMobile}>
      <NSRoundedCardWithDescriptionStyle.ImageContainer>
        <NSRoundedCardWithDescriptionStyle.RoundedImage
          src={image}
          isMobile={isMobile}
        />
      </NSRoundedCardWithDescriptionStyle.ImageContainer>
      <NSRoundedCardWithDescriptionStyle.Title>
        {title}
      </NSRoundedCardWithDescriptionStyle.Title>
      <NSRoundedCardWithDescriptionStyle.Description>
        {description}
      </NSRoundedCardWithDescriptionStyle.Description>
    </NSRoundedCardWithDescriptionStyle.Container>
  );
};
