import styled from "styled-components";
import colors from "../../style/colors";
import { NSSelectBoxStyle } from "../SelectBox/SelectBox.style";

export namespace NSCareerDropdownStyle {
  export const Container = styled.div`
    ${NSSelectBoxStyle.TitleContainer} {
      background-color: ${colors.light.light};
      height: 56px;
      min-width: 164px;
      width: 100%;
      border: 2px solid ${colors.light.light};

      :hover {
        border: 2px solid ${colors.blue.blue01};
      }
    }
  `;
}
