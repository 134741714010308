import { z } from "zod";

export namespace NSCvContactType {
  export type ICvContact = {
    onSubmitContact: (data: CVContactType) => void;
  };

  export const CvContactSchema = z
    .object({
      email: z.string().min(1, "Email zorunlu").email("Geçersiz E-posta"),
      phone: z.string().min(10, "Telefon numarasını kontrol ediniz")
        .regex(/^[0-9+]*$/, "Telefon numarası '()' karakterler içermemeli"),
      country: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Ülke zorunlu",
            required_error: "Ülke zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Ülke zorunlu",
          path: ["country"],
        }),
      province: z
        .object({ id: z.string(), label: z.string() })
        .nullable()
        .optional(),
      district: z
        .object({ id: z.string(), label: z.string() })
        .nullable()
        .optional(),
      address: z.string().min(1, "Ev adresi zorunlu"),
      relativeName: z.string().min(1, "Yakınının adı zorunlu"),
      relativeSurname: z.string().min(1, "Yakınının soyadı zorunlu"),
      relativePhone: z.string().min(10, "Yakınının telefon numarasını kontrol ediniz")
        .regex(/^[0-9+]*$/, "Telefon numarası '()' karakterler içermemeli"),
    })
    .refine(
      (data) => {
        if (data.country.id === "turkiye") {
          return !!data.province && !!data.province.id;
        }
        return true;
      },
      {
        message: "İl zorunlu",
        path: ["province"],
      }
    )
    .refine(
      (data) => {
        if (data.country.id === "turkiye") {
          return !!data.district && !!data.district.id;
        }
        return true;
      },
      {
        message: "İlçe zorunlu",
        path: ["district"],
      }
    );
  export type CVContactType = z.infer<typeof CvContactSchema>;
}


