import colors from "../../style/colors";
import { NSSingleRoundedCardStyle } from "./SingleRoundedCard.style";
import { NSSingleRoundedCardType } from "./SingleRoundedCard.types";

export const SingleRoundedCard = ({
  isMobile,
  title,
  children,
  backgroundColor = colors.orange.orange03,
  textColor = colors.light.light,
}: NSSingleRoundedCardType.ISingleRoundedCard) => {
  return (
    <NSSingleRoundedCardStyle.Container
      textColor={textColor}
      isMobile={isMobile}
      backgroundColor={backgroundColor}
    >
      <NSSingleRoundedCardStyle.Title isMobile={isMobile}>
        {title}
      </NSSingleRoundedCardStyle.Title>
      {children}
    </NSSingleRoundedCardStyle.Container>
  );
};
