import { Scrollbar } from "swiper";
import { NSRolloverImageGalleryStyle } from "./RolloverImageGallery.style";
import { NSRolloverImageGalleryType } from "./RolloverImageGallery.types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
export const RolloverImageGallery = ({
  cardList,
}: NSRolloverImageGalleryType.IRolloverImageGallery) => {
  const renderDesktop = () =>
    cardList.map((card, index) => (
      <NSRolloverImageGalleryStyle.CardDesktop
        href={card.link}
        key={index}
        target="_blank"
      >
        <NSRolloverImageGalleryStyle.ImageDesktop src={card.front} />

        <NSRolloverImageGalleryStyle.LogoDesktop src={card.back} />
      </NSRolloverImageGalleryStyle.CardDesktop>
    ));

  const breakpointsHelper = {
    220: {
      slidesPerView: 2,
    },
    360: {
      slidesPerView: 3,
    },
    560: {
      slidesPerView: 4,
    },
    680: {
      slidesPerView: 5,
    },
    800: {
      slidesPerView: 6,
    },
    900: {
      slidesPerView: 7,
    },
    1040: {
      slidesPerView: 8,
    },
    1240: {
      slidesPerView: 9,
    },
  };

  return (
    <div>
      <NSRolloverImageGalleryStyle.DesktopContainer>
        {renderDesktop()}
      </NSRolloverImageGalleryStyle.DesktopContainer>
      <NSRolloverImageGalleryStyle.MobileContainer>
        <NSRolloverImageGalleryStyle.SwiperContainer>
          <Swiper
            modules={[Scrollbar]}
            breakpoints={breakpointsHelper}
            scrollbar={{ draggable: true }}
          >
            {cardList?.map((item) => (
              <SwiperSlide key={item.front}>
                <NSRolloverImageGalleryStyle.CardMobile
                  key={item.front}
                  href={item.link}
                  target="_blank"
                >
                  <NSRolloverImageGalleryStyle.ImageMobile src={item.front} />
                  <NSRolloverImageGalleryStyle.LogoMobile src={item.back} />
                </NSRolloverImageGalleryStyle.CardMobile>
              </SwiperSlide>
            ))}
          </Swiper>
        </NSRolloverImageGalleryStyle.SwiperContainer>
      </NSRolloverImageGalleryStyle.MobileContainer>
    </div>
  );
};
