import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSCompanyConditionsStyle {
  export const CompanyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const Title = styled.div`
    font-size: ${typography.pharagraph1_5.size};
    line-height: 28.08px;
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
    margin-bottom: 4px;
  `;

  export const Desc = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: 22.96px;
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
    font-family: "ReadexPro";
  `;
  export const ItemsList = styled.ul`
    padding-left: 15px;
    margin-bottom: 0;
  `;

  export const ListItem = styled.li`
    margin-top: 16px;
    list-style: disc;
  `;
}
