import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSCvUploadModalStyle {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
  `;
  export const Description = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 28.08px;
    font-weight: 400;
    color: ${colors.dark.dark};
    a {
      text-decoration: none;
      margin-right: 6px;
      color: ${colors.blue.blue01};
    }
  `;
}
