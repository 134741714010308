import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSImageCarouselStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 0;
    .swiper {
      padding-bottom: ${(props) => (props.isMobile ? "24px" : "64px")};
    }
    .swiper-slide {
      width: fit-content;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    .swiper-scrollbar {
      height: 1px;
      background-color: ${colors.neutrals.grey04};
    }
    .swiper-scrollbar-drag {
      height: 6px;
      background-color: ${colors.dark.dark};
      margin-top: -2.5px;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  `;
  export const HoverOrb = styled.div`
    position: absolute;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    height: 64px;
    width: 64px;
    background-color: ${colors.light.light};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s opacity;
  `;
  export const SlideImage = styled.img<{
    isMobile: boolean;
    imageFit: string;
  }>`
    position: relative;
    height: ${(props) => (props.isMobile ? "160px" : "192px")};
    width: ${(props) => (props.isMobile ? "236px" : "300px")};
    border-radius: ${(props) => (props.isMobile ? "16px" : "32px")};
    object-fit: ${({ imageFit }) => imageFit};
    transition: filter 0.5s;
    ${({ imageFit }) =>
      imageFit === "none" &&
      css`
        background-color: ${colors.light.light};
      `}
  `;

  export const SlideContainer = styled.div<{ displayImagePreview: boolean }>`
    position: relative;
    cursor: ${({ displayImagePreview }) =>
      displayImagePreview ? "cursor" : "grab"};
    &:hover {
      ${HoverOrb} {
        opacity: 1;
      }
    }
    &:hover {
      ${SlideImage} {
        ${({ displayImagePreview }) =>
          displayImagePreview &&
          css`
            filter: brightness(76%);
          `}
      }
    }
  `;

  export const NavigationButton = styled.button`
    all: unset;
    cursor: pointer;
    padding: 8px;

    margin-bottom: 64px;
    &:first-child {
      margin-right: 16px;
      margin-left: -64px;
    }
    &:last-child {
      margin-left: 16px;
      margin-right: -64px;
    }
  `;

  export const TitleContainer = styled.div<{ isMobile: boolean }>`
    font-size: ${({ isMobile }) =>
      isMobile ? typography.preText.size : typography.smallText2.size};
    background-color: ${colors.light.light};
    border-radius: 16px;
    padding: 12px 16px 12px 16px;
    font-family: "ReadexPro";
    position: absolute;
    bottom: 16px;
    left: 16px;
  `;

  export const DisplayedImage = styled.img<{ isMobile: boolean }>`
    object-fit: cover;
    border-radius: 32px;
    ${({ isMobile }) =>
      isMobile
        ? css`
            width: 95vw;
            height: auto;
          `
        : css`
            height: 70vh;
            max-width: 70vw;
          `}
  `;
  export const ImageNumber = styled.div<{ isMobile: boolean }>`
    text-align: center;
    z-index: -1;
    width: ${({ isMobile }) => (isMobile ? "auto" : "100%")};
    position: ${({ isMobile }) => (isMobile ? "relative" : "absolute")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "-48px" : "0")};
    bottom: ${({ isMobile }) => (isMobile ? "0" : "-72px")};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh5.size : typography.titleh4.size};
    font-weight: ${typography.fontWeights.regular};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh5.lineHeight : typography.titleh4.lineHeight};
    color: ${colors.neutrals.grey04};
  `;
}
