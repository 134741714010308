import useDeviceType from "../../util/isMobile";
import { NSDrawerCardStyle } from "./DrawerCard.style";
import { NSDrawerCardType } from "./DrawerCard.types";

export const DrawerCard = ({
  title,
  description,
  list,
}: NSDrawerCardType.IDrawerCard) => {
  const { isMobile } = useDeviceType();

  const renderList = () => {
    return list.map((item) => {
      return (
        <NSDrawerCardStyle.ListItemContainer
          key={item.title}
          isMobile={isMobile}
        >
          <NSDrawerCardStyle.InnerTitle>
            {item.title}
          </NSDrawerCardStyle.InnerTitle>
          <NSDrawerCardStyle.ListItem>
            Konuşmacılarımız:
            <NSDrawerCardStyle.TextContainer>
              {item.innerList.map((text) => {
                return (
                  <NSDrawerCardStyle.ListText key={text}>
                    {text}
                  </NSDrawerCardStyle.ListText>
                );
              })}
            </NSDrawerCardStyle.TextContainer>
          </NSDrawerCardStyle.ListItem>
        </NSDrawerCardStyle.ListItemContainer>
      );
    });
  };
  return (
    <NSDrawerCardStyle.Container>
      <NSDrawerCardStyle.Title isMobile={isMobile}>
        {title}
      </NSDrawerCardStyle.Title>
      <NSDrawerCardStyle.Description isMobile={isMobile}>
        {description}
      </NSDrawerCardStyle.Description>
      {renderList()}
    </NSDrawerCardStyle.Container>
  );
};
