import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { NSSelectBoxStyle } from "../SelectBox/SelectBox.style";

export namespace NSPaginationStyle {
  export const Container = styled.div<{ isMobile?: boolean }>`
    display: flex;
    align-items: ${(props) => (props.isMobile ? "center" : "flex-start")};
    justify-content: space-between;
    font-family: "ReadexPro";
    width: 100%;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    gap: ${(props) => (props.isMobile ? "24px" : "")};
  `;

  export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: fit-content;

    @media (max-width: 768px) {
      gap: 8px;
      justify-content: space-between;
    }
  `;

  export const PageNumberContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @media (max-width: 768px) {
      gap: 0px;
    }
  `;

  export const Page = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${typography.fontWeights.medium};
    font-size: 14px;
    line-height: 22.96px;
  `;

  export const DotContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 24px;
    height: 24px;
  `;

  export const Dot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: ${colors.neutrals.grey04};
  `;

  export const CountSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    ${NSSelectBoxStyle.TitleContainer} {
      padding: 0;
      border: 0;
      width: fit-content;
    }
    ${NSSelectBoxStyle.Container} {
      width: fit-content;
    }
    ${NSSelectBoxStyle.Title} {
      padding-right: 4px;
    }
    ${NSSelectBoxStyle.ItemContainer} {
      top: 25px;
    }
  `;

  export const CountSelectorTitle = styled.div`
    color: ${colors.neutrals.grey05};
    font-weight: 400;
    font-size: 14px;
    line-height: 22.96px;
    text-align: left;
    width: max-content;
  `;
}
