import styled, { css } from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSAccordionOpportunitiesMobileStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "ReadexPro";
  `;
  export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `;
  export const Title = styled.div`
    font-size: ${typography.titleh5.size};
    line-height: ${typography.titleh5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    color: ${colors.dark.dark};
    margin-bottom: 32px;
  `;
  export const OrbArrow = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 100%;
    background-color: ${colors.orange.orange02};
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transition: transform 0.3s;
      transform-origin: center;
      transform: rotate(90deg);
    }
  `;

  export const OptionItem = styled.button<{ isSelected: boolean }>`
    all: unset;
    cursor: pointer;
    color: ${colors.dark.dark};
    width: 100%;
    transition: width 0.3s;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 128px;
    padding: 0px 4px 0px 24px;
    background-color: ${colors.yellow.yellow01};
    margin-bottom: 12px;
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      filter: brightness(98%);
    }
    svg {
      ${({ isSelected }) =>
        isSelected &&
        css`
          transform: rotate(-90deg);
        `}
    }
  `;

  export const CurvedCard = styled.div<{ isSelected: boolean }>`
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.yellow.yellow01};
    padding: ${({ isSelected }) => (isSelected ? "32px 16px" : "0px")};
    height: ${({ isSelected }) => (isSelected ? "520px" : "0px")};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    width: 100%;
    box-sizing: border-box;
    transition: 0.3s all, opacity 0.2s;
    margin-bottom: ${({ isSelected }) => (isSelected ? "12px" : "0px")};
  `;

  export const IllustrationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 298px;
    max-width: 298px;
    align-self: center;
    overflow: hidden;
    border-radius: 32px;
  `;
  export const Description = styled.div`
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    color: ${colors.dark.dark};
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  `;
  export const Button = styled.button`
    all: unset;
    cursor: pointer;
    padding: 16px 24px 16px 24px;
    border-radius: 32px;
    background-color: ${colors.orange.orange02};
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    color: ${colors.light.light};
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    &:hover {
      filter: brightness(90%);
    }
  `;
}
