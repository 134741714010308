import styled from "styled-components";
import typography from "../../style/typography";
import { ReactComponent as Fingerprint } from "../../assets/icons/fingerprint_unfiltered.svg";
import colors from "../../style/colors";
export namespace NSInnerPageHeaderStyle {
  export const Container = styled.div<{
    isMobile: boolean;
    backgroundColor: string;
    iconColor?: string;
  }>`
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: "ReadexPro";
    height: ${({ isMobile, iconColor }) =>
      isMobile ? (iconColor ? "auto" : "auto") : "320px"};
    justify-content: space-between;
    padding-bottom: ${({ isMobile }) => (isMobile ? "0px" : "32px")};
    width: 100vw;
    box-sizing: border-box;
    background-color: ${({ backgroundColor }) => backgroundColor};
    overflow: visible;
    color: ${colors.light.light};
  `;
  export const InnerContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    padding: ${({ isMobile }) => (isMobile ? "24px 16px" : "0px 72px")};
  `;

  export const IconContainer = styled.div<{
    isVisible: boolean;
    isMobile: boolean;
  }>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: ${({ isMobile }) => (isMobile ? "32px" : "64px")};
      width: ${({ isMobile }) => (isMobile ? "32px" : "64px")};
      fill: ${colors.light.light};
    }
    z-index: 3;
  `;
  export const Title = styled.div<{ isMobile: boolean; isIcon: boolean }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${({ isMobile }) =>
      isMobile ? typography.titleh4.size : typography.titleh1.size};
    line-height: ${({ isMobile }) =>
      isMobile ? typography.titleh4.lineHeight : typography.titleh1.lineHeight};
    padding-left: ${({ isMobile, isIcon }) =>
      isMobile && !isIcon ? "0px" : isMobile ? "48px" : "84px"};
    z-index: 3;
  `;
  export const StyledFingerprint = styled(Fingerprint)<{
    issmall: string;
    isInJobPage?: boolean;
  }>`
    position: absolute;
    right: 0px;
    top: ${({ issmall, isInJobPage }) =>
      issmall === "true" ? (isInJobPage ? "-130px" : "-60px") : "-140px"};
    height: ${({ issmall }) => (issmall === "true" ? "166px" : "528px")};
    width: ${({ issmall }) => (issmall === "true" ? "204px" : "570px")};
    right: ${({ issmall }) => (issmall === "true" ? "30px" : "auto")};
    @media (max-width: 768px) {
      z-index: 3;
    }
  `;
}
