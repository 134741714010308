import { NSCvUploadModalType } from "./CvUploadModal.types";

import { ModalWithTemplate } from "../ModalWithTemplate/ModalWithTemplate";
import { DragDropFileUpload } from "../DragDropFileUpload/DragDropFileUpload";
import { useState } from "react";
import { NSCvUploadModalStyle } from "./CvUploadModal.style";

export const CvUploadModal = ({
  isOpen,
  onDeclineHandler,
  onAcceptHandler,
  isMobile,
  error,
  onCloseHandler,
}: NSCvUploadModalType.ICvUploadModal) => {
  const [uploadedFile, setUploadedFile] = useState<Blob | undefined>(undefined);

  const [fileName, setFileName] = useState<string | undefined>();
  return (
    <ModalWithTemplate
      onCloseHandler={onCloseHandler}
      declineButton={{ onClick: onDeclineHandler }}
      acceptButton={{
        onClick: () => onAcceptHandler(fileName),
        isDisabled: fileName === undefined || !!error,
      }}
      header="Özgeçmiş Yükle"
      isMobile={isMobile}
      isOpen={isOpen}
    >
      <NSCvUploadModalStyle.Container>
        <NSCvUploadModalStyle.Description>
          <a href="https://kariyer.net">Kariyer.net</a>
          özgeçmişinizi yükleyebilirsiniz. Kariyer.net özgeçmişinizi yüklemeniz
          halinde, özgeçmiş alanındaki bazı bilgiler otomatik olarak
          tamamlanacaktır.{" "}
        </NSCvUploadModalStyle.Description>
        <DragDropFileUpload
          setUploadedFile={setUploadedFile}
          uploadedFile={uploadedFile}
          error={error}
          onUpload={(_url, acceptedFiles) =>
            setFileName((acceptedFiles as File).name)
          }
        />
      </NSCvUploadModalStyle.Container>
    </ModalWithTemplate>
  );
};
