import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSLogoStyle {
  export const Container = styled.a<{ isMobile: boolean }>`
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `;
  export const VerticalLine = styled.div<{
    isMobile: boolean;
    isDark: boolean;
  }>`
    width: 1px;
    height: ${(props) => (props.isMobile ? "31px" : "38px")};
    background-color: ${(props) =>
      props.isDark ? colors.dark.dark : colors.light.light};
  `;
  export const Title = styled.div<{ isMobile: boolean; isDark: boolean }>`
    font-family: "ReadexPro";
    color: ${colors.light.light};
    font-weight: ${typography.fontWeights.medium};
    font-size: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.size
        : typography.pharagraph1.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.lineHeight
        : typography.pharagraph1.lineHeight};
    margin-right: 12px;
    color: ${(props) => (props.isDark ? colors.dark.dark : colors.light.light)};
  `;
}
