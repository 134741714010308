import { NSQuotationCardStyle } from "./QuotationCard.style";
import { NSQuotationCardType } from "./QuotationCard.types";

export const QuotationCard = ({
  isMobile,
  largeText,
  subTitle,
  secondSubTitle,
}: NSQuotationCardType.IQuotationCard) => {
  return (
    <NSQuotationCardStyle.Container isMobile={isMobile}>
      <NSQuotationCardStyle.Title isMobile={isMobile}>
        {largeText}
      </NSQuotationCardStyle.Title>
      <NSQuotationCardStyle.SubTitle isMobile={isMobile}>
        {subTitle}
      </NSQuotationCardStyle.SubTitle>
      <NSQuotationCardStyle.SecondSubTitle isMobile={isMobile}>
        {secondSubTitle}
      </NSQuotationCardStyle.SecondSubTitle>
    </NSQuotationCardStyle.Container>
  );
};
