import { NSSelectBoxType } from "../components/SelectBox/SelectBox.types";
import { years } from "./formDropdownItems";

export const shownLogic: { [key: string]: string[] } = {
  primarySchool: ["primarySchool"],
  secondarySchool: ["primarySchool", "secondarySchool"],
  highSchool: ["primarySchool", "secondarySchool", "highSchool"],
  associateDegree: [
    "primarySchool",
    "secondarySchool",
    "highSchool",
    "associateDegree",
  ],
  degree: [
    "primarySchool",
    "secondarySchool",
    "highSchool",
    "associateDegree",
    "degree",
  ],
  masterDegree: [
    "primarySchool",
    "secondarySchool",
    "highSchool",
    "associateDegree",
    "degree",
    "masterDegree",
  ],
  doctorate: [
    "primarySchool",
    "secondarySchool",
    "highSchool",
    "associateDegree",
    "degree",
    "masterDegree",
    "doctorate",
  ],
};

export const prepareEndDateArray = (
  item: NSSelectBoxType.ISelectBoxItem | undefined
) => {
  let prepArray = years;
  if (item) {
    const index = years.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      prepArray = years.slice(0, index);
    }
  }

  return prepArray;
};

export const defaultValues = {
  schoolName: "",
  department: "",
  startDate: { id: "", label: "" },
  endDate: { id: "", label: "" },
  gradingSystem: { id: "", label: "" },
  gpa: "",
  continue: null,
};
