import styled from "styled-components";

export namespace NSApplicationsPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 16px 16px 32px 16px;
  `;

  export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;
}
