import { NSMembershipCancellationStyle } from "../MembershipCancellation/MembershipCancellation.style";
import { Button } from "../Button/Button";
import { NSMembershipCancellationType } from "../MembershipCancellation/MembershipCancellation.types";
import colors from "../../style/colors";
import { ModalWithTemplate } from "../ModalWithTemplate/ModalWithTemplate";
import { useState } from "react";

export const MembershipCancellation = ({
  isMobile,
}: NSMembershipCancellationType.IMembershipCancellation) => {
  const [
    modalMembershipCancellationIsOpen,
    setModalMembershipCancellationIsOpen,
  ] = useState(false);
  const onRequestCloseHandler = () => {
    setModalMembershipCancellationIsOpen(false);
  };

  return (
    <NSMembershipCancellationStyle.Kvkk>
      <ModalWithTemplate
        isMobile={isMobile ?? false}
        isOpen={modalMembershipCancellationIsOpen}
        onCloseHandler={onRequestCloseHandler}
        header={"Hesabınız kullanıma kapatılacaktır. Onaylıyor musunuz?"}
        headerColor={colors.error.error01}
        acceptButton={{
          onClick: () => {
            console.log("Accept");
          },
        }}
        declineButton={{
          onClick: () => {
            console.log("Decline");
          },
        }}
      >
        <div></div>
      </ModalWithTemplate>
      <NSMembershipCancellationStyle.CancelTitle>
        Üyelik İptali
        <NSMembershipCancellationStyle.TitleDesc>
          Özgeçmiş bilgilerinizin artık Doğuş Otomotiv Grubu veri tabanında yer
          almasını istemiyorsanız üyelikten ayrılabilirsiniz. Aynı e-posta
          adresiniz ile yeniden üyelik oluşturabilirsiniz.
        </NSMembershipCancellationStyle.TitleDesc>
      </NSMembershipCancellationStyle.CancelTitle>
      <Button
        onClick={() => setModalMembershipCancellationIsOpen(true)}
        variant="solid"
        backgroundColor={colors.error.error01}
        color={colors.light.light}
        fontSize={16}
        width={isMobile ? ("100%" as unknown as number) : 361}
        height={56}
      >
        Üyeliğimi iptal et ve tüm bilgilerimi sil
      </Button>
    </NSMembershipCancellationStyle.Kvkk>
  );
};
