import { footerData, headerAnchorList } from "../../../util/headerFooterData";
import useDeviceType from "../../../util/isMobile";
import PositionListPageDesktop from "./DesktopView/PositionListPageDesktop";
import PositionListPageMobile from "./MobileView/PositionListPageMobile";
import { NSPositionListPageType } from "./PositionListPage.types";

const positionListData: NSPositionListPageType.IPositionListPageData = {
  anchorList: headerAnchorList,
  footer: footerData,
  logoClicked: () => {},
  positionList: [
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça ve VW Binek Ürün Pazarlama Birim Yöneticisi",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "1",
    },
    {
      tag: "Finans",
      title: "Finansal Kontrol ve Yatırım Analizi Proje Bazlı Stajyer",
      locations: ["Kocaeli"],
      date: "8 Mayıs 2024",
      id: "2",
    },
    {
      tag: "Depo / Antredepo",
      title: "Yedek Parça Depo Elemanı",
      locations: ["İstanbul(Asya)"],
      date: "8 Mayıs 2024",
      id: "3",
    },
    {
      tag: "Pazarlama",
      title: "Scania Yedek Parça ve VW Binek Ürün Pazarlama ",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "4",
    },
    {
      tag: "Finans",
      title: "Finansal Kontrol ve Yatırım Analizi Proje Bazlı Stajyer",
      locations: ["Kocaeli"],
      date: "8 Mayıs 2024",
      id: "5",
    },
    {
      tag: "Satış",
      title: "Satış ve SSH Koordinasyon Uzmanı",
      locations: ["Bodrum"],
      date: "8 Mayıs 2024",
      id: "6",
    },
    {
      tag: "Teknik",
      title: "Teknik Eleman",
      locations: ["İstanbul(Asya)", "İstanbul(Avr.)", "Kocaeli"],
      date: "8 Mayıs 2024",
      id: "7",
    },
    {
      tag: "Teknik",
      title: "Elektrik Teknisyeni",
      locations: ["Kocaeli"],
      date: "8 Mayıs 2024",
      id: "8",
    },

    {
      tag: "Depo / Antredepo",
      title: "Yedek Parça Depo Elemanı",
      locations: ["İstanbul(Asya)"],
      date: "8 Mayıs 2024",
      id: "9",
    },
  ],
  listCount: 246,
};
function PositionListPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <PositionListPageMobile positionListData={positionListData} />;
  } else {
    return <PositionListPageDesktop positionListData={positionListData} />;
  }
}

export default PositionListPage;
