import styled from "styled-components";

export namespace NSCVOtherStyle {
  export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: "ReadexPro";
  `;

  export const DragAndDropContainer = styled.div`
    max-width: 360px;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  `;
}
