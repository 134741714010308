import { forwardRef } from "react";
import { NSTextAreaStyle } from "./TextArea.style";
import { NSTextAreaType } from "./TextArea.types";

const TextArea = forwardRef<HTMLTextAreaElement, NSTextAreaType.ITextArea>(
  (
    {
      error,
      label,
      isRequired,
      labelShowType = "withValue",
      val,
      infoText,
      ...props
    },
    ref
  ) => {
    const showLabel = {
      alwaysShow: !!label,
      hide: false,
      withValue: !!label && !!val,
    };

    return (
      <NSTextAreaStyle.Container>
        {showLabel[labelShowType] && (
          <NSTextAreaStyle.Label>
            {label}
            {isRequired && <NSTextAreaStyle.Star>*</NSTextAreaStyle.Star>}
          </NSTextAreaStyle.Label>
        )}
        <NSTextAreaStyle.BaseTextArea ref={ref} error={!!error} {...props} />
        {error && (
          <NSTextAreaStyle.ErrorMessage>{error}</NSTextAreaStyle.ErrorMessage>
        )}

        {!error && infoText && (
          <NSTextAreaStyle.InfoText>{infoText}</NSTextAreaStyle.InfoText>
        )}
      </NSTextAreaStyle.Container>
    );
  }
);

TextArea.displayName = "TextArea";

export { TextArea };
