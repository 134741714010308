import { useRef, useState } from "react";

import { NSLearnWithUsDesktopStyle } from "./LearnWithUsDesktop.style";
import { InnerPageHeader } from "../../../components/InnerPageHeader/InnerPageHeader";
import colors from "../../../style/colors";
import { HeaderDesktop } from "../../../components/HeaderDesktop/HeaderDesktop";
import { Footer } from "../../../components/Footer/Footer";
import { SingleRoundedCard } from "../../../components/SingleRoundedCard/SingleRoundedCard";
import { VideoPlayerCard } from "../../../components/VideoPlayerCard/VideoPlayerCard";
import ReactPlayer from "react-player";
import { ImageCarousel } from "../../../components/ImageCarousel/ImageCarousel";
import { NSLearnWithUsType } from "../LearnWithUs.types";
import { CircleButton } from "../../../components/CircleButton/CircleButton";
import { scrollToTop } from "../../../util/scrollToTop";
import { AccordionOpportunitiesDesktop } from "../../../components/AccordionOpportunitiesDesktop/AccordionOpportunitiesDesktop";
import { AccordionList } from "../../../components/AccordionList/AccordionList";
import { RoundedCardDescriptionCarousel } from "../../../components/RoundedCardDescriptionCarousel/RoundedCardDescriptionCarousel";
import { TempSpacer } from "../../../util/tempSpacer";
import { Modal } from "../../../components/Modal/Modal";
import useStickyElement from "../../../util/useStickyElement";
import { Badge } from "../../../components/Badge/Badge";

function LearnWithUsDesktop({
  learnWithUsData,
}: NSLearnWithUsType.ILearnWithUs) {
  const isMobile = false;
  const stickyDeciderDifRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const isSticky = useStickyElement(stickyDeciderDifRef);
  const [modalIsOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(true);
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const renderHeader = () => (
    <HeaderDesktop
      logoClicked={() => {}}
      anchorList={learnWithUsData.anchorList}
      isDark={isSticky}
      backgroundColor={"transparent"}
    />
  );
  return (
    <NSLearnWithUsDesktopStyle.Container>
      {isSticky && renderHeader()}
      <NSLearnWithUsDesktopStyle.HeaderContainer ref={stickyDeciderDifRef}>
        <InnerPageHeader
          isMobile={isMobile}
          title="Bizimle Öğren"
          backgroundColor={colors.yellow.yellow01}
          iconColor={colors.yellow.yellow04}
          children={renderHeader()}
        />
      </NSLearnWithUsDesktopStyle.HeaderContainer>
      <NSLearnWithUsDesktopStyle.MainContainer>
        <NSLearnWithUsDesktopStyle.Title>
          Sosyal Öğrenme Platformumuz:
          <br />
          <NSLearnWithUsDesktopStyle.GoLogo src="images/learn-with-us/go-gelisim-okulu.png" />
        </NSLearnWithUsDesktopStyle.Title>
        <NSLearnWithUsDesktopStyle.Description>
          <b>
            Çalışma arkadaşlarımızın farklı kişisel ve mesleki öğrenim
            ihtiyaçları ile yetkinlik gelişim alanları doğrultusunda sürekli
            gelişimlerini destekleyen eğitim programları tasarlamaktayız.
          </b>{" "}
          GO Gelişim Okulu bu amaca özel olarak geliştirilmiş sosyal öğrenme
          platformudur. Bu platformda Yetkinlik ve Liderlik Gelişim
          Programlarının yanı sıra çalışan gelişimine bütünsel yaklaşarak
          planlanan Kişisel Gelişim Programları da yer almaktadır.
        </NSLearnWithUsDesktopStyle.Description>
        <TempSpacer height={64} />
        <NSLearnWithUsDesktopStyle.StatCardContainer>
          {learnWithUsData.iconDescriptionList.statsList.map((stat) => (
            <NSLearnWithUsDesktopStyle.StatCard>
              <NSLearnWithUsDesktopStyle.StatImage src={stat.icon as string} />
              {stat.description}
            </NSLearnWithUsDesktopStyle.StatCard>
          ))}
        </NSLearnWithUsDesktopStyle.StatCardContainer>
        <TempSpacer height={64} />
        <ImageCarousel
          {...learnWithUsData.imageCarouselData}
          isMobile={isMobile}
          imageFit="none"
        />
        <TempSpacer height={120} />

        <NSLearnWithUsDesktopStyle.AccordionlistBadgeContainer>
          <Badge
            isMobile={isMobile}
            textVariant="LEARNWITHUS"
            iconVariant="ASTRIX"
            color="YELLOW"
          />
          <AccordionList
            {...learnWithUsData.accordionList}
            isMobile={isMobile}
          />
        </NSLearnWithUsDesktopStyle.AccordionlistBadgeContainer>
        <TempSpacer height={120} />
        <NSLearnWithUsDesktopStyle.RoundedCardAndVideoCardContainer>
          <SingleRoundedCard
            {...learnWithUsData.singleRoundedCard}
            isMobile={isMobile}
          />
          <NSLearnWithUsDesktopStyle.VideoPlayerCardContainer>
            <VideoPlayerCard
              isMobile={isMobile}
              showTitle={false}
              imageUrl={learnWithUsData.videoPlayerImageAndVideo.image}
              height="568px"
              width="568px"
              onClick={onClickHandler}
            />
          </NSLearnWithUsDesktopStyle.VideoPlayerCardContainer>
        </NSLearnWithUsDesktopStyle.RoundedCardAndVideoCardContainer>
        <TempSpacer height={120} />
        <AccordionOpportunitiesDesktop
          title="Fikirlerin Bizim İçin Değerli"
          opportunityList={learnWithUsData.opportunityList}
        />
        <TempSpacer height={120} />
        <NSLearnWithUsDesktopStyle.Title>
          Mavi Direksiyon Ödülleri
        </NSLearnWithUsDesktopStyle.Title>
        <NSLearnWithUsDesktopStyle.Description>
          Çalışma arkadaşlarımız Mavi Direksiyon ödülleri adı altında farklı
          başlıklar altında ödüllendirilmektedir.
        </NSLearnWithUsDesktopStyle.Description>
        <TempSpacer height={56} />
        <RoundedCardDescriptionCarousel
          {...learnWithUsData.roundedDescriptionCarousel}
          isMobile={isMobile}
        />
        <CircleButton onClick={scrollToTop} isMobile={isMobile} />
      </NSLearnWithUsDesktopStyle.MainContainer>
      <Footer {...learnWithUsData.footer} isMobile={isMobile} />
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <ReactPlayer
          ref={playerRef}
          url={learnWithUsData.videoPlayerImageAndVideo.video}
          controls={true}
          width={"100%"}
        />
      </Modal>
    </NSLearnWithUsDesktopStyle.Container>
  );
}

export default LearnWithUsDesktop;
