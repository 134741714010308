import colors from "../../style/colors";
import { Badge } from "../Badge/Badge";
import { BlockHeaderTitle } from "../BlockHeaderTitle/BlockHeaderTitle";

import { NSIconListCardStyle } from "./IconListCard.style";
import { NSIconListCardType } from "./IconListCard.types";
import { IconCardHelper } from "./IconCardHelper";
import { useState } from "react";
import { ReactComponent as Add } from "../../assets/icons/add.svg";

export const IconListCard = ({
  isMobile,
  iconList,
  title,
  description,
  buttonTitle,
  onClick,
  badge,
}: NSIconListCardType.IIconListCard) => {
  const [selectedItem, setSelectedItem] =
    useState<NSIconListCardType.IIconItem | null>();
  const renderIcons = () => {
    if (isMobile) {
      return (
        <NSIconListCardStyle.MobileGrid>
          {renderItemsMobile()}
        </NSIconListCardStyle.MobileGrid>
      );
    } else {
      return (
        <NSIconListCardStyle.DesktopGrid>
          {renderItemsDesktop()}
        </NSIconListCardStyle.DesktopGrid>
      );
    }
  };

  const renderItemsDesktop = () =>
    iconList.map((icon) => (
      <IconCardHelper
        icon={icon.icon}
        description={icon.description}
        title={icon.title}
        key={icon.title}
      />
    ));

  const itemSelectionHandler = (icon: NSIconListCardType.IIconItem) => {
    if (icon === selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(icon);
    }
  };

  const renderItemsMobile = () =>
    iconList.map((icon) => (
      <NSIconListCardStyle.MobileCard
        onClick={() => {
          itemSelectionHandler(icon);
        }}
      >
        <NSIconListCardStyle.IconTitle>
          {icon.title}
          <Add stroke={colors.blue.blue01} />
        </NSIconListCardStyle.IconTitle>
        <NSIconListCardStyle.DescriptionMobile isOpen={selectedItem === icon}>
          {icon.description}
        </NSIconListCardStyle.DescriptionMobile>
      </NSIconListCardStyle.MobileCard>
    ));

  return (
    <NSIconListCardStyle.Container isMobile={isMobile}>
      <NSIconListCardStyle.Title isMobile={isMobile}>
        {title}
      </NSIconListCardStyle.Title>
      <NSIconListCardStyle.Description isMobile={isMobile}>
        {description}
      </NSIconListCardStyle.Description>
      <BlockHeaderTitle
        isMobile={isMobile}
        title={buttonTitle}
        backgroundColor={colors.blue.blue01}
        titleColor={colors.dark.dark}
        onClick={onClick}
      />
      <Badge {...badge} />
      {renderIcons()}
    </NSIconListCardStyle.Container>
  );
};
