/* c8 ignore start */
const colors = {
  dark: {
    dark: "#0A1117",
  },

  light: {
    light: "#FFFFFF",
  },
  background: {
    bg: "#e6e9ec",
  },
  green: {
    green01: "#41CC60",
    green02: "#44A058",
    green03: "#8CD490",
    green04: "#44A0583D",
  },
  blue: {
    blue01: "#4AA3E2",
    blue02: "#346DC0",
    blue03: "#A6DCDE",
    blue04: "#4697d2",
    blue05: "#346DC03D",
    blue06: "#2E61AC",
  },
  yellow: {
    yellow01: "#FFBD3D",
    yellow02: "#F4AA00",
    yellow03: "#E89900",
    yellow04: "#EE9D003D",
  },
  orange: {
    orange01: "#FF9C40",
    orange02: "#F56A3D",
    orange03: "#DB5450",
    orange04: "#F56A3D3D",
  },

  neutrals: {
    grey01: "#F3F5F7",
    grey02: "#EDEFF1",
    grey03: "#DBE1E6",
    grey04: "#C3CCD6",
    grey05: "#94A3B4",
    grey06: "#7C8FA4",
    grey07: "#415364",
    grey08: "#202B37",
    grey09: "#12171C",
  },

  shadesOfdark: {
    dark80: "rgba(10, 17, 23, 0.8)",
    dark64: "rgba(10, 17, 23, 0.64)",
    dark48: "rgba(10, 17, 23, 0.48)",
    dark32: "rgba(10, 17, 23, 0.32)",
    dark16: "rgba(10, 17, 23, 0.16)",
    dark08: "rgba(10, 17, 23, 0.08)",
    dark04: "rgba(10, 17, 23, 0.04)",
  },

  shadesOflight: {
    light80: "rgba(255, 255, 255, 0.8)",
    light64: "rgba(255, 255, 255, 0.64)",
    light48: "rgba(255, 255, 255, 0.48)",
    light32: "rgba(255, 255, 255, 0.32)",
    light16: "rgba(255, 255, 255, 0.16)",
    light08: "rgba(255, 255, 255, 0.08)",
    light04: "rgba(255, 255, 255, 0.04)",
  },

  error: {
    error01: "#E13E5B",
    error02: "#92242B",
    error03: "#FBE7E8",
  },

  warning: {
    warning01: "#EFB340",
    warning02: "#BF810A",
    warning03: "#FFF8E4",
  },

  success: {
    success01: "#07BA39",
    success02: "#06511B",
    success03: "#EBFFF0",
  },

  information: {
    information01: "#0079E9",
    information02: "#003E78",
    information03: "#E9F4FF",
  },

  gradients: {
    gradientsCars: {
      secondary: "hsla(234, 70%, 50%, 0)",
      primary: "hsla(240, 75%, 15%, 1)",
    },
    gradientsCirclePrimary: {
      primary: "hsla(234, 70%, 50%, 1)",
      grey02: "hsla(210, 6%, 94%, 0)",
    },
    gradientsCircleTeriary: {
      tertiary: "hsla(133, 64%, 64%, 1)",
      grey02: "hsla(210, 6%, 94%, 0)",
    },
  },
};

export default colors;
/* c8 ignore stop */
