import { useState } from "react";
import { NSSwitchButtonStyle } from "./SwitchButton.style";
import { NSSwitchButtonType } from "./SwitchButton.types";
import { Button } from "../Button/Button";
import colors from "../../style/colors";

export const SwitchButton = ({
  firstButton,
  secondButton,
  defaultSelectedButton,
}: NSSwitchButtonType.ISwitchButton) => {
  const [activeButton, setActiveButton] = useState<"first" | "second">(
    defaultSelectedButton
  );

  const handleToggle = (button: "first" | "second") => {
    setActiveButton(button);
  };

  const commonButtonProps = (button: "first" | "second") => {
    return {
      backgroundColor:
        activeButton === button
          ? colors.yellow.yellow02
          : colors.neutrals.grey01,
      color:
        activeButton === button ? colors.light.light : colors.neutrals.grey05,
      borderRadius: 12,
      height: 48,
      fontWeight: 500,
    };
  };

  return (
    <NSSwitchButtonStyle.Container>
      <Button
        {...firstButton}
        {...commonButtonProps("first")}
        onClick={() => {
          firstButton?.onClick?.();
          handleToggle("first");
        }}
      />
      <Button
        {...secondButton}
        {...commonButtonProps("second")}
        onClick={() => {
          secondButton?.onClick?.();
          handleToggle("second");
        }}
      />
    </NSSwitchButtonStyle.Container>
  );
};
