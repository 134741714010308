import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import { NSImageCarouselStyle } from "./ImageCarousel.style";
import { NSImageCarouselType } from "./ImageCarousel.types";
import { useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import "swiper/css";
import "swiper/css/scrollbar";
import colors from "../../style/colors";
import { NSVideoPlayerCarouselStyle } from "../VideoPlayerCarousel/VideoPlayerCarousel.style";
import { Modal } from "../Modal/Modal";

export const ImageCarousel = ({
  isMobile,
  imageList,
  imageFit = "cover",
  displayImagePreview,
}: NSImageCarouselType.IImageCarousel) => {
  const sliderRef = useRef<SwiperRef>(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] =
    useState<NSImageCarouselType.IImageCarouselItem>(imageList[0]);
  const currentIndex = imageList?.indexOf(selectedImage);
  const nextImageHandler = () => {
    if (currentIndex !== imageList.length - 1) {
      setSelectedImage(imageList[currentIndex + 1]);
    }
  };
  const previousImageHandler = () => {
    if (currentIndex !== 0) {
      setSelectedImage(imageList[currentIndex + -1]);
    }
  };

  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  const slideClickedHandler = (
    image: NSImageCarouselType.IImageCarouselItem
  ) => {
    if (displayImagePreview) {
      setSelectedImage(image);
      setIsOpen(true);
    }
  };

  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };
  return (
    <NSImageCarouselStyle.Container isMobile={isMobile}>
      {!isMobile && (
        <NSImageCarouselStyle.NavigationButton onClick={handlePrev}>
          <ArrowLeft fill={colors.dark.dark} />
        </NSImageCarouselStyle.NavigationButton>
      )}
      <Swiper
        modules={[Navigation, Scrollbar]}
        spaceBetween={isMobile ? 8 : 20}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        ref={sliderRef}
      >
        {imageList?.map((item) => (
          <SwiperSlide
            key={item.image}
            onClick={() => slideClickedHandler(item)}
          >
            <NSImageCarouselStyle.SlideContainer
              displayImagePreview={displayImagePreview}
            >
              <NSImageCarouselStyle.SlideImage
                imageFit={imageFit}
                isMobile={isMobile}
                src={item.thumbnail ? item.thumbnail : item.image}
              />
              {displayImagePreview && (
                <NSImageCarouselStyle.HoverOrb>
                  <Add height={32} width={32} />
                </NSImageCarouselStyle.HoverOrb>
              )}
            </NSImageCarouselStyle.SlideContainer>
            {item.title && (
              <NSImageCarouselStyle.TitleContainer isMobile={isMobile}>
                {item.title}
              </NSImageCarouselStyle.TitleContainer>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {!isMobile && (
        <NSImageCarouselStyle.NavigationButton onClick={handleNext}>
          <ArrowRight fill={colors.dark.dark} />
        </NSImageCarouselStyle.NavigationButton>
      )}
      <Modal
        isOpen={modalIsOpen}
        isMobile={isMobile}
        onCloseHandler={onRequestCloseHandler}
      >
        <NSVideoPlayerCarouselStyle.ModalContentContainer isMobile={isMobile}>
          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}

          <NSImageCarouselStyle.DisplayedImage
            src={selectedImage.image}
            isMobile={isMobile}
          />

          {!isMobile && (
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          )}
        </NSVideoPlayerCarouselStyle.ModalContentContainer>
        <NSImageCarouselStyle.ImageNumber isMobile={isMobile}>
          {currentIndex + 1}/{imageList.length}
        </NSImageCarouselStyle.ImageNumber>
        {isMobile && (
          <NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              onClick={previousImageHandler}
              isClickable={currentIndex !== 0}
            >
              <ArrowLeft fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
            <NSVideoPlayerCarouselStyle.ModalNavigationButton
              isClickable={currentIndex !== imageList.length - 1}
              onClick={nextImageHandler}
            >
              <ArrowRight fill={colors.neutrals.grey04} />
            </NSVideoPlayerCarouselStyle.ModalNavigationButton>
          </NSVideoPlayerCarouselStyle.ModalNavigationMobileContainer>
        )}
      </Modal>
    </NSImageCarouselStyle.Container>
  );
};
