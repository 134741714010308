import { Swiper, SwiperSlide } from "swiper/react";
import { NSBrandImagesStyle } from "./BrandImages.style";
import { NSBrandImagesType } from "./BrandImages.types";
import { Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { NSRolloverImageGalleryStyle } from "../RolloverImageGallery/RolloverImageGallery.style";

export const BrandImages = ({
  logoList,
  isMobile,
}: NSBrandImagesType.IBrandImages) => {
  const renderLogos = () => {
    return (
      <NSBrandImagesStyle.Container>
        {logoList.map((logo) => (
          <a href={logo.link} target="_blank" key={logo.link} rel="noreferrer">
            <NSBrandImagesStyle.LogoImage
              src={logo.image}
              key={logo.image}
              alt={logo.image}
            />
          </a>
        ))}
      </NSBrandImagesStyle.Container>
    );
  };

  const renderDecider = () => {
    if (isMobile) {
      return (
        <NSRolloverImageGalleryStyle.SwiperContainer>
          <Swiper
            modules={[Scrollbar]}
            breakpoints={breakpointsHelper}
            scrollbar={{ draggable: true }}
          >
            {logoList.map((logo) => (
              <SwiperSlide>
                <a
                  href={logo.link}
                  target="_blank"
                  key={logo.link}
                  rel="noreferrer"
                >
                  <NSBrandImagesStyle.LogoImage
                    src={logo.image}
                    key={logo.image}
                    alt={logo.image}
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </NSRolloverImageGalleryStyle.SwiperContainer>
      );
    } else {
      return renderLogos();
    }
  };

  const breakpointsHelper = {
    220: {
      slidesPerView: 2,
    },
    360: {
      slidesPerView: 3,
    },
    560: {
      slidesPerView: 4,
    },
    680: {
      slidesPerView: 5,
    },
    800: {
      slidesPerView: 6,
    },
    900: {
      slidesPerView: 7,
    },
    1040: {
      slidesPerView: 8,
    },
    1240: {
      slidesPerView: 9,
    },
  };

  return <div>{renderDecider()}</div>;
};
