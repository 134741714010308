import { useState, useEffect } from "react";

const useDeviceType = (): { isMobile: boolean; isTablet: boolean } => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.outerWidth <= 768; // Adjust the breakpoint as per your requirements
      const isTabletDevice =
        window.innerWidth <= 1248 && window.innerWidth > 768;

      setIsTablet(isTabletDevice);
      setIsMobile(isMobileDevice);
    };

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile, isTablet };
};

export default useDeviceType;
