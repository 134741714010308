import { z } from "zod";
type RegisterSchemaType = z.infer<typeof NSRegisterPageType.registerSchema>;

export namespace NSRegisterPageType {
  export type IRegisterPage = {
    registerPageData: IRegisterPageData;
  };

  export type IRegisterPageData = {
    registerSchema: typeof registerSchema;
    onSubmitRegister: (data: RegisterSchemaType) => void;
  };

  export const registerSchema = z
    .object({
      name: z.string().min(1, "Ad zorunlu"),
      surname: z.string().min(1, "Soyad zorunlu"),
      email: z.string().min(1, "E-posta zorunlu").email("Geçersiz E-posta"),
      password: z
        .string()
        .min(1, "Şifre zorunlu")
        .min(8, "Şifren en az 8 karakterden oluşmalı")
        .regex(new RegExp(".*[A-Z].*"), "En az 1 adet büyük harf içermeli")
        .regex(new RegExp(".*[a-z].*"), "En az 1 adet küçük harf içermeli")
        .regex(new RegExp(".*\\d.*"), "En az 1 adet rakam içermeli")
        .regex(
          new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
          "En az 1 özel karakter içermeli"
        ),
      confirmPassword: z.string().min(1, "Şifre tekrarı zorunlu"),
      kvkk: z.boolean().refine((value) => value === true, {
        message: "KVKK onaylanmalı",
      }),
      termsOfUse: z.boolean().refine((value) => value === true, {
        message: "Kullanim kosullari onaylanmalı",
      }),
      referenceCommitment: z.boolean().refine((value) => value === true, {
        message: "Referans taahhüdü onaylanmalı",
      }),
    })

    .refine((data) => data.password === data.confirmPassword, {
      path: ["confirmPassword"],
      message: "Şifreniz eşleşmiyor",
    });
}
