import styled, { css } from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";
import { ReactComponent as Arrow1 } from "../../assets/icons/arrow1.svg";
import { ReactComponent as Arrow2 } from "../../assets/icons/arrow2.svg";
import { ReactComponent as Arrow3 } from "../../assets/icons/arrow3.svg";

export namespace NSArrowNumberedListStyle {
  export const Container = styled.div<{}>`
    display: inline-flex;

    flex-direction: column;
    width: 360px;
    height: 226px;
    align-items: flex-start;
    padding: 32px;
    background: ${colors.yellow.yellow01};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
    text-align: center;
    border-radius: 32px;
    box-sizing: border-box;
    gap: 8px;
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    position: relative;
    ${() =>
      css`
        &:nth-child(2) {
          margin-top: 24px;
        }
        &:nth-child(3) {
          margin-top: 48px;
        }
        &:nth-child(4) {
          margin-top: -48px;
        }
        &:nth-child(5) {
          margin-top: -24px;
        }
      `}
  `;
  export const IconContainer = styled.div`
    min-height: 64px;
    min-width: 64px;
    background-color: ${colors.orange.orange02};
    color: ${colors.yellow.yellow01};
    font-size: ${typography.pharagraph1_5.size};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const Title = styled.div`
    font-size: ${typography.pharagraph1_5.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
  `;

  export const Description = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    text-align: left;
  `;

  export const MainContainer = styled.div<{
    isMobile: boolean;
  }>`
    ${({ isMobile }) =>
      isMobile &&
      css`
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    ${({ isMobile }) =>
      !isMobile &&
      css`
        display: inline-grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        align-self: center;
      `}
  `;
  export const StyledFirstArrow = styled(Arrow1)`
    position: absolute;
    z-index: 1;
    right: -48px;
    top: 0px;
  `;
  export const StyledSecondArrow = styled(Arrow2)`
    position: absolute;
    z-index: 1;
    right: -48px;
    top: -24px;
  `;
  export const StyledThirdArrow = styled(Arrow3)`
    position: absolute;
    z-index: 1;
    right: -48px;
    bottom: -24px;
  `;
}
