import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSPositionDetailPageDesktopStyle {
  export const Container = styled.div``;

  export const HeaderContainer = styled.div`
    position: relative;
    overflow: hidden;
  `;

  export const MainContainer = styled.div`
    padding: 40px 84px 107px 84px;
    overflow: hidden;
    background-color: ${colors.background.bg};
    position: relative;
    display: flex;
    flex-direction: column;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 40px;
    }
    @media (max-width: 1200px) {
      padding: 84px 64px;
      padding-bottom: 40px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 40px;
    }
  `;

  export const Content = styled.div`
    display: flex;
    flex-dierction: row;
    gap: 32px;
  `;

  export const SideBySideContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `;
}
