import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import { NSRoundedCardDescriptionCarouselStyle } from "./RoundedCardDescriptionCarousel.style";
import { NSRoundedCardDescriptionCarouselType } from "./RoundedCardDescriptionCarousel.types";
import { useRef } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import colors from "../../style/colors";
import "swiper/css";
import "swiper/css/scrollbar";
import { RoundedCardWithDescription } from "../RoundedCardWithDescription/RoundedCardWithDescription";
import { MobileSwiperHelper } from "../MobileSwiperHelper/MobileSwiperHelper";

export const RoundedCardDescriptionCarousel = ({
  isMobile,
  cardList,
}: NSRoundedCardDescriptionCarouselType.IRoundedCardDescriptionCarousel) => {
  const sliderRef = useRef<SwiperRef>(null);

  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  const renderCarousel = () => {
    if (isMobile) {
      return (
        <MobileSwiperHelper
          childrenList={cardList.map((card, index) => (
            <RoundedCardWithDescription
              {...card}
              isMobile={isMobile}
              key={index}
            />
          ))}
        />
      );
    } else {
      return (
        <NSRoundedCardDescriptionCarouselStyle.Container isMobile={isMobile}>
          {!isMobile && (
            <NSRoundedCardDescriptionCarouselStyle.NavigationButton
              onClick={handlePrev}
            >
              <ArrowLeft fill={colors.dark.dark} />
            </NSRoundedCardDescriptionCarouselStyle.NavigationButton>
          )}
          <Swiper
            modules={[Navigation, Scrollbar]}
            spaceBetween={isMobile ? 8 : 20}
            slidesPerView={"auto"}
            scrollbar={{ draggable: true }}
            ref={sliderRef}
          >
            {cardList.map((card, index) => (
              <SwiperSlide key={index}>
                <RoundedCardWithDescription {...card} isMobile={isMobile} />
              </SwiperSlide>
            ))}
          </Swiper>

          {!isMobile && (
            <NSRoundedCardDescriptionCarouselStyle.NavigationButton
              onClick={handleNext}
            >
              <ArrowRight fill={colors.dark.dark} />
            </NSRoundedCardDescriptionCarouselStyle.NavigationButton>
          )}
        </NSRoundedCardDescriptionCarouselStyle.Container>
      );
    }
  };

  return renderCarousel();
};
