import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSQuotationCardStyle {
  export const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${(props) => (props.isMobile ? "32px" : "72px 64px")};
    background: ${(props) =>
      props.isMobile
        ? "linear-gradient(135deg, #001F29 0%, #2A82B0 52.08%, #001F29 100%)"
        : "linear-gradient(95.64deg,#001f29 0%,#2a82b0 48.96%,#001f29 101.04%)"};

    font-family: "ReadexPro";
    color: ${colors.light.light};
    text-align: center;
    border-radius: 32px;
  `;

  export const Title = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.medium};
    font-size: ${(props) =>
      props.isMobile ? typography.titleh5.size : typography.titleh3.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.titleh5.lineHeight
        : typography.titleh3.lineHeight};
    margin-bottom: 32px;
  `;
  export const SubTitle = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.bold};
    font-family: ${typography.fontFamily.bold};
    font-size: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.size
        : typography.pharagraph1.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.pharagraph2.lineHeight
        : typography.pharagraph1.lineHeight};
    margin-bottom: 4px;
  `;
  export const SecondSubTitle = styled.div<{ isMobile: boolean }>`
    font-weight: ${typography.fontWeights.medium};
    font-size: ${(props) =>
      props.isMobile
        ? typography.smallText1.size
        : typography.pharagraph2.size};
    line-height: ${(props) =>
      props.isMobile
        ? typography.smallText1.lineHeight
        : typography.pharagraph2.lineHeight};
  `;
}
